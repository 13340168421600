// import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import DataTable from "react-data-table-component";
// import { DownOutlined } from "@ant-design/icons";
// import { Dropdown, Menu } from "antd";
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css"; // optional
// function CustomTable({
//   rowData,
//   routeUrl,
//   currentPage,
//   pageSize,
//   handlePageChange,
//   handlePageSizeChange,
//   totalDocuments,
// }) {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const navigate = useNavigate();
//   const [skin, setSkin] = useState(currentSkin);
// console.log(rowData,'===============')
//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");

//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );

//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   switchSkin(skin);
//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   const handleMenuClick = (e, record) => {
//     console.log("Click on menu item:", e, "for record:", record);
//   };

//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };
//   const menu = (record) => {
//     return (
//       <Menu onClick={(e) => handleMenuClick(e, record)}>
//         {record?.ipAddress?.map((v, index) => (
//           <Menu.Item key={index}>{v}</Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   const menuadmin = (record) => {
//     return (
//       <Menu onClick={(e) => handleMenuClick(e, record)}>
//         {record?.adminAccountsName?.map((v, index) => (
//           <Menu.Item key={index}>{v}</Menu.Item>
//         ))}
//       </Menu>
//     );
//   }
//   const columns = React.useMemo(
//     () => [
//       {
//         name: "S. No.",
//         selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
//         width: "70px",
//       },
//       {
//         name: "Hostname",
//         selector: (row) => row.hostname,
//         sortable: true,
//       },

//       {
//         name: "Serial Name",
//         selector: (row) =>
//         <Tippy content={row.serialNumber}>
//         <div>{row.serialNumber}</div>
//       </Tippy>
//         ,
//         sortable: true,
//       },
//       {
//         name: "IP Address",
//         selector: (row) => row.ipAddress, // This targets the IP address
//         cell: (row) => (
//           <Dropdown overlay={menu(row)} trigger={["click"]}>
//             <Button className="bg-transparent border-0 p-0 m-0">
//               {row.ipAddress || "NA"} <DownOutlined />
//             </Button>
//           </Dropdown>
//         ),
//         sortable: true,
//       },
//       {
//         name: "Admin Accounts Name",
//         selector: (row) => row.adminAccountsName, // This targets the admin accounts name array
//         cell: (row) => (
//           <Dropdown overlay={menuadmin(row)} trigger={["click"]}>
//             <Button className="bg-transparent border-0 p-0 m-0">
//               {row.adminAccountsName?.length > 0
//                 ? row.adminAccountsName.join(", ")
//                 : "NA"}
//               <DownOutlined />
//             </Button>
//           </Dropdown>
//         ),
//         sortable: true,
//       },

//       {
//         name: "Client Score",
//         selector: (row) =>

//         <Tippy content={row.clientScore}>
//         <div>{row.clientScore}</div>
//       </Tippy>,
//         sortable: true,
//       },
//       {
//         name: "Sync time",
//         selector: (row) =>

//         <Tippy content={formatTimestamp(row.updatedAt)}>
//         <div>{formatTimestamp(row.updatedAt)}</div>
//       </Tippy>,
//         sortable: true,
//       },

//       {
//         name: "Action",
//         cell: (row) => (
//           <Button
//             className="btn rounded-md w-100 border-0 d-flex align-items-center fs10 justify-content-center gap-2 mx-auto px-2 text-light bg-dark"
//             onClick={() =>
//               navigate(routeUrl, {
//                 state: {
//                   basicInfo: row,
//                   pcInfoId: row?.pc_Id,
//                 },
//               })
//             }
//           >
//             <p className="m-0 p-0 fs-12">View Details</p>
//             <i className="fas fa-long-arrow-alt-right ps-4"></i>
//           </Button>
//         ),
//         ignoreRowClick: true,
//         allowoverflow: false,
//       },
//     ],
//     [currentPage, pageSize, navigate, routeUrl]
//   );

//   return (
//     <DataTable
//       columns={columns}
//       data={rowData || []}
//       pagination
//       paginationServer
//       paginationTotalRows={totalDocuments}
//       paginationDefaultPage={currentPage}
//       paginationPerPage={pageSize}
//       onChangePage={(page) => {
//         console.log("Page", page);
//         handlePageChange(page);
//       }}
//       onChangeRowsPerPage={(newPageSize) => {
//         console.log("Page Size", newPageSize);
//         handlePageSizeChange(newPageSize);
//       }}
//       noDataComponent={<p className="p-0 my-2 me-auto">No Data Found</p>}
//     />
//   );
// }

// export default CustomTable;
// import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import DataTable from "react-data-table-component";
// import { DownOutlined } from "@ant-design/icons";
// import { Dropdown, Menu } from "antd";
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css"; // optional

// function CustomTable({
//   rowData,
//   routeUrl,
//   currentPage,
//   pageSize,
//   handlePageChange,
//   handlePageSizeChange,
//   totalDocuments,
// }) {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const navigate = useNavigate();
//   const [skin, setSkin] = useState(currentSkin);

//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");

//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );

//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   switchSkin(skin);
//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   const handleMenuClick = (e, record) => {
//     console.log("Click on menu item:", e, "for record:", record);
//   };

//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };

//   const menu = (record) => {
//     return (
//       <Menu onClick={(e) => handleMenuClick(e, record)}>
//         {record?.ipAddress?.map((v, index) => (
//           <Menu.Item key={index}>{v}</Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   const menuadmin = (record) => {
//     return (
//       <Menu onClick={(e) => handleMenuClick(e, record)}>
//         {record?.adminAccountsName?.map((v, index) => (
//           <Menu.Item key={index}>{v}</Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   const columns = React.useMemo(() => {
//     const baseColumns = [
//       {
//         name: "S. No.",
//         selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
//         width: "90px",
//       },
//       {
//         name: "Hostname",
//         selector: (row) => row.hostname,
//         sortable: true,
//       },
//       {
//         name: "Serial Name",
//         selector: (row) => (
//           <Tippy content={row.serialNumber}>
//             <div>{row.serialNumber}</div>
//           </Tippy>
//         ),
//         sortable: true,
//       },
//       {
//         name: "IP Address",
//         selector: (row) => row.ipAddress, // This targets the IP address
//         cell: (row) => (
//           <Dropdown overlay={menu(row)} trigger={["click"]}>
//             <Button className="bg-transparent border-0 p-0 m-0">
//               {row.ipAddress || "NA"} <DownOutlined />
//             </Button>
//           </Dropdown>
//         ),
//         sortable: true,
//       },
//       {
//         name: "Client Score",
//         selector: (row) => (
//           <Tippy content={row.clientScore}>
//             <div>{row.clientScore}</div>
//           </Tippy>
//         ),
//         sortable: true,
//       },
//       {
//         name: "Sync time",
//         selector: (row) => (
//           <Tippy content={formatTimestamp(row.updatedAt)}>
//             <div>{formatTimestamp(row.updatedAt)}</div>
//           </Tippy>
//         ),
//         sortable: true,
//       },
//       {
//         name: "Action",
//         cell: (row) => (
//           <Button
//             className="btn rounded-md w-100 border-0 d-flex align-items-center fs10 justify-content-center gap-2 mx-auto px-2 text-light bg-dark"
//             onClick={() =>
//               navigate(routeUrl, {
//                 state: {
//                   basicInfo: row,
//                   pcInfoId: row?.pc_Id,
//                 },
//               })
//             }
//           >
//             <p className="m-0 p-0 fs-12">View Details</p>
//             <i className="fas fa-long-arrow-alt-right ps-4"></i>
//           </Button>
//         ),
//         ignoreRowClick: true,
//         allowoverflow: false,
//       },
//     ];

//     // Conditionally add the "Admin Accounts Name" column if it's present
//     if (rowData?.some((row) => row.adminAccountsName)) {
//       baseColumns.splice(4, 0, {
//         name: "Admin Accounts Name",
//         selector: (row) => row.adminAccountsName,
//         cell: (row) => (
//           <Dropdown overlay={menuadmin(row)} trigger={["click"]}>
//             <Button className="bg-transparent border-0 p-0 m-0">
//               {row.adminAccountsName?.length > 0
//                 ? row.adminAccountsName.join(", ")
//                 : "NA"}
//               <DownOutlined />
//             </Button>
//           </Dropdown>
//         ),
//         sortable: true,
//       });
//     }

//     return baseColumns;
//   }, [currentPage, pageSize, navigate, routeUrl, rowData]);

//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#cccccc",
//       },
//     },
//   };
//   return (
//     <DataTable
//       columns={columns}
//       data={rowData || []}
//       pagination
//       customStyles={customStyles}
//       paginationServer
//       paginationTotalRows={totalDocuments}
//       paginationDefaultPage={currentPage}
//       paginationPerPage={pageSize}
//       onChangePage={(page) => {
//         console.log("Page", page);
//         handlePageChange(page);
//       }}
//       onChangeRowsPerPage={(newPageSize) => {
//         console.log("Page Size", newPageSize);
//         handlePageSizeChange(newPageSize);
//       }}
//       noDataComponent={<p className="p-0 my-2 me-auto">No Data Found</p>}
//     />
//   );
// }

// export default CustomTable;

// import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import DataTable from "react-data-table-component";
// import { DownOutlined } from "@ant-design/icons";
// import { Dropdown, Menu } from "antd";
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css"; // optional

// function CustomTable({
//   rowData,
//   routeUrl,
//   currentPage,
//   pageSize,
//   handlePageChange,
//   handlePageSizeChange,
//   totalDocuments,
// }) {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const navigate = useNavigate();
//   const [skin, setSkin] = useState(currentSkin);

//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");

//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );

//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   switchSkin(skin);
//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   const handleMenuClick = (e, record) => {
//     console.log("Click on menu item:", e, "for record:", record);
//   };

//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };

//   const menu = (record) => {
//     return (
//       <Menu onClick={(e) => handleMenuClick(e, record)}>
//         {record?.ipAddress?.map((v, index) => (
//           <Menu.Item key={index}>{v}</Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   const menuadmin = (record) => {
//     return (
//       <Menu onClick={(e) => handleMenuClick(e, record)}>
//         {record?.adminAccountsName?.map((v, index) => (
//           <Menu.Item key={index}>{v}</Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   const sharedDirectoriesMenu = (directories) => {
//     return (
//       <Menu>
//         {directories?.map((dir, index) => (
//           <Menu.Item key={index}>
//             <Tippy content={`Path: ${dir.path}`}>
//               <div>
//                 {dir.name}
//                 <br />
//                 <small>
//                   Owner: {dir.permission.owner || "N/A"}, Group:{" "}
//                   {dir.permission.group || "N/A"}, Other:{" "}
//                   {dir.permission.other || "N/A"}
//                 </small>
//               </div>
//             </Tippy>
//           </Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   const columns = React.useMemo(() => {
//     const baseColumns = [
//       {
//         name: "S. No.",
//         selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
//         width: "90px",
//       },
//       {
//         name: "Hostname",
//         selector: (row) => row.hostname,
//         sortable: true,
//       },
//       {
//         name: "Serial Name",
//         selector: (row) => (
//           <Tippy content={row.serialNumber}>
//             <div>{row.serialNumber}</div>
//           </Tippy>
//         ),
//         sortable: true,
//       },
//       {
//         name: "IP Address",
//         selector: (row) => row.ipAddress, // This targets the IP address
//         cell: (row) => (
//           <Dropdown overlay={menu(row)} trigger={["click"]}>
//             <Button className="bg-transparent border-0 p-0 m-0">
//               {row.ipAddress || "NA"} <DownOutlined />
//             </Button>
//           </Dropdown>
//         ),
//         sortable: true,
//       },
//       {
//         name: "Client Score",
//         selector: (row) => (
//           <Tippy content={row.clientScore}>
//             <div>{row.clientScore}</div>
//           </Tippy>
//         ),
//         sortable: true,
//       },
//       {
//         name: "Sync time",
//         selector: (row) => (
//           <Tippy content={formatTimestamp(row.updatedAt)}>
//             <div>{formatTimestamp(row.updatedAt)}</div>
//           </Tippy>
//         ),
//         sortable: true,
//       },
//       {
//         name: "Action",
//         cell: (row) => (
//           <Button
//             className="btn rounded-md w-100 border-0 d-flex align-items-center fs10 justify-content-center gap-2 mx-auto px-2 text-light bg-dark"
//             onClick={() =>
//               navigate(routeUrl, {
//                 state: {
//                   basicInfo: row,
//                   pcInfoId: row?.pc_Id,
//                 },
//               })
//             }
//           >
//             <p className="m-0 p-0 fs-12">View Details</p>
//             <i className="fas fa-long-arrow-alt-right ps-4"></i>
//           </Button>
//         ),
//         ignoreRowClick: true,
//         allowoverflow: false,
//       },
//     ];

//     // Conditionally add the "Admin Accounts Name" column if it's present
//     if (rowData?.some((row) => row.adminAccountsName)) {
//       baseColumns.splice(4, 0, {
//         name: "Admin Accounts Name",
//         selector: (row) => row.adminAccountsName,
//         cell: (row) => (
//           <Dropdown overlay={menuadmin(row)} trigger={["click"]}>
//             <Button className="bg-transparent border-0 p-0 m-0">
//               {row.adminAccountsName?.length > 0
//                 ? row.adminAccountsName.join(", ")
//                 : "NA"}
//               <DownOutlined />
//             </Button>
//           </Dropdown>
//         ),
//         sortable: true,
//       });
//     }

//     // Conditionally add the "Shared Directories" column if it's present
//     if (rowData?.some((row) => row.sharedDirectories)) {
//       baseColumns.push({
//         name: "Shared Directories",
//         selector: (row) => row.sharedDirectories,
//         cell: (row) => (
//           <Dropdown
//             overlay={sharedDirectoriesMenu(row.sharedDirectories)}
//             trigger={["click"]}
//           >
//             <Button className="bg-transparent border-0 p-0 m-0">
//               {row.sharedDirectories?.length > 0
//                 ? row.sharedDirectories.map((dir) => dir.name).join(", ")
//                 : "N/A"}{" "}
//               <DownOutlined />
//             </Button>
//           </Dropdown>
//         ),
//         sortable: true,
//       });
//     }

//     return baseColumns;
//   }, [currentPage, pageSize, navigate, routeUrl, rowData]);

//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#cccccc",
//       },
//     },
//   };
//   return (
//     <DataTable
//       columns={columns}
//       data={rowData || []}
//       pagination
//       customStyles={customStyles}
//       paginationServer
//       paginationTotalRows={totalDocuments}
//       paginationDefaultPage={currentPage}
//       paginationPerPage={pageSize}
//       onChangePage={(page) => {
//         console.log("Page", page);
//         handlePageChange(page);
//       }}
//       onChangeRowsPerPage={(newPageSize) => {
//         console.log("Page Size", newPageSize);
//         handlePageSizeChange(newPageSize);
//       }}
//       noDataComponent={<p className="p-0 my-2 me-auto">No Data Found</p>}
//     />
//   );
// }

// export default CustomTable;

import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import Excellogo from "../../../assets/img/Excellogo.png";
import { height } from "@mui/system";

function CustomTable({
  rowData,
  routeUrl,
  currentPage,
  pageSize,
  handlePageChange,
  handlePageSizeChange,
  totalDocuments,
  title,
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const navigate = useNavigate();
  const [skin, setSkin] = useState(currentSkin);
  const [modalData, setModalData] = useState(null);
  // const [isModalOpen, setModalOpen] = useState(false);
  const { user } = useSelector((state) => state.authSlice);

  const [modalOpen, setModalOpen] = useState(false); // Modal visibility state

  console.log(modalData, "modalData");
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const handleMenuClick = (e, record) => {
    console.log("Click on menu item:", e, "for record:", record);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const menu = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record?.ipAddress?.map((v, index) => (
          <Menu.Item key={index}>{v}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const menuService = (record) => {
    return (
      <Menu>
        {record?.service?.map((detail, index) => {
          const [key, value] = Object.entries(detail)[0];
          return (
            <Menu.Item key={index}>
              {key}: {value}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };
  const menuadmin = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record?.notInstalledAvs?.map((v, index) => (
          <Menu.Item key={index}>{v}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const menuadminssid = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record?.ssid?.map((v, index) => (
          <Menu.Item key={index}>{v}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const menuadminauthenticationType = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record?.authenticationType?.map((v, index) => (
          <Menu.Item key={index}>{v}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const sharedDirectoriesMenu = (directories) => {
    return (
      <Menu>
        {directories?.map((dir, index) => (
          <Menu.Item key={index}>
            <Tippy content={`Path: ${dir.path}`}>
              <div>
                {dir.name}
                <br />
                <small>
                  Owner: {dir.permission.owner || "N/A"}, Group:{" "}
                  {dir.permission.group || "N/A"}, Other:{" "}
                  {dir.permission.other || "N/A"}
                </small>
              </div>
            </Tippy>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const avDetails = (directories) => {
    return (
      <Menu>
        {directories?.map((dir, index) => (
          <Menu.Item key={index}>
            <Tippy
              content={`Current Version: ${dir.currentAVVersion}, Expected Version: ${dir.expectedAVVersion}`}
            >
              <div>
                {dir.avName}
                <br />
                <small>
                  Current Version: {dir.currentAVVersion || "N/A"}, Expected
                  Version: {dir.expectedAVVersion || "N/A"}
                </small>
              </div>
            </Tippy>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const osDetails = (directories) => {
    return (
      <Menu>
        {directories?.map((dir, index) => (
          <Menu.Item key={index}>
            <Tippy
              content={`Current Version: ${dir.currentOSVersion}, Expected Version: ${dir.expectedOSVersion}`}
            >
              <div>
                {dir.osName}
                <br />
                <small>
                  Current Version: {dir.currentOSVersion || "N/A"}, Expected
                  Version: {dir.expectedOSVersion || "N/A"}
                </small>
              </div>
            </Tippy>
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const userAdminpassword = (directories) => {
    return (
      <Menu>
        {directories?.map((dir, index) => (
          <Menu.Item key={index}>
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active mb-0" aria-current="page">
                  {dir.userType}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {dir.userName}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {dir.passwordAge}
                </li>
              </ol>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  // Initialize variables
  let isAslrEnabled = true;
  let isPrelinkDisabled = true;
  const columns = React.useMemo(() => {
    const baseColumns = [
      {
        name: "S. No.",
        selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
        width: "90px",
      },
      {
        name: "Hostname",
        selector: (row) => (
          <Tippy content={row.hostname}>
            <div>{row.hostname}</div>
          </Tippy>
        ),
        sortable: true,
      },
      {
        name: "Serial No.",
        selector: (row) => (
          <Tippy content={row.serialNumber}>
            <div>{row.serialNumber}</div>
          </Tippy>
        ),
        sortable: true,
        width: "120px",
      },
      {
        name: "IP Address",
        selector: (row) => row.ipAddress, // This targets the IP address
        cell: (row) => {
          const ipArray = row.ipAddress || []; // Ensure it's an array, even if null/undefined
          if (ipArray.length === 1) {
            return <span>{ipArray[0]}</span>; // Render the single IP without dropdown
          } else if (ipArray.length > 1) {
            return (
              <Dropdown overlay={menu(row)} trigger={["click"]}>
                <Button className="bg-transparent border-0 p-0 m-0">
                  {ipArray[0]} <i className="fa-solid fa-angle-down"></i>
                </Button>
              </Dropdown>
            );
          } else {
            return <span>NA</span>; // Render "NA" if no IPs are present
          }
        },
        sortable: true,
        width: "150px",
      },
      // {
      //   name: "Timestamp",
      //   selector: (row) => (
      //     <Tippy content={formatTimestamp(row.updatedAt)}>
      //       <div>{formatTimestamp(row.updatedAt)}</div>
      //     </Tippy>
      //   ),
      //   sortable: true,
      //   width: "160px",
      // },
    ];

    if (rowData?.some((row) => row.updatedAt)) {
      baseColumns.splice(4, 0, {
        name: "Timestamp",
        selector: (row) => formatTimestamp(row.updatedAt),
        sortable: true,
      });
    }
    // Conditionally add the "Admin Accounts Name" column if it's present
    if (rowData?.some((row) => row.adminAccountsName)) {
      baseColumns.splice(4, 0, {
        name: "Admin Accounts",
        selector: (row) =>
          row.adminAccountsName?.length > 0
            ? row.adminAccountsName.join(", ")
            : "NA",
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.highCpuUsageCount)) {
      baseColumns.splice(4, 0, {
        name: "Count",
        selector: (row) => row.highCpuUsageCount,
        sortable: true,
        width: "120px",
        cell: (row) => (
          <span
            onClick={() => handleCountClick(row)}
            style={{
              color: "blue",
              cursor: "pointer",
              // textDecoration: "underline", // Optional: makes it look like a link
            }}
          >
            {row.highCpuUsageCount}
          </span>
        ),
      });
    }

    const handleCountClick = (row) => {
      // console.log("Row clicked:", row); // Log the row object
      if (row.highCpuUsage) {
        console.log("High CPU Usage Data:", row.highCpuUsage); // Log the highCpuUsage data
        setModalData(row.highCpuUsage);
        setModalOpen(true); // Open the modal
      } else {
        console.log("No highCpuUsage data found for this row.");
      }
    };

    const handleCountClickRam = (row) => {
      console.log("Row clicked:", row); // Log the row object
      if (row?.ramUsage) {
        console.log("High RAM Usage Data:", row?.ramUsage); // Log the highCpuUsage data
        setModalData(row?.ramUsage);
        setModalOpen(true);
      } else {
        console.log("No highCpuUsage data found for this row.");
      }
    };

    if (rowData?.some((row) => row.highRamUsageCount)) {
      baseColumns.splice(4, 0, {
        name: "Count",
        selector: (row) => (
          <span
            onClick={() => handleCountClickRam(row)}
            style={{
              color: "blue",
              cursor: "pointer",
              // textDecoration: "underline", // Optional: makes it look like a link
            }}
          >
            {row.highRamUsageCount}
          </span>
        ),
        sortable: true,
        width: "120px",
      });
    }

    if (rowData?.some((row) => row.currentOSVersion)) {
      baseColumns.splice(4, 0, {
        name: "Current Version",
        selector: (row) => row.currentOSVersion,
        sortable: true,
        width: "220px",
      });
    }
    if (rowData?.some((row) => row.expectedOSVersion)) {
      baseColumns.splice(4, 0, {
        name: "Expected Version",
        selector: (row) => row.expectedOSVersion,
        sortable: true,
        width: "220px",
      });
    }
    if (rowData?.some((row) => row.osName)) {
      baseColumns.splice(4, 0, {
        name: "OS Name",
        selector: (row) => row.osName,
        sortable: true,
      });
    }
    if (rowData?.some((row) => row.currentAVVersion)) {
      baseColumns.splice(4, 0, {
        name: "Current Version",
        selector: (row) => row.currentAVVersion,
        sortable: true,
        width: "220px",
      });
    }
    if (rowData?.some((row) => row.expectedAVVersion)) {
      baseColumns.splice(4, 0, {
        name: "Expected Version",
        selector: (row) => row.expectedAVVersion,
        sortable: true,
        width: "220px",
      });
    }
    if (rowData?.some((row) => row.avName)) {
      baseColumns.splice(4, 0, {
        name: "AV Name",
        selector: (row) => row.avName,
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.serverTimestamp)) {
      baseColumns.splice(4, 0, {
        name: "Timestamp",
        selector: (row) => formatTimestamp(row.serverTimestamp),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.service)) {
      baseColumns.splice(4, 0, {
        name: "Services",
        selector: (row) => row.service,
        cell: (row) => {
          const serviceArray = row.service || []; // Ensure it's an array, even if null/undefined
          if (serviceArray.length === 1) {
            // Extract key and value from the single service entry
            const [key, value] = Object.entries(serviceArray[0])[0];
            return (
              <span>
                {key}: {value}
              </span>
            );
          } else if (serviceArray.length > 1) {
            return (
              <Dropdown overlay={menuService(row)} trigger={["click"]}>
                <Button className="bg-transparent border-0 p-0 m-0">
                  {Object.entries(serviceArray[0])[0].join(": ")}{" "}
                  <i className="fa-solid fa-angle-down"></i>
                </Button>
              </Dropdown>
            );
          } else {
            return <span>NA</span>; // Render "NA" if no services are present
          }
        },
        sortable: true,
        width: "220px",
      });
    }

    if (rowData?.some((row) => row.antivirusVersion)) {
      baseColumns.splice(4, 0, {
        name: "Current Version",
        selector: (row) => row.antivirusVersion,
        sortable: true,
        width: "220px",
      });
    }

    if (rowData?.some((row) => row.antivirusName)) {
      baseColumns.splice(4, 0, {
        name: "AV Name",
        selector: (row) => row.antivirusName,
        sortable: true,
      });
    }

    // if (
    //   rowData?.some(
    //     (row) => row.hardeningIssues && typeof row.hardeningIssues === "object"
    //   )
    // ) {
    //   baseColumns.splice(4, 0, {
    //     // Adjust the index as needed
    //     name: "Hardening Issues",
    //     selector: (row) => {
    //       const hardeningIssues = row.hardeningIssues;
    //       if (hardeningIssues) {
    //         return Object.entries(hardeningIssues)
    //           .map(([key, value]) => `${key}: ${value}`)
    //           .join(", ");
    //       }
    //       return "No issues"; // Default message if no hardening issues
    //     },
    //     sortable: false, // Change to true if you want sorting capability
    //     minWidth: "220px", // Set a minimum width
    //     width: "35rem", // Set the desired width
    //     wrap: true, // Allow text wrapping if supported by your table component
    //   });
    // }

    if (
      rowData?.some(
        (row) => row.hardeningIssues && typeof row.hardeningIssues === "object"
      )
    ) {
      baseColumns.splice(4, 0, {
        name: "Hardening Issues",
        selector: (row) => {
          const hardeningIssues = row.hardeningIssues;
          if (hardeningIssues) {
            return Object.entries(hardeningIssues)
              .map(([key, value]) => {
                // Transform key and value for better readability
                const formattedKey = key
                  .replace(/is(.*)Disabled/, '$1') // Remove 'is' and 'Disabled'
                  .replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
                  .trim(); // Trim any extra spaces
                const formattedValue = value ? "Enabled" : "Disabled"; // Map boolean to user-friendly string
                return `${formattedKey}: ${formattedValue}`;
              })
              .join(", ");
          }
          return "No issues"; // Default message if no hardening issues
        },
        sortable: false, // Change to true if you want sorting capability
        minWidth: "220px", // Set a minimum width
        width: "30rem", // Set the desired width
        wrap: true, // Allow text wrapping if supported by your table component
      });
    }
    

    // Conditionally add the "Shared Directories" column if it's present
    if (rowData?.some((row) => row.sharedDirectories)) {
      baseColumns.push({
        name: "Shared Directories",
        selector: (row) => row.sharedDirectories,
        cell: (row) => (
          <Dropdown
            overlay={sharedDirectoriesMenu(row.sharedDirectories)}
            trigger={["click"]}
          >
            <Button className="bg-transparent border-0 p-0 m-0">
              {row.sharedDirectories?.length > 0
                ? row.sharedDirectories.map((dir) => dir.name).join(", ")
                : "N/A"}{" "}
              <DownOutlined />
            </Button>
          </Dropdown>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.avDetails)) {
      baseColumns.push({
        name: "Av Details",
        selector: (row) => row.avDetails,
        cell: (row) => (
          <div overlay={avDetails(row.avDetails)} trigger={["click"]}>
            <Button className="bg-transparent border-0 p-0 m-0">
              {/* {row.avDetails?.length > 0
                ? row.avDetails.map((dir) => dir.avName).join(", ")
                : "N/A"}{" "} */}
              {row.avDetails?.length > 0
                ? row.avDetails
                    .map(
                      (dir) =>
                        `${dir.avName}: Current Version: ${
                          dir.currentAVVersion || "N/A"
                        }, Expected Version: ${dir.expectedAVVersion || "N/A"}`
                    )
                    .join(", ")
                : "N/A"}

              <DownOutlined />
            </Button>
          </div>
        ),
        sortable: true,
        width: "450px",
      });
    }

    if (rowData?.some((row) => row.osDetails)) {
      baseColumns.push({
        name: "Os Details",
        selector: (row) => row.osDetails,
        cell: (row) => (
          <Dropdown overlay={osDetails(row.osDetails)} trigger={["click"]}>
            <Button className="bg-transparent border-0 p-0 m-0">
              {row.osDetails?.length > 0
                ? row.osDetails.map((dir) => dir.osName).join(", ")
                : "N/A"}{" "}
              <DownOutlined />
            </Button>
          </Dropdown>
        ),
        sortable: true,
      });
    }

    // if (rowData?.some((row) => row.usersPasswordAge)) {
    //   baseColumns.push({
    //     name: "Users Password Age",
    //     selector: (row) => row.usersPasswordAge,
    //     cell: (row) => (
    //       <Dropdown
    //         overlay={userAdminpassword(row.usersPasswordAge)}
    //         trigger={["click"]}
    //       >
    //         <Button className="bg-transparent border-0 p-0 m-0">
    //           {row.usersPasswordAge?.length > 0
    //             ? row.usersPasswordAge.map((dir) => dir.userName).join(", ")
    //             : "N/A"}{" "}
    //           <DownOutlined />
    //         </Button>
    //       </Dropdown>
    //     ),
    //     sortable: true,
    //   });
    // }
    // if (rowData?.some((row) => row.usersPasswordAge)) {
    //   baseColumns.push({
    //     name: "Users Password Age",
    //     selector: (row) => row.usersPasswordAge,
    //     cell: (row) => (
    //       <span>
    //         {row.usersPasswordAge?.length > 0
    //           ? row.usersPasswordAge.map((dir) => (
    //               <span key={dir.userName} style={{ marginRight: '5px' }}>
    //                 {`${dir.userType} (${dir.userName}, ${dir.passwordAge})`}
    //               </span>
    //             ))
    //           : "N/A"}
    //       </span>
    //     ),
    //     sortable: true,
    //   });
    // }
    if (rowData?.some((row) => row.usersPasswordAge)) {
      baseColumns.push({
        name: "Users Password Age",
        selector: (row) => row.usersPasswordAge,
        cell: (row) => (
          <span>
            {row.usersPasswordAge?.length > 0
              ? row.usersPasswordAge.map((dir, index) => (
                  <span key={dir.userName}>
                    {`${dir.userName}/${dir.userType}/${dir.passwordAge}`}
                    {index < row.usersPasswordAge.length - 1 && ", "}
                  </span>
                ))
              : "N/A"}
          </span>
        ),
        sortable: true,
      });
    }

    // if (rowData?.some((row) => row.notInstalledAvs)) {
    //   baseColumns.push({
    //     name: "Not Installed",
    //     selector: (row) => row.notInstalledAvs,
    //     cell: (row) => {
    //       let avArray = row.notInstalledAvs?.[0] || []; // Ensure it's an array, even if null/undefined

    //       // Convert to array if it's a single value
    //       if (!Array.isArray(avArray)) {
    //         avArray = [avArray];
    //       }

    //       if (avArray.length === 1) {
    //         return <span>{avArray[0]}</span>; // Render the single item directly
    //       } else if (avArray.length > 1) {
    //         return (
    //           <Dropdown overlay={menuadmin(row)} trigger={["click"]}>
    //             <Button className="bg-transparent border-0 p-0 m-0">
    //               {avArray.join(", ")} <DownOutlined />
    //             </Button>
    //           </Dropdown>
    //         );
    //       } else {
    //         return <span>NA</span>; // Render "NA" if no items are present
    //       }
    //     },
    //     sortable: true,
    //     width: "150px",
    //   });
    // }
    if (rowData?.some((row) => row.notInstalledAvs)) {
      baseColumns.push({
        name: "Not Installed",
        selector: (row) => row.notInstalledAvs,
        cell: (row) => {
          const avArray = Array.isArray(row.notInstalledAvs)
            ? row.notInstalledAvs
            : [];

          if (avArray.length === 1) {
            // If there's only one item in the array, render it directly without a dropdown
            return <span>{avArray[0]}</span>;
          } else if (avArray.length > 1) {
            // If there are multiple items, show the dropdown
            return (
              <Dropdown overlay={menuadmin(row)} trigger={["click"]}>
                <Button className="bg-transparent border-0 p-0 m-0">
                  {avArray.join(", ")} <DownOutlined />
                </Button>
              </Dropdown>
            );
          } else {
            // Render "NA" if the array is empty
            return <span>NA</span>;
          }
        },
        sortable: true,
        width: "210px",
      });
    }

    if (rowData?.some((row) => row.ssid)) {
      baseColumns.push({
        name: "SSID",
        selector: (row) => row.ssid,
        cell: (row) => {
          const avArray = row.ssid || []; // Ensure it's an array, even if null/undefined

          if (avArray.length === 1) {
            return <span>{avArray[0]}</span>; // Render the single item directly
          } else if (avArray.length > 1) {
            return (
              <Dropdown overlay={menuadminssid(row)} trigger={["click"]}>
                <Button className="bg-transparent border-0 p-0 m-0">
                  {avArray.join(", ")} <i class="fa-solid fa-angle-down"></i>
                </Button>
              </Dropdown>
            );
          } else {
            return <span>NA</span>; // Render "NA" if no items are present
          }
        },
        sortable: true,
        width: "150px",
      });
    }

    if (rowData?.some((row) => row.authenticationType)) {
      baseColumns.push({
        name: "Auth Type",
        selector: (row) => row.authenticationType,
        cell: (row) => {
          const avArray = row.authenticationType || []; // Ensure it's an array, even if null/undefined

          if (avArray.length === 1) {
            return <span>{avArray[0]}</span>; // Render the single item directly
          } else if (avArray.length > 1) {
            return (
              <Dropdown
                overlay={menuadminauthenticationType(row)}
                trigger={["click"]}
              >
                <Button className="bg-transparent border-0 p-0 m-0">
                  {avArray.join(", ")} <DownOutlined />
                </Button>
              </Dropdown>
            );
          } else {
            return <span>NA</span>; // Render "NA" if no items are present
          }
        },
        sortable: true,
        width: "150px",
      });
    }

    // Add the "Action" column at the last position
    baseColumns.push({
      name: "Action",
      cell: (row) => (
        <div className="flex justify-center">
          <Button
            className="btn rounded-md  border-0 d-flex  fs10  gap-2 mx-auto px-2 text-light bg-dark"
            onClick={() =>
              navigate(routeUrl, {
                state: {
                  basicInfo: row,
                  pcInfoId: row?.pc_Id,
                  title: title,
                },
              })
            }
          >
            <p className="m-0 p-0 fs-12">Details</p>
            <i className="fas fa-long-arrow-alt-right ps-4"></i>
          </Button>
        </div>
      ),
      ignoreRowClick: true,
      allowoverflow: false,
      width: "160px",
    });

    return baseColumns;
  }, [currentPage, pageSize, navigate, routeUrl, rowData]);

  const columnsList = [
    {
      name: "Process Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "PID",
      selector: (row) => row?.pid,
      sortable: true,
    },
    {
      name: "CPU Usage (%)",
      selector: (row) => row?.cpuPercent || row?.memoryPercent,
      sortable: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#D7E3E8",
      },
    },
  };

  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    width: "80%",
    maxWidth: "600px",
    maxHeight: "80vh", // Set a maximum height for the modal
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    overflowY: "auto", // Enable vertical scrolling
    zIndex: "1000",
  };

  const backdropStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "999", // One less than modal
  };

  const exportToPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });

    // Add header and title
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = new Date().toLocaleString();

    doc.setFontSize(12);
    doc.text(`Organization: ${orgName}`, 14, 15);
    doc.text(
      `Exported: ${exportDateTime}`,
      doc.internal.pageSize.getWidth() - 14,
      15,
      { align: "right" }
    );
    doc.setFontSize(15);
    doc.text(`High Usage List`, 14, 22);

    // Get all keys from the first item in modalData, excluding _id
    const tableColumn = Object.keys(modalData[0] || {}).filter(
      (key) => key !== "_id"
    );
    const tableRows = modalData.map((item) => {
      return tableColumn.map((col) => item[col]); // Use the keys to get values
    });

    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
    });

    doc.save(`Usage_List${exportDateTime}.pdf`);
  };

  const exportToExcel = () => {
    const filteredData = modalData.map(({ _id, ...rest }) => rest); // Exclude _id
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Violations");

    // Create a buffer and save
    const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([buffer], { type: "application/octet-stream" });

    saveAs(blob, `Usage_List.xlsx`);
  };

  return (
    <>
      {" "}
      <DataTable
        columns={columns}
        data={rowData || []}
        pagination
        customStyles={customStyles}
        paginationServer
        paginationTotalRows={totalDocuments}
        paginationDefaultPage={currentPage}
        paginationPerPage={pageSize}
        onChangePage={(page) => {
          console.log("Page", page);
          handlePageChange(page);
        }}
        onChangeRowsPerPage={(newPageSize) => {
          console.log("Page Size", newPageSize);
          handlePageSizeChange(newPageSize);
        }}
        noDataComponent={<p className="p-0 my-2 me-auto">No Data Found</p>}
      />
      <Modal show={modalOpen} onHide={() => setModalOpen(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Usage List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* DataTable to display API response */}
          <DataTable
            columns={columnsList}
            data={modalData}
            pagination
            customStyles={customStyles}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm btn-primary text-white"
            onClick={exportToPDF}
          >
            <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
          </button>
          <button
            type="button"
            className="btn btn-sm btn-success text-white"
            onClick={exportToExcel}
          >
            <img src={Excellogo} height={15} width={15} />
            &nbsp; CSV
          </button>
          <Button
            variant="btn btn-secondary btn-sm"
            onClick={() => setModalOpen(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CustomTable;
