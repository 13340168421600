import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import Tippy from "@tippyjs/react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import "tippy.js/dist/tippy.css"; // optional
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import Excellogo from "../../../assets/img/Excellogo.png";
function CustomTableLinuxUsb({
  rowData,
  routeUrl,
  currentPage,
  pageSize,
  handlePageChange,
  handlePageSizeChange,
  totalDocuments,
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const navigate = useNavigate();
  const [skin, setSkin] = useState(currentSkin);
  const [modalOpen, setModalOpen] = useState(false); // Modal visibility state
  const [networkData, setNetworkData] = useState([]); // State to hold API data
  // const [isModalOpen, setModalOpen] = useState(false);

  const { user } = useSelector((state) => state.authSlice);
  console.log(networkData, "sdfsdfsdfsdfdsfsfs");
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const handleMenuClick = (e, record) => {
    console.log("Click on menu item:", e, "for record:", record);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  const menu = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record?.ipAddress?.map((v, index) => (
          <Menu.Item key={index}>{v}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const styleViewbutton = {
    marginLeft: "-6px !important",
  };

  const handleCountClick = (pcId) => {
    console.log(pcId, "pcId");

    axios
      .get(
        `${process.env.REACT_APP_LINUX_URL}/networkinfo/network-communication-violation-list/${user?.data?.user?.userId}?pcId=${pcId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((response) => {
        console.log(
          "API Response:",
          response.data.establishedNetworkConnections
        );
        setNetworkData(response.data.establishedNetworkConnections);
        setModalOpen(true); // Open the modal
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const columnsList = [
    {
      name: "Local Address",
      selector: (row) => row.localAddress,
      sortable: true,
    },
    { name: "Local Port", selector: (row) => row.localPort, sortable: true },
    {
      name: "Foreign Address",
      selector: (row) => row.foreignAddress,
      sortable: true,
    },
    {
      name: "Foreign Port",
      selector: (row) => row.foreignPort,
      sortable: true,
    },
    {
      name: "Is Outside",
      selector: (row) => (row.isOutside ? "Yes" : "No"),
      sortable: true,
    },
  ];
  const columns = React.useMemo(
    () => [
      {
        name: "S. No.",
        selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
        width: "90px",
      },
      {
        name: "Hostname",
        selector: (row) => row.hostname,
        sortable: true,
        width: "230px",
      },
      {
        name: "Serial No.",
        selector: (row) => (
          <Tippy content={row.serialNumber}>
            <div>{row.serialNumber}</div>
          </Tippy>
        ),
        sortable: true,
        // width: "120px",
      },

      // Conditionally add the "IP Address" column if any row has a valid IP Address
      ...(rowData.some((row) => row.ipAddress)
        ? [
            {
              name: "IP Address",
              selector: (row) => (
                <Tippy content={row.ipAddress}>
                  <div>{row.ipAddress}</div>
                </Tippy>
              ),
              sortable: true,
              // width: "150px",
            },
          ]
        : []),

      ...(rowData.some((row) => row.ipAddress)
        ? [
            {
              name: "Count",
              selector: (row) => row.foreignAddressCount,
              sortable: true,
              width: "120px",
              cell: (row) => (
                <span
                  onClick={() => handleCountClick(row.pcId)}
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    // textDecoration: "underline", // Optional: makes it look like a link
                  }}
                >
                  {row.foreignAddressCount}
                </span>
              ),
            },
          ]
        : []),

      ...(rowData.some((row) => row.time)
        ? [
            {
              name: "Timestamp",
              selector: (row) => row.time,
              sortable: true,
              width: "180px",
            },
          ]
        : []),
      ...(rowData.some((row) => row.macAddress)
        ? [
            {
              name: "Mac Address",
              selector: (row) => (
                <Tippy content={row.macAddress}>
                  <div>{row.macAddress}</div>
                </Tippy>
              ),
              sortable: true,
              width: "250px",
            },
          ]
        : []),
      // Conditionally add the "Local Address" column if present
      ...(rowData.some((row) => row.localAddress)
        ? [
            {
              name: "Local IP",
              selector: (row) => row.localAddress || "N/A",
              sortable: true,
              width: "150px",
            },
          ]
        : []),
      // Conditionally add the "Local Port" column if present
      ...(rowData.some((row) => row.localPort)
        ? [
            {
              name: "Local Port",
              selector: (row) => row.localPort || "N/A",
              sortable: true,
              width: "120px",
            },
          ]
        : []),
      // Conditionally add the "Foreign Address" column if present
      ...(rowData.some((row) => row.foreignAddress)
        ? [
            {
              name: "Remote IP",
              selector: (row) => row.foreignAddress || "N/A",
              sortable: true,
              width: "150px",
            },
          ]
        : []),
      // Conditionally add the "Foreign Port" column if present
      ...(rowData.some((row) => row.foreignPort)
        ? [
            {
              name: "Remote Port",
              selector: (row) => row.foreignPort || "N/A",
              sortable: true,
              width: "150px",
            },
          ]
        : []),
      ...(rowData.some((row) => row.usbName && row.usbName.length > 0)
        ? [
            {
              name: "Name",
              selector: (row) => row.usbName.join(", "),
              sortable: true,
              width: "150px",
            },
          ]
        : []),

      ...(rowData.some(
        (row) => row.usbSerialNumber && row.usbSerialNumber.length > 0
      )
        ? [
            {
              name: "USB Serial No.",
              selector: (row) => (
                <Tippy content={row.usbSerialNumber}>
                  <div>{row.usbSerialNumber}</div>
                </Tippy>
              ),
              sortable: true,
              width: "210px",
            },
          ]
        : []),

      ...(rowData.some((row) => row.usbManufacturer)
        ? [
            {
              name: "Manufacturer",
              selector: (row) => (
                <Tippy content={row.usbManufacturer}>
                  <div>{row.usbManufacturer}</div>
                </Tippy>
              ),
              sortable: true,
            },
          ]
        : []),
      ...(rowData.some((row) => row.usbProductId)
        ? [
            {
              name: "Product ID",
              selector: (row) => row.usbProductId,
              sortable: true,
            },
          ]
        : []),
      ...(rowData.some((row) => row.usbVendorId)
        ? [
            {
              name: "Vendor ID",
              selector: (row) => row.usbVendorId,
              sortable: true,
            },
          ]
        : []),
      ...(rowData.some(
        (row) => Array.isArray(row.openTcpPorts) && row.openTcpPorts.length > 0
      )
        ? [
            {
              name: "Open Ports",
              selector: (row) =>
                Array.isArray(row.openTcpPorts)
                  ? row.openTcpPorts.join(", ")
                  : "N/A",
              cell: (row) => (
                <Tippy
                  content={
                    Array.isArray(row.openTcpPorts)
                      ? row.openTcpPorts.join(", ")
                      : "N/A"
                  }
                >
                  <div>
                    {Array.isArray(row.openTcpPorts)
                      ? row.openTcpPorts.join(", ")
                      : "N/A"}
                  </div>
                </Tippy>
              ),
              sortable: true,
            },
          ]
        : []),
      {
        name: "Action",
        cell: (row) => (
          <Button
            className="btn rounded-md border-0 d-flex justify-content-between align-items-center gap-2 mx-auto px-2 text-light bg-dark ms-1"
            style={{ flex: "1", minWidth: "100px", maxWidth: "150px" }}
            onClick={() =>
              navigate(routeUrl, {
                state: {
                  basicInfo: row,
                  pcInfoId: row?.pc_Id,
                },
              })
            }
          >
            <p className="m-0 p-0 fs-12">Details</p>
            <i className="fas fa-long-arrow-alt-right ps-4"></i>
          </Button>
        ),
        ignoreRowClick: true,
        allowoverflow: true,
        // style: { minWidth: 'auto', maxWidth: '200px' }, // Adjust the width range
      },
    ],
    [currentPage, pageSize, navigate, routeUrl, rowData]
  );

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#D7E3E8",
      },
    },
  };
  console.log(rowData, "rowData");

  const exportToPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });

    // Add header and title
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = new Date().toLocaleString();

    doc.setFontSize(12);
    doc.text(`Organization: ${orgName}`, 14, 15);
    doc.text(
      `Exported: ${exportDateTime}`,
      doc.internal.pageSize.getWidth() - 14,
      15,
      { align: "right" }
    );
    doc.setFontSize(15);
    doc.text(`Established Network Connections List`, 14, 22);

    // Get all keys from the first item in networkData, excluding _id
    const tableColumn = Object.keys(networkData[0] || {}).filter(
      (key) => key !== "_id"
    );
    const tableRows = networkData.map((item) => {
      return tableColumn.map((col) => item[col]); // Use the keys to get values
    });

    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
    });

    doc.save(`Network_Violation_List_${exportDateTime}.pdf`);
  };

  const exportToExcel = () => {
    const filteredData = networkData.map(({ _id, ...rest }) => rest); // Exclude _id
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Violations");

    // Create a buffer and save
    const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([buffer], { type: "application/octet-stream" });

    saveAs(blob, `Network_Violation_List.xlsx`);
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={rowData || []}
        customStyles={customStyles}
        pagination
        paginationServer
        paginationTotalRows={totalDocuments}
        paginationDefaultPage={currentPage}
        paginationPerPage={pageSize}
        onChangePage={(page) => {
          console.log("Page", page);
          handlePageChange(page);
        }}
        onChangeRowsPerPage={(newPageSize) => {
          console.log("Page Size", newPageSize);
          handlePageSizeChange(newPageSize);
        }}
        noDataComponent={<p className="p-0 my-2 me-auto">No Data Found</p>}
      />

      {/* Modal */}
      <Modal show={modalOpen} onHide={() => setModalOpen(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Established Network Connections List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* DataTable to display API response */}
          <DataTable
            columns={columnsList}
            data={networkData}
            pagination
            customStyles={customStyles}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm btn-primary text-white"
            onClick={exportToPDF}
          >
            <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
          </button>
          <button
            type="button"
            className="btn btn-sm btn-success text-white"
            onClick={exportToExcel}
          >
            <img src={Excellogo} height={15} width={15} />
            &nbsp; CSV
          </button>
          <Button
            variant="btn btn-secondary btn-sm"
            onClick={() => setModalOpen(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CustomTableLinuxUsb;
