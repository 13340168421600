// import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import Header from "../../layouts/Header";
// import DataTable from "react-data-table-component";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// function AdminConfig() {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const [skin, setSkin] = useState(currentSkin);
//   const [passwordThresholdAge, setPasswordThresholdAge] = useState("");
//   const [nonReportingThresholdDay, setNonReportingThresholdDay] = useState("");
//   const [highCpuThresholdPercent, setHighCpuThresholdPercent] = useState("");
//   const [highMemoryThresholdPercent, setHighMemoryThresholdPercent] =
//     useState("");
//   const [edrName, setEdrName] = useState("");
//   const [edrVersion, setEdrVersion] = useState("");
//   const [avName, setAvName] = useState("");
//   const [avVersion, setAvVersion] = useState("");
//   // New state variables for OS details
//   const [osDetails, setOsDetails] = useState([{ osName: "", osVersion: "" }]);
//   // const [avDetails, setAvdetails] = useState([
//   //   {
//   //     avName: "",
//   //     avVersion: "",
//   //   },
//   // ]);
//   // const [edrDetails, setEdrdetails] = useState([
//   //   {
//   //     edrName: "",
//   //     edrVersion: "",
//   //   },
//   // ]);

//   const [edrDetails, setEdrDetails] = useState([
//     { edrName: "", edrVersion: "" },
//   ]);

//   const [avDetails, setAvDetails] = useState([{ avName: "", avVersion: "" }]);
//   const [isEdrOpen, setIsEdrOpen] = useState(true);
//   const [isAvOpen, setIsAvOpen] = useState(false);
//   const [isOsOpen, setIsOsOpen] = useState(false);
//   const toggleEdrAccordion = () => setIsEdrOpen(!isEdrOpen);
//   const toggleAvAccordion = () => setIsAvOpen(!isAvOpen);
//   const toggleOsAccordion = () => setIsOsOpen(!isOsOpen);

//   const { user } = useSelector((state) => state.authSlice);
//   const [columnData, setColumnData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   console.log(columnData[0], "columnData");
//   // Fetch agent configuration data
//   const fetchConfigData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_NOTIFY_URL}/agent-configs/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       setColumnData(response.data.data);
//       console.log(response.data.data, "ashok");
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching configuration data:", error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchConfigData();
//   }, [user]);

//   // Handle change in OS details
//   const handleOsDetailChange = (index, field, value) => {
//     const newOsDetails = [...osDetails];
//     newOsDetails[index] = { ...newOsDetails[index], [field]: value };
//     setOsDetails(newOsDetails);
//   };

//   // Handle removal of an OS detail
//   const handleRemoveOsDetail = (index) => {
//     const newOsDetails = osDetails.filter((_, i) => i !== index);
//     setOsDetails(newOsDetails);
//   };

//   // EDR Handlers
//   const handleEdrDetailChange = (index, field, value) => {
//     const updatedEdrDetails = [...edrDetails];
//     updatedEdrDetails[index][field] = value;
//     setEdrDetails(updatedEdrDetails);
//   };

//   const handleAddEdrDetail = () => {
//     setEdrDetails([...edrDetails, { edrName: "", edrVersion: "" }]);
//   };

//   const handleRemoveEdrDetail = (index) => {
//     const updatedEdrDetails = edrDetails.filter((_, i) => i !== index);
//     setEdrDetails(updatedEdrDetails);
//   };

//   // AV Handlers
//   const handleAvDetailChange = (index, field, value) => {
//     const updatedAvDetails = [...avDetails];
//     updatedAvDetails[index][field] = value;
//     setAvDetails(updatedAvDetails);
//   };

//   const handleAddAvDetail = () => {
//     setAvDetails([...avDetails, { avName: "", avVersion: "" }]);
//   };

//   const handleRemoveAvDetail = (index) => {
//     const updatedAvDetails = avDetails.filter((_, i) => i !== index);
//     setAvDetails(updatedAvDetails);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const data = {
//       organizationId: user?.data?.user?.organizationId,
//       passwordThresholdAge: parseInt(passwordThresholdAge, 10)
//         ? parseInt(passwordThresholdAge, 10)
//         : columnData[0]?.passwordThresholdAge,
//       nonReportingThresholdDay: parseInt(nonReportingThresholdDay, 10)
//         ? parseInt(nonReportingThresholdDay, 10)
//         : columnData[0]?.nonReportingThresholdDay,
//       highCpuThresholdPercent: parseFloat(highCpuThresholdPercent)
//         ? parseFloat(highCpuThresholdPercent)
//         : columnData[0]?.highCpuThresholdPercent,
//       highMemoryThresholdPercent: parseFloat(highMemoryThresholdPercent)
//         ? parseFloat(highMemoryThresholdPercent)
//         : columnData[0]?.highMemoryThresholdPercent,
//       // edrName: edrName ? edrName : columnData[0]?.edrName,
//       // edrVersion: edrVersion ? edrVersion : columnData[0]?.edrVersion,
//       // avName: avName ? avName : columnData[0]?.avName,
//       // avVersion: avVersion ? avVersion : columnData[0]?.avVersion,
//       osDetails: osDetails ? osDetails : columnData[0]?.osDetails, // Add osDetails to the request body
//       avDetails: avDetails ? avDetails : "n/a",
//       edrDetails: edrDetails ? edrDetails : "n/a",
//     };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_NOTIFY_URL}/add/agent-config/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       console.log("API response:", response?.data.message);
//       toast.success(response?.data.message, {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       fetchConfigData(); // Refresh DataTable after adding new config
//     } catch (error) {
//       toast.error(
//         error?.response?.data.errors[0]?.msg ||
//           error?.response?.data.errors[1]?.msg
//       );
//     }
//   };
//   const columns = [
//     {
//       name: "Password Age Permitted (Days)",
//       selector: (row) => row.passwordThresholdAge,
//       sortable: true,
//     },
//     {
//       name: "Non-reporting Threshold (Days)",
//       selector: (row) => row.nonReportingThresholdDay,
//       sortable: true,
//     },
//     {
//       name: "High CPU Utilization Threshold (%)",
//       selector: (row) => row.highCpuThresholdPercent,
//       sortable: true,
//     },
//     {
//       name: "High Memory Utilization Threshold (%)",
//       selector: (row) => row.highMemoryThresholdPercent,
//       sortable: true,
//     },
//   ];

//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#cccccc",
//       },
//     },
//   };

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div
//         className="main main-app p-3 p-lg-4"
//         style={{ backgroundColor: "#ECF9FF" }}
//       >
//         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
//           <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
//             <p className="fs-18 fw-bolder p-0 m-0">
//               Admin Configurable Parameters
//             </p>
//           </span>
//         </div>

//         <div className="card rounded cardStyle" style={{ padding: "75px" }}>
//           {/* <form onSubmit={handleSubmit}>
//             <div className="mb-3 row">
//               <label htmlFor="inputPermitted" className="col-sm-4 col-form-label">
//                 Password age permitted (Days)
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="inputPermitted"
//                   value={passwordThresholdAge ? passwordThresholdAge : columnData[0]?.passwordThresholdAge }
//                   onChange={(e) => setPasswordThresholdAge(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="mb-3 row">
//               <label htmlFor="inputReporting" className="col-sm-4 col-form-label">
//                 Non-reporting threshold (Days)
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="inputReporting"
//                   value={nonReportingThresholdDay ? nonReportingThresholdDay : columnData[0]?.nonReportingThresholdDay }
//                   onChange={(e) => setNonReportingThresholdDay(e.target.value)}
//                 />
//               </div>
//             </div>

//             <div className="mb-3 row">
//               <label htmlFor="inputedr" className="col-sm-4 col-form-label">
//                EDR Name
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="inputedr"
//                    value={edrName ? edrName :columnData[0]?.edrName}
//                   onChange={(e) => setEdrName(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="mb-3 row">
//               <label htmlFor="inputversion" className="col-sm-4 col-form-label">
//                EDR Version
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="inputversion"
//                   value={edrVersion ? edrVersion :columnData[0]?.edrVersion}
//                   onChange={(e) => setEdrVersion(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="mb-3 row">
//               <label htmlFor="inputav" className="col-sm-4 col-form-label">
//                AV Name
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="inputav"
//                   value={avName ? avName : columnData[0]?.avName}
//                    onChange={(e) => setAvName(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="mb-3 row">
//               <label htmlFor="inputavversion" className="col-sm-4 col-form-label">
//                AV Version
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="inputavversion"
//                   value={avVersion ? avVersion : columnData[0]?.avVersion}
//                   onChange={(e) => setAvVersion(e.target.value)}
//                 />
//               </div>
//             </div>

//             <div className="mb-3 row">
//               <label htmlFor="inputCpu" className="col-sm-4 col-form-label">
//                 High CPU utilization threshold (%)
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="number"
//                   step="0.01"
//                   className="form-control"
//                   id="inputCpu"
//                   value={highCpuThresholdPercent ? highCpuThresholdPercent : columnData[0]?.highCpuThresholdPercent}
//                   onChange={(e) => setHighCpuThresholdPercent(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="mb-3 row">
//               <label htmlFor="inputMemory" className="col-sm-4 col-form-label">
//                 High memory utilization threshold (%)
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="number"
//                   step="0.01"
//                   className="form-control"
//                   id="inputMemory"
//                   value={highMemoryThresholdPercent ? highMemoryThresholdPercent : columnData[0]?.highMemoryThresholdPercent}
//                   onChange={(e) => setHighMemoryThresholdPercent(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="mb-3 row">
//               <div className="col-sm-5">
//                 <button type="submit" className="btn btn-success">
//                   Save Configuration
//                 </button>
//               </div>
//             </div>
//           </form> */}
//           <form onSubmit={handleSubmit}>
//             {/* Existing input fields */}
//             <div className="mb-3 row">
//               <label
//                 htmlFor="inputPermitted"
//                 className="col-sm-4 col-form-label"
//               >
//                 Password age permitted (Days)
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="inputPermitted"
//                   value={
//                     passwordThresholdAge
//                       ? passwordThresholdAge
//                       : columnData[0]?.passwordThresholdAge
//                   }
//                   onChange={(e) => setPasswordThresholdAge(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="mb-3 row">
//               <label
//                 htmlFor="inputReporting"
//                 className="col-sm-4 col-form-label"
//               >
//                 Non-reporting threshold (Days)
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="inputReporting"
//                   value={
//                     nonReportingThresholdDay
//                       ? nonReportingThresholdDay
//                       : columnData[0]?.nonReportingThresholdDay || ""
//                   }
//                   onChange={(e) => setNonReportingThresholdDay(e.target.value)}
//                 />
//               </div>
//             </div>

//             <div className="mb-3 row">
//               <label htmlFor="inputCpu" className="col-sm-4 col-form-label">
//                 High CPU utilization threshold (%)
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="number"
//                   step="0.01"
//                   className="form-control"
//                   id="inputCpu"
//                   value={
//                     highCpuThresholdPercent
//                       ? highCpuThresholdPercent
//                       : columnData[0]?.highCpuThresholdPercent
//                   }
//                   onChange={(e) => setHighCpuThresholdPercent(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="mb-3 row">
//               <label htmlFor="inputMemory" className="col-sm-4 col-form-label">
//                 High memory utilization threshold (%)
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="number"
//                   step="0.01"
//                   className="form-control"
//                   id="inputMemory"
//                   value={
//                     highMemoryThresholdPercent
//                       ? highMemoryThresholdPercent
//                       : columnData[0]?.highMemoryThresholdPercent
//                   }
//                   onChange={(e) =>
//                     setHighMemoryThresholdPercent(e.target.value)
//                   }
//                 />
//               </div>
//             </div>

//             {/* <div className="mb-3 row">
//         <label htmlFor="inputedr" className="col-sm-4 col-form-label">
//           EDR Name
//         </label>
//         <div className="col-sm-5">
//           <input
//             type="text"
//             className="form-control"
//             id="inputedr"
//             value={edrName ? edrName :columnData[0]?.edrName}
//             onChange={(e) => setEdrName(e.target.value)}
//           />
//         </div>
//       </div>
//       <div className="mb-3 row">
//         <label htmlFor="inputversion" className="col-sm-4 col-form-label">
//           EDR Version
//         </label>
//         <div className="col-sm-5">
//           <input
//             type="text"
//             className="form-control"
//             id="inputversion"
//             value={edrVersion ? edrVersion :columnData[0]?.edrVersion}
//             onChange={(e) => setEdrVersion(e.target.value)}
//           />
//         </div>
//       </div> */}
//             {/* <div
//               className="accordion"
//               id="accordionExample"
//               style={{ width: "51rem" }}
//             >
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingOne">
//                   <button
//                     className="accordion-button"
//                     type="button"
//                     data-bs-toggle="collapse"
//                     data-bs-target="#collapseOne"
//                     aria-expanded="true"
//                     aria-controls="collapseOne"
//                   >
//                     EDR Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseOne"
//                   className="accordion-collapse collapse show"
//                   aria-labelledby="headingOne"
//                   data-bs-parent="#accordionExample"
//                 >
//                   <div className="accordion-body">
//                     <div className="mb-3 row">
//                       <label
//                         htmlFor="inputedr"
//                         className="col-sm-4 col-form-label"
//                       >
//                         EDR Name
//                       </label>
//                       <div className="col-sm-5">
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="inputedr"
//                           value={edrName ? edrName : columnData[0]?.edrName}
//                           onChange={(e) => setEdrName(e.target.value)}
//                         />
//                       </div>
//                     </div>
//                     <div className="mb-3 row">
//                       <label
//                         htmlFor="inputversion"
//                         className="col-sm-4 col-form-label"
//                       >
//                         EDR Version
//                       </label>
//                       <div className="col-sm-5">
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="inputversion"
//                           value={
//                             edrVersion ? edrVersion : columnData[0]?.edrVersion
//                           }
//                           onChange={(e) => setEdrVersion(e.target.value)}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               className="accordion"
//               id="accordionExample2"
//               style={{ width: "51rem" }}
//             >
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingTwo">
//                   <button
//                     className="accordion-button"
//                     type="button"
//                     data-bs-toggle="collapse"
//                     data-bs-target="#collapseTwo"
//                     aria-expanded="true"
//                     aria-controls="collapseTwo"
//                   >
//                     AV Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseTwo"
//                   className="accordion-collapse collapse show"
//                   aria-labelledby="headingTwo"
//                   data-bs-parent="#accordionExample2"
//                 >
//                   <div className="accordion-body">
//                     <div className="mb-3 row">
//                       <label
//                         htmlFor="inputav"
//                         className="col-sm-4 col-form-label"
//                       >
//                         AV Name
//                       </label>
//                       <div className="col-sm-5">
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="inputav"
//                           value={avName ? avName : columnData[0]?.avName}
//                           onChange={(e) => setAvName(e.target.value)}
//                         />
//                       </div>
//                     </div>
//                     <div className="mb-3 row">
//                       <label
//                         htmlFor="inputavversion"
//                         className="col-sm-4 col-form-label"
//                       >
//                         AV Version
//                       </label>
//                       <div className="col-sm-5">
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="inputavversion"
//                           value={
//                             avVersion ? avVersion : columnData[0]?.avVersion
//                           }
//                           onChange={(e) => setAvVersion(e.target.value)}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div> */}
//             {/* <div
//               className="accordion"
//               id="accordionExample"
//               style={{ width: "51rem" }}
//             >
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingOne">
//                   <button
//                     className="accordion-button"
//                     type="button"
//                     data-bs-toggle="collapse"
//                     data-bs-target="#collapseOne"
//                     aria-expanded="true"
//                     aria-controls="collapseOne"
//                   >
//                     EDR Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseOne"
//                   className="accordion-collapse collapse show"
//                   aria-labelledby="headingOne"
//                   data-bs-parent="#accordionExample"
//                 >
//                   <div className="accordion-body">
//                     {edrDetails.map((edrDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`edrName${index}`}
//                             className="col-sm-4 col-form-label"
//                           >
//                             EDR Name
//                           </label>
//                           <div className="col-sm-5">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`edrName${index}`}
//                               value={edrDetail.edrName}
//                               onChange={(e) =>
//                                 handleEdrDetailChange(
//                                   index,
//                                   "edrName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                         </div>
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`edrVersion${index}`}
//                             className="col-sm-4 col-form-label"
//                           >
//                             EDR Version
//                           </label>
//                           <div className="col-sm-5">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`edrVersion${index}`}
//                               value={edrDetail.edrVersion}
//                               onChange={(e) =>
//                                 handleEdrDetailChange(
//                                   index,
//                                   "edrVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                         </div>
//                         <div className="row mb-2">
//                           <div className="col-sm-5">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleRemoveEdrDetail(index)}
//                             >
//                               Remove
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={handleAddEdrDetail}
//                     >
//                       Add Another EDR
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div
//               className="accordion"
//               id="accordionExample2"
//               style={{ width: "51rem" }}
//             >
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingTwo">
//                   <button
//                     className="accordion-button"
//                     type="button"
//                     data-bs-toggle="collapse"
//                     data-bs-target="#collapseTwo"
//                     aria-expanded="true"
//                     aria-controls="collapseTwo"
//                   >
//                     AV Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseTwo"
//                   className="accordion-collapse collapse show"
//                   aria-labelledby="headingTwo"
//                   data-bs-parent="#accordionExample2"
//                 >
//                   <div className="accordion-body">
//                     {avDetails.map((avDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`avName${index}`}
//                             className="col-sm-4 col-form-label"
//                           >
//                             AV Name
//                           </label>
//                           <div className="col-sm-5">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`avName${index}`}
//                               value={avDetail.avName}
//                               onChange={(e) =>
//                                 handleAvDetailChange(
//                                   index,
//                                   "avName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                         </div>
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`avVersion${index}`}
//                             className="col-sm-4 col-form-label"
//                           >
//                             AV Version
//                           </label>
//                           <div className="col-sm-5">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`avVersion${index}`}
//                               value={avDetail.avVersion}
//                               onChange={(e) =>
//                                 handleAvDetailChange(
//                                   index,
//                                   "avVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                         </div>
//                         <div className="row mb-2">
//                           <div className="col-sm-5">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleRemoveAvDetail(index)}
//                             >
//                               Remove
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={handleAddAvDetail}
//                     >
//                       Add Another AV
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div> */}
//             <div
//               className="accordion"
//               id="accordionExample"
//               style={{ width: "51rem" }}
//             >
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingOne">
//                   <button
//                     className="accordion-button"
//                     type="button"
//                     onClick={toggleEdrAccordion}
//                     aria-expanded={isEdrOpen}
//                     aria-controls="collapseOne"
//                   >
//                     EDR Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseOne"
//                   className={`accordion-collapse collapse ${
//                     isEdrOpen ? "show" : ""
//                   }`}
//                   aria-labelledby="headingOne"
//                   data-bs-parent="#accordionExample"
//                 >
//                   <div className="accordion-body">
//                     {edrDetails.map((edrDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`edrName${index}`}
//                             className="col-sm-4 col-form-label"
//                           >
//                             EDR Name
//                           </label>
//                           <div className="col-sm-5">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`edrName${index}`}
//                               value={edrDetail.edrName}
//                               onChange={(e) =>
//                                 handleEdrDetailChange(
//                                   index,
//                                   "edrName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                         </div>
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`edrVersion${index}`}
//                             className="col-sm-4 col-form-label"
//                           >
//                             EDR Version
//                           </label>
//                           <div className="col-sm-5">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`edrVersion${index}`}
//                               value={edrDetail.edrVersion}
//                               onChange={(e) =>
//                                 handleEdrDetailChange(
//                                   index,
//                                   "edrVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                         </div>
//                         <div className="row mb-2">
//                           <div className="col-sm-5">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleRemoveEdrDetail(index)}
//                             >
//                               Remove
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={handleAddEdrDetail}
//                     >
//                       Add Another EDR
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div
//               className="accordion"
//               id="accordionExample2"
//               style={{ width: "51rem" }}
//             >
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingTwo">
//                   <button
//                     className="accordion-button"
//                     type="button"
//                     onClick={toggleAvAccordion}
//                     aria-expanded={isAvOpen}
//                     aria-controls="collapseTwo"
//                   >
//                     AV Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseTwo"
//                   className={`accordion-collapse collapse ${
//                     isAvOpen ? "show" : ""
//                   }`}
//                   aria-labelledby="headingTwo"
//                   data-bs-parent="#accordionExample2"
//                 >
//                   <div className="accordion-body">
//                     {avDetails.map((avDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`avName${index}`}
//                             className="col-sm-4 col-form-label"
//                           >
//                             AV Name
//                           </label>
//                           <div className="col-sm-5">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`avName${index}`}
//                               value={avDetail.avName}
//                               onChange={(e) =>
//                                 handleAvDetailChange(
//                                   index,
//                                   "avName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                         </div>
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`avVersion${index}`}
//                             className="col-sm-4 col-form-label"
//                           >
//                             AV Version
//                           </label>
//                           <div className="col-sm-5">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`avVersion${index}`}
//                               value={avDetail.avVersion}
//                               onChange={(e) =>
//                                 handleAvDetailChange(
//                                   index,
//                                   "avVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                         </div>
//                         <div className="row mb-2">
//                           <div className="col-sm-5">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleRemoveAvDetail(index)}
//                             >
//                               Remove
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={handleAddAvDetail}
//                     >
//                       Add Another AV
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div
//               className="accordion"
//               id="osDetailsAccordion"
//               style={{ width: "51rem" }}
//             >
//               {/* Accordion Item */}
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingThree">
//                   <button
//                     className="accordion-button"
//                     type="button"
//                     onClick={toggleOsAccordion}
//                     aria-expanded={isOsOpen}
//                     aria-controls="collapseThree"
//                   >
//                     OS Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseThree"
//                   className={`accordion-collapse collapse ${
//                     isOsOpen ? "show" : ""
//                   }`}
//                   aria-labelledby="headingThree"
//                   data-bs-parent="#osDetailsAccordion"
//                 >
//                   <div className="accordion-body">
//                     {osDetails.map((osDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`osName${index}`}
//                             className="col-sm-4 col-form-label"
//                           >
//                             OS Name
//                           </label>
//                           <div className="col-sm-5">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`osName${index}`}
//                               value={osDetail.osName}
//                               onChange={(e) =>
//                                 handleOsDetailChange(
//                                   index,
//                                   "osName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                         </div>
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`osVersion${index}`}
//                             className="col-sm-4 col-form-label"
//                           >
//                             OS Version
//                           </label>
//                           <div className="col-sm-5">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`osVersion${index}`}
//                               value={osDetail.osVersion}
//                               onChange={(e) =>
//                                 handleOsDetailChange(
//                                   index,
//                                   "osVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                         </div>
//                         <div className="row mb-2">
//                           <div className="col-sm-5">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleRemoveOsDetail(index)}
//                             >
//                               Remove
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                     <button
//                     type="button"
//                     className="btn btn-primary"
//                     onClick={() =>
//                       setOsDetails([
//                         ...osDetails,
//                         { osName: "", osVersion: "" },
//                       ])
//                     }
//                   >
//                     Add Another OS
//                   </button>
//                   </div>
//                 </div>
//               </div>

//               <div className="mt-2 mb-3">
//                 <div className="col-sm-5">
//                   <button type="submit" className="btn btn-success">
//                     Save Configuration
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// }

// export default AdminConfig;
// import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import Header from "../../layouts/Header";
// import DataTable from "react-data-table-component";
// import { useSelector } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { notifyApi } from "../../api/axiosSet";
// import { useNavigate } from "react-router-dom";

// function AdminConfig() {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const [skin, setSkin] = useState(currentSkin);
//   const [passwordThresholdAge, setPasswordThresholdAge] = useState("");
//   const [nonReportingThresholdDay, setNonReportingThresholdDay] = useState("");
//   const [highCpuThresholdPercent, setHighCpuThresholdPercent] = useState("");
//   const [highMemoryThresholdPercent, setHighMemoryThresholdPercent] =
//     useState("");
//   const [edrName, setEdrName] = useState("");
//   const [edrVersion, setEdrVersion] = useState("");
//   const [avName, setAvName] = useState("");
//   const [avVersion, setAvVersion] = useState("");

//   // New state variables for OS details
//   const [osDetails, setOsDetails] = useState([]);
//   const [edrDetails, setEdrDetails] = useState([]);
//   const [avDetails, setAvDetails] = useState([]);
//   const [zenworksDetails, setZenworksDetails] = useState([]);

//   console.log(zenworksDetails, "zenworksDetails");
//   const [certificationDetails, setCertificationDetails] = useState([]);
//   const [isEdrOpen, setIsEdrOpen] = useState(true);
//   const [isAvOpen, setIsAvOpen] = useState(false);
//   const [isOsOpen, setIsOsOpen] = useState(false);
//   const [isZenworksOpen, setIsZenworksOpen] = useState(false);
//   const [isCertificationOpen, setIsCertificationOpen] = useState(false);
//   const toggleEdrAccordion = () => setIsEdrOpen(!isEdrOpen);
//   const toggleAvAccordion = () => setIsAvOpen(!isAvOpen);
//   const toggleOsAccordion = () => setIsOsOpen(!isOsOpen);
//   const toggleZenworksAccordion = () => setIsZenworksOpen(!isZenworksOpen);
//   const toggleCertificationAccordion = () =>
//     setIsCertificationOpen(!isCertificationOpen);
//   const { user } = useSelector((state) => state.authSlice);
//   const [columnData, setColumnData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [getRespId, setGetRespId] = useState();

//   // Fetch agent configuration data
//   const fetchConfigData = async () => {
//     try {
//       const response = await notifyApi.get(
//         `/agent-configs/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       setColumnData(response.data.data);
//       console.log(response.data.data[0]._id, "ashok");
//       const resData = response.data.data[0];
//       setLoading(false);
//       setGetRespId(response.data.data[0]._id);
//       setAvDetails(resData.avDetails);
//       setEdrDetails(resData.edrDetails);
//       setOsDetails(resData.osDetails);
//       setZenworksDetails(resData.zenWorksDetails);
//       setCertificationDetails(resData.certificateDetails);
//       setPasswordThresholdAge(resData.passwordThresholdAge);
//       setNonReportingThresholdDay(resData.nonReportingThresholdDay);
//       setHighCpuThresholdPercent(resData.highCpuThresholdPercent);
//       setHighMemoryThresholdPercent(resData.highMemoryThresholdPercent);
//     } catch (error) {
//       console.error("Error fetching configuration data:", error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchConfigData();
//   }, [user]);

//   // Handle change in OS details
//   const handleOsDetailChange = (index, field, value) => {
//     const newOsDetails = [...osDetails];
//     newOsDetails[index] = { ...newOsDetails[index], [field]: value };
//     setOsDetails(newOsDetails);
//   };

//   const handleDeleteOsDetail = async (index, id, userId) => {
//     try {
//       const osToDelete = osDetails[index];
//       // Make the API call to delete the configuration by ID
//       await notifyApi.delete(
//         `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//           data: {
//             osDetailsToDelete: [osToDelete],
//           },
//         }
//       );
//       toast.success("OS Deleted Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       fetchConfigData(); // Refresh DataTable after adding new config
//       // If the API call is successful, update the state
//       const newOsDetails = osDetails.filter((_, i) => i !== index);
//       setOsDetails(newOsDetails);
//     } catch (error) {
//       console.error("Error deleting OS detail:", error);
//     }
//   };
//   const handleRemoveOsDetail = (index) => {
//     const newOsDetails = osDetails.filter((_, i) => i !== index);
//     setOsDetails(newOsDetails);
//   };
//   const handleOsDetailUpdate = async (index, id, userId) => {
//     try {
//       const osToDelete = osDetails[index];
//       const data = {
//         organizationId: user?.data?.user?.organizationId,
//         osDetails: [osToDelete],
//       };
//       // Make the API call to delete the configuration by ID
//       await notifyApi.post(
//         `/add/agent-config/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       toast.success("OS Updated Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       fetchConfigData(); // Refresh DataTable after adding new config
//       // If the API call is successful, update the state
//       const newOsDetails = osDetails.filter((_, i) => i !== index);
//       setOsDetails(newOsDetails);
//     } catch (error) {
//       console.error("Error deleting OS detail:", error);
//     }
//   };
//   // EDR Handlers
//   const handleEdrDetailChange = (index, field, value) => {
//     //  console.log(index, field, value, "ashok");
//     const updatedEdrDetails = [...edrDetails];
//     updatedEdrDetails[index][field] = value;
//     setEdrDetails([...updatedEdrDetails]);
//     //  console.log("ashok", edrDetails);
//   };

//   // const handleAddEdrDetail = () => {
//   //   setEdrDetails([...edrDetails, { edrName: "", edrVersion: "" }]);
//   // };
//   const handleAddEdrDetail = () => {
//     setEdrDetails([
//       ...edrDetails,
//       { edrName: "", edrVersion: "", isNew: true }  // Flagging the new entry
//     ]);
//   };
  
//   const handleDeleteEdrDetail = async (index) => {
//     try {
//       const edrToDelete = edrDetails[index];
//       console.log(edrDetails, "edrrrrrr");
//       // Make the API call to delete the configuration by ID
//       await notifyApi.delete(
//         `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//           data: {
//             edrDetailsToDelete: [edrToDelete],
//           },
//         }
//       );

//       toast.success("EDR Deleted Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       fetchConfigData(); // Refresh DataTable after adding new config
//       // If the API call is successful, update the state
//       // const updatedEdrDetails = edrDetails.filter((_, i) => i !== index);
//       // setEdrDetails([...updatedEdrDetails]);
//     } catch (error) {
//       console.error("Error deleting EDR detail:", error);
//     }
//   };

//   const handleRemoveEdrDetail = (index) => {
//     const updatedEdrDetails = edrDetails.filter((_, i) => i !== index);
//     setEdrDetails(updatedEdrDetails);
//   };

//   const handleRemoveEdrDetailUpdate = async (index) => {
//     try {
//       const edrToDelete = edrDetails[index];
//       const data = {
//         organizationId: user?.data?.user?.organizationId,
//         edrDetails: [edrToDelete],
//       };

//       await notifyApi.post(
//         `/add/agent-config/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );

//       toast.success("EDR Updated Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       fetchConfigData(); // Refresh DataTable after adding new config
//       // If the API call is successful, update the state
//       const updatedEdrDetails = edrDetails.filter((_, i) => i !== index);
//       setEdrDetails([...updatedEdrDetails]);
//     } catch (error) {
//       console.error("Error deleting EDR detail:", error);
//     }
//   };
//   // AV Handlers
//   const handleAvDetailChange = (index, field, value) => {
//     const updatedAvDetails = [...avDetails];
//     updatedAvDetails[index][field] = value;
//     setAvDetails(updatedAvDetails);
//   };

//   // const handleAddAvDetail = () => {
//   //   setAvDetails([...avDetails, { avName: "", avVersion: "" }]);
//   // };
//   const handleAddAvDetail = () => {
//     setAvDetails([
//       ...avDetails,
//       { avName: "", avVersion: "", isNew: true }  // New entry
//     ]);
//   };
//   const handleAddOsDetail = () => {
//     setOsDetails([
//       ...osDetails,
//       { osName: "", osVersion: "", isNew: true }  // New entry flagged as isNew
//     ]);
//   };
  
//   const handleAvDetailUpdate = async (index) => {
//     try {
//       const avToDelete = avDetails[index];
//       const data = {
//         organizationId: user?.data?.user?.organizationId,
//         avDetails: [avToDelete],
//       };
//       // Make the API call to delete the configuration by ID
//       await notifyApi.post(
//         `/add/agent-config/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );

//       toast.success("AV Updated Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       fetchConfigData(); // Refresh DataTable after adding new config
//       // If the API call is successful, update the state
//       const updatedAvDetails = avDetails.filter((_, i) => i !== index);
//       setAvDetails([...updatedAvDetails]);
//     } catch (error) {
//       console.error("Error deleting AV detail:", error);
//     }
//   };
//   const handleDeleteAvDetail = async (index) => {
//     const avToDelete = avDetails[index];

//     // Display a confirmation dialog before proceeding with deletion
//     const isConfirmed = window.confirm(
//       `Are you sure you want to delete AV detail: ${avToDelete.avName} (version: ${avToDelete.avVersion})?`
//     );

//     if (isConfirmed) {
//       try {
//         // Make the API call to delete the configuration by ID
//         await notifyApi.delete(
//           `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${user?.data?.accessToken}`,
//             },
//             data: {
//               avDetailsToDelete: [avToDelete],
//             },
//           }
//         );

//         toast.success("AV Deleted Successfully", {
//           position: "top-center",
//           autoClose: 2000,
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: false,
//           theme: "light",
//         });

//         fetchConfigData(); // Refresh DataTable after adding new config

//         // If the API call is successful, update the state
//         // const updatedAvDetails = avDetails.filter((_, i) => i !== index);
//         // setAvDetails([...updatedAvDetails]);
//       } catch (error) {
//         console.error("Error deleting AV detail:", error);
//       }
//     }
//   };
//     const handleRemoveAvDetail = (index) => {
//     const updatedAvDetails = avDetails.filter((_, i) => i !== index);
//     setAvDetails(updatedAvDetails);
//   };


//   const handleZenworksDetailChange = (index, field, value) => {
//     const updatedDetails = [...zenworksDetails];
//     updatedDetails[index][field] = value;
//     setZenworksDetails(updatedDetails);
//   };
//   const handleAddZenworksDetail = () => {
//     setZenworksDetails([
//       ...zenworksDetails,
//       { zenWokrs: "", zenWokrsVersion: "", isNew: true }  // New entry flagged as isNew
//     ]);
//   };
  
//   const handleCertificationDetailChange = (index, field, value) => {
//     const updatedDetails = [...certificationDetails];
//     updatedDetails[index][field] = value;
//     setCertificationDetails(updatedDetails);
//   };

//   const handleRemoveZenworksDetail = (index) => {
//     const updatedDetails = zenworksDetails.filter((_, i) => i !== index);
//     setZenworksDetails(updatedDetails);
//   };
//   const handleDeleteZenworksDetail = async (index) => {
//     try {
//       const zenworksToDelete = zenworksDetails[index];
//       // Make the API call to delete the configuration by ID
//       await notifyApi.delete(
//         `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//           data: {
//             zenWorksDetailsToDelete: [zenworksToDelete],
//           },
//         }
//       );
//       toast.success("Zenworks Deleted Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       fetchConfigData(); // Refresh DataTable after deletion
//       // If the API call is successful, update the state
//       // const newZenworksDetails = zenworksDetails.filter((_, i) => i !== index);
//       // setZenworksDetails(newZenworksDetails);
//     } catch (error) {
//       console.error("Error deleting Zenworks detail:", error);
//     }
//   };
//   const handleZenworksDetailUpdate = async (index) => {
//     try {
//       const zenworksToDelete = zenworksDetails[index];
//       // Make the API call to delete the configuration by ID
//       const data = {
//         organizationId: user?.data?.user?.organizationId,
//         zenWorksDetails: [zenworksToDelete],
//       };
//       await notifyApi.post(
//         `/add/agent-config/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       toast.success("Zenworks Update Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       fetchConfigData(); // Refresh DataTable after deletion
//       // If the API call is successful, update the state
//       const newZenworksDetails = zenworksDetails.filter((_, i) => i !== index);
//       setZenworksDetails(newZenworksDetails);
//     } catch (error) {
//       console.error("Error deleting Zenworks detail:", error);
//     }
//   };

//   const handleRemoveCertificationDetail = (index) => {
//     const updatedDetails = certificationDetails.filter((_, i) => i !== index);
//     setCertificationDetails(updatedDetails);
//   };
//   const handleDeleteCertificationDetail = async (index, id, userId) => {
//     try {
//       const certificationToDelete = certificationDetails[index];
//       // Make the API call to delete the configuration by ID
//       await notifyApi.delete(
//         `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//           data: {
//             certificationDetailsToDelete: [certificationToDelete],
//           },
//         }
//       );
//       toast.success("Certification Deleted Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       fetchConfigData(); // Refresh DataTable after deletion
//       // If the API call is successful, update the state
//       const newCertificationDetails = certificationDetails.filter(
//         (_, i) => i !== index
//       );
//       setCertificationDetails(newCertificationDetails);
//     } catch (error) {
//       console.error("Error deleting Certification detail:", error);
//     }
//   };

//   const handleRemoveCertificationDetailUpdate = async (index) => {
//     try {
//       const certificationToDelete = certificationDetails[index];
//       console.log(certificationToDelete, "certificationToDelete");
//       // Make the API call to delete the configuration by ID
//       const data = {
//         organizationId: user?.data?.user?.organizationId,
//         certificateDetails: [certificationToDelete],
//       };

//       await notifyApi.post(
//         `/add/agent-config/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       toast.success("Certification Deleted Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       fetchConfigData(); // Refresh DataTable after deletion
//       // If the API call is successful, update the state
//       const newCertificationDetails = certificationDetails.filter(
//         (_, i) => i !== index
//       );
//       setCertificationDetails(newCertificationDetails);
//     } catch (error) {
//       console.error("Error deleting Certification detail:", error);
//     }
//   };
//   const handleAddCertificationDetail = () => {
//     setCertificationDetails([
//       ...certificationDetails,
//       { certificateName: "", certificateVersion: "", isNew: true }  // New entry flagged as isNew
//     ]);
//   };
  
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const data = {
//       organizationId: user?.data?.user?.organizationId,
//       passwordThresholdAge: parseInt(passwordThresholdAge, 10)
//         ? parseInt(passwordThresholdAge, 10)
//         : columnData[0]?.passwordThresholdAge,
//       nonReportingThresholdDay: parseInt(nonReportingThresholdDay, 10)
//         ? parseInt(nonReportingThresholdDay, 10)
//         : columnData[0]?.nonReportingThresholdDay,
//       highCpuThresholdPercent: parseFloat(highCpuThresholdPercent)
//         ? parseFloat(highCpuThresholdPercent)
//         : columnData[0]?.highCpuThresholdPercent,
//       highMemoryThresholdPercent: parseFloat(highMemoryThresholdPercent)
//         ? parseFloat(highMemoryThresholdPercent)
//         : columnData[0]?.highMemoryThresholdPercent,
//       osDetails: osDetails ? osDetails : columnData[0]?.osDetails,
//       avDetails: avDetails ? avDetails : columnData[0]?.avDetails,
//       edrDetails: edrDetails ? edrDetails : columnData[0]?.edrDetails,
//       zenWorksDetails: zenworksDetails
//         ? zenworksDetails
//         : "columnData[0]?.zenWorksDetails",
//       certificateDetails: certificationDetails
//         ? certificationDetails
//         : "columnData[0]?.certificateDetails",
//     };
//     //  console.log(data, "ashok");

//     try {
//       const response = await notifyApi.post(
//         `/add/agent-config/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       toast.success(response?.data.message, {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       fetchConfigData(); // Refresh DataTable after adding new config
//     } catch (error) {
//       toast.error(
//         error?.response?.data.errors[0]?.msg ||
//           error?.response?.data.errors[1]?.msg
//       );
//     }
//   };

  
//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div
//         className="main main-app p-3 p-lg-4"
//         style={{ backgroundColor: "#ECF9FF" }}
//       >
//         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
//           <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
//             <p className="fs-18 fw-bolder p-0 m-0">
//               Admin Configurable Parameters
//             </p>
//           </span>
//         </div>

//         <div className="card rounded cardStyle" style={{ padding: "75px" }}>
//           <form onSubmit={handleSubmit}>
//             {/* Existing input fields */}
//             <div className="mb-3 row">
//               <label
//                 htmlFor="inputPermitted"
//                 className="col-sm-4 col-form-label"
//               >
//                 Password age permitted (Days)
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="inputPermitted"
//                   value={passwordThresholdAge}
//                   onChange={(e) => setPasswordThresholdAge(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="mb-3 row">
//               <label
//                 htmlFor="inputReporting"
//                 className="col-sm-4 col-form-label"
//               >
//                 Non-reporting threshold (Days)
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="inputReporting"
//                   value={nonReportingThresholdDay}
//                   onChange={(e) => setNonReportingThresholdDay(e.target.value)}
//                 />
//               </div>
//             </div>

//             <div className="mb-3 row">
//               <label htmlFor="inputCpu" className="col-sm-4 col-form-label">
//                 High CPU utilization threshold (%)
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="number"
//                   step="0.01"
//                   className="form-control"
//                   id="inputCpu"
//                   value={highCpuThresholdPercent}
//                   onChange={(e) => setHighCpuThresholdPercent(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="mb-3 row">
//               <label htmlFor="inputMemory" className="col-sm-4 col-form-label">
//                 High memory utilization threshold (%)
//               </label>
//               <div className="col-sm-5">
//                 <input
//                   type="number"
//                   step="0.01"
//                   className="form-control"
//                   id="inputMemory"
//                   value={highMemoryThresholdPercent}
//                   onChange={(e) =>
//                     setHighMemoryThresholdPercent(e.target.value)
//                   }
//                 />
//               </div>
//             </div>
//             <div
//               className="accordion my-3"
//               id="accordionExample"
//               style={{ width: "51rem" }}
//             >
//               <div className="accordion-item ">
//                 <h2 className="accordion-header" id="headingOne">
//                   <button
//                     className="accordion-button"
//                     type="button"
//                     onClick={toggleEdrAccordion}
//                     aria-expanded={isEdrOpen}
//                     aria-controls="collapseOne"
//                   >
//                     EDR Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseOne"
//                   className={`accordion-collapse collapse ${
//                     isEdrOpen ? "show" : ""
//                   }`}
//                   aria-labelledby="headingOne"
//                   data-bs-parent="#accordionExample"
//                 >
//                   {/* <div className="accordion-body">
//                     {edrDetails.map((edrDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`edrName${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             EDR Name
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`edrName${index}`}
//                               value={edrDetail.edrName}
//                               onChange={(e) =>
//                                 handleEdrDetailChange(
//                                   index,
//                                   "edrName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>

//                           <label
//                             htmlFor={`edrVersion${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             EDR Version
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`edrVersion${index}`}
//                               value={edrDetail.edrVersion}
//                               onChange={(e) =>
//                                 handleEdrDetailChange(
//                                   index,
//                                   "edrVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                           <div className="col-sm-2">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleDeleteEdrDetail(index)}
//                             >
//                               <i class="fa-solid fa-trash"></i>
//                             </button>
//                             &nbsp; &nbsp;
//                             <button
//                               className="btn btn-info"
//                               type="button"
//                               onClick={() => handleRemoveEdrDetail(index)}
//                             >
//                               <i class="fa-solid fa-minus"></i>
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={handleAddEdrDetail}
//                     >
//                       <i class="fa-solid fa-plus"></i>
//                     </button>
//                   </div> */}

//                   <div className="accordion-body">
//                     {edrDetails.map((edrDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`edrName${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             EDR Name
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`edrName${index}`}
//                               value={edrDetail.edrName}
//                               onChange={(e) =>
//                                 handleEdrDetailChange(
//                                   index,
//                                   "edrName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>

//                           <label
//                             htmlFor={`edrVersion${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             EDR Version
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`edrVersion${index}`}
//                               value={edrDetail.edrVersion}
//                               onChange={(e) =>
//                                 handleEdrDetailChange(
//                                   index,
//                                   "edrVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>

//                           <div className="col-sm-2">
//                             {edrDetail.isNew ? (
//                               // Show "Remove" button for newly added EDR details
//                               <button
//                                 className="btn btn-info"
//                                 type="button"
//                                 onClick={() => handleRemoveEdrDetail(index)}
//                               >
//                                 <i className="fa-solid fa-minus"></i>
//                               </button>
//                             ) : (
//                               // Show "Delete" button for existing EDR details
//                               <button
//                                 type="button"
//                                 className="btn btn-danger"
//                                 onClick={() => handleDeleteEdrDetail(index)}
//                               >
//                                 <i className="fa-solid fa-trash"></i>
//                               </button>
//                             )}
//                           </div>
//                         </div>
//                       </div>
//                     ))}

//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={handleAddEdrDetail}
//                     >
//                       <i className="fa-solid fa-plus"></i>
//                     </button>
//                   </div>
//                 </div>
//               </div>

//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingTwo">
//                   <button
//                     className="accordion-button"
//                     type="button"
//                     onClick={toggleAvAccordion}
//                     aria-expanded={isAvOpen}
//                     aria-controls="collapseTwo"
//                   >
//                     AV Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseTwo"
//                   className={`accordion-collapse collapse ${
//                     isAvOpen ? "show" : ""
//                   }`}
//                   aria-labelledby="headingTwo"
//                   data-bs-parent="#accordionExample2"
//                 >
//                   {/* <div className="accordion-body">
//                     {avDetails.map((avDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`avName${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             AV Name
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`avName${index}`}
//                               value={avDetail.avName}
//                               onChange={(e) =>
//                                 handleAvDetailChange(
//                                   index,
//                                   "avName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>

//                           <label
//                             htmlFor={`avVersion${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             AV Version
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`avVersion${index}`}
//                               value={avDetail.avVersion}
//                               onChange={(e) =>
//                                 handleAvDetailChange(
//                                   index,
//                                   "avVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                           <div className="col-sm-2">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleDeleteAvDetail(index)}
//                             >
//                               <i class="fa-solid fa-trash"></i>
//                             </button>
//                             &nbsp; &nbsp;
//                             <button
//                               type="button"
//                               className="btn btn-info"
//                               onClick={() => handleRemoveAvDetail(index)}
//                             >
//                               <i class="fa-solid fa-floppy-disk"></i>
//                             </button>
//                           </div>
//                         </div>
                     
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={handleAddAvDetail}
//                     >
//                       <i class="fa-solid fa-plus"></i>
//                     </button>
//                   </div> */}
//                   <div className="accordion-body">
//   {avDetails.map((avDetail, index) => (
//     <div key={index} className="mb-3">
//       <div className="row mb-2">
//         <label
//           htmlFor={`avName${index}`}
//           className="col-sm-2 col-form-label"
//         >
//           AV Name
//         </label>
//         <div className="col-sm-3">
//           <input
//             type="text"
//             className="form-control"
//             id={`avName${index}`}
//             value={avDetail.avName}
//             onChange={(e) =>
//               handleAvDetailChange(index, "avName", e.target.value)
//             }
//           />
//         </div>

//         <label
//           htmlFor={`avVersion${index}`}
//           className="col-sm-2 col-form-label"
//         >
//           AV Version
//         </label>
//         <div className="col-sm-3">
//           <input
//             type="text"
//             className="form-control"
//             id={`avVersion${index}`}
//             value={avDetail.avVersion}
//             onChange={(e) =>
//               handleAvDetailChange(index, "avVersion", e.target.value)
//             }
//           />
//         </div>

//         <div className="col-sm-2">
//           {avDetail.isNew ? (
//             // Show "Remove" button for newly added AV details
//             <button
//               className="btn btn-info"
//               type="button"
//               onClick={() => handleRemoveAvDetail(index)}
//             >
//               <i className="fa-solid fa-minus"></i>
//             </button>
//           ) : (
//             // Show "Delete" button for existing AV details
//             <button
//               type="button"
//               className="btn btn-danger"
//               onClick={() => handleDeleteAvDetail(index)}
//             >
//               <i className="fa-solid fa-trash"></i>
//             </button>
//           )}
//         </div>
//       </div>
//     </div>
//   ))}

//   <button
//     type="button"
//     className="btn btn-primary"
//     onClick={handleAddAvDetail}
//   >
//     <i className="fa-solid fa-plus"></i>
//   </button>
// </div>

//                 </div>
//               </div>

//               {/* Accordion Item */}
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingThree">
//                   <button
//                     className="accordion-button"
//                     type="button"
//                     onClick={toggleOsAccordion}
//                     aria-expanded={isOsOpen}
//                     aria-controls="collapseThree"
//                   >
//                     OS Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseThree"
//                   className={`accordion-collapse collapse ${
//                     isOsOpen ? "show" : ""
//                   }`}
//                   aria-labelledby="headingThree"
//                   data-bs-parent="#osDetailsAccordion"
//                 >
//                   {/* <div className="accordion-body">
//                     {osDetails.map((osDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`osName${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             OS Name
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`osName${index}`}
//                               value={osDetail.osName}
//                               onChange={(e) =>
//                                 handleOsDetailChange(
//                                   index,
//                                   "osName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                           <label
//                             htmlFor={`osVersion${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             OS Version
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`osVersion${index}`}
//                               value={osDetail.osVersion}
//                               onChange={(e) =>
//                                 handleOsDetailChange(
//                                   index,
//                                   "osVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>

//                           <div className="col-sm-2">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleDeleteOsDetail(index)}
//                             >
//                               <i class="fa-solid fa-trash"></i>
//                             </button>
//                             &nbsp; &nbsp;
//                             <button
//                               type="button"
//                               className="btn btn-info"
//                               onClick={() => handleRemoveOsDetail(index)}
//                             >
//                               <i class="fa-solid fa-floppy-disk"></i>
//                             </button>
//                           </div>
//                         </div>
                      
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={() =>
//                         setOsDetails([
//                           ...osDetails,
//                           { osName: "", osVersion: "" },
//                         ])
//                       }
//                     >
//                       <i class="fa-solid fa-plus"></i>
//                     </button>
//                   </div> */}
//                   <div className="accordion-body">
//   {osDetails.map((osDetail, index) => (
//     <div key={index} className="mb-3">
//       <div className="row mb-2">
//         <label
//           htmlFor={`osName${index}`}
//           className="col-sm-2 col-form-label"
//         >
//           OS Name
//         </label>
//         <div className="col-sm-3">
//           <input
//             type="text"
//             className="form-control"
//             id={`osName${index}`}
//             value={osDetail.osName}
//             onChange={(e) =>
//               handleOsDetailChange(index, "osName", e.target.value)
//             }
//           />
//         </div>

//         <label
//           htmlFor={`osVersion${index}`}
//           className="col-sm-2 col-form-label"
//         >
//           OS Version
//         </label>
//         <div className="col-sm-3">
//           <input
//             type="text"
//             className="form-control"
//             id={`osVersion${index}`}
//             value={osDetail.osVersion}
//             onChange={(e) =>
//               handleOsDetailChange(index, "osVersion", e.target.value)
//             }
//           />
//         </div>

//         <div className="col-sm-2">
//           {osDetail.isNew ? (
//             // Show "Remove" button for newly added OS details
//             <button
//               className="btn btn-info"
//               type="button"
//               onClick={() => handleRemoveOsDetail(index)}
//             >
//               <i className="fa-solid fa-minus"></i>
//             </button>
//           ) : (
//             // Show "Delete" button for existing OS details
//             <button
//               type="button"
//               className="btn btn-danger"
//               onClick={() => handleDeleteOsDetail(index)}
//             >
//               <i className="fa-solid fa-trash"></i>
//             </button>
//           )}
//         </div>
//       </div>
//     </div>
//   ))}

//   <button
//     type="button"
//     className="btn btn-primary"
//     onClick={handleAddOsDetail}
//   >
//     <i className="fa-solid fa-plus"></i>
//   </button>
// </div>

//                 </div>
//               </div>

//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingZenworks">
//                   <button
//                     className="accordion-button"
//                     type="button"
//                     onClick={toggleZenworksAccordion}
//                     aria-expanded={isZenworksOpen}
//                     aria-controls="collapseZenworks"
//                   >
//                     Zenworks Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseZenworks"
//                   className={`accordion-collapse collapse ${
//                     isZenworksOpen ? "show" : ""
//                   }`}
//                   aria-labelledby="headingZenworks"
//                   data-bs-parent="#osDetailsAccordion"
//                 >
//                   {/* <div className="accordion-body">
//                     {zenworksDetails.map((zenworksDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`zenWokrs${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             Zenworks Name
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`zenWokrs${index}`}
//                               value={zenworksDetail.zenWorks}
//                               onChange={(e) =>
//                                 handleZenworksDetailChange(
//                                   index,
//                                   "zenWokrs",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                           <label
//                             htmlFor={`zenWokrsVersion${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             Zenworks Version
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`zenWokrsVersion${index}`}
//                               value={zenworksDetail.zenWorksVersion}
//                               onChange={(e) =>
//                                 handleZenworksDetailChange(
//                                   index,
//                                   "zenWokrsVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>

//                           <div className="col-sm-2">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleDeleteZenworksDetail(index)}
//                             >
//                               <i class="fa-solid fa-trash"></i>
//                             </button>
//                             &nbsp; &nbsp;
//                             <button
//                               type="button"
//                               className="btn btn-info"
//                               onClick={() => handleRemoveZenworksDetail(index)}
//                             >
//                               <i class="fa-solid fa-floppy-disk"></i>
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={() =>
//                         setZenworksDetails([
//                           ...zenworksDetails,
//                           { zenWokrs: "", zenWokrsVersion: "" },
//                         ])
//                       }
//                     >
//                       <i class="fa-solid fa-plus"></i>
//                     </button>
//                   </div> */}
//                   <div className="accordion-body">
//   {zenworksDetails.map((zenworksDetail, index) => (
//     <div key={index} className="mb-3">
//       <div className="row mb-2">
//         <label
//           htmlFor={`zenWokrs${index}`}
//           className="col-sm-2 col-form-label"
//         >
//           Zenworks Name
//         </label>
//         <div className="col-sm-3">
//           <input
//             type="text"
//             className="form-control"
//             id={`zenWokrs${index}`}
//             value={zenworksDetail.zenWorks}
//             onChange={(e) =>
//               handleZenworksDetailChange(
//                 index,
//                 "zenWokrs",
//                 e.target.value
//               )
//             }
//           />
//         </div>

//         <label
//           htmlFor={`zenWokrsVersion${index}`}
//           className="col-sm-2 col-form-label"
//         >
//           Zenworks Version
//         </label>
//         <div className="col-sm-3">
//           <input
//             type="text"
//             className="form-control"
//             id={`zenWokrsVersion${index}`}
//             value={zenworksDetail.zenWorksVersion}
//             onChange={(e) =>
//               handleZenworksDetailChange(
//                 index,
//                 "zenWokrsVersion",
//                 e.target.value
//               )
//             }
//           />
//         </div>

//         <div className="col-sm-2">
//           {zenworksDetail.isNew ? (
//             // Show "Remove" button for newly added Zenworks details
//             <button
//               type="button"
//               className="btn btn-info"
//               onClick={() => handleRemoveZenworksDetail(index)}
//             >
//               <i className="fa-solid fa-minus"></i>
//             </button>
//           ) : (
//             // Show "Delete" button for existing Zenworks details
//             <button
//               type="button"
//               className="btn btn-danger"
//               onClick={() => handleDeleteZenworksDetail(index)}
//             >
//               <i className="fa-solid fa-trash"></i>
//             </button>
//           )}
//         </div>
//       </div>
//     </div>
//   ))}

//   <button
//     type="button"
//     className="btn btn-primary"
//     onClick={handleAddZenworksDetail}
//   >
//     <i className="fa-solid fa-plus"></i>
//   </button>
// </div>

//                 </div>
//               </div>

//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingCertification">
//                   <button
//                     className="accordion-button"
//                     type="button"
//                     onClick={toggleCertificationAccordion}
//                     aria-expanded={isCertificationOpen}
//                     aria-controls="collapseCertification"
//                   >
//                     Certification Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseCertification"
//                   className={`accordion-collapse collapse ${
//                     isCertificationOpen ? "show" : ""
//                   }`}
//                   aria-labelledby="headingCertification"
//                   data-bs-parent="#osDetailsAccordion"
//                 >
//                   {/* <div className="accordion-body">
//                     {certificationDetails.map((certificationDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`certificateName${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             Certification Name
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`certificateName${index}`}
//                               value={certificationDetail.certificateName}
//                               onChange={(e) =>
//                                 handleCertificationDetailChange(
//                                   index,
//                                   "certificateName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                           <label
//                             htmlFor={`certificateVersion${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             Certification Version
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`certificateVersion${index}`}
//                               value={certificationDetail.certificateVersion}
//                               onChange={(e) =>
//                                 handleCertificationDetailChange(
//                                   index,
//                                   "certificateVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>

//                           <div className="col-sm-2">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() =>
//                                 handleDeleteCertificationDetail(index)
//                               }
//                             >
//                               <i class="fa-solid fa-trash"></i>
//                             </button>
//                             &nbsp; &nbsp;
//                             <button
//                               type="button"
//                               className="btn btn-info"
//                               onClick={() =>
//                                 handleRemoveCertificationDetail(index)
//                               }
//                             >
//                               <i class="fa-solid fa-floppy-disk"></i>
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={() =>
//                         setCertificationDetails([
//                           ...certificationDetails,
//                           { certificationName: "", certificationVersion: "" },
//                         ])
//                       }
//                     >
//                       <i class="fa-solid fa-plus"></i>
//                     </button>
//                   </div> */}
//                   <div className="accordion-body">
//   {certificationDetails.map((certificationDetail, index) => (
//     <div key={index} className="mb-3">
//       <div className="row mb-2">
//         <label
//           htmlFor={`certificateName${index}`}
//           className="col-sm-2 col-form-label"
//         >
//           Certification Name
//         </label>
//         <div className="col-sm-3">
//           <input
//             type="text"
//             className="form-control"
//             id={`certificateName${index}`}
//             value={certificationDetail.certificateName}
//             onChange={(e) =>
//               handleCertificationDetailChange(
//                 index,
//                 "certificateName",
//                 e.target.value
//               )
//             }
//           />
//         </div>

//         <label
//           htmlFor={`certificateVersion${index}`}
//           className="col-sm-2 col-form-label"
//         >
//           Certification Version
//         </label>
//         <div className="col-sm-3">
//           <input
//             type="text"
//             className="form-control"
//             id={`certificateVersion${index}`}
//             value={certificationDetail.certificateVersion}
//             onChange={(e) =>
//               handleCertificationDetailChange(
//                 index,
//                 "certificateVersion",
//                 e.target.value
//               )
//             }
//           />
//         </div>

//         <div className="col-sm-2">
//           {certificationDetail.isNew ? (
//             // Show "Remove" button for newly added Certification details
//             <button
//               type="button"
//               className="btn btn-info"
//               onClick={() => handleRemoveCertificationDetail(index)}
//             >
//               <i className="fa-solid fa-minus"></i>
//             </button>
//           ) : (
//             // Show "Delete" button for existing Certification details
//             <button
//               type="button"
//               className="btn btn-danger"
//               onClick={() => handleDeleteCertificationDetail(index)}
//             >
//               <i className="fa-solid fa-trash"></i>
//             </button>
//           )}
//         </div>
//       </div>
//     </div>
//   ))}

//   <button
//     type="button"
//     className="btn btn-primary"
//     onClick={handleAddCertificationDetail}
//   >
//     <i className="fa-solid fa-plus"></i>
//   </button>
// </div>

//                 </div>
//               </div>

//               <div className="mt-2 mb-3">
//                 <div className="col-sm-5">
//                   <button type="submit" className="btn btn-success">
//                     Save Configuration
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// }

// export default AdminConfig;


// import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// // import Header from "../../layouts/Header";
// import DataTable from "react-data-table-component";
// import { useSelector } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { notifyApi } from "../../api/axiosSet";
// import { useNavigate } from "react-router-dom";

// function AdminConfig() {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const [skin, setSkin] = useState(currentSkin);
//   const [passwordThresholdAge, setPasswordThresholdAge] = useState("");
//   const [nonReportingThresholdDay, setNonReportingThresholdDay] = useState("");
//   const [highCpuThresholdPercent, setHighCpuThresholdPercent] = useState("");
//   const [highMemoryThresholdPercent, setHighMemoryThresholdPercent] =
//     useState("");
//   const [edrName, setEdrName] = useState("");
//   const [edrVersion, setEdrVersion] = useState("");
//   const [avName, setAvName] = useState("");
//   const [avVersion, setAvVersion] = useState("");

//   // New state variables for OS details
//   const [osDetails, setOsDetails] = useState([]);
//   const [edrDetails, setEdrDetails] = useState([]);
//   const [avDetails, setAvDetails] = useState([]);
//   const [zenworksDetails, setZenworksDetails] = useState([]);
//   const [certificationDetails, setCertificationDetails] = useState([]);
//   const [fileIntegrityInfo, setFileIntegrityInfo] = useState([]);

//   const [isEdrOpen, setIsEdrOpen] = useState(false);
//   const [isAvOpen, setIsAvOpen] = useState(false);
//   const [isOsOpen, setIsOsOpen] = useState(false);
//   const [isZenworksOpen, setIsZenworksOpen] = useState(false);
//   const [isCertificationOpen, setIsCertificationOpen] = useState(false);
//   const toggleEdrAccordion = () => setIsEdrOpen(!isEdrOpen);
//   const toggleAvAccordion = () => setIsAvOpen(!isAvOpen);
//   const toggleOsAccordion = () => setIsOsOpen(!isOsOpen);
//   const toggleZenworksAccordion = () => setIsZenworksOpen(!isZenworksOpen);
//   const toggleCertificationAccordion = () =>
//     setIsCertificationOpen(!isCertificationOpen);
//   const { user } = useSelector((state) => state.authSlice);
//   const [columnData, setColumnData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [getRespId, setGetRespId] = useState();

//   // Fetch agent configuration data
//   const fetchConfigData = async () => {
//     try {
//       const response = await notifyApi.get(
//         `/agent-configs/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       setColumnData(response.data.data);
//       const resData = response.data.data[0];
//       setLoading(false);
//       setGetRespId(response.data.data[0]._id);
//       setAvDetails(resData.avDetails);
//       setEdrDetails(resData.edrDetails);
//       setOsDetails(resData.osDetails);
//       setZenworksDetails(resData.zenWorksDetails);
//       setCertificationDetails(resData.certificateDetails);
//       setPasswordThresholdAge(resData.passwordThresholdAge);
//       setNonReportingThresholdDay(resData.nonReportingThresholdDay);
//       setHighCpuThresholdPercent(resData.highCpuThresholdPercent);
//       setHighMemoryThresholdPercent(resData.highMemoryThresholdPercent);
//       setFileIntegrityInfo(resData.fileIntegrityInfo);
//     } catch (error) {
//       console.error("Error fetching configuration data:", error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchConfigData();
//   }, [user]);

//   // Handle change in OS details
//   const handleOsDetailChange = (index, field, value) => {
//     const newOsDetails = [...osDetails];
//     newOsDetails[index] = { ...newOsDetails[index], [field]: value };
//     setOsDetails(newOsDetails);
//   };

//   const handleRemoveOsDetail = async (index) => {
//     const ask = window.confirm("Are you sure you want to delete this OS?");
//     if (!ask) {
//       return;
//     }
//     try {
//       const osToDelete = osDetails[index];
//       // Make the API call to delete the configuration by ID
//       await notifyApi.delete(
//         `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//           data: {
//             osDetailsToDelete: [{ "_id": osToDelete?._id }],
//           },
//         }
//       );
//       toast.success("OS Deleted Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       const updatedOsDetails = osDetails.filter((_, i) => i !== index);
//       setOsDetails([...updatedOsDetails]);
//     } catch (error) {
//       console.error("Error deleting OS detail:", error);
//     }
//   };

//   // EDR Handlers
//   const handleEdrDetailChange = (index, field, value) => {
//     const updatedEdrDetails = [...edrDetails];
//     updatedEdrDetails[index][field] = value;
//     setEdrDetails([...updatedEdrDetails]);
//   };

//   const handleAddEdrDetail = () => {
//     setEdrDetails([...edrDetails, { edrName: "", edrVersion: "" }]);
//   };

//   const handleRemoveEdrDetail = async (index) => {
//     const ask = window.confirm("Are you sure you want to delete this EDR?");
//     if (!ask) {
//       return;
//     }
//     try {
//       const edrToDelete = edrDetails[index];
//       // Make the API call to delete the configuration by ID
//       await notifyApi.delete(
//         `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//           data: {
//             edrDetailsToDelete: [{ "_id": edrToDelete?._id }],
//           },
//         }
//       );

//       toast.success("EDR Deleted Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });

//       const updatedEdrDetails = edrDetails.filter((_, i) => i !== index);
//       setEdrDetails([...updatedEdrDetails]);
//     } catch (error) {
//       console.error("Error deleting EDR detail:", error);
//     }
//   };

//   const handleAvDetailChange = (index, field, value) => {
//     const updatedAvDetails = [...avDetails];
//     updatedAvDetails[index][field] = value;
//     setAvDetails(updatedAvDetails);
//   };

//   const handleAddAvDetail = () => {
//     setAvDetails([...avDetails, { avName: "", avVersion: "" }]);
//   };

//   const handleRemoveAvDetail = async (index) => {
//     const ask = window.confirm("Are you sure you want to delete this AV?");
//     if (!ask) {
//       return;
//     }
//     const avToDelete = avDetails[index];

//     try {
//       // Make the API call to delete the configuration by ID
//       await notifyApi.delete(
//         `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//           data: {
//             avDetailsToDelete: [{ "_id": avToDelete?._id }],
//           },
//         }
//       );

//       toast.success("AV Deleted Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });

//       const updatedAvDetails = avDetails.filter((_, i) => i !== index);
//       setAvDetails([...updatedAvDetails]);
//     } catch (error) {
//       console.error("Error deleting AV detail:", error);
//     }
//   };

//   const handleZenworksDetailChange = (index, field, value) => {
//     const updatedDetails = [...zenworksDetails];
//     updatedDetails[index] = { ...updatedDetails[index], [field]: value };
//     setZenworksDetails(updatedDetails);
//   };

//   const handleCertificationDetailChange = (index, field, value) => {
//     const updatedDetails = [...certificationDetails];
//     updatedDetails[index][field] = value;
//     setCertificationDetails(updatedDetails);
//   };

//   const handleRemoveZenworksDetail = async (index) => {
//     const ask = window.confirm("Are you sure you want to delete this Zenworks?");
//     if (!ask) {
//       return;
//     }
//     try {
//       const zenworksToDelete = zenworksDetails[index];
//       // Make the API call to delete the configuration by ID
//       await notifyApi.delete(
//         `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//           data: {
//             zenWorksDetailsToDelete: [{ "_id": zenworksToDelete?._id }],
//           },
//         }
//       );
//       toast.success("Zenworks Deleted Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });

//       const newZenworksDetails = zenworksDetails.filter((_, i) => i !== index);
//       setZenworksDetails(newZenworksDetails);
//     } catch (error) {
//       console.error("Error deleting Zenworks detail:", error);
//     }
//   };


//   const handleRemoveCertificationDetail = async (index) => {
//     const ask = window.confirm("Are you sure you want to delete this Certification?");
//     if (!ask) {
//       return;
//     }
//     try {
//       const certificationToDelete = certificationDetails[index];
//       // Make the API call to delete the configuration by ID
//       await notifyApi.delete(
//         `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//           data: {
//             certificateDetailsToDelete: [{ "_id": certificationToDelete?._id }],
//           },
//         }
//       );
//       toast.success("Certification Deleted Successfully", {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });

//       const newCertificationDetails = certificationDetails.filter(
//         (_, i) => i !== index
//       );
//       setCertificationDetails(newCertificationDetails);
//     } catch (error) {
//       console.error("Error deleting Certification detail:", error);
//     }
//   };

//   function validateData(data) {
//     const errors = [];

//     if (data.passwordThresholdAge < 0) {
//       errors.push("Password threshold age cannot be negative.");
//     }
//     if (data.nonReportingThresholdDay < 0) {
//       errors.push("Non-reporting threshold day cannot be negative.");
//     }
//     if (data.highCpuThresholdPercent < 0) {
//       errors.push("High CPU threshold percent cannot be negative.");
//     }
//     if (data.highMemoryThresholdPercent < 0) {
//       errors.push("High memory threshold percent cannot be negative.");
//     }

//     if (data.highCpuThresholdPercent > 100) {
//       errors.push("High CPU threshold percent cannot be greater than 100.");
//     }
//     if (data.highMemoryThresholdPercent > 100) {
//       errors.push("High memory threshold percent cannot be greater than 100.");
//     }
//     function areObjectValuesNotEmptyOrSpaces(objectsArray) {
//       objectsArray.forEach((obj, index) => {
//         for (const key in obj) {
//           if (obj.hasOwnProperty(key)) {
//             const value = obj[key];
//             if (!value || value.trim().length === 0) {
//               errors.push(`Value for "${key}" in object at index ${index} is empty or consists only of spaces.`);
//             }
//           }
//         }
//       });

//       return {
//         isValid: errors.length === 0,
//         errors: errors
//       };
//     }
//     areObjectValuesNotEmptyOrSpaces(data.edrDetails || []);
//     areObjectValuesNotEmptyOrSpaces(data.osDetails || []);
//     areObjectValuesNotEmptyOrSpaces(data.avDetails || []);
//     areObjectValuesNotEmptyOrSpaces(data.zenWorksDetails || []);
//     areObjectValuesNotEmptyOrSpaces(data.certificateDetails || []);
//     return {
//       isValid: errors.length === 0,
//       errors: errors
//     };
//   }

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const data = {
//       organizationId: user?.data?.user?.organizationId,
//       passwordThresholdAge: parseInt(passwordThresholdAge, 10)
//         ? parseInt(passwordThresholdAge, 10)
//         : columnData[0]?.passwordThresholdAge,
//       nonReportingThresholdDay: parseInt(nonReportingThresholdDay, 10)
//         ? parseInt(nonReportingThresholdDay, 10)
//         : columnData[0]?.nonReportingThresholdDay,
//       highCpuThresholdPercent: parseFloat(highCpuThresholdPercent)
//         ? parseFloat(highCpuThresholdPercent)
//         : columnData[0]?.highCpuThresholdPercent,
//       highMemoryThresholdPercent: parseFloat(highMemoryThresholdPercent)
//         ? parseFloat(highMemoryThresholdPercent)
//         : columnData[0]?.highMemoryThresholdPercent,
//       osDetails: osDetails ? osDetails : columnData[0]?.osDetails,
//       avDetails: avDetails ? avDetails : columnData[0]?.avDetails,
//       edrDetails: edrDetails ? edrDetails : columnData[0]?.edrDetails,
//       zenWorksDetails: zenworksDetails
//         ? zenworksDetails
//         : columnData[0]?.zenWorksDetails,
//       certificateDetails: certificationDetails
//         ? certificationDetails
//         : columnData[0]?.certificateDetails,
//     };

//     const { isValid, errors } = validateData(data);
//     if (!isValid) {
//       toast.error(errors[0], {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       return;
//     }

//     try {
//       let response = null;
//       if (!getRespId) {
//         response = await notifyApi.post(
//           `/add/agent-config/${user?.data?.user?.userId}`,
//           data,
//           {
//             headers: {
//               Authorization: `Bearer ${user?.data?.accessToken}`,
//             },
//           }
//         );
//       }
//       else {
//         response = await notifyApi.put(
//           `/update/agent-config/${user?.data?.user?.userId}`,
//           data,
//           {
//             headers: {
//               Authorization: `Bearer ${user?.data?.accessToken}`,
//             },
//           }
//         );
//       }

//       toast.success(response?.data.message, {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       fetchConfigData();
//     } catch (error) {
//       toast.error(
//         error?.response?.data.errors[0]?.msg ||
//         error?.response?.data.errors[1]?.msg
//       );
//     }
//   };

//   const [file, setFile] = useState(null);

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const uploadFile = async () => {
//     if (!file) {
//       alert('Please select a file first.');
//       return;
//     }

//     const formData = new FormData();
//     formData.append('organizationId', user?.data?.user?.organizationId);
//     formData.append('file', file);

//     try {
//       const response = await notifyApi.post(
//         `/upload/file-integrity-info/${user?.data?.user?.userId}`,
//         formData,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//             'Content-Type': 'multipart/form-data',
//           },
//         }
//       );
//       toast.success(response.data.message, {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       fetchConfigData();
//     } catch (error) {
//       toast.error(
//         "Error uploading file. Please try again later."
//       )
//     }
//   };

//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#D7E3E8",
//       },
//     },
//   };

//   return (
//     <React.Fragment>
//       {/* <Header onSkin={setSkin} /> */}
//       <div
//         className="main main-app p-3 p-lg-4"
//         style={{ backgroundColor: "#ECF9FF" }}
//       >
//         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
//           <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
//             <p className="fs-18 fw-bolder p-0 m-0">
//               Configurable Parameters
//             </p>
//           </span>
//         </div>

//         <div className="card rounded cardStyle" style={{ padding: "3rem" }}>
//           <form onSubmit={handleSubmit}>
//             {/* Existing input fields */}
//             <div className="mb-3 row">
//               <label
//                 htmlFor="inputPermitted"
//                 className="col-sm-4 col-form-label"
//               >
//                 Password age permitted (Days)
//               </label>
//               <div className="col-sm-2">
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="inputPermitted"
//                   placeholder="90 will be set as default"
//                   value={passwordThresholdAge}
//                   onChange={(e) => setPasswordThresholdAge(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="mb-3 row">
//               <label
//                 htmlFor="inputReporting"
//                 className="col-sm-4 col-form-label"
//               >
//                 Non-reporting threshold (Days)
//               </label>
//               <div className="col-sm-2">
//                 <input
//                   type="number"
//                   className="form-control"
//                   id="inputReporting"
//                   value={nonReportingThresholdDay}
//                   placeholder="5 will be set as default"
//                   onChange={(e) => setNonReportingThresholdDay(e.target.value)}
//                 />
//               </div>
//             </div>

//             <div className="mb-3 row">
//               <label htmlFor="inputCpu" className="col-sm-4 col-form-label">
//                 High CPU utilization threshold (%)
//               </label>
//               <div className="col-sm-2">
//                 <input
//                   type="number"
//                   step="0.01"
//                   className="form-control"
//                   id="inputCpu"
//                   value={highCpuThresholdPercent}
//                   placeholder="4 will be set as default"
//                   onChange={(e) => setHighCpuThresholdPercent(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="mb-3 row">
//               <label htmlFor="inputMemory" className="col-sm-4 col-form-label">
//                 High memory utilization threshold (%)
//               </label>
//               <div className="col-sm-2">
//                 <input
//                   type="number"
//                   step="0.01"
//                   className="form-control"
//                   id="inputMemory"
//                   value={highMemoryThresholdPercent}
//                   placeholder="4 will be set as default"
//                   onChange={(e) =>
//                     setHighMemoryThresholdPercent(e.target.value)
//                   }
//                 />
//               </div>
//             </div>
//             <div
//               className="accordion my-3 w-50"
//               id="accordionExample"
//             >
//               <div className="accordion-item ">
//                 <h2 className="accordion-header" id="headingOne">
//                   <button
//                     className={`accordion-button ${isEdrOpen ? '' : 'collapsed'}`}
//                     type="button"
//                     onClick={toggleEdrAccordion}
//                     aria-expanded={isEdrOpen}
//                     aria-controls="collapseOne"
//                   >
//                     EDR Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseOne"
//                   className={`accordion-collapse collapse ${isEdrOpen ? "show" : ""
//                     }`}
//                   aria-labelledby="headingOne"
//                   data-bs-parent="#accordionExample"
//                 >
//                   <div className="accordion-body">
//                     {edrDetails.map((edrDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`edrName${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             EDR Name
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`edrName${index}`}
//                               value={edrDetail.edrName}
//                               onChange={(e) =>
//                                 handleEdrDetailChange(
//                                   index,
//                                   "edrName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>

//                           <label
//                             htmlFor={`edrVersion${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             EDR Version
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`edrVersion${index}`}
//                               value={edrDetail.edrVersion}
//                               onChange={(e) =>
//                                 handleEdrDetailChange(
//                                   index,
//                                   "edrVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                           <div className="col-sm-2">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleRemoveEdrDetail(index)}
//                             >
//                               <i className="fa-solid fa-trash"></i>
//                             </button>
//                             &nbsp; &nbsp;
//                             {/* <button
//                               className="btn btn-info"
//                               type="button"
//                               onClick={() => handleRemoveEdrDetailUpdate(index)}
//                             >
//                               <i className="fa-solid fa-floppy-disk"></i>
//                             </button> */}
//                           </div>
//                         </div>
//                         {/* <div className="row mb-2">
//                           <label
//                             htmlFor={`edrVersion${index}`}
//                             className="col-sm-4 col-form-label"
//                           >
//                             EDR Version
//                           </label>
//                           <div className="col-sm-5">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`edrVersion${index}`}
//                               value={edrDetail.edrVersion}
//                               onChange={(e) =>
//                                 handleEdrDetailChange(
//                                   index,
//                                   "edrVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                         </div> */}
//                         {/* <div className="row mb-2">
//                           <div className="col-sm-5">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleRemoveEdrDetail(index)}
//                             >
//                               Remove
//                             </button>
//                           </div>
//                         </div> */}
//                       </div>
//                     ))}
//                     {edrDetails.length < 10 && <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={handleAddEdrDetail}
//                     >
//                       <i className="fa-solid fa-plus"></i>
//                     </button>}
//                   </div>
//                 </div>
//               </div>

//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingTwo">
//                   <button
//                     className={`accordion-button ${isAvOpen ? '' : 'collapsed'}`}
//                     type="button"
//                     onClick={toggleAvAccordion}
//                     aria-expanded={isAvOpen}
//                     aria-controls="collapseTwo"
//                   >
//                     AV Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseTwo"
//                   className={`accordion-collapse collapse ${isAvOpen ? "show" : ""
//                     }`}
//                   aria-labelledby="headingTwo"
//                   data-bs-parent="#accordionExample2"
//                 >
//                   <div className="accordion-body">
//                     {avDetails.map((avDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`avName${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             AV Name
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`avName${index}`}
//                               value={avDetail.avName}
//                               onChange={(e) =>
//                                 handleAvDetailChange(
//                                   index,
//                                   "avName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>

//                           <label
//                             htmlFor={`avVersion${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             AV Version
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`avVersion${index}`}
//                               value={avDetail.avVersion}
//                               onChange={(e) =>
//                                 handleAvDetailChange(
//                                   index,
//                                   "avVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                           <div className="col-sm-2">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleRemoveAvDetail(index)}
//                             >
//                               <i className="fa-solid fa-trash"></i>
//                             </button>
//                             &nbsp; &nbsp;
//                             {/* <button
//                               type="button"
//                               className="btn btn-info"
//                               onClick={() => handleAvDetailUpdate(index)}
//                             >
//                               <i className="fa-solid fa-floppy-disk"></i>
//                             </button> */}
//                           </div>
//                         </div>
//                         {/* <div className="row mb-2">
//                           <label
//                             htmlFor={`avVersion${index}`}
//                             className="col-sm-4 col-form-label"
//                           >
//                             AV Version
//                           </label>
//                           <div className="col-sm-5">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`avVersion${index}`}
//                               value={avDetail.avVersion}
//                               onChange={(e) =>
//                                 handleAvDetailChange(
//                                   index,
//                                   "avVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                         </div> */}
//                         {/* <div className="row mb-2">
//                           <div className="col-sm-5">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleRemoveAvDetail(index)}
//                             >
//                               Remove
//                             </button>
//                           </div>
//                         </div> */}
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={handleAddAvDetail}
//                     >
//                       <i className="fa-solid fa-plus"></i>
//                     </button>
//                   </div>
//                 </div>
//               </div>

//               {/* Accordion Item */}
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingThree">
//                   <button
//                     className={`accordion-button ${isOsOpen ? '' : 'collapsed'}`}
//                     type="button"
//                     onClick={toggleOsAccordion}
//                     aria-expanded={isOsOpen}
//                     aria-controls="collapseThree"
//                   >
//                     OS Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseThree"
//                   className={`accordion-collapse collapse ${isOsOpen ? "show" : ""
//                     }`}
//                   aria-labelledby="headingThree"
//                   data-bs-parent="#osDetailsAccordion"
//                 >
//                   <div className="accordion-body">
//                     {osDetails.map((osDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`osName${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             OS Name
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`osName${index}`}
//                               value={osDetail.osName}
//                               onChange={(e) =>
//                                 handleOsDetailChange(
//                                   index,
//                                   "osName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                           <label
//                             htmlFor={`osVersion${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             OS Version
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`osVersion${index}`}
//                               value={osDetail.osVersion}
//                               onChange={(e) =>
//                                 handleOsDetailChange(
//                                   index,
//                                   "osVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>

//                           <div className="col-sm-2">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleRemoveOsDetail(index)}
//                             >
//                               <i className="fa-solid fa-trash"></i>
//                             </button>
//                             &nbsp; &nbsp;
//                             {/* <button
//                               type="button"
//                               className="btn btn-info"
//                               onClick={() => handleOsDetailUpdate(index)}
//                             >
//                               <i className="fa-solid fa-floppy-disk"></i>
//                             </button> */}
//                           </div>
//                         </div>
//                         {/* <div className="row mb-2">
//                           <label
//                             htmlFor={`osVersion${index}`}
//                             className="col-sm-4 col-form-label"
//                           >
//                             OS Version
//                           </label>
//                           <div className="col-sm-5">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`osVersion${index}`}
//                               value={osDetail.osVersion}
//                               onChange={(e) =>
//                                 handleOsDetailChange(
//                                   index,
//                                   "osVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                         </div> */}
//                         {/* <div className="row mb-2">
//                           <div className="col-sm-5">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleRemoveOsDetail(index)}
//                             >
//                               Remove
//                             </button>
//                           </div>
//                         </div> */}
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={() =>
//                         setOsDetails([
//                           ...osDetails,
//                           { osName: "", osVersion: "" },
//                         ])
//                       }
//                     >
//                       <i className="fa-solid fa-plus"></i>
//                     </button>
//                   </div>
//                 </div>
//               </div>

//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingZenworks">
//                   <button
//                     className={`accordion-button ${isZenworksOpen ? '' : 'collapsed'}`}
//                     type="button"
//                     onClick={toggleZenworksAccordion}
//                     aria-expanded={isZenworksOpen}
//                     aria-controls="collapseZenworks"
//                   >
//                     Zenworks Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseZenworks"
//                   className={`accordion-collapse collapse ${isZenworksOpen ? "show" : ""
//                     }`}
//                   aria-labelledby="headingZenworks"
//                   data-bs-parent="#osDetailsAccordion"
//                 >
//                   <div className="accordion-body">
//                     {zenworksDetails.map((zenworksDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`zenWorks${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             Zenworks Name
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`zenWorks${index}`}
//                               value={zenworksDetail.zenWorks}
//                               onChange={(e) =>
//                                 handleZenworksDetailChange(
//                                   index,
//                                   "zenWorks",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                           <label
//                             htmlFor={`zenWorksVersion${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             Zenworks Version
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`zenWorksVersion${index}`}
//                               value={zenworksDetail.zenWorksVersion}
//                               onChange={(e) =>
//                                 handleZenworksDetailChange(
//                                   index,
//                                   "zenWorksVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>

//                           <div className="col-sm-2">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() => handleRemoveZenworksDetail(index)}
//                             >
//                               <i className="fa-solid fa-trash"></i>
//                             </button>
//                             &nbsp; &nbsp;
//                             {/* <button
//                               type="button"
//                               className="btn btn-info"
//                               onClick={() => handleZenworksDetailUpdate(index)}
//                             >
//                               <i className="fa-solid fa-floppy-disk"></i>
//                             </button> */}
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={() =>
//                         setZenworksDetails([
//                           ...zenworksDetails,
//                           { zenWorks: "", zenWorksVersion: "" },
//                         ])
//                       }
//                     >
//                       <i className="fa-solid fa-plus"></i>
//                     </button>
//                   </div>
//                 </div>
//               </div>

//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingCertification">
//                   <button
//                     className={`accordion-button ${isCertificationOpen ? '' : 'collapsed'}`}
//                     type="button"
//                     onClick={toggleCertificationAccordion}
//                     aria-expanded={isCertificationOpen}
//                     aria-controls="collapseCertification"
//                   >
//                     Certification Details
//                   </button>
//                 </h2>
//                 <div
//                   id="collapseCertification"
//                   className={`accordion-collapse collapse ${isCertificationOpen ? "show" : ""
//                     }`}
//                   aria-labelledby="headingCertification"
//                   data-bs-parent="#osDetailsAccordion"
//                 >
//                   <div className="accordion-body">
//                     {certificationDetails.map((certificationDetail, index) => (
//                       <div key={index} className="mb-3">
//                         <div className="row mb-2">
//                           <label
//                             htmlFor={`certificateName${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             Certification Name
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`certificateName${index}`}
//                               value={certificationDetail.certificateName}
//                               onChange={(e) =>
//                                 handleCertificationDetailChange(
//                                   index,
//                                   "certificateName",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>
//                           <label
//                             htmlFor={`certificateVersion${index}`}
//                             className="col-sm-2 col-form-label"
//                           >
//                             Certification Version
//                           </label>
//                           <div className="col-sm-3">
//                             <input
//                               type="text"
//                               className="form-control"
//                               id={`certificateVersion${index}`}
//                               value={certificationDetail.certificateVersion}
//                               onChange={(e) =>
//                                 handleCertificationDetailChange(
//                                   index,
//                                   "certificateVersion",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           </div>

//                           <div className="col-sm-2">
//                             <button
//                               type="button"
//                               className="btn btn-danger"
//                               onClick={() =>
//                                 handleRemoveCertificationDetail(index)
//                               }
//                             >
//                               <i className="fa-solid fa-trash"></i>
//                             </button>
//                             &nbsp; &nbsp;
//                             {/* <button
//                               type="button"
//                               className="btn btn-info"
//                               onClick={() =>
//                                 handleRemoveCertificationDetailUpdate(index)
//                               }
//                             >
//                               <i className="fa-solid fa-floppy-disk"></i>
//                             </button> */}
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                     <button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={() =>
//                         setCertificationDetails([
//                           ...certificationDetails,
//                           { certificationName: "", certificationVersion: "" },
//                         ])
//                       }
//                     >
//                       <i className="fa-solid fa-plus"></i>
//                     </button>
//                   </div>
//                 </div>
//               </div>

//               <div className="mt-2 mb-3">
//                 <div className="col-sm-5">
//                   <button type="submit" className="btn btn-success">
//                     Save Configuration
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </form>
//         </div>
//         <div className="d-flex align-items-center justify-content-between my-4 card card-one p-4 flex-row rounded cardStyle border">
//           <div className="col-xl">
//             <p className="fs-18 text-dark fw-bolder p-0 m-0">File Integrity Data</p>
//             <p className="text-danger p-0 m-0 fw-semibold">
//               Total Entries: {fileIntegrityInfo && fileIntegrityInfo.length}
//             </p>
//           </div>

//           <div className="col-sm-3 d-flex gap-2 align-items-center justify-content-between">
//             {/* <label htmlFor="fileInput" className="form-label">Upload File Integrity CSV</label> */}
//             <input
//               type="file"
//               id="fileInput"
//               placeholder="Upload File Integrity CSV"
//               className="form-control"
//               accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
//               onChange={handleFileChange}
//             />
//             <button className="btn btn-primary" onClick={uploadFile} disabled={!file}>Upload</button>
//           </div>
//         </div>

//         <div className="card rounded mb-2 shadow cardStyle">
//           <DataTable
//             columns={[
//               {
//                 name: "Sl no.",
//                 selector: (_, i) => i + 1,
//                 sortable: false,
//                 width: "70px",
//               },
//               {
//                 name: "Host Type",
//                 selector: (row) => row.hostType || "NA",
//                 sortable: false,
//                 width: "120px",
//               },
//               {
//                 name: "File Path",
//                 selector: (row) => row.path || "NA",
//                 sortable: false,
//               },
//               {
//                 name: "File Hash",
//                 selector: (row) => row.fileHash || "NA",
//                 sortable: false,
//               }
//             ]}
//             data={fileIntegrityInfo}
//             pagination
//             customStyles={customStyles}
//           />
//         </div>
//       </div>
//     </React.Fragment>
//   );
// }

// export default AdminConfig;


import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../layouts/Header";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifyApi } from "../../api/axiosSet";
import { useNavigate } from "react-router-dom";

function AdminConfig() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [passwordThresholdAge, setPasswordThresholdAge] = useState("");
  const [nonReportingThresholdDay, setNonReportingThresholdDay] = useState("");
  const [highCpuThresholdPercent, setHighCpuThresholdPercent] = useState("");
  const [highMemoryThresholdPercent, setHighMemoryThresholdPercent] =
    useState("");
  const [edrName, setEdrName] = useState("");
  const [edrVersion, setEdrVersion] = useState("");
  const [avName, setAvName] = useState("");
  const [avVersion, setAvVersion] = useState("");

  // New state variables for OS details
  const [osDetails, setOsDetails] = useState([]);
  const [edrDetails, setEdrDetails] = useState([]);
  const [avDetails, setAvDetails] = useState([]);
  const [zenworksDetails, setZenworksDetails] = useState([]);
  const [certificationDetails, setCertificationDetails] = useState([]);
  const [fileIntegrityInfo, setFileIntegrityInfo] = useState([]);

  const [isEdrOpen, setIsEdrOpen] = useState(false);
  const [isAvOpen, setIsAvOpen] = useState(false);
  const [isOsOpen, setIsOsOpen] = useState(false);
  const [isZenworksOpen, setIsZenworksOpen] = useState(false);
  const [isCertificationOpen, setIsCertificationOpen] = useState(false);
  const toggleEdrAccordion = () => setIsEdrOpen(!isEdrOpen);
  const toggleAvAccordion = () => setIsAvOpen(!isAvOpen);
  const toggleOsAccordion = () => setIsOsOpen(!isOsOpen);
  const toggleZenworksAccordion = () => setIsZenworksOpen(!isZenworksOpen);
  const toggleCertificationAccordion = () =>
    setIsCertificationOpen(!isCertificationOpen);
  const { user } = useSelector((state) => state.authSlice);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getRespId, setGetRespId] = useState();
  const invalidCharacters = /[<>'"]/;

  // Fetch agent configuration data
  const fetchConfigData = async () => {
    try {
      const response = await notifyApi.get(
        `/agent-configs/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      setColumnData(response.data.data);
      const resData = response.data.data[0];
      setLoading(false);
      setGetRespId(response.data.data[0]._id);
      setAvDetails(resData.avDetails);
      setEdrDetails(resData.edrDetails);
      setOsDetails(resData.osDetails);
      setZenworksDetails(resData.zenWorksDetails);
      setCertificationDetails(resData.certificateDetails);
      setPasswordThresholdAge(resData.passwordThresholdAge);
      setNonReportingThresholdDay(resData.nonReportingThresholdDay);
      setHighCpuThresholdPercent(resData.highCpuThresholdPercent);
      setHighMemoryThresholdPercent(resData.highMemoryThresholdPercent);
      setFileIntegrityInfo(resData.fileIntegrityInfo);
    } catch (error) {
      console.error("Error fetching configuration data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfigData();
  }, [user]);

  // Handle change in OS details
  const handleOsDetailChange = (index, field, value) => {
    const newOsDetails = [...osDetails];
    newOsDetails[index] = { ...newOsDetails[index], [field]: value };
    setOsDetails(newOsDetails);
  };

  const handleRemoveOsDetail = async (index) => {
    const ask = window.confirm("Are you sure you want to delete this OS?");
    if (!ask) {
      return;
    }
    try {
      const osToDelete = osDetails[index];
      // Make the API call to delete the configuration by ID
      await notifyApi.post(
        `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
          osDetailsToDelete: [{ "_id": osToDelete?._id }],
        }
      );
      toast.success("OS Deleted Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      const updatedOsDetails = osDetails.filter((_, i) => i !== index);
      setOsDetails([...updatedOsDetails]);
    } catch (error) {
      console.error("Error deleting OS detail:", error);
    }
  };

  // EDR Handlers
  const handleEdrDetailChange = (index, field, value) => {
    const updatedEdrDetails = [...edrDetails];
    updatedEdrDetails[index][field] = value;
    setEdrDetails([...updatedEdrDetails]);
  };

  const handleAddEdrDetail = () => {
    setEdrDetails([...edrDetails, { edrName: "", edrVersion: "" }]);
  };

  const handleRemoveEdrDetail = async (index) => {
    const ask = window.confirm("Are you sure you want to delete this EDR?");
    if (!ask) {
      return;
    }
    try {
      const edrToDelete = edrDetails[index];
      // Make the API call to delete the configuration by ID
      await notifyApi.post(
        `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
          edrDetailsToDelete: [{ "_id": edrToDelete?._id }],
        }
      );

      toast.success("EDR Deleted Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });

      const updatedEdrDetails = edrDetails.filter((_, i) => i !== index);
      setEdrDetails([...updatedEdrDetails]);
    } catch (error) {
      console.error("Error deleting EDR detail:", error);
    }
  };

  const handleAvDetailChange = (index, field, value) => {
    const updatedAvDetails = [...avDetails];
    updatedAvDetails[index][field] = value;
    setAvDetails(updatedAvDetails);
  };

  const handleAddAvDetail = () => {
    setAvDetails([...avDetails, { avName: "", avVersion: "" }]);
  };

  const handleRemoveAvDetail = async (index) => {
    const ask = window.confirm("Are you sure you want to delete this AV?");
    if (!ask) {
      return;
    }
    const avToDelete = avDetails[index];

    try {
      // Make the API call to delete the configuration by ID
      await notifyApi.post(
        `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
          avDetailsToDelete: [{ "_id": avToDelete?._id }],
        }
      );

      toast.success("AV Deleted Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });

      const updatedAvDetails = avDetails.filter((_, i) => i !== index);
      setAvDetails([...updatedAvDetails]);
    } catch (error) {
      console.error("Error deleting AV detail:", error);
    }
  };

  const handleZenworksDetailChange = (index, field, value) => {
    const updatedDetails = [...zenworksDetails];
    updatedDetails[index] = { ...updatedDetails[index], [field]: value };
    setZenworksDetails(updatedDetails);
  };

  const handleCertificationDetailChange = (index, field, value) => {
    const updatedDetails = [...certificationDetails];
    updatedDetails[index][field] = value;
    setCertificationDetails(updatedDetails);
  };

  const handleRemoveZenworksDetail = async (index) => {
    const ask = window.confirm("Are you sure you want to delete this Zenworks?");
    if (!ask) {
      return;
    }
    try {
      const zenworksToDelete = zenworksDetails[index];
      // Make the API call to delete the configuration by ID
      await notifyApi.post(
        `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
          zenWorksDetailsToDelete: [{ "_id": zenworksToDelete?._id }],
        }
      );
      toast.success("Zenworks Deleted Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });

      const newZenworksDetails = zenworksDetails.filter((_, i) => i !== index);
      setZenworksDetails(newZenworksDetails);
    } catch (error) {
      console.error("Error deleting Zenworks detail:", error);
    }
  };


  const handleRemoveCertificationDetail = async (index) => {
    const ask = window.confirm("Are you sure you want to delete this Certification?");
    if (!ask) {
      return;
    }
    try {
      const certificationToDelete = certificationDetails[index];
      // Make the API call to delete the configuration by ID
      await notifyApi.post(
        `/agent-config/${getRespId}/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
          certificateDetailsToDelete: [{ "_id": certificationToDelete?._id }],
        }
      );
      toast.success("Certification Deleted Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });

      const newCertificationDetails = certificationDetails.filter(
        (_, i) => i !== index
      );
      setCertificationDetails(newCertificationDetails);
    } catch (error) {
      console.error("Error deleting Certification detail:", error);
    }
  };

  function validateData(data) {
    const errors = [];

    if (data.passwordThresholdAge < 0) {
      errors.push("Password threshold age cannot be negative.");
    }
    if (data.nonReportingThresholdDay < 0) {
      errors.push("Non-reporting threshold day cannot be negative.");
    }
    if (data.highCpuThresholdPercent < 0) {
      errors.push("High CPU threshold percent cannot be negative.");
    }
    if (data.highMemoryThresholdPercent < 0) {
      errors.push("High memory threshold percent cannot be negative.");
    }

    if (data.highCpuThresholdPercent > 100) {
      errors.push("High CPU threshold percent cannot be greater than 100.");
    }
    if (data.highMemoryThresholdPercent > 100) {
      errors.push("High memory threshold percent cannot be greater than 100.");
    }
    function areObjectValuesNotEmptyOrSpaces(objectsArray) {
      objectsArray.forEach((obj, index) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (!value || value.trim().length === 0) {
              errors.push(`Value for "${key}" cannot be empty.`);
            }
            else if (invalidCharacters.test(value)) {
              errors.push(`Value for "${key}" contains invalid characters.`);
            }
          }
        }
      });

      return {
        isValid: errors.length === 0,
        errors: errors
      };
    }
    areObjectValuesNotEmptyOrSpaces(data.edrDetails || []);
    areObjectValuesNotEmptyOrSpaces(data.osDetails || []);
    areObjectValuesNotEmptyOrSpaces(data.avDetails || []);
    areObjectValuesNotEmptyOrSpaces(data.zenWorksDetails || []);
    areObjectValuesNotEmptyOrSpaces(data.certificateDetails || []);
    return {
      isValid: errors.length === 0,
      errors: errors
    };
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      organizationId: user?.data?.user?.organizationId,
      passwordThresholdAge: parseInt(passwordThresholdAge, 10)
        ? parseInt(passwordThresholdAge, 10)
        : columnData[0]?.passwordThresholdAge,
      nonReportingThresholdDay: parseInt(nonReportingThresholdDay, 10)
        ? parseInt(nonReportingThresholdDay, 10)
        : columnData[0]?.nonReportingThresholdDay,
      highCpuThresholdPercent: parseFloat(highCpuThresholdPercent)
        ? parseFloat(highCpuThresholdPercent)
        : columnData[0]?.highCpuThresholdPercent,
      highMemoryThresholdPercent: parseFloat(highMemoryThresholdPercent)
        ? parseFloat(highMemoryThresholdPercent)
        : columnData[0]?.highMemoryThresholdPercent,
      osDetails: osDetails ? osDetails : columnData[0]?.osDetails,
      avDetails: avDetails ? avDetails : columnData[0]?.avDetails,
      edrDetails: edrDetails ? edrDetails : columnData[0]?.edrDetails,
      zenWorksDetails: zenworksDetails
        ? zenworksDetails
        : columnData[0]?.zenWorksDetails,
      certificateDetails: certificationDetails
        ? certificationDetails
        : columnData[0]?.certificateDetails,
    };

    const { isValid, errors } = validateData(data);
    if (!isValid) {
      toast.error(errors[0], {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      return;
    }

    try {
      let response = null;
      if (!getRespId) {
        response = await notifyApi.post(
          `/add/agent-config/${user?.data?.user?.userId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );
      }
      else {
        response = await notifyApi.put(
          `/update/agent-config/${user?.data?.user?.userId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );
      }

      toast.success(response?.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      fetchConfigData();
    } catch (error) {
      toast.error(
        error?.response?.data.errors[0]?.msg ||
        error?.response?.data.errors[1]?.msg
      );
    }
  };

  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async () => {
    if (!file) {
      alert('Please select a file first.');
      return;
    }

    const formData = new FormData();
    formData.append('organizationId', user?.data?.user?.organizationId);
    formData.append('file', file);

    try {
      const response = await notifyApi.post(
        `/upload/file-integrity-info/${user?.data?.user?.userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setFile(null);
      fileInputRef.current.value = "";
      fetchConfigData();
    } catch (error) {
      toast.error(
        "Error uploading file. Please try again later."
      )
      setFile(null);
      fileInputRef.current.value = "";
    }
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#D7E3E8",
      },
    },
  };

  const exportToCSV = (rows, filename = "file_integrity.csv") => {

    // Table headers
    const csvHeader = ["host_type", "file_path", "file_hash"];

    const csvRows = rows.map((row) => [
      row.hostType,
      row.path,
      row.fileHash,
    ]);

    // Combine all CSV content
    const csvContent = [
      csvHeader, // Add the table headers
      ...csvRows, // Add the data rows
    ]
      .map((e) => e.join(","))
      .join("\n");

    // Create a Blob and download link for the CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const [avOptions, setAvOptions] = useState([
    "Bitdefender GravityZone",
    "Kaspersky Endpoint Security",
    "Sophos Intercept X Endpoint",
    "Avast Business Security",
    "ClamAV",
    "Lynis",
    "Chkrootkit",
    "RootKit Hunter",
    "Linux Malware Detect",
    "Trend Micro",
    "F-Secure",
    "ESCAN",
  ]);

  const [osOptions, setOsOptions] = useState([
    "Red Hat Enterprise Linux",
  ]);

  return (
    <React.Fragment>
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-18 fw-bolder p-0 m-0">
              Configurable Parameters
            </p>
          </span>
        </div>

        <div className="card rounded cardStyle" style={{ padding: "3rem" }}>
          <form onSubmit={handleSubmit}>
            {/* Existing input fields */}
            <div className="mb-3 row">
              <label
                htmlFor="inputPermitted"
                className="col-sm-4 col-form-label"
              >
                Password age permitted (Days)
              </label>
              <div className="col-sm-2">
                <input
                  type="number"
                  className="form-control"
                  id="inputPermitted"
                  placeholder="90 will be set as default"
                  min={1}
                  max={999}
                  value={passwordThresholdAge}
                  onChange={(e) => setPasswordThresholdAge(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="inputReporting"
                className="col-sm-4 col-form-label"
              >
                Non-reporting threshold (Days)
              </label>
              <div className="col-sm-2">
                <input
                  type="number"
                  className="form-control"
                  id="inputReporting"
                  value={nonReportingThresholdDay}
                  min={1}
                  max={365}
                  placeholder="5 will be set as default"
                  onChange={(e) => setNonReportingThresholdDay(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="mb-3 row">
              <label htmlFor="inputCpu" className="col-sm-4 col-form-label">
                High CPU utilization threshold (%)
              </label>
              <div className="col-sm-2">
                <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  id="inputCpu"
                  value={highCpuThresholdPercent}
                  min={1}
                  max={100}
                  placeholder="4 will be set as default"
                  onChange={(e) => setHighCpuThresholdPercent(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label htmlFor="inputMemory" className="col-sm-4 col-form-label">
                High memory utilization threshold (%)
              </label>
              <div className="col-sm-2">
                <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  id="inputMemory"
                  value={highMemoryThresholdPercent}
                  placeholder="4 will be set as default"
                  min={1}
                  max={100}
                  onChange={(e) =>
                    setHighMemoryThresholdPercent(e.target.value)
                  }
                  required
                />
              </div>
            </div>
            <div
              className="accordion my-3 w-75"
              id="accordionExample"
            >
              <div className="accordion-item ">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={`accordion-button ${isEdrOpen ? '' : 'collapsed'}`}
                    type="button"
                    onClick={toggleEdrAccordion}
                    aria-expanded={isEdrOpen}
                    aria-controls="collapseOne"
                  >
                    EDR Details
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className={`accordion-collapse collapse ${isEdrOpen ? "show" : ""
                    }`}
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {edrDetails.map((edrDetail, index) => (
                      <div key={index} className="mb-3">
                        <div className="row mb-2">
                          <label
                            htmlFor={`edrName${index}`}
                            className="col-sm-2 col-form-label"
                          >
                            EDR Name
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              id={`edrName${index}`}
                              value={edrDetail.edrName}
                              onChange={(e) =>
                                handleEdrDetailChange(
                                  index,
                                  "edrName",
                                  e.target.value
                                )
                              }
                              maxLength={50}
                            />
                          </div>

                          <label
                            htmlFor={`edrVersion${index}`}
                            className="col-sm-2 col-form-label"
                          >
                            EDR Version
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              id={`edrVersion${index}`}
                              value={edrDetail.edrVersion}
                              onChange={(e) =>
                                handleEdrDetailChange(
                                  index,
                                  "edrVersion",
                                  e.target.value
                                )
                              }
                              maxLength={50}
                            />
                          </div>
                          <div className="col-sm-2">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => handleRemoveEdrDetail(index)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                            &nbsp; &nbsp;
                          </div>
                        </div>
                      </div>
                    ))}
                    {edrDetails.length < 10 && <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleAddEdrDetail}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>}
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className={`accordion-button ${isAvOpen ? '' : 'collapsed'}`}
                    type="button"
                    onClick={toggleAvAccordion}
                    aria-expanded={isAvOpen}
                    aria-controls="collapseTwo"
                  >
                    AV Details
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className={`accordion-collapse collapse ${isAvOpen ? "show" : ""
                    }`}
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div className="accordion-body">
                    {avDetails.map((avDetail, index) => (
                      <div key={index} className="mb-3">
                        <div className="row mb-2">
                          <label
                            htmlFor={`avName${index}`}
                            className="col-sm-2 col-form-label"
                          >
                            AV Name
                          </label>
                          <div className="col-sm-3">
                            <select
                              className="form-control"
                              id={`avName${index}`}
                              value={avDetail.avName}
                              onChange={(e) =>
                                handleAvDetailChange(index, "avName", e.target.value)
                              }
                            >
                              <option value="" disabled>Select an option</option>
                              {avOptions.map((option, optionIndex) => (
                                <option key={optionIndex} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>

                          <label
                            htmlFor={`avVersion${index}`}
                            className="col-sm-2 col-form-label"
                          >
                            AV Version
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              id={`avVersion${index}`}
                              value={avDetail.avVersion}
                              onChange={(e) =>
                                handleAvDetailChange(
                                  index,
                                  "avVersion",
                                  e.target.value
                                )
                              }
                              maxLength={50}
                            />
                          </div>
                          <div className="col-sm-2">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => handleRemoveAvDetail(index)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                            &nbsp; &nbsp;
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleAddAvDetail}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>

              {/* Accordion Item */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className={`accordion-button ${isOsOpen ? '' : 'collapsed'}`}
                    type="button"
                    onClick={toggleOsAccordion}
                    aria-expanded={isOsOpen}
                    aria-controls="collapseThree"
                  >
                    OS Details
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className={`accordion-collapse collapse ${isOsOpen ? "show" : ""
                    }`}
                  aria-labelledby="headingThree"
                  data-bs-parent="#osDetailsAccordion"
                >
                  <div className="accordion-body">
                    {osDetails.map((osDetail, index) => (
                      <div key={index} className="mb-3">
                        <div className="row mb-2">
                          <label
                            htmlFor={`osName${index}`}
                            className="col-sm-2 col-form-label"
                          >
                            OS Name
                          </label>
                          <div className="col-sm-3">
                            <select
                              className="form-control"
                              id={`osName${index}`}
                              value={osDetail.osName}
                              onChange={(e) =>
                                handleOsDetailChange(index, "osName", e.target.value)
                              }
                            >
                              <option value="" disabled>Select an OS</option>
                              {osOptions.map((option, optionIndex) => (
                                <option key={optionIndex} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <label
                            htmlFor={`osVersion${index}`}
                            className="col-sm-2 col-form-label"
                          >
                            OS Version
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              id={`osVersion${index}`}
                              value={osDetail.osVersion}
                              onChange={(e) =>
                                handleOsDetailChange(
                                  index,
                                  "osVersion",
                                  e.target.value
                                )
                              }
                              maxLength={50}
                            />
                          </div>

                          <div className="col-sm-2">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => handleRemoveOsDetail(index)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                            &nbsp; &nbsp;
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        setOsDetails([
                          ...osDetails,
                          { osName: "", osVersion: "" },
                        ])
                      }
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingZenworks">
                  <button
                    className={`accordion-button ${isZenworksOpen ? '' : 'collapsed'}`}
                    type="button"
                    onClick={toggleZenworksAccordion}
                    aria-expanded={isZenworksOpen}
                    aria-controls="collapseZenworks"
                  >
                    Zenworks Details
                  </button>
                </h2>
                <div
                  id="collapseZenworks"
                  className={`accordion-collapse collapse ${isZenworksOpen ? "show" : ""
                    }`}
                  aria-labelledby="headingZenworks"
                  data-bs-parent="#osDetailsAccordion"
                >
                  <div className="accordion-body">
                    {zenworksDetails.map((zenworksDetail, index) => (
                      <div key={index} className="mb-3">
                        <div className="row mb-2">
                          <label
                            htmlFor={`zenWorks${index}`}
                            className="col-sm-2 col-form-label"
                          >
                            Zenworks Name
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              id={`zenWorks${index}`}
                              value={zenworksDetail.zenWorks}
                              onChange={(e) =>
                                handleZenworksDetailChange(
                                  index,
                                  "zenWorks",
                                  e.target.value
                                )
                              }
                              maxLength={50}
                            />
                          </div>
                          <label
                            htmlFor={`zenWorksVersion${index}`}
                            className="col-sm-2 col-form-label"
                          >
                            Zenworks Version
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              id={`zenWorksVersion${index}`}
                              value={zenworksDetail.zenWorksVersion}
                              onChange={(e) =>
                                handleZenworksDetailChange(
                                  index,
                                  "zenWorksVersion",
                                  e.target.value
                                )
                              }
                              maxLength={50}
                            />
                          </div>

                          <div className="col-sm-2">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => handleRemoveZenworksDetail(index)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                            &nbsp; &nbsp;
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        setZenworksDetails([
                          ...zenworksDetails,
                          { zenWorks: "", zenWorksVersion: "" },
                        ])
                      }
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingCertification">
                  <button
                    className={`accordion-button ${isCertificationOpen ? '' : 'collapsed'}`}
                    type="button"
                    onClick={toggleCertificationAccordion}
                    aria-expanded={isCertificationOpen}
                    aria-controls="collapseCertification"
                  >
                    Certification Details
                  </button>
                </h2>
                <div
                  id="collapseCertification"
                  className={`accordion-collapse collapse ${isCertificationOpen ? "show" : ""
                    }`}
                  aria-labelledby="headingCertification"
                  data-bs-parent="#osDetailsAccordion"
                >
                  <div className="accordion-body">
                    {certificationDetails.map((certificationDetail, index) => (
                      <div key={index} className="mb-3">
                        <div className="row mb-2">
                          <label
                            htmlFor={`certificateName${index}`}
                            className="col-sm-2 col-form-label"
                          >
                            Certification Name
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              id={`certificateName${index}`}
                              value={certificationDetail.certificateName}
                              onChange={(e) =>
                                handleCertificationDetailChange(
                                  index,
                                  "certificateName",
                                  e.target.value
                                )
                              }
                              maxLength={50}
                            />
                          </div>
                          <label
                            htmlFor={`certificateVersion${index}`}
                            className="col-sm-2 col-form-label"
                          >
                            Certification Version
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              id={`certificateVersion${index}`}
                              value={certificationDetail.certificateVersion}
                              onChange={(e) =>
                                handleCertificationDetailChange(
                                  index,
                                  "certificateVersion",
                                  e.target.value
                                )
                              }
                              maxLength={50}
                            />
                          </div>

                          <div className="col-sm-2">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() =>
                                handleRemoveCertificationDetail(index)
                              }
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                            &nbsp; &nbsp;
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        setCertificationDetails([
                          ...certificationDetails,
                          { certificateName: "", certificateVersion: "" },
                        ])
                      }
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-2 mb-3">
                <div className="col-sm-5">
                  <button type="submit" className="btn btn-success">
                    Save Configuration
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="d-flex align-items-center justify-content-between my-4 card card-one p-4 flex-row rounded cardStyle border">
          <div className="col-xl">
            <p className="fs-18 text-dark fw-bolder p-0 m-0">File Integrity Data</p>
            <p className="text-danger p-0 m-0 fw-semibold">
              Total Entries: {fileIntegrityInfo && fileIntegrityInfo.length}
            </p>
          </div>

          <div className="col-sm-4 d-flex gap-2 align-items-center justify-content-between">
            <button
              type="button"
              className="btn btn-success text-white d-flex align-items-center"
              onClick={() => exportToCSV(fileIntegrityInfo)}
            >
              <i className="fa-solid fa-download"></i>&nbsp; <div className="fa-md d-flex align-items-center">Template</div>
            </button>
            <input
              type="file"
              id="fileInput"
              ref={fileInputRef}
              placeholder="Upload File Integrity CSV"
              className="form-control"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleFileChange}
            />
            <button className="btn btn-primary" onClick={uploadFile} disabled={!file}>Upload</button>
          </div>
        </div>

        <div className="card rounded mb-2 shadow cardStyle">
          <DataTable
            columns={[
              {
                name: "Sl no.",
                selector: (_, i) => i + 1,
                sortable: false,
                width: "70px",
              },
              {
                name: "Host Type",
                selector: (row) => row.hostType || "NA",
                sortable: false,
                width: "120px",
              },
              {
                name: "File Path",
                selector: (row) => row.path || "NA",
                sortable: false,
              },
              {
                name: "File Hash",
                selector: (row) => row.fileHash || "NA",
                sortable: false,
              }
            ]}
            data={fileIntegrityInfo}
            pagination
            customStyles={customStyles}
            noDataComponent={
              <p className="p-0 my-2  me-auto">
                No data found, please upload file
              </p>
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminConfig;
