import React from "react";
import Maindashboard from "../pages/Useradmin/Maindashboard";
import ManageOrg from "../pages/Useradmin/ManageOrg";
import AllEndPoint from "../pages/Useradmin/AllEndPoint";
import Test from "../pages/Useradmin/Test";
import Users from "../pages/Useradmin/Users";
import AdminConfig from "../pages/Useradmin/AdminConfig";

import WhitelistDevice from "../pages/Useradmin/WhitePages/WhitelistDevice";
import WhitelistPorts from "../pages/Useradmin/WhitePages/WhitelistPorts";
import WhitelistServices from "../pages/Useradmin/WhitePages/WhitelistServices";
import WhitelistApplication from "../pages/Useradmin/WhitePages/WhitelistApplication";
import Service from "../pages/Useradmin/WhitePages/Service";

import DownloadAgent from "../pages/Admin/Download/DownloadAgent.jsx"


const UserAdminRoutes = [
  { path: "/admin-info", element: <Maindashboard /> },
  { path: "/manage-org", element: <ManageOrg /> },
  { path: "/All-endpoint", element: <AllEndPoint /> },
  { path: "/Test", element: <Test /> }, 
  { path: "/users", element: <Users /> },
  { path: "/whitelist-devices", element: <WhitelistDevice /> },
  { path: "/whitelist-ports", element: <WhitelistPorts /> },
  { path: "/whitelist-subnet", element: <WhitelistServices /> },
  { path: "/whitelist-application", element: <WhitelistApplication /> },
  { path: "/whitelist-service", element: <Service /> },
  { path: "/admin-config", element: <AdminConfig /> },

  { path: "/download-agent", element: <DownloadAgent /> },

//   { path: "/admin-Setting", element: <AdminSettings /> },
//   { path: "/role", element: <Role /> },
//   { path: "/add-org", element: <AddOrg /> },
//   { path: "/org-detail/:id/licenses", element: <License /> },
//   { path: "/org-detail/:id/licenses/:createdAt/:validity", element: <LicenseView /> },
];

export default UserAdminRoutes;
