// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import Header from "../../layouts/Header";
// import Footer from "../../layouts/Footer";
// import { Button, Card, Col, Nav, Row } from "react-bootstrap";
// import DataTable from "react-data-table-component";
// import { useNavigate } from "react-router-dom";
// import ReactApexChart from "react-apexcharts";
// import { dp3 } from "../../data/DashboardData";
// import { useSelector } from "react-redux";
// import Excellogo from "../../assets/img/Excellogo.png";
// import jsPDF from "jspdf";
// import "jspdf-autotable"; // For adding tables easily
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css"; // optional
// import axios from "axios";

// function Maindashboard() {
//   const { user } = useSelector((state) => state.authSlice);
//   const navigate = useNavigate();
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const location = useLocation();

//   const [skin, setSkin] = useState(currentSkin);
//   const [columnData, setColumnData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const [licenseDate, setLicenseDate] = useState("");

//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");

//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );

//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };
//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };
//   switchSkin(skin);
//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_NOTIFY_URL}/${user?.data?.user?.organizationId}/license-details-org`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       setColumnData([response?.data?.licenses]);
//       setLicenseDate(response?.data?.licenseCounts);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching data: ", error.response);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [user]);

//   const exportToCSV = (rows, filename) => {
//     const orgName = user?.data?.user?.organization || "Organization Name";
//     const exportDateTime = formatTimestamp(new Date());
  
//     // Header with organization name and export date/time
//     const headerInfo = [`Organization: ${orgName}`, `Exported: ${exportDateTime}`];
    
//     // Table headers
//     const csvHeader = ["License No", "Valid From", "Valid Upto", "Device ID", "Status"];
    
//     // Table rows
//     const csvRows = rows.map(row => [
//       row.licKey,
//       row.validFrom,
//       row.validTo,
//       row.pcIdAssociated ? row.pcIdAssociated : "NA",
//       row.lkeyStatus ? row.lkeyStatus : "NA",
//     ]);
  
//     // Combine all CSV content
//     const csvContent = [
//       headerInfo,        // Add organization name and export date/time
//       csvHeader,         // Add the table headers
//       ...csvRows         // Add the data rows
//     ]
//       .map(e => e.join(","))  // Join each array into a comma-separated string
//       .join("\n");            // Join each row with a new line
  
//     // Create a Blob and download link for the CSV file
//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//     const link = document.createElement("a");
  
//     if (link.download !== undefined) {
//       const url = URL.createObjectURL(blob);
//       link.setAttribute("href", url);
//       link.setAttribute("download", filename);
//       link.style.visibility = "hidden";
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     }
//   };
  


//   const exportToPDF = (dataSet, filename = 'whitelistPort.pdf') => {
//     const doc = new jsPDF();
  
//     // Organization name and export date/time
//     const orgName = user?.data?.user?.organization || "Organization Name";
//     const exportDateTime = formatTimestamp(new Date());
  
//     // Add header with organization name on the left and export date/time on the right
//     doc.setFontSize(12);
//     doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
//     doc.text(`Exported: ${exportDateTime}`, doc.internal.pageSize.getWidth() - 14, 15, { align: 'right' }); // Right-aligned
  
//     // Define table columns and rows
//     const tableColumn =  ["License No", "Valid From", "Valid Upto", "Device ID", "Status"];
//     const tableRows = dataSet.map(row => [
//       row.licKey,
//       row.validFrom,
//       row.validTo,
//       row.pcIdAssociated ? row.pcIdAssociated : "NA",
//       row.lkeyStatus ? row.lkeyStatus : "NA",
//     ]);
  
//     // Add the table to the PDF
//     // Adjust `startY` to ensure the table does not overlap with the header
//     doc.autoTable(tableColumn, tableRows, { startY: 25 });
  
//     // Save the PDF with the given filename
//     doc.save(filename);
//   };
  

//   const columns = [
//     {
//       name: "License No",
//       selector: (row) => row.licKey,
//       sortable: false,
//       width: "320px",
//     },
//     {
//       name: "Valid From",
//       selector: (row) => row.validFrom,
//       sortable: true,
//       width: "140px",
//     },
//     {
//       name: "Valid Upto",
//       selector: (row) => row.validTo,
//       sortable: true,
//       width: "140px",
//     },

//     {
//       name: "Device ID",
//       sortable: true,
//       selector: (row) => (
//         <Tippy content={row.pcIdAssociated ? row.pcIdAssociated : "NA"}>
//           <div>{row.pcIdAssociated ? row.pcIdAssociated : "NA"}</div>
//         </Tippy>
//       ),
//       width: "520px",
//     },
//     {
//       name: "Status",
//       selector: (row) => (row?.lkeyStatus ? row?.lkeyStatus : "NA"),
//       sortable: true,
//     },
//   ];

//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#cccccc",
//       },
//     },
//   };

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div
//         className="main main-app p-3 p-lg-4"
//         style={{ backgroundColor: "#ECF9FF" }}
//       >
//         <div class="card rounded-4 shadow mb-4  p-3  bg-body-tertiary">
//           <div class="card-body">
//             <h5 class="card-title text-center fw-bolder">Basic Details</h5>
//             <hr />

//             <div class="container text-center">
//               <div class="row">
//                 <div class="col">
//                   <div class="card border border-0">
//                     <div class="card-body border-bottom shadow p-3">
//                       <h5 class="card-title">Org. Name</h5>
//                       <p class="card-text">{user?.data?.user?.organization}</p>
//                     </div>
//                   </div>
//                 </div>
//                 {/* <div class="col">
//                   <div class="card border border-0">
//                     <div class="card-body border-bottom shadow p-3">
//                       <h5 class="card-title">ID</h5>
//                       <p class="card-text">{user?.data?.user?.organizationId}</p>
//                     </div>
//                   </div>
//                 </div> */}

//                 <div class="col">
//                   <div class="card border border-0">
//                     <div class="card-body border-bottom shadow p-3">
//                       <h5 class="card-title">Admin Name</h5>
//                       <p class="card-text">
//                         {user?.data?.user?.firstName}{" "}
//                         {user?.data?.user?.lastName}
//                       </p>
//                     </div>
//                   </div>
//                 </div>

//                 <div class="col">
//                   <div class="card border border-0">
//                     <div class="card-body border-bottom shadow p-3">
//                       <h5 class="card-title">Admin Email</h5>
//                       <p class="card-text">{user?.data?.user?.email}</p>
//                     </div>
//                   </div>
//                 </div>

//                 {/* <div class="col">
//                   <div class="card border border-0">
//                     <div class="card-body border-bottom">
//                       <h5 class="card-title">Status</h5>
//                       <p class="card-text text-success">{user?.data?.user?.email}</p>
//                     </div>
//                   </div>
//                 </div>  */}
//                 {/* 
//                 <div class="col">
//                   <div class="card border border-0">
//                     <div class="card-body border-bottom">
//                       <h5 class="card-title">Type</h5>
//                       <p class="card-text ">Piad</p>
//                     </div>
//                   </div>
//                 </div>  */}

//                 <div class="col">
//                   <div class="card border border-0">
//                     <div class="card-body border-bottom shadow p-3">
//                       <h5 class="card-title">Registered</h5>
//                       <p class="card-text ">
//                         {formatTimestamp(user?.data?.user?.createdAt)}
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div class="card rounded-4 shadow mb-4  p-3  bg-body-tertiary">
//           <div class="card-body">
//             <h5 class="card-title text-center fw-bolder">Licenses Details</h5>
//             <hr />

//             <div class="container text-center">
//               <div class="row">
//                 <div class="col">
//                   <div class="card border border-0">
//                     <div class="card-body border-bottom shadow p-3">
//                       <h5 class="card-title">Total License</h5>
//                       <p class="card-text">{licenseDate?.total}</p>
//                     </div>
//                   </div>
//                 </div>

//                 <div class="col">
//                   <div class="card border border-0">
//                     <div class="card-body border-bottom shadow p-3">
//                       <h5 class="card-title">Used License</h5>
//                       <p class="card-text">{licenseDate.used} </p>
//                     </div>
//                   </div>
//                 </div>

//                 <div class="col">
//                   <div class="card border border-0">
//                     <div class="card-body border-bottom shadow p-3">
//                       <h5 class="card-title">Unused License</h5>
//                       <p class="card-text">{licenseDate.unused}</p>
//                     </div>
//                   </div>
//                 </div>

//                 <div class="col">
//                   <div class="card border border-0">
//                     <div class="card-body border-bottom shadow p-3">
//                       <h5 class="card-title">Expired License</h5>
//                       <p class="card-text ">{licenseDate.expired}</p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded shadow cardStyle">
//           <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
//             <p className="fs-18 fw-bolder p-0 m-0">Licenses </p>
//           </span>
//           <div className="d-flex align-items-center justify-content-between gap-2">
//             <button
//               type="button"
//               className="btn btn-primary text-white"
//               onClick={() =>
//                 exportToPDF(columnData[0] || [], "licenses_report")
//               }
//             >
//               <i className="fa-solid fa-file-pdf"></i> &nbsp; Export
//             </button>

//             <button
//               type="button"
//               className="btn btn-success text-white"
//               onClick={() =>
//                 exportToCSV(columnData[0] || [], "licenses_report")
//               }
//             >
//               <img src={Excellogo} height={20} width={15} />
//               &nbsp; Export
//             </button>
//           </div>
//         </div>

//         <div className="card rounded mb-2 shadow cardStyle">
//           {loading ? (
//             <p>Loading...</p>
//           ) : (
//             <DataTable
//               columns={columns}
//               data={columnData[0] || []}
//               pagination
//               customStyles={customStyles}
//             />
//           )}
//         </div>
//       </div>
//     </React.Fragment>
//   );
// }

// export default Maindashboard;
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Button, Card, Col, Nav, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { dp3 } from "../../data/DashboardData";
import { useSelector } from "react-redux";
import Excellogo from "../../assets/img/Excellogo.png";
import jsPDF from "jspdf";
import "jspdf-autotable"; // For adding tables easily
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { notifyApi } from "../../api/axiosSet";
import { saveAs } from "file-saver";

function Maindashboard() {
  const { user } = useSelector((state) => state.authSlice);
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [licenseDate, setLicenseDate] = useState("");

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const fetchData = async () => {
    try {
      const response = await notifyApi.get(
        `/${user?.data?.user?.organizationId}/license-details-org`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      setColumnData([response?.data?.licenses]);
      setLicenseDate(response?.data?.licenseCounts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  const exportToCSV = () => {
    if (columnData[0] && columnData[0].length > 0) {
      // Determine which columns have data
      const hasData = (key) => ["pcIdAssociated", "lkeyStatus"].includes(key) ? true : columnData[0].some((item) => item[key]);

      const headers = [
        { label: "Endpoint Identifier Key", key: "licKey" },
        { label: "Valid From", key: "validFrom" },
        { label: "Valid To", key: "validTo" },
        { label: "PC ID", key: "pcIdAssociated" },
        { label: "Status", key: "lkeyStatus" },
      ].filter((header) => hasData(header.key));

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail)
            const value = Object.values(detail)
            return `${key}:${value}`;
          })
          .join(" | ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      const formatMultipleIpAddresses = (ipAddresses) => {
        if (typeof ipAddresses === "string") {
          return ipAddresses
        }
        return ipAddresses
          .map((ipAddress) => ipAddress)
          .join(" ")
          .replace(/ /g, " | ");
      };

      const formatUsersPasswordAge = (usersPasswordAge) => {
        if (!usersPasswordAge || usersPasswordAge.length === 0) return "N/A"; // Handle empty or undefined case

        return usersPasswordAge
          .map((user) => `${user.userType}:${user.userName}:${user.passwordAge}`)
          .join(" | ");
      };

      const csvData = [
        headers.map((header) => header.label),
        ...columnData[0].map((item) =>
          headers.map((header) =>
            header.key === "ipAddress"
              ? formatMultipleIpAddresses(item[header.key])
              :
              header.key === "craetedAt"
                ? formatTimestamp(item[header.key])
                : header.key === "usersPasswordAge"
                  ? formatUsersPasswordAge(item[header.key])
                  : header.key === "sharedDirectories"
                    ? formatSharedDirectories(item[header.key])
                    : header.key === "avDetails"
                      ? formatAvDetails(item[header.key])
                      : header.key === "service"
                        ? formatOsDetails(item[header.key])
                        : header.key === "department" ? item[header.key] || "NA"
                          : item[header.key]
          )
        ),
      ];
      // Add header information
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `endpoint_identifier_keys.csv`);
    }
  };

  function exportToPDF() {
    if (columnData[0] && columnData[0].length > 0) {
      // Set the orientation to landscape
      const doc = new jsPDF({
        orientation: "landscape", // Force landscape orientation
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      doc.text(`Endpoint Identifier Keys`, 14, 22);

      const hasData = (key) => ["pcIdAssociated", "lkeyStatus"].includes(key) ? true : columnData[0].some((item) => item[key]);
      const headers = [
        { label: "Endpoint Identifier Key", key: "licKey" },
        { label: "Valid From", key: "validFrom" },
        { label: "Valid To", key: "validTo" },
        { label: "PC ID", key: "pcIdAssociated" },
        { label: "Status", key: "lkeyStatus" },
      ].filter((header) => hasData(header.key));

      const tableColumn = ["Sl no.", ...headers.map((header) => header.label)];
      const tableRows = [];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail).find((k) => k.startsWith("clamav"));
            const avName = key ? key : "Unknown AV";
            const avStatus = detail[key] || "Status not available";

            return `AV Name: ${avName}, Status: ${avStatus}`;
          })
          .join("; ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      columnData[0].forEach((item, index) => {
        const rowData = [index + 1, ...headers.map((header) =>
          header.key === "craetedAt"
            ? formatTimestamp(item[header.key])
            : header.key === "sharedDirectories"
              ? formatSharedDirectories(item[header.key])
              : header.key === "usersPasswordAge"
                ? item[header.key]
                  .map(
                    (user) =>
                      `${user.userType} :${user.userName}: ${user.passwordAge}`
                  )
                  .join("; ")
                : header.key === "avDetails"
                  ? formatAvDetails(item[header.key])
                  : header.key === "service"
                    ? formatOsDetails(item[header.key])
                    : header.key === "department" ? item[header.key] || "NA"
                      : Array.isArray(item[header.key])
                        ? item[header.key].join(", ")
                        : item[header.key]
        ),];
        tableRows.push(rowData);
      });

      // Add table with borders for rows and columns
      doc.autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: {
          cellPadding: 3, // Padding for cells
          lineColor: [44, 62, 80], // Border color (RGB)
          lineWidth: 0.1, // Border width
        },
        headStyles: {
          fillColor: [52, 73, 94], // Header background color
          textColor: 255, // Header text color
          halign: "center", // Center align header text
          lineWidth: 0.5, // Border width for header
        },
        bodyStyles: {
          lineColor: [44, 62, 80], // Row border color
          lineWidth: 0.1, // Border width for rows
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Background color for alternate rows
        },
        columnStyles: {
          4: { cellWidth: 50 }, // Adjust the width of the column at index 3
        },
      });

      // Save the PDF
      doc.save(`endpoint_identifier_keys.pdf`);
    }
  }

  const columns = [
    {
      name: "Endpoint Identifier Key",
      selector: (row) => row.licKey,
      sortable: false,
      width: "320px",
    },
    {
      name: "Valid From",
      selector: (row) => row.validFrom,
      sortable: true,
      width: "140px",
    },
    {
      name: "Valid Upto",
      selector: (row) => row.validTo,
      sortable: true,
      width: "140px",
    },

    {
      name: "Device ID",
      sortable: true,
      selector: (row) => (
        <Tippy content={row.pcIdAssociated ? row.pcIdAssociated : "NA"}>
          <div>{row.pcIdAssociated ? row.pcIdAssociated : "NA"}</div>
        </Tippy>
      ),
      width: "520px",
    },
    {
      name: "Status",
      selector: (row) => (row?.lkeyStatus ? row?.lkeyStatus : "NA"),
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#D7E3E8",
      },
    },
  };

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <div className="card rounded-4 shadow mb-4  p-3  bg-body-tertiary">
          <div className="card-body">
            <h5 className="card-title text-center fw-bolder">Basic Details</h5>
            <hr />

            <div className="container text-center">
              <div className="row">
                <div className="col">
                  <div className="card border border-0">
                    <div className="card-body border-bottom shadow p-3">
                      <h5 className="card-title">Org. Name</h5>
                      <p className="card-text">{user?.data?.user?.organization}</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col">
                  <div className="card border border-0">
                    <div className="card-body border-bottom shadow p-3">
                      <h5 className="card-title">ID</h5>
                      <p className="card-text">{user?.data?.user?.organizationId}</p>
                    </div>
                  </div>
                </div> */}

                <div className="col">
                  <div className="card border border-0">
                    <div className="card-body border-bottom shadow p-3">
                      <h5 className="card-title">Admin Name</h5>
                      <p className="card-text">
                        {user?.data?.user?.firstName}{" "}
                        {user?.data?.user?.lastName}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card border border-0">
                    <div className="card-body border-bottom shadow p-3">
                      <h5 className="card-title">Admin Email</h5>
                      <p className="card-text">{user?.data?.user?.email}</p>
                    </div>
                  </div>
                </div>

                {/* <div className="col">
                  <div className="card border border-0">
                    <div className="card-body border-bottom">
                      <h5 className="card-title">Status</h5>
                      <p className="card-text text-success">{user?.data?.user?.email}</p>
                    </div>
                  </div>
                </div>  */}
                {/* 
                <div className="col">
                  <div className="card border border-0">
                    <div className="card-body border-bottom">
                      <h5 className="card-title">Type</h5>
                      <p className="card-text ">Piad</p>
                    </div>
                  </div>
                </div>  */}

                <div className="col">
                  <div className="card border border-0">
                    <div className="card-body border-bottom shadow p-3">
                      <h5 className="card-title">Registered</h5>
                      <p className="card-text ">
                        {formatTimestamp(user?.data?.user?.createdAt)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card rounded-4 shadow mb-4  p-3  bg-body-tertiary">
          <div className="card-body">
            <h5 className="card-title text-center fw-bolder">Endpoints Identifier Keys</h5>
            <hr />

            <div className="container text-center">
              <div className="row">
                <div className="col">
                  <div className="card border border-0">
                    <div className="card-body border-bottom shadow p-3">
                      <h5 className="card-title">Total</h5>
                      <p className="card-text">{licenseDate?.total}</p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card border border-0">
                    <div className="card-body border-bottom shadow p-3">
                      <h5 className="card-title">Used</h5>
                      <p className="card-text">{licenseDate.used} </p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card border border-0">
                    <div className="card-body border-bottom shadow p-3">
                      <h5 className="card-title">Unused</h5>
                      <p className="card-text">{licenseDate.unused}</p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card border border-0">
                    <div className="card-body border-bottom shadow p-3">
                      <h5 className="card-title">Expired</h5>
                      <p className="card-text ">{licenseDate.expired}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded shadow cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-18 fw-bolder p-0 m-0">Identifier Keys</p>
          </span>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={() =>
                exportToPDF()
              }
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() =>
                exportToCSV()
              }
            >
              <i className="fa-solid fa-file-excel"></i> &nbsp; CSV
            </button>
          </div>
        </div>

        <div className="card rounded mb-2 shadow cardStyle">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataTable
              columns={columns}
              data={columnData[0] || []}
              pagination
              customStyles={customStyles}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Maindashboard;
