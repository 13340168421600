import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { notifyApi } from "../../api/axiosSet";
import { useSelector } from "react-redux";
// import { Button,Modal } from "antd";
import { Modal, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Refresh } from "@mui/icons-material";
import Header from "../../layouts/Header";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Excellogo from "../../assets/img/Excellogo.png";
import { utils, writeFile } from "xlsx";
function Test() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [columnDataWhitelist, setColumnDataWhitelist] = useState([]);

  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  console.log(selectedRows, "selectedRows");
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showOffcanvasOne, setShowOffcanvasOne] = useState(false);
  const [networkConfigs, setNetworkConfigs] = useState([
    { ServiceName: "", PortNumber: "", TransportProtocol: "", Description: "" },
  ]);


  const [isEdit, setIsEdit] = useState(false); // Determines if it's an edit
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [show, setShow] = useState(false);
  const [portNumber, setPortNumber] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [transportProtocol, setTransportProtocol] = useState("");
  const [description, setDescription] = useState("");
  const [isControlClicked, setIsControlClicked] = useState(false);
  const [isControlClickeOne, setIsControlClickedOne] = useState(false);
  const { user } = useSelector((state) => state.authSlice);
  const [currentId, setCurrentId] = useState(null);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setIsEdit(false); // Switch to add mode
    setPortNumber(""); // Clear the form fields
    setServiceName("");
    setTransportProtocol("");
    setDescription("");
    setCurrentId(null); // Clear the current ID
    setShow(true); // Show the modal
  };
  

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };
  const fetchDataWhitelist = async () => {
    try {
      const response = await notifyApi.get(
        `/whitelist-ports/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}?page=${pageNumber}&pageSize=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      const devices = response.data.data;
      console.log(response.data, "whitelist");
      setColumnDataWhitelist(devices);
      setTotalDocuments(response?.data?.pagination?.totalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
    }
  };
  useEffect(() => {
    // fetchData();
    fetchDataWhitelist();
  }, [user, pageNumber, pageSize]);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this PORT device?"
    );

    if (confirmDelete) {
      try {
        await notifyApi.post(`/whitelist-port/${user?.data?.user?.userId}`, {
          id: id,
        });
        toast.success("USB device deleted successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else {
      // User canceled the deletion
      toast.info("Deletion canceled.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
    }
  };


  const handleEdit = (row) => {
    setIsEdit(true); // Switch to edit mode
    setPortNumber(row.PortNumber); // Prefill form fields
    setServiceName(row.ServiceName);
    setTransportProtocol(row.TransportProtocol);
    setDescription(row.Description);
    setCurrentId(row._id); // Set the current ID of the row being edited
    setShow(true); // Show the modal
  };
  
  const columnsWhite = [
    {
      name: "Service Name",
      selector: (row) => row.ServiceName,
      sortable: true,
    },
    {
      name: "Port Number",
      selector: (row) => row.PortNumber,
      sortable: true,
    },
    {
      name: "Protocol",
      selector: (row) => row.TransportProtocol || "NA",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <span
          className={`badge rounded-pill ${
            row.status === true
              ? "text-bg-success text-white"
              : "text-bg-danger text-white"
          }`}
          disabled
        >
          {row.status === true ? "Authorized" : "Unauthorized"}
        </span>
      ),
      sortable: true,
    },

    {
      name: "Description",
      selector: (row) => row.Description || "NA",
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row._id || "NA",
      cell: (row) => (
        <>
          <button
            type="button"
            className="btn btn-dark"
            onClick={() => handleEdit(row)}
            aria-label={`Edit ${row.ServiceName}`}
          >
            <i className="fa-solid fa-pen"></i>
          </button>
          &nbsp;
          {/* <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleDelete(row._id)}
          >
            <i className="fa-solid fa-trash"></i>
          </button> */}
        </>
      ),
      sortable: false,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#cccccc",
      },
    },
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  const exportToPDF = (data, filename = "whitelistPort.pdf") => {
    const doc = new jsPDF();

    // Organization name and export date/time
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = formatTimestamp(new Date());
  
    // Add header with organization name on the left and export date/time on the right
    doc.setFontSize(12);
    doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
    doc.text(`Exported: ${exportDateTime}`, doc.internal.pageSize.getWidth() - 14, 15, { align: 'right' }); // Right-aligned

    const startYPosition = 22; // Adjust this value as needed
    const tableColumn = [
      "Service Name",
      "Port Number",
      "Protocol",
      "Status",
      "Description",
    ];
    const tableRows = data.map((row) => [
      row.ServiceName,
      row.PortNumber,
      row.TransportProtocol || "NA",
      row.status === true ? "Authorized" : "Unauthorized",
      row.Description || "NA",
    ]);

    doc.autoTable(tableColumn, tableRows, { startY: startYPosition });
    doc.save(filename);
  };

  // const exportToExcel = (rows, filename = "whitelistPort.xlsx") => {
  //   const tableColumn = [
  //     "Service Name",
  //     "Port Number",
  //     "Protocol",
  //     "Status",
  //     "Description",
  //   ];
  //   const dataRows = rows.map((row) => [
  //     row.ServiceName || "NA",
  //     row.PortNumber || "NA",
  //     row.TransportProtocol || "NA",
  //     row.status === true ? "Authorized" : "Unauthorized",
  //     row.Description || "NA",
  //   ]);

  //   const ws = utils.aoa_to_sheet([tableColumn, ...dataRows]);
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, ws, "Sheet1");
  //   writeFile(wb, filename);
  // };

  const exportToCSV = (rows, filename) => {
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = formatTimestamp(new Date());
  
    // Header with organization name and export date/time
    const headerInfo = [`Organization: ${orgName}`, `Exported: ${exportDateTime}`];
    
    // Table headers
    const csvHeader = [
      "Service Name",
      "Port Number",
      "Protocol",
      "Status",
      "Description",
    ];
    
    // Table rows
    const csvRows = rows.map(row => [
      row.ServiceName || "NA",
      row.PortNumber || "NA",
      row.TransportProtocol || "NA",
      row.status === true ? "Authorized" : "Unauthorized",
      row.Description || "NA",
    ]);
  
    // Combine all CSV content
    const csvContent = [
      headerInfo,        // Add organization name and export date/time
      csvHeader,         // Add the table headers
      ...csvRows         // Add the data rows
    ]
      .map(e => e.join(","))  // Join each array into a comma-separated string
      .join("\n");            // Join each row with a new line
  
    // Create a Blob and download link for the CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
  
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleControlClick = () => {
    setIsControlClicked(true);
    setShowOffcanvas(true);
  };

  const handleSubmitUnAuthorized = async (e) => {
    e.preventDefault();

    // Check if any row has a status of true
    const isAlreadyAuthorized = selectedRows.some((row) => row.status === true);

    if (isAlreadyAuthorized) {
      toast.info("Oops! Already Authorized", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      return; // Exit the function to prevent further execution
    }

    // Proceed with the API call if no rows are already authorized
    const data = {
      organizationId: user?.data?.user?.organizationId,
      networkConfigs: selectedRows.map((row) => ({
        ServiceName: row.ServiceName,
        PortNumber: row.PortNumber,
        TransportProtocol: row.TransportProtocol,
        Description: row.Description,
      })),
    };

    try {
      const response = await notifyApi.post(
        `/add-whitelist-ports/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log("API response:", response.data.message);
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setShowOffcanvas(false);
      setSelectedRows([]);
      fetchDataWhitelist(); // Refresh the data for the whitelist!
      setNetworkConfigs([
        {
          ServiceName: "",
          PortNumber: "",
          TransportProtocol: "",
          Description: "",
        },
      ]);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      console.error("Error adding user:", error);
    }
  };

  const handleSubmitAuthorized = async (e) => {
    e.preventDefault();

    // Check if any row has a status of true
    const isAlreadyAuthorized = selectedRows.some(
      (row) => row.status === false
    );

    if (isAlreadyAuthorized) {
      toast.info("Oops! Already Unauthorized", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      return; // Exit the function to prevent further execution
    }

    const data = selectedRows.map((row) => ({
      id: row._id, // Include the ID if it exists for updates
      ServiceName: row.ServiceName,
      PortNumber: row.PortNumber,
      TransportProtocol: row.TransportProtocol,
      Description: row.Description,
      organizationId: user?.data?.user?.organizationId,
      status: false,
    }));

    try {
      const promises = data.map((item) =>
        notifyApi.put(`/whitelist-port/${user?.data?.user?.userId}`, item, {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        })
      );

      const responses = await Promise.all(promises);
      responses.forEach((response) =>
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
        })
      );

      setShowOffcanvasOne(false);
      setSelectedRows([]);
      fetchDataWhitelist(); // Refresh the data for the whitelist!
    } catch (error) {
      toast.error(error.response?.data?.message || "Error updating ports");
      console.error("Error updating ports:", error);
    }
  };

  const handleSubmit = async () => {

    if (!serviceName) {
      toast.error("Service Name is required", { position: "top-right" });
      return;
    }
    if (!portNumber || isNaN(portNumber)) {
      toast.error("Valid Port Number is required", { position: "top-right" });
      return;
    }
    if (!transportProtocol) {
      toast.error("Transport Protocol is required", { position: "top-right" });
      return;
    }
    if (!description) {
      toast.error("Description is required", { position: "top-right" });
      return;
    }


    const formData = {
      organizationId: user?.data?.user?.organizationId,

      networkConfigs: [
        {
          ServiceName: serviceName, // Ensure that serviceName is a state variable
          PortNumber: portNumber, // Ensure that portNumber is a state variable
          TransportProtocol: transportProtocol, // Ensure that transportProtocol is a state variable
          Description: description, // Ensure that description is a state variable
        },
      ],
    };

    console.log("Form Data:", formData); // For debugging

    try {
      const response = await notifyApi.post(
        `/add-whitelist-ports/${user?.data?.user?.userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log("Success:", response.data);
      fetchDataWhitelist();
      handleClose(); // Close the modal on success
      toast.success("Added whitelist ports successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const data = networkConfigs.map((config) => ({
      id: currentId, // ID of the item to be updated
      ServiceName: serviceName,
      PortNumber: parseInt(portNumber, 10),
      TransportProtocol: transportProtocol,
      Description: description,
      organizationId: user?.data?.user?.organizationId,
    }));

    try {
      const promises = data.map((item) =>
        notifyApi.put(`/whitelist-port/${user?.data?.user?.userId}`, item, {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        })
      );

      const responses = await Promise.all(promises);
      responses.forEach((response) =>
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
        })
      );
      fetchDataWhitelist();
      setShowOffcanvas(false);
      setSelectedRows([]);
      setIsEdit(false); // Switch to edit mode
      setPortNumber(); // Prefill form fields
      setServiceName();
      setTransportProtocol();
      setDescription();
      setCurrentId(); // Set the current ID of the row being edited
      setShow(false); // Show the modal
    } catch (error) {
      toast.error(error.response?.data?.message || "Error updating ports");
      console.error("Error updating ports:", error);
    }
  };

  



// const handleRemoveEdrDetail = async (index, id, userId) => {
//   try {
//     // Make the API call to delete the configuration by ID
//     await axios.delete(`/agent-config/${id}/${userId}`);
    
//     // If the API call is successful, update the state
//     const updatedEdrDetails = edrDetails.filter((_, i) => i !== index);
//     setEdrDetails([...updatedEdrDetails]);
//   } catch (error) {
//     console.error('Error deleting EDR detail:', error);
//   }
// };

// const handleRemoveAvDetail = async (index, id, userId) => {
//   try {
//     // Make the API call to delete the configuration by ID
//     await axios.delete(`/agent-config/${id}/${userId}`);
    
//     // If the API call is successful, update the state
//     const updatedAvDetails = avDetails.filter((_, i) => i !== index);
//     setAvDetails([...updatedAvDetails]);
//   } catch (error) {
//     console.error('Error deleting AV detail:', error);
//   }
// };

// const handleRemoveOsDetail = async (index, id, userId) => {
//   try {
//     // Make the API call to delete the configuration by ID
//     await axios.delete(`/agent-config/${id}/${userId}`);
    
//     // If the API call is successful, update the state
//     const newOsDetails = osDetails.filter((_, i) => i !== index);
//     setOsDetails(newOsDetails);
//   } catch (error) {
//     console.error('Error deleting OS detail:', error);
//   }
// };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-18 fw-bolder p-0 m-0">Whitelisted Ports</p>
          </span>

          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={() => exportToPDF(columnDataWhitelist)}
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => exportToCSV(columnDataWhitelist)}
            >
              <img src={Excellogo} height={20} width={15} />
              &nbsp; CSV
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <div className="col-xl">
            <p className="fs-18 text-dark fw-bolder p-0 m-0">
              Whitelist Permitted
            </p>
            <p className="text-danger p-0 m-0 fw-semibold">
              Total Ports:{columnDataWhitelist.length}
            </p>
          </div>
          <button
            type="button"
            className="btn btn-secondary text-white"
            onClick={() => setShowOffcanvasOne(true) }
            disabled={selectedRows.length === 0}
          >
            <i class="fa-solid fa-lock"></i> Unauthorize
          </button>
          &nbsp; &nbsp;
          <button
            type="button"
            className="btn btn-success text-white"
            onClick={() => setShowOffcanvas(true)}
            disabled={selectedRows.length === 0}
          >
            <i class="fa-solid fa-check"></i> Authorize
          </button>
          &nbsp; &nbsp;
          <Button variant="info" onClick={handleShow}>
            <i class="fa-solid fa-plus"></i> Add Port
          </Button>
        </div>

        <div className="card rounded cardStyle">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataTable
              columns={columnsWhite}
              data={columnDataWhitelist}
              pagination
              customStyles={customStyles}
              selectableRowsHighlight
              highlightOnHover
              paginationServer
              paginationTotalRows={totalDocuments}
              paginationDefaultPage={pageNumber}
              paginationPerPage={pageSize}
              selectableRows
              clearSelectedRows={selectedRows.length === 0} // Clear selected rows when state is reset
              onSelectedRowsChange={handleSelectedRowsChange}
              onChangePage={(page) => {
                console.log("Page", page);
                handlePageChange(page);
              }}
              onChangeRowsPerPage={(newPageSize) => {
                console.log("Page Size", newPageSize);
                handlePageSizeChange(newPageSize);
              }}
              noDataComponent={<p className="p-0 m-2 me-auto">No Data Found</p>}
            />
          )}
        </div>

        {/* <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Port</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-3">
                <label htmlFor="portNumber" className="form-label">
                  Port Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="portNumber"
                  min="1"
                  max="65535"
                  value={portNumber}
                  onChange={(e) => setPortNumber(e.target.value)}
                  required
                />
                <div className="invalid-feedback">
                  Port number must be between 1 and 65535.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="serviceName" className="form-label">
                  Service Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="serviceName"
                  value={serviceName}
                  onChange={(e) => setServiceName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="transportProtocol" className="form-label">
                  Transport Protocol
                </label>
                <select
                  id="transportProtocol"
                  className=" form-control form-select"
                  value={transportProtocol}
                  onChange={(e) => setTransportProtocol(e.target.value)}
                >
                  <option value="" disabled>
                    --Select--
                  </option>
                  <option value="DCCP">DCCP</option>
                  <option value="SCTP">SCTP</option>
                  <option value="TCP">TCP</option>
                  <option value="UDP">UDP</option>
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal> */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isEdit ? "Edit Port" : "Add Port"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-3">
                <label htmlFor="portNumber" className="form-label">
                  Port Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="portNumber"
                  min="1"
                  max="65535"
                  value={portNumber}
                  onChange={(e) => setPortNumber(e.target.value)}
                  required
                />
                <div className="invalid-feedback">
                  Port number must be between 1 and 65535.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="serviceName" className="form-label">
                  Service Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="serviceName"
                  value={serviceName}
                  onChange={(e) => setServiceName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="transportProtocol" className="form-label">
                  Transport Protocol
                </label>
                <select
                  id="transportProtocol"
                  className="form-control form-select"
                  value={transportProtocol}
                  onChange={(e) => setTransportProtocol(e.target.value)}
                >
                  <option value="" disabled>
                    --Select--
                  </option>
                  <option value="DCCP">DCCP</option>
                  <option value="SCTP">SCTP</option>
                  <option value="TCP">TCP</option>
                  <option value="UDP">UDP</option>
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={isEdit ? handleUpdate : handleSubmit}
            >
              {isEdit ? "Update" : "Submit"}
            </Button>
          </Modal.Footer>
        </Modal>

        <Offcanvas
          show={showOffcanvasOne}
          onHide={() => setShowOffcanvasOne(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Authorize Selected Ports</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div>
              <p>Selected Ports:</p>
              {selectedRows.map((row, index) => (
                <div key={index}>
                  <p>{row.ServiceName}</p>
                  <p>{row.PortNumber}</p>
                  <p>{row.TransportProtocol}</p>
                  <p>{row.Description}</p>
                  <hr />
                </div>
              ))}
              <button
                onClick={handleSubmitAuthorized}
                className="btn btn-primary"
              >
                Authorize Selected Ports
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas
          show={showOffcanvas}
          onHide={() => setShowOffcanvas(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Authorize Selected Ports</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div>
              <p>Selected Ports:</p>
              {selectedRows.map((row, index) => (
                <div key={index}>
                  <p>{row.ServiceName}</p>
                  <p>{row.PortNumber}</p>
                  <p>{row.TransportProtocol}</p>
                  <p>{row.Description}</p>
                  <hr />
                </div>
              ))}
              <button
                onClick={handleSubmitUnAuthorized}
                className="btn btn-primary"
              >
                Authorize Selected Ports
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
}

export default Test;
