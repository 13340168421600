import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../layouts/Header";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { notifyApi } from "../../../api/axiosSet";
import { useSelector } from "react-redux";
import { Button } from "antd";
import Offcanvas from "react-bootstrap/Offcanvas";
import Excellogo from "../../../assets/img/Excellogo.png";
import AsyncSelect from "react-select/async";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Tippy from "@tippyjs/react";

function WhitelistDevice() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingDevice, setEditingDevice] = useState(null);

  const [formState, setFormState] = useState({
    name: "",
    manufacturer: "",
    serialNumber: "",
    productId: "",
    vendorId: "",
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const invalidCharacters = /[<>'"]/;
  const hasSpecialChars = /[^a-zA-Z0-9\s]/;
  const underscoreAllowed = /^[a-zA-Z0-9-_]+$/;

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  const { user } = useSelector((state) => state.authSlice);
  useEffect(() => {
    if (user?.isPasswordUpdated === false) {
      navigate('/update-profile')
    }
  }, []);
  const fetchData = async () => {
    try {
      const response = await notifyApi.get(
        `/get/whitelist-usbs/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}?page=${pageNumber}&limit=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      const devices = response.data.data;
      setColumnData(devices);
      setTotalDocuments(response?.data?.pagination?.totalDocuments);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
      setColumnData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user, pageNumber, pageSize]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <Tippy content={row.usbName}>
          <span>{row.usbName}</span>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Serial Number",
      selector: (row) => (
        <Tippy content={row.usbSerialNumber}>
          <span>{row.usbSerialNumber}</span>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Manufacturer",
      selector: (row) => (
        <Tippy content={row.usbManufacturer}>
          <span>{row.usbManufacturer}</span>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Product ID",
      selector: (row) => row.usbProductId || "NA",
      sortable: true,
    },
    {
      name: "Vendor ID",
      selector: (row) => row.usbVendorId || "NA",
      sortable: true,
    },
    {
      name: "Sync Time",
      selector: (row) => formatTimestamp(row.addedOn) || "NA",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button type="button" className="btn btn-dark" onClick={() => handleEdit(row)}>
            <i className="fa-solid fa-pen"></i>
          </button>
          &nbsp;
          <button type="button" className="btn btn-danger" onClick={() => handleDelete(row._id)}>
            <i className="fa-solid fa-trash"></i>
          </button>
        </>
      ),
      sortable: false,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#D7E3E8",
      },
    },
  };

  const handleEdit = (device) => {
    setFormState({
      name: device.usbName,
      manufacturer: device.usbManufacturer,
      serialNumber: device.usbSerialNumber,
      productId: device.usbProductId,
      vendorId: device.usbVendorId,
    });
    setEditingDevice(device?._id); // Store the ID of the device being edited
    document.getElementById("openModalButton").click();
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this USB device?"
    );

    if (!confirmDelete) {
      return;
    }
    try {
      await notifyApi.post(
        `/delete-usb/${user?.data?.user?.userId}`,
        {
          _id: id,
        }
      );
      toast.success("USB device deleted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      fetchData();
    } catch (error) {
      toast.error("Error deleting USB device!");
      console.error("Error deleting device:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingDevice) {
      handleSubmitEditDevice();
    } else {
      handleSubmitAddDevice();
    }
  };

  const validate = ()=>{
    let isValid = true;
    let error;
    if (!formState.name || formState.name.trim() === "") {
      isValid = false;
      error = "USB name is required";
    }
    else if (!formState.manufacturer || formState.manufacturer.trim() === "") {
      isValid = false;
      error = "USB manufacturer is required";
    }
    else if (!formState.serialNumber || formState.serialNumber.trim() === "") {
      isValid = false;
      error = "USB serial number is required";
    }
    else if (!formState.productId || formState.productId.trim() === "") {
      isValid = false;
      error = "USB product id is required";
    }
    else if (!formState.vendorId || formState.vendorId.trim() === "") {
      isValid = false;
      error = "USB vendor id is required";
    }
    else if(!underscoreAllowed.test(formState.name)){
      isValid = false;
      error = "USB name should not contain special characters";
    }
    else if(invalidCharacters.test(formState.manufacturer)){
      isValid = false;
      error = "USB manufacturer contains invalid characters";
    }
    else if(hasSpecialChars.test(formState.serialNumber)){
      isValid = false;
      error = "USB serial number should not contain special characters";
    }
    else if(hasSpecialChars.test(formState.productId)){
      isValid = false;
      error = "USB product id should not contain special characters";
    }
    else if(hasSpecialChars.test(formState.vendorId)){
      isValid = false;
      error = "USB vendor id should not contain special characters";
    }
    return { error, isValid };
  }

  const handleSubmitAddDevice = async () => {
    const { error, isValid } = validate();
    if (!isValid) {
      alert(error);
      return;
    }

    const data = {
      organizationId: user?.data?.user?.organizationId,
      usbName: formState.name,
      usbManufacturer: formState.manufacturer,
      usbSerialNumber: formState.serialNumber,
      usbProductId: formState.productId,
      usbVendorId: formState.vendorId,
    };

    try {
      await notifyApi.post(
        `/add/whitelist-usb/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      toast.success("USB device added successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setFormState({
        name: "",
        manufacturer: "",
        serialNumber: "",
        productId: "",
        vendorId: "",
      });
      setEditingDevice(null);
      fetchData();
      document.getElementById("closeModal").click();
    } catch (error) {
      document.getElementById("closeModal").click();
      let errormesseage = "Error adding USB device!";
      if (error.response && error.response.data && error.response.data.error && error.response.data.error.includes("duplicate key error collection")) {
        errormesseage = "Device with same Serial Number already exists!";
      }
      toast.error(errormesseage, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
    }
  };

  const handleSubmitEditDevice = async () => {
    if (
      !formState.name || formState.name.trim() === "" ||
      !formState.manufacturer || formState.manufacturer.trim() === "" ||
      !formState.serialNumber || formState.serialNumber.trim() === "" ||
      !formState.productId || formState.productId.trim() === "" ||
      !formState.vendorId || formState.vendorId.trim() === ""
    ) {
      alert("Please fill valid fields.");
      return;
    }

    const data = {
      _id: editingDevice,
      organizationId: user?.data?.user?.organizationId,
      usbName: formState.name,
      usbManufacturer: formState.manufacturer,
      usbSerialNumber: formState.serialNumber,
      usbProductId: formState.productId,
      usbVendorId: formState.vendorId,
    };

    try {
      await notifyApi.put(
        `/update-usb/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      toast.success("USB device updated successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setEditingDevice(null);
      fetchData();
      setFormState({
        name: "",
        manufacturer: "",
        serialNumber: "",
        productId: "",
        vendorId: "",
      });
      setEditingDevice(null);
      document.getElementById("closeModal").click();
    } catch (error) {
      toast.error("Error updating USB device!");
      console.error("Error updating device:", error);
    }
  };

  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      // Determine which columns have data
      const hasData = (key) => columnData.some((item) => item[key]);

      const headers = [
        { label: "USB Name", key: "usbName" },
        { label: "Serial Number", key: "usbSerialNumber" },
        { label: "Manufacturer", key: "usbManufacturer" },
        { label: "Product ID", key: "usbProductId" },
        { label: "Vendor ID", key: "usbVendorId" },
        { label: "Sync Time", key: "updatedAt" },
      ].filter((header) => hasData(header.key));

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail)
            const value = Object.values(detail)
            return `${key}:${value}`;
          })
          .join(" | ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      const formatMultipleIpAddresses = (ipAddresses) => {
        if (typeof ipAddresses === "string") {
          return ipAddresses
        }
        return ipAddresses
          .map((ipAddress) => ipAddress)
          .join(" ")
          .replace(/ /g, " | ");
      };

      const formatUsersPasswordAge = (usersPasswordAge) => {
        if (!usersPasswordAge || usersPasswordAge.length === 0) return "N/A"; // Handle empty or undefined case

        return usersPasswordAge
          .map((user) => `${user.userType}:${user.userName}:${user.passwordAge}`)
          .join(" | ");
      };

      const csvData = [
        headers.map((header) => header.label),
        ...columnData.map((item) =>
          headers.map((header) =>
            header.key === "ipAddress"
              ? formatMultipleIpAddresses(item[header.key])
              :
              header.key === "updatedAt"
                ? formatTimestamp(item[header.key])
                : header.key === "usersPasswordAge"
                  ? formatUsersPasswordAge(item[header.key])
                  : header.key === "sharedDirectories"
                    ? formatSharedDirectories(item[header.key])
                    : header.key === "avDetails"
                      ? formatAvDetails(item[header.key])
                      : header.key === "service"
                        ? formatOsDetails(item[header.key])
                        : item[header.key]
          )
        ),
      ];
      // Add header information
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `whitelist_usb_devices.csv`);
    }
  };

  function exportToPDF() {
    if (columnData && columnData.length > 0) {
      // Set the orientation to landscape
      const doc = new jsPDF({
        orientation: "landscape", // Force landscape orientation
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      doc.text(`Whitelist USB Devices`, 14, 22);

      const hasData = (key) => columnData.some((item) => item[key]);
      const headers = [
        { label: "USB Name", key: "usbName" },
        { label: "Serial Number", key: "usbSerialNumber" },
        { label: "Manufacturer", key: "usbManufacturer" },
        { label: "Product ID", key: "usbProductId" },
        { label: "Vendor ID", key: "usbVendorId" },
        { label: "Sync Time", key: "updatedAt" },
      ].filter((header) => hasData(header.key));

      const tableColumn = headers.map((header) => header.label);
      const tableRows = [];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail).find((k) => k.startsWith("clamav"));
            const avName = key ? key : "Unknown AV";
            const avStatus = detail[key] || "Status not available";

            return `AV Name: ${avName}, Status: ${avStatus}`;
          })
          .join("; ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      columnData.forEach((item) => {
        const rowData = headers.map((header) =>
          header.key === "updatedAt"
            ? formatTimestamp(item[header.key])
            : header.key === "sharedDirectories"
              ? formatSharedDirectories(item[header.key])
              : header.key === "usersPasswordAge"
                ? item[header.key]
                  .map(
                    (user) =>
                      `${user.userType} :${user.userName}: ${user.passwordAge}`
                  )
                  .join("; ")
                : header.key === "avDetails"
                  ? formatAvDetails(item[header.key])
                  : header.key === "service"
                    ? formatOsDetails(item[header.key])
                    : Array.isArray(item[header.key])
                      ? item[header.key].join(", ")
                      : item[header.key]
        );
        tableRows.push(rowData);
      });

      // Add table with borders for rows and columns
      doc.autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: {
          cellPadding: 3, // Padding for cells
          lineColor: [44, 62, 80], // Border color (RGB)
          lineWidth: 0.1, // Border width
        },
        headStyles: {
          fillColor: [52, 73, 94], // Header background color
          textColor: 255, // Header text color
          halign: "center", // Center align header text
          lineWidth: 0.5, // Border width for header
        },
        bodyStyles: {
          lineColor: [44, 62, 80], // Row border color
          lineWidth: 0.1, // Border width for rows
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Background color for alternate rows
        },
      });

      // Save the PDF
      doc.save(`whitelist_usb_devices.pdf`);
    }
  }


  const resetFormState = () => {
    setFormState({
      name: "",
      manufacturer: "",
      serialNumber: "",
      productId: "",
      vendorId: "",
    });
    setEditingDevice(null);
  };

  useEffect(() => {
    // Add event listener when the component mounts
    const modal = document.getElementById('deviceModal');

    modal.addEventListener('hidden.bs.modal', resetFormState);

    // Cleanup event listener when the component unmounts
    return () => {
      modal.removeEventListener('hidden.bs.modal', resetFormState);
    };
  }, []);

  return (
    <React.Fragment>
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-20 fw-bolder p-0 m-0"> Whitelist USB Devices</p>
          </span>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={() => exportToPDF()}
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => exportToCSV()}
            >
              <i className="fa-solid fa-file-excel"></i> &nbsp; CSV
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <div className="col-xl">
            <p className="fs-18 text-dark fw-bolder p-0 m-0">USB Devices List</p>
            <p className="text-danger p-0 m-0 fw-semibold">
              Total Devices: {totalDocuments}
            </p>
          </div>

          <button
            type="button"
            id="openModalButton"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#deviceModal"
          >
            Add USB Device
          </button>
        </div>
        <div className="card rounded cardStyle">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataTable
              columns={columns}
              data={columnData}
              pagination
              paginationServer
              highlightOnHover
              customStyles={customStyles}
              progressPending={loading}
              progressComponent={<span>Loading...</span>}
              paginationTotalRows={totalDocuments}
              paginationDefaultPage={pageNumber}
              paginationPerPage={pageSize}
              onChangePage={(page) => {
                handlePageChange(page);
              }}
              onChangeRowsPerPage={(newPageSize) => {
                handlePageSizeChange(newPageSize);
              }}
              noDataComponent={<span className="p-2">No Device Found</span>}
            />
          )}
        </div>
        <div>
          <div
            className="modal fade"
            id="deviceModal"
            tabIndex="-1"
            aria-labelledby="deviceModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="deviceModalLabel">
                    {editingDevice ? "Edit USB Device" : "Add USB Device"}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="closeModal"
                  ></button>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Device Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formState.name}
                        onChange={handleChange}
                        maxLength={50}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="manufacturer" className="form-label">
                        Manufacturer <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="manufacturer"
                        name="manufacturer"
                        value={formState.manufacturer}
                        onChange={handleChange}
                        maxLength={50}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="serialNumber" className="form-label">
                        Serial Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="serialNumber"
                        name="serialNumber"
                        value={formState.serialNumber}
                        onChange={handleChange}
                        maxLength={50}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="productId" className="form-label">
                        Product ID <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="productId"
                        name="productId"
                        value={formState.productId}
                        onChange={handleChange}
                        maxLength={50}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="vendorId" className="form-label">
                        Vendor ID <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="vendorId"
                        name="vendorId"
                        value={formState.vendorId}
                        onChange={handleChange}
                        maxLength={50}
                        required
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                      {editingDevice ? "Update" : "Add"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WhitelistDevice;
