import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const useFetchOsLinux = (pcInfoId, timeTrigger, legacyId) => {
  const [osData, setOsData] = useState([]);
  const [networkData, setNetworkData] = useState([]);
  const [basicInfoData, setBasicInfoData] = useState([]);
  const [antivirusInfoData, setAntivirusInfoData] = useState([]);
  const [hardwareInfoData, setHardwareInfoData] = useState([]);
  const [osPatchUpdateInfoData, setOsPatchUpdateInfoData] = useState(null);
  const [usbInfoData, setUsbInfoData] = useState([]);
  const [bluetoothInfoData, setBluetoothInfoData] = useState([]);
  const [installedProgrameData, setInstalledProgrameData] = useState([]);
  const [serviceProgrameData, setServiceProgrameData] = useState([]);
  const [startUpProgrameData, setStartUpProgrameData] = useState([]);
  const [recentEventData, setRecentEventData] = useState([]);
  const [linuxCisData, setLinuxCisData] = useState([]);
  const [establishedConnection, setEstablishedConnection] = useState([]);
  const [highCpuusage, setHighCpuUsage] = useState([]);
  const [ramUsage, setRamUsage] = useState([]);
  const [osloading, setOsLoading] = useState(true);
  const [securityControls, setSecurityControls] = useState([]);
  const [sharedDir, setSharedDir] = useState([]);
  const [osPatchInformation, setOsPatchInformation] = useState([]);
  const [accountDetail, setAccountDetail] = useState([]);
  const [internalHardDrive, setInternalHardDrive] = useState([]);
  const [logError, setLogError] = useState(false);
  const [usbFileData, setUsbFileData] = useState([]);
  const [auditScore, setAuditScore] = useState([0]);
  const [avCards, setAvCards] = useState([]);
  const [fileIntegrityInfo, setFileIntegrityInfo] = useState([]);
  console.log(avCards, "avCards");

  const { user } = useSelector((state) => state.authSlice);
  //legacyId  routing set
  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_LINUX_URL
          }/legacyinfo/dashboard/legacy-latest-by-pcId/${
            user?.data?.user?.userId
          }?pcId=${pcInfoId}&legacyId=${legacyId ? legacyId : ""}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          },
          { cancelToken: source.token }
        );

        const installedPResponse = await axios.get(
          `${process.env.REACT_APP_LINUX_URL}/backendinfo/installed-programs/in/pc/by/${pcInfoId}/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          },
          { cancelToken: source.token }
        );

        const osInstalledTime = response?.data?.formattedosInstallationDate;
        const loginTime = response?.data?.formattedLoginTime;
        const osEntriesData = response?.data?.legacyLogs;

        const usdHistoryData = response?.data?.UsbInformation;
        const recentEventTrigger = response?.data?.RecentEventsTriggered;
        const recentAvCards = response?.data?.filteredAvInfo;

        const serverTimeeStamp = response?.data?.formattedserverTimestamp;
        const bootTime = response?.data?.formattedSystemBootTime;
        const EventsTriggered = response?.data;
        const usbFileHistroy = response?.data;
        const triggerUser_type = response?.data?.latestEventTriggerType;
        const audit_score = osEntriesData?.pcIdentityInfo;

        console.log(triggerUser_type, "qqqqqqqqqq");
        setAuditScore(audit_score?.auditScore);

        setBasicInfoData([
          {
            "Serial Number":
              osEntriesData?.pcIdentityInfo?.serialNumber || "NA",
            "Endpoint ID": osEntriesData?.pcIdentityInfo?.licenseKey || "NA",
            Hostname: osEntriesData?.pcIdentityInfo?.hostname || "NA",
            "IP Address":
              osEntriesData?.pcIdentityInfo?.ipAddress?.split(" ") || [],
            "Logged-In User":
              osEntriesData?.pcIdentityInfo?.currentUser || "NA",
            "User Type":
              osEntriesData?.accountInfo?.adminAccountsAreMultiple?.adminAccountsName?.includes(
                osEntriesData?.pcIdentityInfo?.currentUser
              )
                ? "Admin"
                : "User" || "NA",

            "Trigger Type": triggerUser_type || "NA",
            "Audit Time": loginTime || "NA",
            "Last Sync Time": serverTimeeStamp || "NA",
            "Last Boot Time": bootTime || "NA",
            "Audit Agent":
              osEntriesData?.pcIdentityInfo?.currentAgentVersion || "NA",
          },
        ]);
        setOsData([
          {
            OS: osEntriesData?.pcIdentityInfo?.osName || "NA",
            "OS Version": osEntriesData?.osInfo?.osVersion || "NA",
            "OS Architecture":
              osEntriesData?.hardwareInfo?.machineBitType || "NA",

            "OS Installed On": osInstalledTime || "NA",
            Timezone: osEntriesData?.hardwareInfo?.timeZone || "NA",
            "Audit Logging":
              osEntriesData?.osInfo?.auditLogs === "active"
                ? "Active"
                : "Inactive" || "NA",

            "Boot Device": osEntriesData?.osInfo?.bootDevice || "NA",
          },
        ]);

        console.log(osEntriesData?.networkInfo?.wifiStatus[0], "wwwwww");
        setAvCards([recentAvCards]);
        setNetworkData([
          [
            {
              Name:
                osEntriesData?.networkInfo?.wifiStatus.length > 0 &&
                osEntriesData?.networkInfo?.ethernetStatus?.length > 0
                  ? osEntriesData?.networkInfo?.ethernetStatus
                    ? "Ethernet"
                    : "OFF"
                  : osEntriesData?.networkInfo?.wifiStatus.length > 0
                  ? osEntriesData?.networkInfo?.wifiStatus[0]?.ssid
                  : osEntriesData?.networkInfo?.ethernetStatus?.length > 0
                  ? "Ethernet"
                  : "OFF",
              // "IP Address":
              //   osEntriesData?.pcIdentityInfo?.ipAddress.split(" ")[0] || "NA",
              "IP Address": osEntriesData?.pcIdentityInfo?.ipAddress
                ? osEntriesData.pcIdentityInfo.ipAddress
                    .trim() // Trim whitespace
                    .split(",")[0] // Split by comma and take the first IP
                    .trim() // Trim the first IP again in case there are spaces
                : "NA",

              // Type:
              //   osEntriesData?.networkInfo?.connectionStatus?.connectionType ||
              //   "NA",
              "MAC Address":
                osEntriesData?.networkInfo?.wifiStatus?.length > 0 &&
                osEntriesData?.networkInfo?.ethernetStatus?.length > 0
                  ? osEntriesData?.networkInfo?.ethernetStatus
                    ? osEntriesData?.networkInfo?.ethernetStatus[0]?.macAddress
                    : "NA"
                  : osEntriesData?.networkInfo?.wifiStatus?.length > 0
                  ? osEntriesData?.networkInfo?.wifiStatus[0]?.macAddress
                  : osEntriesData?.networkInfo?.ethernetStatus?.length > 0
                  ? osEntriesData?.networkInfo?.ethernetStatus[0]?.macAddress
                  : "NA",
              "Network Category":
                osEntriesData?.networkInfo?.wifiStatus?.length > 0 &&
                osEntriesData?.networkInfo?.ethernetStatus?.length > 0
                  ? osEntriesData?.networkInfo?.ethernetStatus
                    ? "Wired"
                    : "NA"
                  : osEntriesData?.networkInfo?.wifiStatus?.length > 0
                  ? "Wifi"
                  : osEntriesData?.networkInfo?.ethernetStatus?.length > 0
                  ? "Wired"
                  : "NA",
              "DNS Server": osEntriesData?.networkInfo?.dnsServer || " NA",
              "Connected To Domain":
                osEntriesData?.pcIdentityInfo?.connectedToDomainName || " NA",

              "NTP Server":
                osEntriesData?.networkInfo?.ntpDetails?.ntpServer || "NA",
              "NTP Status": osEntriesData?.networkInfo?.ntpDetails?.isNtpEnabled
                ? "Enabled"
                : "Disabled" || "NA",

              "Total NIC Cards":
                osEntriesData?.hardwareInfo?.nicDetails?.nicCount || "NA",
              "Total Open Ports":
                osEntriesData?.networkInfo?.openTcpPorts.length || "NA",
            },
          ],
          {
            totalOpenPorts: osEntriesData?.networkInfo?.openTcpPorts || [],
            totalNICCards:
              osEntriesData?.hardwareInfo?.nicDetails?.nicInfo || [],
          },
        ]);

        setAntivirusInfoData([
          [
            {
              "Installed AV": "0",
              "Enterprise AV Installed": "0",
            },
          ],
          [],
        ]);

        setHardwareInfoData([
          [
            {
              Manufacturer:
                osEntriesData?.pcIdentityInfo?.systemManufacturer || "NA",
              Model: osEntriesData?.pcIdentityInfo?.systemModel || "NA",
              "Machine Type": osEntriesData?.hardwareInfo?.machineType || "NA",
              "Motherboard SN":
                osEntriesData?.pcIdentityInfo?.motherboardSerialNumber || "NA",
              CPU: osEntriesData?.hardwareInfo?.processorInfo || "NA",
              "Processor Architecture":
                osEntriesData?.hardwareInfo?.machineBitType || "NA",
              RAM:
                osEntriesData?.hardwareInfo?.memoryInformation?.totalRAM ||
                "NA",
              "HDD/SSD":
                Math.round(
                  osEntriesData?.hardwareInfo?.internalHardDrivesInfo?.reduce(
                    (s, v) => (s += +v?.size),
                    0
                  )
                ) + " GB" || "NA",
              "Device UUID":
                osEntriesData?.hardwareInfo?.deviceUUIDNumber || "NA",

              "BIOS Version": osEntriesData?.hardwareInfo?.biosVersion || "NA",
              "BIOS Battery State": osEntriesData?.hardwareInfo
                ?.biosBatteryServiceable
                ? "Serviceable"
                : "Unserviceable" || "NA",
            },
          ],
          {
            ramData: osEntriesData?.hardwareInfo?.memoryInformation || [],
          },
        ]);

        console.log(osEntriesData?.osInfo?.edrInstalled, "bbbbbbbb");

        setSecurityControls([
          {
            "RDP Status":
              osEntriesData?.osInfo?.rdpStatus?.rdpEnabled === "False"
                ? "Disabled"
                : "Enabled" || "NA",
            "SSH Status":
              osEntriesData?.osInfo?.rdpStatus?.sshActive === "False"
                ? "Disabled"
                : "Enabled" || "NA",
            Firewall: "UFW.service",
            "Firewall Status":
              osEntriesData?.networkInfo?.firewallStatus || "NA",
            "Installed AV": "NA",
            "Av Services Status": "NA",
            "EDR Installed": osEntriesData?.osInfo?.edrInstalled
              ? "True"
              : "False" || "NA",
            "EDR Service Status": "NA",
            "NAC Installed": osEntriesData?.networkInfo?.nacInstalled || "NA",
          },
        ]);

        setOsPatchUpdateInfoData(osEntriesData?.OsPatchUpdateInfo || []);
        setUsbFileData(usbFileHistroy?.FileHistory);

        setUsbInfoData([
          [
            {
              "Usb History": usdHistoryData?.history?.length,
            },
          ],
          {
            usbHistory: usdHistoryData?.history || [],
            usbConnection: osEntriesData?.usbInfo?.usbInfoDetails || [],
          },
        ]);

        setBluetoothInfoData([
          [
            {
              "Bluetooth Status": osEntriesData?.networkInfo?.bluetoothInfo
                ? osEntriesData?.networkInfo?.bluetoothInfo?.status
                : "NA",
              "End Point Serial No":
                osEntriesData?.networkInfo?.bluetoothInfo?.deviceSerial || "NA",
              "Paried Devices":
                osEntriesData?.networkInfo?.bluetoothInfo?.pairedDevice
                  .length || 0,
            },
          ],
          {
            blueToothPariedDevice:
              osEntriesData?.networkInfo?.bluetoothInfo?.pairedDevice || [],
            blueToothConnectedDevice:
              osEntriesData?.networkInfo?.bluetoothInfo?.connectedDevice || [],
          },
        ]);

        setInstalledProgrameData(
          osEntriesData?.backendInfo?.installedPrograms || []
        );

        setServiceProgrameData(osEntriesData?.osInfo?.servicesInfo || []);
        setStartUpProgrameData(osEntriesData?.osInfo?.startupPrograms || []);
        setRecentEventData([EventsTriggered?.RecentEventsTriggered]);
        setRecentEventData([recentEventTrigger]);

        setLinuxCisData(osEntriesData?.cisInfo || []);

        setEstablishedConnection(
          osEntriesData?.networkInfo?.establishedNetworkConnections || []
        );

        setHighCpuUsage(osEntriesData?.hardwareInfo?.highCpuUsage || []);
        setRamUsage(osEntriesData?.hardwareInfo?.ramUsage || []);

        setAccountDetail([osEntriesData?.accountInfo] || []);
        setOsPatchInformation(osEntriesData?.backendInfo?.osPatchInfo || []);

        console.log(osEntriesData, "OS INFORMAITON");
        setFileIntegrityInfo(response?.data?.fileIntegrityComparision || []);
        setSharedDir(osEntriesData?.osInfo?.sharedDirectories || []);

        setInternalHardDrive(
          osEntriesData?.hardwareInfo?.internalHardDrivesInfo || []
        );

        setOsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
          setOsLoading(false);
        } else if (error.response && error.response.status === 404) {
          console.log(error.response, "Data not found");
          setLogError(true);

          setOsLoading(false);
        } else {
          console.error("Error fetching data:", error);
          setOsLoading(false);
        }
      }
    };

    fetchData();

    // Cleanup function to cancel the request when the component unmounts
    return () => {
      source.cancel("Request canceled due to component unmount.");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pcInfoId, timeTrigger]);

  return {
    osData,
    osloading,
    networkData,
    basicInfoData,
    antivirusInfoData,
    hardwareInfoData,
    osPatchUpdateInfoData,
    usbInfoData,
    bluetoothInfoData,
    installedProgrameData,
    serviceProgrameData,
    startUpProgrameData,
    recentEventData,
    linuxCisData,
    establishedConnection,
    highCpuusage,
    ramUsage,
    securityControls,
    accountDetail,
    osPatchInformation,
    sharedDir,
    internalHardDrive,
    logError,
    usbFileData,
    auditScore,
    avCards,
  };
};

export default useFetchOsLinux;
