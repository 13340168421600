import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
// import Header from "../../layouts/Header";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import { Link, useLocation, NavLink,useNavigate } from "react-router-dom";
import CustomTable from "../../components/common/Table/CustomTable";
import axios from "axios";
import PageLoader from "../../components/common/Loader/PageLoader";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import Excellogo from "../../assets/img/Excellogo.png";
import jsPDF from "jspdf";
import "jspdf-autotable"; // For adding table support
function LinuxServerityData() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const { state } = useLocation();
console.log(state.titleState,'statatatatata')
  const [linuxSummary, setLinuxSummary] = useState({
    totalClients: 0,
    totalSafeClients: 0,
    totalUnsafeClients: 0,
    totalUnresponsivelients: 0,
  });
  const [skin, setSkin] = useState(currentSkin);

  const [columnData, setColumnData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.authSlice);

  const [formData, setFormData] = useState({
    hostname: "",
    serialNumber: "",
    ipAddress: "",
    clientScore: "",
  });
  const handleInputChange = (e, labelKey) => {
    const { value, name } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [labelKey]: value,
    }));
  };

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  useEffect(() => {
    let isMounted = true;
    let timeoutId;

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_LINUX_URL}/${state?.callUrl}/${user?.data?.user?.userId}?page=${pageNumber}&pageSize=${pageSize}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );

        setTotalDocuments(response?.data?.pagination?.totalDocuments);
        if (isMounted) {
          console.log(response, "IN THE API RESPONSE");

          if (response?.data?.data.length > 0) {
            console.log(response?.data?.data, "LOGRESPONSE");
            const calculateData = response?.data?.data?.map((v) => {
              return {
                hostname: v?.hostname || "NA",
                serialNumber: v?.serialNumber || "NA",
                ipAddress: v?.ipAddress?.split(" ") || [],
                clientScore: v?.auditScore,
                updatedAt: v?.updatedAt,
                adminAccountsName: v?.adminAccountsName,
                sharedDirectories: v?.sharedDirectories,
                usersPasswordAge: v?.usersPasswordAge,
                notInstalledAvs: v?.notInstalledAvs,
                avDetails: v?.avDetails,
                osDetails: v?.osDetails,
                ssid: v?.ssid,
                authenticationType: v?.authenticationType,
                osName: v?.osName,
                currentOSVersion: v?.currentOSVersion,
                expectedOSVersion: v?.expectedOSVersion,
                avName: v?.avName,
                currentAVVersion: v?.currentAVVersion,
                expectedAVVersion: v?.expectedAVVersion,
                antivirusName: v?.antivirusName,
                antivirusVersion: v?.antivirusVersion,
                service: v?.service,
                serverTimestamp: v?.serverTimestamp,
                highCpuUsageCount:v?.highCpuUsageCount,
                highRamUsageCount:v?.highRamUsageCount,
                highCpuUsage:v?.highCpuUsage,
                ramUsage:v?.ramUsage,
                hardeningIssues:v?.hardeningIssues,
                action: "yourActionValue",
                pc_Id: v?.pcId || "NA",
              };
            });
            setColumnData(calculateData);
          } else {
            setColumnData([]);
          }
        }
      } catch (error) {
        if (isMounted) {
          if (error.response) {
            if (error.response.status === 401) {
              console.log("Unauthorized error occurred");
            } else if (error.response.status === 504) {
              console.log("Gateway Timeout error occurred");
            }
          } else {
            console.error("An error occurred:", error.message);
          }
          setError(error);
          console.log(error, "IN THE ERROR");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
          timeoutId = setTimeout(fetchData, 50000); // Adjusted interval to 50 seconds
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  }, [user, state, pageNumber, pageSize]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      // Determine which columns have data
      const hasData = (key) => columnData.some((item) => item[key]);

      const headers = [
        { label: "Hostname", key: "hostname" },
        { label: "Serial Number", key: "serialNumber" },
        { label: "IP Address", key: "ipAddress" },
        // { label: "Score", key: "clientScore" },
        { label: "Timestamp", key: "updatedAt" },
        { label: "Shared Directories", key: "sharedDirectories" },
        { label: "Users PasswordAge", key: "usersPasswordAge" }, // New field
        { label: "Not Installed", key: "notInstalledAvs" },
        { label: "Av Details", key: "avDetails" },
        { label: "Os Details", key: "osDetails" },
        { label: "SS Id", key: "ssid" },
        { label: "Authentication Type", key: "authenticationType" },
        { label: "Authentication Status", key: "authenticationStatus" },
        { label: "OS Name", key: "osName" },
        { label: "Current Version", key: "currentOSVersion" },
        { label: "Expected Version", key: "expectedOSVersion" },
        { label: "AV Name", key: "avName" },
        { label: "Current Version", key: "currentAVVersion" },
        { label: "Expected Version", key: "expectedAVVersion" },
        { label: "Antivirus", key: "antivirusName" },
        { label: "Antivirus Version", key: "antivirusVersion" },
        { label: "service", key: "service" },
        { label: "Timestamp", key: "serverTimestamp" },
        { label: "High Cpu Usage Count", key: "highCpuUsageCount" },
        { label: "High Ram Usage Count", key: "highRamUsageCount" },
      ].filter((header) => hasData(header.key));

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            // Extracting the key for dynamic property access
            const key = Object.keys(detail).find((k) => k.startsWith("clamav"));
            const avName = key ? key : "Unknown AV";
            const avStatus = detail[key] || "Status not available";

            return `AV Name: ${avName}, Status: ${avStatus}`;
          })
          .join("; ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      const csvData = [
        headers.map((header) => header.label),
        ...columnData.map((item) =>
          headers.map((header) =>
            header.key === "updatedAt"
              ? formatTimestamp(item[header.key])
              : header.key === "usersPasswordAge"
              ? item[header.key]
                  .map(
                    (user) =>
                      `${user.userType} :${user.userName}: ${user.passwordAge}`
                  )
                  .join("; ")
              : header.key === "sharedDirectories"
              ? formatSharedDirectories(item[header.key])
              : header.key === "avDetails"
              ? formatAvDetails(item[header.key])
              : header.key === "service"
              ? formatOsDetails(item[header.key])
              : item[header.key]
          )
        ),
      ];

      // Add header information
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `${state?.titleState}.csv`);
    }
  };

  // function exportToPDF() {
  //   if (columnData && columnData.length > 0) {
  //     const doc = new jsPDF();
  //     // Organization name and export date/time
  //     const orgName = user?.data?.user?.organization || "Organization Name";
  //     const exportDateTime = formatTimestamp(new Date());

  //     // Add header with org name on the left and export date/time on the right
  //     doc.setFontSize(12);
  //     doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
  //     doc.text(
  //       `Exported: ${exportDateTime}`,
  //       doc.internal.pageSize.getWidth() - 14,
  //       15,
  //       { align: "right" }
  //     ); // Right-aligned

  //     // Add title
  //     doc.setFontSize(15);
  //     doc.text(`${state?.titleState}`, 14, 22);

  //     // Determine which columns have data
  //     const hasData = (key) => columnData.some((item) => item[key]);
  //     const headers = [
  //       { label: "Hostname", key: "hostname" },
  //       { label: "Serial Number", key: "serialNumber" },
  //       { label: "IP Address", key: "ipAddress" },
  //       { label: "Score", key: "clientScore" },
  //       { label: "Timestamp", key: "updatedAt" },
  //       { label: "Shared Directories", key: "sharedDirectories" },
  //       { label: "Users PasswordAge", key: "usersPasswordAge" },
  //       { label: "Not Installed", key: "notInstalledAvs" },
  //       { label: "Av Details", key: "avDetails" },
  //       { label: "Os Details", key: "osDetails" },
  //       { label: "SS Id", key: "ssid" },
  //       { label: "Authentication Type", key: "authenticationType" },
  //       { label: "OS Name", key: "osName" },
  //       { label: "Current Version", key: "currentOSVersion" },
  //       { label: "Expected Version", key: "expectedOSVersion" },
  //       { label: "AV Name", key: "avName" },
  //       { label: "Current Version", key: "currentAVVersion" },
  //       { label: "Expected Version", key: "expectedAVVersion" },
  //       { label: "Antivirus", key: "antivirusName" },
  //       { label: "Antivirus Version", key: "antivirusVersion" },
  //       { label: "service", key: "service" }
  //     ].filter((header) => hasData(header.key));

  //     const tableColumn = headers.map((header) => header.label);
  //     const tableRows = [];

  //     // Function to format the avDetails array
  //     const formatAvDetails = (avDetails) => {
  //       return avDetails
  //         .map((detail) => {
  //           return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
  //         })
  //         .join("; ");
  //     };

  //     // const formatOsDetails = (osDetails) => {
  //     //   return osDetails
  //     //     .map((detail) => {
  //     //       return `OS Name: ${detail.osName}, Current Version: ${detail.currentOSVersion}, Expected Version: ${detail.expectedOSVersion}`;
  //     //     })
  //     //     .join("; ");
  //     // };
  //     const formatOsDetails = (service) => {
  //       return service
  //         .map((detail) => {
  //           // Extracting the key for dynamic property access
  //           const key = Object.keys(detail).find(k => k.startsWith('clamav'));
  //           const avName = key ? key : 'Unknown AV';
  //           const avStatus = detail[key] || 'Status not available';

  //           return `AV Name: ${avName}, Status: ${avStatus}`;
  //         })
  //         .join("; ");
  //     };
  //     const formatSharedDirectories = (sharedDirectories) => {
  //       return sharedDirectories
  //         .map((directory) => {
  //           const permissions = directory.permission;
  //           return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
  //         })
  //         .join("; ");
  //     };

  //     columnData.forEach((item) => {
  //       const rowData = headers.map((header) =>
  //         header.key === "updatedAt"
  //           ? formatTimestamp(item[header.key])
  //           : header.key === "sharedDirectories"
  //           ? formatSharedDirectories(item[header.key])
  //           : header.key === "usersPasswordAge"
  //           ? item[header.key]
  //               .map((user) => `${user.userType} :${user.userName}: ${user.passwordAge}`)
  //               .join("; ")
  //           : header.key === "avDetails"
  //           ? formatAvDetails(item[header.key])
  //           : header.key === "service"
  //           ? formatOsDetails(item[header.key])
  //           : Array.isArray(item[header.key])

  //           ? item[header.key].join(", ")
  //           : item[header.key]
  //       );
  //       tableRows.push(rowData);
  //     });

  //     // Add table
  //     doc.autoTable({
  //       startY: 30,
  //       head: [tableColumn],
  //       body: tableRows,
  //     });

  //     // Save the PDF
  //     doc.save(`${state?.titleState}_Report.pdf`);
  //   }
  // }
  // function exportToPDF() {
  //   if (columnData && columnData.length > 0) {
  //     // Determine orientation based on the number of columns
  //     // const orientation = columnData[0] && columnData[0].length > 10 ? "landscape" : "portrait";

  //     const doc = new jsPDF({
  //       orientation: "landscape", // Set the orientation based on the number of columns
  //     });

  //     // Organization name and export date/time
  //     const orgName = user?.data?.user?.organization || "Organization Name";
  //     const exportDateTime = formatTimestamp(new Date());

  //     // Add header with org name on the left and export date/time on the right
  //     doc.setFontSize(12);
  //     doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
  //     doc.text(
  //       `Exported: ${exportDateTime}`,
  //       doc.internal.pageSize.getWidth() - 14,
  //       15,
  //       { align: "right" }
  //     ); // Right-aligned

  //     // Add title
  //     doc.setFontSize(15);
  //     doc.text(`${state?.titleState}`, 14, 22);

  //     // Determine which columns have data
  //     const hasData = (key) => columnData.some((item) => item[key]);
  //     const headers = [
  //       { label: "Hostname", key: "hostname" },
  //       { label: "Serial Number", key: "serialNumber" },
  //       { label: "IP Address", key: "ipAddress" },
  //       { label: "Score", key: "clientScore" },
  //       { label: "Timestamp", key: "updatedAt" },
  //       { label: "Shared Directories", key: "sharedDirectories" },
  //       { label: "Users PasswordAge", key: "usersPasswordAge" },
  //       { label: "Not Installed", key: "notInstalledAvs" },
  //       { label: "Av Details", key: "avDetails" },
  //       { label: "Os Details", key: "osDetails" },
  //       { label: "SS Id", key: "ssid" },
  //       { label: "Authentication Type", key: "authenticationType" },
  //       { label: "OS Name", key: "osName" },
  //       { label: "Current Version", key: "currentOSVersion" },
  //       { label: "Expected Version", key: "expectedOSVersion" },
  //       { label: "AV Name", key: "avName" },
  //       { label: "Current Version", key: "currentAVVersion" },
  //       { label: "Expected Version", key: "expectedAVVersion" },
  //       { label: "Antivirus", key: "antivirusName" },
  //       { label: "Antivirus Version", key: "antivirusVersion" },
  //       { label: "service", key: "service" },
  //     ].filter((header) => hasData(header.key));

  //     const tableColumn = headers.map((header) => header.label);
  //     const tableRows = [];

  //     // Function to format the avDetails array
  //     const formatAvDetails = (avDetails) => {
  //       return avDetails
  //         .map((detail) => {
  //           return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
  //         })
  //         .join("; ");
  //     };

  //     const formatOsDetails = (service) => {
  //       return service
  //         .map((detail) => {
  //           // Extracting the key for dynamic property access
  //           const key = Object.keys(detail).find(k => k.startsWith('clamav'));
  //           const avName = key ? key : 'Unknown AV';
  //           const avStatus = detail[key] || 'Status not available';

  //           return `AV Name: ${avName}, Status: ${avStatus}`;
  //         })
  //         .join("; ");
  //     };

  //     const formatSharedDirectories = (sharedDirectories) => {
  //       return sharedDirectories
  //         .map((directory) => {
  //           const permissions = directory.permission;
  //           return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
  //         })
  //         .join("; ");
  //     };

  //     columnData.forEach((item) => {
  //       const rowData = headers.map((header) =>
  //         header.key === "updatedAt"
  //           ? formatTimestamp(item[header.key])
  //           : header.key === "sharedDirectories"
  //           ? formatSharedDirectories(item[header.key])
  //           : header.key === "usersPasswordAge"
  //           ? item[header.key]
  //               .map((user) => `${user.userType} :${user.userName}: ${user.passwordAge}`)
  //               .join("; ")
  //           : header.key === "avDetails"
  //           ? formatAvDetails(item[header.key])
  //           : header.key === "service"
  //           ? formatOsDetails(item[header.key])
  //           : Array.isArray(item[header.key])

  //           ? item[header.key].join(", ")
  //           : item[header.key]
  //       );
  //       tableRows.push(rowData);
  //     });

  //     // Add table
  //     doc.autoTable({
  //       startY: 30,
  //       head: [tableColumn],
  //       body: tableRows,
  //     });

  //     // Save the PDF
  //     doc.save(`${state?.titleState}_Report.pdf`);
  //   }
  // }
  function exportToPDF() {
    if (columnData && columnData.length > 0) {
      // Set the orientation to landscape
      const doc = new jsPDF({
        orientation: "landscape", // Force landscape orientation
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      doc.text(`${state?.titleState}`, 14, 22);

      // Determine which columns have data
      const hasData = (key) => columnData.some((item) => item[key]);
      const headers = [
        { label: "Hostname", key: "hostname" },
        { label: "Serial Number", key: "serialNumber" },
        { label: "IP Address", key: "ipAddress" },
        // { label: "Score", key: "clientScore" },
        { label: "Timestamp", key: "updatedAt" },
        { label: "Shared Directories", key: "sharedDirectories" },
        { label: "Users PasswordAge", key: "usersPasswordAge" },
        { label: "Not Installed", key: "notInstalledAvs" },
        { label: "Av Details", key: "avDetails" },
        { label: "Os Details", key: "osDetails" },
        { label: "SS Id", key: "ssid" },
        { label: "Authentication Type", key: "authenticationType" },
        { label: "OS Name", key: "osName" },
        { label: "Current Version", key: "currentOSVersion" },
        { label: "Expected Version", key: "expectedOSVersion" },
        { label: "AV Name", key: "avName" },
        { label: "Current Version", key: "currentAVVersion" },
        { label: "Expected Version", key: "expectedAVVersion" },
        { label: "Antivirus", key: "antivirusName" },
        { label: "Antivirus Version", key: "antivirusVersion" },
        { label: "service", key: "service" },
        { label: "Timestamp", key: "serverTimestamp" },
        { label: "High Cpu Usage Count", key: "highCpuUsageCount" },
        { label: "High Ram Usage Count", key: "highRamUsageCount" },
      ].filter((header) => hasData(header.key));

      const tableColumn = headers.map((header) => header.label);
      const tableRows = [];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail).find((k) => k.startsWith("clamav"));
            const avName = key ? key : "Unknown AV";
            const avStatus = detail[key] || "Status not available";

            return `AV Name: ${avName}, Status: ${avStatus}`;
          })
          .join("; ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      columnData.forEach((item) => {
        const rowData = headers.map((header) =>
          header.key === "updatedAt"
            ? formatTimestamp(item[header.key])
            : header.key === "sharedDirectories"
            ? formatSharedDirectories(item[header.key])
            : header.key === "usersPasswordAge"
            ? item[header.key]
                .map(
                  (user) =>
                    `${user.userType} :${user.userName}: ${user.passwordAge}`
                )
                .join("; ")
            : header.key === "avDetails"
            ? formatAvDetails(item[header.key])
            : header.key === "service"
            ? formatOsDetails(item[header.key])
            : Array.isArray(item[header.key])
            ? item[header.key].join(", ")
            : item[header.key]
        );
        tableRows.push(rowData);
      });

      // Add table with borders for rows and columns
      doc.autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: {
          cellPadding: 3, // Padding for cells
          lineColor: [44, 62, 80], // Border color (RGB)
          lineWidth: 0.1, // Border width
        },
        headStyles: {
          fillColor: [52, 73, 94], // Header background color
          textColor: 255, // Header text color
          halign: "center", // Center align header text
          lineWidth: 0.5, // Border width for header
        },
        bodyStyles: {
          lineColor: [44, 62, 80], // Row border color
          lineWidth: 0.1, // Border width for rows
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Background color for alternate rows
        },
      });

      // Save the PDF
      doc.save(`${state?.titleState}_Report.pdf`);
    }
  }

  useEffect(() => {
    if (!state?.callUrl) {
      // Fetch necessary data or set default values
    }
  }, [state?.callUrl]);
  

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "aliceblue" }}
      >
        <button
                className="btn-white rounded d-flex align-items-center mb-1 mx-3"
                onClick={() => navigate(-1)}
              >
              <i class="ri-arrow-left-line"
               style={{ verticalAlign: "middle", marginRight: "3px" }}
              ></i>
                {/* <i
                  className="ri-arrow-left-line fs-18 lh-1 mr-2"
                  style={{ verticalAlign: "middle", marginRight: "3px" }}
                ></i> */}
                {/* <span>Back</span> */}
              </button>

        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link
              to="/linux-dash"
              className="shadow"
              style={{ borderRadius: "25px" }}
            >
              <img src={linuxIcon} alt="Linux-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-20 fw-bolder p-0 m-0 ">Linux DashBoard</p>
          </span>
          {/* <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="secondary"
              className="d-flex align-items-center gap-2"
              onClick={exportToCSV}
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
              <span className="d-none d-sm-inline">Generate Report</span>
            </Button>
          </div> */}
        </div>
        {/* <Row>
          <Col xl={6}>
            <p className="fs-20 text-dark fw-bolder p-0 m-0">
              {state?.titleState || "Alert Title"}
            </p>
            <p className="text-danger p-0 m-0 fw-semibold">
              {totalDocuments || 0} Clients
            </p>
          </Col>
        </Row> */}

        <div class="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row allCardEffect shadow">
          <div class="col-xl">
            <p class="fs-18 text-dark fw-bolder p-0 m-0">
              {state?.titleState || "Alert Title"}
            </p>
            <p class="text-danger p-0 m-0 fw-semibold">
              {" "}
              {totalDocuments || 0} Endpoints{" "}
            </p>
          </div>

          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={exportToPDF}
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={exportToCSV}
            >
              <img src={Excellogo} height={20} width={15} />
              &nbsp; CSV
            </button>
          </div>
        </div>

        <div className="card allCardEffect shadow">
          {!loading ? (
            <CustomTable
              rowData={columnData}
              columnData={columnData}
              routeUrl={"/linux-client-Details"}
              title = {state?.titleState}
              currentPage={pageNumber}
              pageSize={pageSize}
              totalDocuments={totalDocuments}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />
          ) : (
            <PageLoader />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default LinuxServerityData;
