import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { notifyApi } from "../../../api/axiosSet";
import { useSelector } from "react-redux";
// import { Button,Modal } from "antd";
import { Modal, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Refresh } from "@mui/icons-material";
import Header from "../../../layouts/Header";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";
import Tippy from "@tippyjs/react";

function WhitelistPorts() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [columnDataWhitelist, setColumnDataWhitelist] = useState([]);

  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showOffcanvasOne, setShowOffcanvasOne] = useState(false);
  const [networkConfigs, setNetworkConfigs] = useState([
    { ServiceName: "", PortNumber: "", TransportProtocol: "", Description: "" },
  ]);

  const [isEdit, setIsEdit] = useState(false); // Determines if it's an edit
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [show, setShow] = useState(false);
  const [portNumber, setPortNumber] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [transportProtocol, setTransportProtocol] = useState("");
  const [description, setDescription] = useState("");
  const [isControlClicked, setIsControlClicked] = useState(false);
  const [isControlClickeOne, setIsControlClickedOne] = useState(false);
  const { user } = useSelector((state) => state.authSlice);
  const [currentId, setCurrentId] = useState(null);
  const [authorize, setAuthorize] = useState([]);
  const [buttonLabel, setButtonLabel] = useState(''); // Default label
  const [buttonClass, setButtonClass] = useState('btn-primary'); // Default color class
  const [status, setStatus] = useState(true); // true for "Active", false for "Inactive"

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [searchTerm, setSearchTerm] = useState(""); // Single input field for search
  const invalidCharacters = /[<>'"]/;
  const hasSpecialChars = /[^a-zA-Z0-9\s]/;
  const hasNumbers = /[0-9]/;
  const underscoreAllowed = /^[a-zA-Z0-9-_]+$/;

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setIsEdit(false); // Switch to add mode
    setPortNumber(""); // Clear the form fields
    setServiceName("");
    setTransportProtocol("");
    setDescription("");
    setCurrentId(null); // Clear the current ID
    setShow(true); // Show the modal
  };

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };
  const fetchDataWhitelist = async (bool) => {
    setLoading(true);
    if (bool) {
      setPageNumber(1);
    }
    try {
      let queryParams = new URLSearchParams({
        page: pageNumber,
        limit: pageSize,
      });

      // Determine if the searchTerm is a number (PortNumber) or a string (ServiceName)
      if (!isNaN(searchTerm) && searchTerm !== "") {
        queryParams.append("PortNumber", searchTerm);
      } else if (searchTerm !== "") {
        queryParams.append("ServiceName", searchTerm);
      }

      const response = await notifyApi.get(
        `/whitelist-ports/${user?.data?.user?.organizationId}/${user?.data?.user?.userId
        }?${queryParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      const devices = response.data.data;
      setColumnDataWhitelist(devices);
      setTotalDocuments(response?.data?.pagination?.totalItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
    }
  };
  useEffect(() => {
    // fetchData();
    fetchDataWhitelist(false);
  }, [user, pageNumber, pageSize]);

  useEffect(() => {
    // fetchData();
    fetchDataWhitelist(true);
  }, [user, searchTerm]);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this PORT device?"
    );

    if (confirmDelete) {
      try {
        await notifyApi.post(`/whitelist-port/${user?.data?.user?.userId}`, {
          id: id,
        });
        toast.success("Port deleted successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else {
      // User canceled the deletion
      // toast.info("Deletion canceled.", {
      //   position: "top-center",
      //   autoClose: 2000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: false,
      //   theme: "light",
      // });
    }
  };

  const handleEdit = (row) => {
    setIsEdit(true); // Switch to edit mode
    setPortNumber(row.PortNumber); // Prefill form fields
    setServiceName(row.ServiceName);
    setTransportProtocol(row.TransportProtocol);
    setDescription(row.Description);
    setCurrentId(row._id); // Set the current ID of the row being edited
    setShow(true); // Show the modal
  };



  const handleUpdateStatus = async (e) => {
    e.preventDefault();

    // Extracting the IDs and statuses from the authorize array
    const ids = authorize?.map((item) => item._id);
    const statusUpdate = authorize?.map((item) => item?.status);

    // Check if the status array contains both true and false
    const hasTrue = statusUpdate.includes(true);
    const hasFalse = statusUpdate.includes(false);

    if (hasTrue && hasFalse) {
      // If both true and false are present, show an alert
      alert(
        "You can only authorize or unauthorize, not both at the same time."
      );
      return;
    }

    // Determine the status to send in the request (either all true or all false)
    const statusToUpdate = hasTrue === true ? false : true;

    const data = {
      id: ids, // Adding the array of IDs
      status: statusToUpdate, // Sending the determined status
    };

    try {
      const response = await notifyApi.put(
        `/whitelist-port/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      // Handle success response
      toast.success(
        statusToUpdate === true
          ? " Port Authorized Successfully!"
          : "Port Unauthorized Successfully!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
        }
      );
      // Reset form fields
      // setShowModal(false);
      fetchDataWhitelist();
      // Close the modal
    } catch (error) {
      toast.error(error.message || "Error updating status");
      console.error("Error updating status:", error);
      // Handle error response
    }
  };
  const columnsWhite = [
    {
      name: "Service Name",
      selector: (row) => row.ServiceName,
      sortable: true,
    },
    {
      name: "Port Number",
      selector: (row) => row.PortNumber,
      sortable: true,
    },
    {
      name: "Protocol",
      selector: (row) => row.TransportProtocol || "NA",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <span
          className={`badge rounded-pill ${row.status === true
            ? "text-bg-success text-white"
            : "text-bg-danger text-white"
            }`}
          disabled
        >
          {row.status === true ? "Authorized" : "Unauthorized"}
        </span>
      ),
      sortable: true,
    },

    {
      name: "Description",
      selector: (row) => (
        <Tippy
          content={row.Description}>
          <span>{row.Description}</span>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row._id || "NA",
      cell: (row) => (
        <>
          <button
            type="button"
            className="btn btn-dark"
            onClick={() => handleEdit(row)}
            aria-label={`Edit ${row.ServiceName}`}
          >
            <i className="fa-solid fa-pen"></i>
          </button>
          &nbsp;
          {/* <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleDelete(row._id)}
          >
            <i className="fa-solid fa-trash"></i>
          </button> */}
        </>
      ),
      sortable: false,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#D7E3E8",
      },
    },
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const exportToCSV = (columnData) => {
    if (columnData && columnData.length > 0) {
      // Determine which columns have data
      const hasData = (key) => columnData.some((item) => item[key]);

      const headers = [
        { label: "Service Name", key: "ServiceName" },
        { label: "Port Number", key: "PortNumber" },
        { label: "Protocol", key: "TransportProtocol" },
        { label: "Status", key: "status" },
        { label: "Description", key: "Description" },
      ].filter((header) => hasData(header.key));

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail)
            const value = Object.values(detail)
            return `${key}:${value}`;
          })
          .join(" | ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      const formatMultipleIpAddresses = (ipAddresses) => {
        if (typeof ipAddresses === "string") {
          return ipAddresses
        }
        return ipAddresses
          .map((ipAddress) => ipAddress)
          .join(" ")
          .replace(/ /g, " | ");
      };

      const formatUsersPasswordAge = (usersPasswordAge) => {
        if (!usersPasswordAge || usersPasswordAge.length === 0) return "N/A"; // Handle empty or undefined case

        return usersPasswordAge
          .map((user) => `${user.userType}:${user.userName}:${user.passwordAge}`)
          .join(" | ");
      };

      const formatStatus = (status) => {
        return status === true ? "Authorized" : "Unauthorized";
      };

      const formatDescription = (description) => {
        return description.replace(/,/g, " ");
      }

      const csvData = [
        headers.map((header) => header.label),
        ...columnData.map((item) =>
          headers.map((header) =>
            header.key === "ipAddress"
              ? formatMultipleIpAddresses(item[header.key])
              :
              header.key === "updatedAt"
                ? formatTimestamp(item[header.key])
                : header.key === "usersPasswordAge"
                  ? formatUsersPasswordAge(item[header.key])
                  : header.key === "sharedDirectories"
                    ? formatSharedDirectories(item[header.key])
                    : header.key === "avDetails"
                      ? formatAvDetails(item[header.key])
                      : header.key === "service"
                        ? formatOsDetails(item[header.key])
                        : header.key === "status" ? formatStatus(item[header.key]) :
                          header.key === "Description" ? formatDescription(item[header.key]) :
                            item[header.key]
          )
        ),
      ];
      // Add header information
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `WhitelistPorts_Report.csv`);
    }
  };

  function exportToPDF(columnData) {
    if (columnData && columnData.length > 0) {
      // Set the orientation to landscape
      const doc = new jsPDF({
        orientation: "landscape", // Force landscape orientation
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      doc.text(`Whitelist Ports`, 14, 22);

      // Determine which columns have data
      const hasData = (key) => columnData.some((item) => item[key]);
      const headers = [
        { label: "Service Name", key: "ServiceName" },
        { label: "Port Number", key: "PortNumber" },
        { label: "Protocol", key: "TransportProtocol" },
        { label: "Status", key: "status" },
        { label: "Description", key: "Description" },
      ].filter((header) => hasData(header.key));

      const tableColumn = headers.map((header) => header.label);
      const tableRows = [];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail).find((k) => k.startsWith("clamav"));
            const avName = key ? key : "Unknown AV";
            const avStatus = detail[key] || "Status not available";

            return `AV Name: ${avName}, Status: ${avStatus}`;
          })
          .join("; ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      const formatStatus = (status) => {
        return status === true ? "Authorized" : "Unauthorized";
      };

      columnData.forEach((item) => {
        const rowData = headers.map((header) =>
          header.key === "updatedAt"
            ? formatTimestamp(item[header.key])
            : header.key === "sharedDirectories"
              ? formatSharedDirectories(item[header.key])
              : header.key === "usersPasswordAge"
                ? item[header.key]
                  .map(
                    (user) =>
                      `${user.userType} :${user.userName}: ${user.passwordAge}`
                  )
                  .join("; ")
                : header.key === "avDetails"
                  ? formatAvDetails(item[header.key])
                  : header.key === "service"
                    ? formatOsDetails(item[header.key])
                    : header.key === "status" ? formatStatus(item[header.key]) : Array.isArray(item[header.key])
                      ? item[header.key].join(", ")
                      : item[header.key]
        );
        tableRows.push(rowData);
      });

      // Add table with borders for rows and columns
      doc.autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: {
          cellPadding: 3, // Padding for cells
          lineColor: [44, 62, 80], // Border color (RGB)
          lineWidth: 0.1, // Border width
        },
        headStyles: {
          fillColor: [52, 73, 94], // Header background color
          textColor: 255, // Header text color
          halign: "center", // Center align header text
          lineWidth: 0.5, // Border width for header
        },
        bodyStyles: {
          lineColor: [44, 62, 80], // Row border color
          lineWidth: 0.1, // Border width for rows
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Background color for alternate rows
        },
      });

      // Save the PDF
      doc.save(`WhitelistPorts_Report.pdf`);
    }
  };

  const validate = () => {
    if (!serviceName || (serviceName && serviceName.trim() === "")) {
      handleClose();
      toast.error("Service Name is required", { position: "top-center" });
      return false;
    }
    if (!portNumber || isNaN(portNumber)) {
      handleClose();
      toast.error("Valid Port Number is required", { position: "top-center" });
      return false;
    }
    if (!transportProtocol || (transportProtocol && transportProtocol.trim() === "")) {
      handleClose();
      toast.error("Transport Protocol is required", { position: "top-center" });
      return false;
    }
    if (!description || (description && description.trim() === "")) {
      handleClose();
      toast.error("Description is required", { position: "top-center" });
      return false;
    }
    if (portNumber > 65535 || portNumber < 1) {
      handleClose();
      toast.error("Valid Port Number is required", { position: "top-center" });
      return false;
    }
    if (!underscoreAllowed.test(serviceName)) {
      handleClose();
      toast.error("Service Name contains invalid characters", { position: "top-center" });
      return false;
    }
    if (invalidCharacters.test(description)) {
      handleClose();
      toast.error("Description contains invalid characters", { position: "top-center" });
      return false;
    }

    return true;
  }

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    setAuthorize(selectedRows);
  };

  useEffect(() => {
    if (selectedRows.length > 0) {
      const allActive = selectedRows.every(row => row.status === true);
      const allInactive = selectedRows.every(row => row.status === false);

      if (allActive) {
        setButtonLabel('Unauthorize');
        setButtonClass('btn-danger'); // Red for unauth

      } else if (allInactive) {
        setButtonLabel('Authorize');
        setButtonClass('btn-success'); // Blue for auth

      } else {
        setButtonLabel('Mixed Status'); // Optional: Handle mixed status cases
        setButtonClass('btn-warning'); // Yellow for mixed status

      }
    } else {
      setButtonLabel('Permit Port'); // Default if no rows are selected
      setButtonClass('btn-primary'); // Default to blue if no rows are selected

    }
  }, [selectedRows]);

  const handleControlClick = () => {
    setIsControlClicked(true);
    setShowOffcanvas(true);
  };

  const handleSubmitUnAuthorized = async (e) => {
    e.preventDefault();

    // Check if any row has a status of true
    const isAlreadyAuthorized = selectedRows.some((row) => row.status === true);

    if (isAlreadyAuthorized) {
      toast.info("Oops! Already Authorized", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      return; // Exit the function to prevent further execution
    }

    // Proceed with the API call if no rows are already authorized
    const data = {
      organizationId: user?.data?.user?.organizationId,
      networkConfigs: selectedRows.map((row) => ({
        ServiceName: row.ServiceName,
        PortNumber: row.PortNumber,
        TransportProtocol: row.TransportProtocol,
        Description: row.Description,
      })),
    };

    try {
      const response = await notifyApi.post(
        `/add-whitelist-ports/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setShowOffcanvas(false);
      setSelectedRows([]);
      fetchDataWhitelist(); // Refresh the data for the whitelist!
      setNetworkConfigs([
        {
          ServiceName: "",
          PortNumber: "",
          TransportProtocol: "",
          Description: "",
        },
      ]);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      console.error("Error adding user:", error);
    }
  };

  const handleSubmitAuthorized = async (e) => {
    e.preventDefault();

    // Check if any row has a status of true
    const isAlreadyAuthorized = selectedRows.some(
      (row) => row.status === false
    );

    if (isAlreadyAuthorized) {
      toast.info("Oops! Already Unauthorized", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      return; // Exit the function to prevent further execution
    }

    const data = selectedRows.map((row) => ({
      id: row._id, // Include the ID if it exists for updates
      ServiceName: row.ServiceName,
      PortNumber: row.PortNumber,
      TransportProtocol: row.TransportProtocol,
      Description: row.Description,
      organizationId: user?.data?.user?.organizationId,
      status: false,
    }));

    try {
      const promises = data.map((item) =>
        notifyApi.put(`/whitelist-port/${user?.data?.user?.userId}`, item, {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        })
      );

      const responses = await Promise.all(promises);
      responses.forEach((response) =>
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
        })
      );

      setShowOffcanvasOne(false);
      setSelectedRows([]);
      fetchDataWhitelist(); // Refresh the data for the whitelist!
    } catch (error) {
      toast.error(error?.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      })
    }
  };

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }

    const formData = {
      organizationId: user?.data?.user?.organizationId,

      networkConfigs: [
        {
          ServiceName: serviceName, // Ensure that serviceName is a state variable
          PortNumber: portNumber, // Ensure that portNumber is a state variable
          TransportProtocol: transportProtocol, // Ensure that transportProtocol is a state variable
          Description: description, // Ensure that description is a state variable
          status: status
        },
      ],
    };

    try {
      const response = await notifyApi.post(
        `/add-whitelist-ports/${user?.data?.user?.userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      fetchDataWhitelist();
      setStatus(true);
      handleClose();
      toast.success("Added whitelist ports successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
    } catch (error) {
      toast.error("Couldn't add whitelist ports", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    const data = networkConfigs.map((config) => ({
      id: currentId, // ID of the item to be updated
      ServiceName: serviceName,
      PortNumber: parseInt(portNumber, 10),
      TransportProtocol: transportProtocol,
      Description: description,
      organizationId: user?.data?.user?.organizationId,
    }));

    try {
      const promises = data.map((item) =>
        notifyApi.put(`/whitelist-port/${user?.data?.user?.userId}`, item, {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        })
      );

      const responses = await Promise.all(promises);
      responses.forEach((response) =>
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
        })
      );
      fetchDataWhitelist();
      setShowOffcanvas(false);
      setSelectedRows([]);
      setIsEdit(false);
      setPortNumber();
      setServiceName();
      setTransportProtocol();
      setDescription();
      setCurrentId();
      setShow(false);
    } catch (error) {
      toast.error(error?.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      })
    }
  };
  return (
    <React.Fragment>
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-20 fw-bolder text-dark p-0 m-0">
              Whitelist Ports
            </p>
          </span>

          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={() => exportToPDF(columnDataWhitelist)}
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => exportToCSV(columnDataWhitelist)}
            >
              <i className="fa-solid fa-file-excel"></i> &nbsp; CSV
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <div className="col-xl">
            <p className="fs-18  fw-bolder p-0 m-0">Ports List</p>
            <p className="text-danger p-0 m-0 fw-semibold">
              Total Ports: {totalDocuments || 0}
            </p>
          </div>
          <div className="" style={{ width: "13rem" }}>
            <input
              className="form-control"
              type="text"
              placeholder="Search by Port  or Service"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          &nbsp;
          <button
            type="button"
            className="btn btn-dark text-white"
            onClick={fetchDataWhitelist}
            disabled={loading}
          >
            {loading ? "Loading..." : "Search"}
          </button>
          &nbsp; &nbsp;
          <button
            type="button"
            className={`btn ${buttonClass} text-white`}
            onClick={handleUpdateStatus}
            disabled={selectedRows.length === 0}
          >
            {buttonLabel}
          </button>
          &nbsp; &nbsp;
          <Button variant="info" onClick={handleShow}>
            <i className="fa-solid fa-plus"></i> Add Port
          </Button>
        </div>

        <div className="card rounded cardStyle">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataTable
              columns={columnsWhite}
              data={columnDataWhitelist}
              pagination
              customStyles={customStyles}
              selectableRowsHighlight
              highlightOnHover
              paginationServer
              paginationTotalRows={totalDocuments}
              paginationDefaultPage={pageNumber}
              paginationPerPage={pageSize}
              selectableRows
              onSelectedRowsChange={handleSelectedRowsChange}
              onChangePage={(page) => {
                handlePageChange(page);
              }}
              onChangeRowsPerPage={(newPageSize) => {
                handlePageSizeChange(newPageSize);
              }}
              noDataComponent={<p className="p-0 m-2 me-auto">No Data Found</p>}
            />
          )}
        </div>


        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isEdit ? "Edit Port" : "Add Port"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="portNumber" className="form-label">
                  Port Number <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="portNumber"
                  min={1}
                  max={65535}
                  value={portNumber}
                  onChange={(e) => setPortNumber(e.target.value)}
                  required
                />
                <div className="invalid-feedback">
                  Port number must be between 1 and 65535.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="serviceName" className="form-label">
                  Service Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="serviceName"
                  value={serviceName}
                  onChange={(e) => setServiceName(e.target.value)}
                  required
                  maxLength={50}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="transportProtocol" className="form-label">
                  Transport Protocol <span className="text-danger">*</span>
                </label>
                <select
                  id="transportProtocol"
                  className="form-control form-select"
                  value={transportProtocol}
                  onChange={(e) => setTransportProtocol(e.target.value)}
                >
                  <option value="" disabled>
                    --Select--
                  </option>
                  <option value="DCCP">DCCP</option>
                  <option value="SCTP">SCTP</option>
                  <option value="TCP">TCP</option>
                  <option value="UDP">UDP</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  maxLength={150}
                  required
                />
              </div>

            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={isEdit ? handleUpdate : handleSubmit}
            >
              {isEdit ? "Update" : "Submit"}
            </Button>
          </Modal.Footer>
        </Modal>

        <Offcanvas
          show={showOffcanvasOne}
          onHide={() => setShowOffcanvasOne(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Authorize Selected Ports</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div>
              <p>Selected Ports:</p>
              {selectedRows.map((row, index) => (
                <div key={index}>
                  <p>{row.ServiceName}</p>
                  <p>{row.PortNumber}</p>
                  <p>{row.TransportProtocol}</p>
                  <p>{row.Description}</p>
                  <hr />
                </div>
              ))}
              <button
                onClick={handleSubmitAuthorized}
                className="btn btn-primary"
              >
                Authorize Selected Ports
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas
          show={showOffcanvas}
          onHide={() => setShowOffcanvas(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Authorize Selected Ports</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div>
              <p>Selected Ports:</p>
              {selectedRows.map((row, index) => (
                <div key={index}>
                  <p>{row.ServiceName}</p>
                  <p>{row.PortNumber}</p>
                  <p>{row.TransportProtocol}</p>
                  <p>{row.Description}</p>
                  <hr />
                </div>
              ))}
              <button
                onClick={handleSubmitUnAuthorized}
                className="btn btn-primary"
              >
                Authorize Selected Ports
              </button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
}

export default WhitelistPorts;
