import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../layouts/Footer";
// import Header from "../layouts/Header";
import { dp3 } from "../data/DashboardData";
import ReactApexChart from "react-apexcharts";
import { PolarArea } from "react-chartjs-2";
import DashCard from "../components/common/DashBoard/DashCard";
import Select from "react-select";

import windowIcon from "../assets/img/DashIcon/newWindowIcon.png";
import androidIcon from "../assets/img/DashIcon/newAndroidIcon.png";
import linuxIcon from "../assets/img/DashIcon/newLinuxIcon.png";
import macIcon from "../assets/img/DashIcon/newMacIcon.png";
import chromeIcon from "../assets/img/DashIcon/newChromeIcon.png";
import iosIcon from "../assets/img/DashIcon/NewIOSIcon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../components/common/Loader/PageLoader";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../feature/slices/AuthSlice";
export default function DashBoard() {
  const navigate = useNavigate();

  const dataPolar = {
    datasets: [
      {
        data: [50, 25, 60, 80, 20],
        backgroundColor: [
          "#506fd9",
          "#85b6ff",
          "#d3dbf6",
          "#6e7985",
          "#dbdde1",
        ],
      },
    ],
  };

  const optionPolar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };


  // const optionOne = {
  //   chart: {
  //     parentHeightOffset: 0,
  //     type: "area",
  //     toolbar: { show: false },
  //     stacked: true,
  //   },
  //   colors: ["#4f6fd9", "#506fd9"],
  //   grid: {
  //     borderColor: "rgba(72,94,144, 0.08)",
  //     padding: { top: -20 },
  //     yaxis: {
  //       lines: { show: false },
  //     },
  //   },
  //   stroke: {
  //     curve: "straight",
  //     width: [2, 0],
  //   },
  //   xaxis: {
  //     type: "numeric",
  //     tickAmount: 13,
  //     axisBorder: { show: false },
  //     labels: {
  //       style: {
  //         colors: "#6e7985",
  //         fontSize: "11px",
  //       },
  //     },
  //   },
  //   yaxis: {
  //     min: 0,
  //     max: 100,
  //     show: false,
  //   },
  //   fill: {
  //     type: "gradient",
  //     gradient: {
  //       opacityFrom: 0.5,
  //       opacityTo: 0,
  //     },
  //   },
  //   dataLabels: { enabled: false },
  //   legend: { show: false },
  //   tooltip: { enabled: false },
  // };



  const optionDonut = {
    chart: {
      parentHeightOffset: 0,
    },
    colors: ["#506fd9", "#85b6ff", "#51596d", "#eff1f5"],
    dataLabels: { enabled: true },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },

    legend: { show: false },
  };

  const [loading, setLoading] = useState(true);
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [windowCount, setWindowCount] = useState(0);
  const [linuxCount, setLinuxCount] = useState(0);
  const [androidCount, setAndroidCount] = useState(0);
  const [macCount, setMacCount] = useState(0);
  const [chromeOsCount, setChromeOsCount] = useState(0);

  const [windowCountRisk, setwindowCountRisk] = useState(0);
  const [windowCountUnRisk, setwindowCountUnRisk] = useState(0);

  const [linuxCountRisk, setLinuxCountRisk] = useState(0);
  const [linuxCountUnRisk, setLinuxCountUnRisk] = useState(0);

  const [androidCountRisk, setAndroidCountRisk] = useState(0);
  const [androidCountUnRisk, setAndroidUnCountRisk] = useState(0);

  const [chromeCountRisk, setChromeCountRisk] = useState(0);
  const [chromeCountUnRisk, setChromeCountUnRisk] = useState(0);

  const [macCountRisk, setMacCountRisk] = useState(0);
  const [macCountUnRisk, setMacCountUnRisk] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const [department, setDepartment] = useState(null);
  const [section, setSection] = useState(null);
  const [subSection, setSubSection] = useState(null);

  const [departments, setDepartments] = useState([]);
  const [sections, setSections] = useState([]);
  const [subsections, setSubsections] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);
  const [deptSafeUnsafe,setDeptSafeUnsafe] = useState([])
  const [sectionSafeUnsafe,setSectionSafeUnsafe] = useState([])
  const [TotalDashboard, setTotalDashboard] = useState(0);
  // const [lineGraph, setLineGraph] = useState();
  const [barGraph, setBarGraph] = useState();

  console.log(selectedDepartment, "selectedDepartment");

  const [summoryCount, setSummoryCount] = useState({});
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state) => state.authSlice);

  var windows_unsafe_per =
    (windowCountRisk / (windowCountRisk + windowCountUnRisk)) * 100;
  var linux_unsafe_per =
    (linuxCountRisk / (linuxCountRisk + linuxCountUnRisk)) * 100;
  var android_unsafe_per =
    (androidCountRisk / (androidCountRisk + androidCountUnRisk)) * 100;
  var chrome_unsafe_per =
    (chromeCountRisk / (chromeCountRisk + chromeCountUnRisk)) * 100;
  var macc_unsafe_per = (macCountRisk / (macCountRisk + macCountUnRisk)) * 100;


const linux_count = Math.round(linux_unsafe_per)
  console.log(macc_unsafe_per, "linux_unsafe_per");
  useEffect(() => {
    if (!isAuthenticated) {
      // User not authenticated, redirect to login page
      navigate("/login");
    } else {
      // Check token expiration time

      const tokenExpirationTimer = setTimeout(() => {
        // Token has expired, log the user out
        dispatch(logout()); // Dispatch logout action to clear user data and token
        navigate("/signin");
      }, 24 * 60 * 60 * 1000); // Convert expiresIn to milliseconds

      return () => clearTimeout(tokenExpirationTimer);
    }
  }, [isAuthenticated, user, navigate, dispatch]);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  // useEffect(() => {
  //   let isMounted = true; // Variable to track mount status

  //   const fetchData = () => {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${user?.data?.accessToken}`,
  //           },
  //         }
  //       )

  //       .then((res) => {
  //         if (isMounted) {
  //           console.log(res?.data,'rrrrrrrrrrrrrrrrrrrrrrrrr')
  //           setChromeCountRisk(res?.data?.chromeOsSummaryInfo?.getUnsafeDeviceCount)
  //           setMacCountRisk(res?.data?.mac?.UnsafemacClients)
  //           setAndroidCountRisk(res?.data?.android?.getUnsafeDeviceCount)
  //           setLinuxCountRisk(res?.data?.linux?.UnsafelinuxClients)
  //           setwindowCountRisk(res?.data?.windows?.UnsafeWindowsClients)

  //           setChromeCountUnRisk(res?.data?.chromeOsSummaryInfo?.getSafeDeviceCount)
  //           setMacCountUnRisk(res?.data?.mac?.SafemacClients)
  //           setAndroidUnCountRisk(res?.data?.android?.getSafeDeviceCount)
  //           setLinuxCountUnRisk(res?.data?.linux?.SafelinuxClients)
  //           setwindowCountUnRisk(res?.data?.windows?.SafeWindowsClients)

  //           setTotalDashboard(res?.data?.totalDevicesCount)

  //           setWindowCount(res?.data?.windows?.getTotalPCCount);
  //           setLinuxCount(res?.data?.linux?.getTotalPCCount);
  //           setAndroidCount(res?.data?.android?.androidDevicesCount);
  //           setMacCount(res?.data?.mac?.macPcCount);
  //           setChromeOsCount(res?.data?.chromeOsSummaryInfo?.chromeDevicesCount)
  //         }
  //       })
  //       .catch((error) => {
  //         if (error.response) {
  //           const statusCode = error.response.status;
  //           switch (statusCode) {
  //             case 404:
  //               console.log("Resource not found (404)");
  //               break;
  //             case 500:
  //               console.log("Internal server error (500)");
  //               break;
  //             case 504:
  //               console.log("Gateway timeout (504)");
  //               break;
  //             default:
  //               console.log(`Error: ${statusCode}`);
  //           }
  //         } else if (error.request) {
  //           console.log("No response received");
  //         } else {
  //           console.log("Error", error.message);
  //         }
  //       })
  //       .finally(() => {
  //         if (isMounted) {
  //           setLoading(false);
  //           // Schedule the next fetchData call after 50 seconds
  //           setTimeout(fetchData, 50000);
  //         }
  //       });
  //   };

  //   fetchData(); // Initial fetchData call

  //   // Cleanup function to set isMounted to false when component unmounts
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [user]);

  // this code need to set id dpt ,section, sub section
  // useEffect(() => {
  //   let isMounted = true; // Track mount status

  //   const fetchData = () => {
  //     let url = `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`;

  //     // Add filters to the URL if they are selected
  //     if (department) {
  //       url += `?department=${'id'}`;
  //     }
  //     if (section) {
  //       url += `${department ? '&' : '?'}section=${'id'}`;
  //     }
  //     if (subSection) {
  //       url += `${department || section ? '&' : '?'}subSection=${'id'}`;
  //     }

  //     axios
  //       .get(url, {
  //         headers: {
  //           Authorization: `Bearer ${user?.data?.accessToken}`,
  //         },
  //       })
  //       .then((res) => {
  //         if (isMounted) {
  //           console.log(res?.data);
  //           setChromeCountRisk(res?.data?.chromeOsSummaryInfo?.getUnsafeDeviceCount);
  //           setMacCountRisk(res?.data?.mac?.UnsafemacClients);
  //           setAndroidCountRisk(res?.data?.android?.getUnsafeDeviceCount);
  //           setLinuxCountRisk(res?.data?.linux?.UnsafelinuxClients);
  //           setwindowCountRisk(res?.data?.windows?.UnsafeWindowsClients);

  //           setChromeCountUnRisk(res?.data?.chromeOsSummaryInfo?.getSafeDeviceCount);
  //           setMacCountUnRisk(res?.data?.mac?.SafemacClients);
  //           setAndroidUnCountRisk(res?.data?.android?.getSafeDeviceCount);
  //           setLinuxCountUnRisk(res?.data?.linux?.SafelinuxClients);
  //           setwindowCountUnRisk(res?.data?.windows?.SafeWindowsClients);

  //           setTotalDashboard(res?.data?.totalDevicesCount);

  //           setWindowCount(res?.data?.windows?.getTotalPCCount);
  //           setLinuxCount(res?.data?.linux?.getTotalPCCount);
  //           setAndroidCount(res?.data?.android?.androidDevicesCount);
  //           setMacCount(res?.data?.mac?.macPcCount);
  //           setChromeOsCount(res?.data?.chromeOsSummaryInfo?.chromeDevicesCount);
  //         }
  //       })
  //       .catch((error) => {
  //         handleError(error);
  //       })
  //       .finally(() => {
  //         if (isMounted) {
  //           setLoading(false);
  //           setTimeout(fetchData, 50000); // Schedule the next fetch after 50s
  //         }
  //       });
  //   };

  //   fetchData(); // Initial fetch

  //   return () => {
  //     isMounted = false; // Cleanup when unmounting
  //   };
  // }, [user, department, section, subSection]);

  // useEffect(() => {
  //   let isMounted = true; // Track mount status

  //   const fetchData = () => {
  //     // Base URL
  //     let url = `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`;

  //     // Add filters to the URL if they are selected
  //     if (department) {
  //       url += `?department=${department.value}`; // Use the department ID value
  //     }
  //     if (section) {
  //       url += `${department ? '&' : '?'}section=${section.value}`; // Use the section ID value
  //     }
  //     if (subSection) {
  //       url += `${department || section ? '&' : '?'}subSection=${subSection.value}`; // Use the sub-section ID value
  //     }

  //     axios
  //       .get(url, {
  //         headers: {
  //           Authorization: `Bearer ${user?.data?.accessToken}`,
  //         },
  //       })
  //       .then((res) => {
  //         if (isMounted) {
  //           console.log(res?.data);

  //           // Set the risk and safe count data
  //           setChromeCountRisk(res?.data?.chromeOsSummaryInfo?.getUnsafeDeviceCount);
  //           setMacCountRisk(res?.data?.mac?.UnsafemacClients);
  //           setAndroidCountRisk(res?.data?.android?.getUnsafeDeviceCount);
  //           setLinuxCountRisk(res?.data?.linux?.UnsafelinuxClients);
  //           setwindowCountRisk(res?.data?.windows?.UnsafeWindowsClients);

  //           setChromeCountUnRisk(res?.data?.chromeOsSummaryInfo?.getSafeDeviceCount);
  //           setMacCountUnRisk(res?.data?.mac?.SafemacClients);
  //           setAndroidUnCountRisk(res?.data?.android?.getSafeDeviceCount);
  //           setLinuxCountUnRisk(res?.data?.linux?.SafelinuxClients);
  //           setwindowCountUnRisk(res?.data?.windows?.SafeWindowsClients);

  //           // Set total devices and individual OS device counts
  //           setTotalDashboard(res?.data?.totalDevicesCount);
  //           setWindowCount(res?.data?.windows?.getTotalPCCount);
  //           setLinuxCount(res?.data?.linux?.getTotalPCCount);
  //           setAndroidCount(res?.data?.android?.androidDevicesCount);
  //           setMacCount(res?.data?.mac?.macPcCount);
  //           setChromeOsCount(res?.data?.chromeOsSummaryInfo?.chromeDevicesCount);
  //         }
  //       })
  //       .catch((error) => {
  //         handleError(error);
  //       })
  //       .finally(() => {
  //         if (isMounted) {
  //           setLoading(false);
  //           setTimeout(fetchData, 50000); // Schedule the next fetch after 50 seconds
  //         }
  //       });
  //   };

  //   fetchData(); // Initial fetch

  //   return () => {
  //     isMounted = false; // Cleanup when unmounting
  //   };
  // }, [user, department, section, subSection]); // Include department, section, and sub-section as dependencies

  // Department API Call
  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}`
        );
        setDepartments(response.data.departments);
      } catch (error) {
        console.error("Error fetching organization data: ", error);
      }
    };

    fetchOrganizationData();
    fetchData();
  }, [user?.data?.user?.organizationId]);

  const fetchData = () => {
    axios
      .get(
        `${process.env.REACT_APP_SUMMARY_URL}/graph-data/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((response) => {
        // Handle the response data here
        // setLineGraph(response.data.response.sevenDayData);
        setDeptSafeUnsafe(response.data.getSafeAndUnsafeCountsByDepartmentAndSection.departmentCounts);
        setSectionSafeUnsafe(response.data.getSafeAndUnsafeCountsByDepartmentAndSection.sectionCounts);
        console.log(response.data.getSafeAndUnsafeCountsByDepartmentAndSection.departmentCounts, "HHHHHHH");
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching data:", error);
      });
  };

  // Fetch Summary Data
  useEffect(() => {
    let isMounted = true; // Track mount status

    const fetchData = () => {
      let url = `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`;

      // Check if department, section, or sub-section is selected and append to URL
      if (selectedDepartment) {
        url += `?department=${selectedDepartment.value}`;
      }
      if (selectedSection) {
        url += `${selectedDepartment ? "&" : "?"}section=${
          selectedSection.value
        }`;
      }
      if (selectedSubsection) {
        url += `${
          selectedDepartment || selectedSection ? "&" : "?"
        }subSection=${selectedSubsection.value}`;
      }

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        })
        .then((res) => {
          if (isMounted) {
            // Update your state with the fetched data
            console.log(res?.data);
            setChromeCountRisk(
              res?.data?.chromeOsSummaryInfo?.getUnsafeDeviceCount
            );
            setMacCountRisk(res?.data?.mac?.UnsafemacClients);
            setAndroidCountRisk(res?.data?.android?.getUnsafeDeviceCount);
            setLinuxCountRisk(res?.data?.linux?.UnsafelinuxClients);
            setwindowCountRisk(res?.data?.windows?.UnsafeWindowsClients);

            setChromeCountUnRisk(
              res?.data?.chromeOsSummaryInfo?.getSafeDeviceCount
            );
            setMacCountUnRisk(res?.data?.mac?.SafemacClients);
            setAndroidUnCountRisk(res?.data?.android?.getSafeDeviceCount);
            setLinuxCountUnRisk(res?.data?.linux?.SafelinuxClients);
            setwindowCountUnRisk(res?.data?.windows?.SafeWindowsClients);

            setTotalDashboard(res?.data?.totalDevicesCount);

            setWindowCount(res?.data?.windows?.getTotalPCCount);
            setLinuxCount(res?.data?.linux?.getTotalPCCount);
            setAndroidCount(res?.data?.android?.androidDevicesCount);
            setMacCount(res?.data?.mac?.macPcCount);
            setChromeOsCount(
              res?.data?.chromeOsSummaryInfo?.chromeDevicesCount
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching summary data:", error);
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false);
            setTimeout(fetchData, 50000); // Schedule next fetch after 50 seconds
          }
        });
    };

    fetchData(); // Initial fetch

    return () => {
      isMounted = false; // Cleanup when unmounting
    };
  }, [user, selectedDepartment, selectedSection, selectedSubsection]); // Add selectedDepartment, selectedSection, and selectedSubsection to dependencies

  // Function to handle API errors
  const handleError = (error) => {
    if (error.response) {
      const statusCode = error.response.status;
      switch (statusCode) {
        case 404:
          console.log("Resource not found (404)");
          break;
        case 500:
          console.log("Internal server error (500)");
          break;
        case 504:
          console.log("Gateway timeout (504)");
          break;
        default:
          console.log(`Error: ${statusCode}`);
      }
    } else if (error.request) {
      console.log("No response received");
    } else {
      console.log("Error", error.message);
    }
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setSelectedSection(null);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedOption.value
    );
    if (department) {
      setSections(department.sections);
    }
  };

  const handleSectionChange = (selectedOption) => {
    setSelectedSection(selectedOption);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedDepartment.value
    );
    if (department) {
      const section = department.sections.find(
        (sec) => sec._id === selectedOption.value
      );
      if (section) {
        setSubsections(section.subSections);
      }
    }
  };
  // const handleSelectedRowsChange = ({ selectedRows }) => {
  //   setSelectedRows(selectedRows);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDepartment && !selectedSection && !selectedSubsection) {
      alert(
        "Please select at least one option (department, section, or subsection)."
      );
      return;
    }

    const deviceIds = selectedRows?.map((row) => "row.pcId");

    // Construct the data object based on which fields are selected
    const data = { deviceIds };

    if (selectedDepartment) {
      data.departmentId = selectedDepartment.value;
    }
    if (selectedSection) {
      data.sectionId = selectedSection.value;
    }
    if (selectedSubsection) {
      data.subSectionId = selectedSubsection.value;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/link/pc/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log("API response:", response.data.message);
      // Handle success response
      // toast.success(response.data.message, {
      //   position: "top-center",
      //   autoClose: 2000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: false,
      //   theme: "light",
      // });

      // Close Offcanvas and update DataTable data
      // setShowOffcanvas(false);
      // fetchData();
      // setSelectedRows([]);
      // setToggleClearRows(!toggleClearRows); // Toggle clear rows
    } catch (error) {
      toast.error(error);

      console.error("Error submitting form:", error);
      // Handle error response
    }
  };


  // const safeData = deptSafeUnsafe.map((item, index) => [index, item.safeCount]);
  // const unsafeData = deptSafeUnsafe.map((item, index) => [index, item.unsafeCount]);

  // const seriesEight = [
  //   {
  //     type: 'column',
  //     data: safeData, // Safe counts mapped to the required format
  //   },
  //   {
  //     type: 'column',
  //     data: unsafeData, // Unsafe counts mapped to the required format
  //   },
  // ];

  // const optionEight = {
  //   chart: {
  //     parentHeightOffset: 0,
  //     stacked: true,
  //     type: "bar", // Change this to "bar" to create a bar chart
  //     toolbar: { show: false },
  //   },
  //   grid: {
  //     borderColor: "rgba(72,94,144, 0.07)",
  //     padding: {
  //       top: -20,
  //       left: 5,
  //       bottom: -15,
  //     },
  //   },
  //   states: {
  //     hover: {
  //       filter: { type: "none" },
  //     },
  //     active: {
  //       filter: { type: "none" },
  //     },
  //   },
  //   colors: ["#506fd9", "#e5e9f2"], // Colors for Safe and Unsafe counts
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "40%",
  //       endingShape: "rounded", // Rounded end for the bars
  //     },
  //   },
  //   stroke: {
  //     curve: "straight",
  //     lineCap: "square",
  //     width: 0,
  //   },
  //   yaxis: {
  //     min: 0,
  //     max: 5, // Adjusting based on your data, you can set the max dynamically
  //     tickAmount: 5,
  //   },
  //   xaxis: {
  //     categories: ['Dept1', 'Dept2', 'No Dept', 'Dept4', 'Dept5'], // Department names
  //     labels: {
  //       style: {
  //         colors: "#6e7985",
  //         fontSize: "10px",
  //         fontWeight: "500",
  //       },
  //     },
  //   },
  //   tooltip: { enabled: true }, // Disable tooltips
  //   legend: { show: true }, // Hiding legend
  // };
  
  console.log(deptSafeUnsafe, 'deptSafeUnsafe');

  // Mapping safe and unsafe counts
  const safeData = deptSafeUnsafe?.map((item, index) => [index, item.safeCount]);
  const unsafeData = deptSafeUnsafe?.map((item, index) => [index, item.unsafeCount]);
  
  // Mapping department names dynamically
  const departmentNames = deptSafeUnsafe?.map(item => item.department); // Assuming each item has a `departmentName`
  
  const seriesEight = [
    {
      name: 'Safe',  // Label for Safe counts
      type: 'column',
      data: safeData, // Safe counts
    },
    {
      name: 'Unsafe',  // Label for Safe counts
      type: 'column',
      data: unsafeData, // Unsafe counts
    },
  ];
  
  const optionEight = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      type: "bar", // Creating bar chart
      toolbar: { show: false },
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: -20,
        left: 5,
        bottom: -15,
      },
    },
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    colors: ["#506fd9", "#e5e9f2"], // Colors for Safe and Unsafe counts
    plotOptions: {
      bar: {
        columnWidth: "40%",
        endingShape: "rounded", // Rounded ends for the bars
      },
    },
    stroke: {
      curve: "straight",
      lineCap: "square",
      width: 0,
    },
    yaxis: {
      min: 0,
      max: 5, // Adjust based on your data
      tickAmount: 5,
    },
    xaxis: {
      categories: departmentNames, // Dynamically generated department names
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "10px",
          fontWeight: "500",
        },
      },
    },
    tooltip: { enabled: true }, // Tooltips enabled
    legend: { show: true }, // Show legend
  };
  
  const lineGraph = [
    {
      date: "2024-09-07",
      getAntivirusNotInstalledCount: 3,
      AntivirusNotUpdatedCount: 2,
      getAntivirusMalfunctionCount: 2,
      getEDRNotInstalledCount: 2,
      firewallOffPCCount: 2,
      getNetworkViolationPCCount: 2,
      getPCsCountWithUnwantedPorts: 2,
      getPCsCountWithUnwhitelistedUSB: 2,
      tpmNotEnableedCount: 2,
      isSecureBootNotEnableedCount: 2,
      isOsNotHardenedCount: 2,
      adminPasswordAgeGreaterThanThresholdCount: 2,
      multipleAdminCount: 2,
      getNotPatchedOSCount: 2,
    },
    {
      date: "2024-09-08",
      getAntivirusNotInstalledCount: 1,
      AntivirusNotUpdatedCount: 1,
      getAntivirusMalfunctionCount: 1,
      getEDRNotInstalledCount: 1,
      firewallOffPCCount: 1,
      getNetworkViolationPCCount: 0,
      getPCsCountWithUnwantedPorts: 0,
      getPCsCountWithUnwhitelistedUSB: 0,
      tpmNotEnableedCount: 0,
      isSecureBootNotEnableedCount: 0,
      isOsNotHardenedCount: 0,
      adminPasswordAgeGreaterThanThresholdCount: 0,
      multipleAdminCount: 0,
      getNotPatchedOSCount: 0,
    },
    {
      date: "2024-09-09",
      getAntivirusNotInstalledCount: 8,
      AntivirusNotUpdatedCount: 4,
      getAntivirusMalfunctionCount: 4,
      getEDRNotInstalledCount: 1,
      firewallOffPCCount: 1,
      getNetworkViolationPCCount: 0,
      getPCsCountWithUnwantedPorts: 0,
      getPCsCountWithUnwhitelistedUSB: 0,
      tpmNotEnableedCount: 0,
      isSecureBootNotEnableedCount: 0,
      isOsNotHardenedCount: 0,
      adminPasswordAgeGreaterThanThresholdCount: 0,
      multipleAdminCount: 0,
      getNotPatchedOSCount: 0,
    },
  ];
  
  // Map data for each series
  const dp1 = lineGraph.map((item) => item.getAntivirusNotInstalledCount);
  const dp2 = lineGraph.map((item) => item.getEDRNotInstalledCount);
  const dp3 = lineGraph.map((item) => item.getNotPatchedOSCount);
  
  // Map dates for the x-axis
  const dates = lineGraph.map((item) => item.date);
  
  const seriesOne = [
    {
      name: "Antivirus Not Installed",
      data: dp1, // getAntivirusNotInstalledCount over time
    },
    {
      name: "EDR Not Installed",
      data: dp2, // getEDRNotInstalledCount over time
    },
    {
      name: "OS Not Patched",
      data: dp3, // getNotPatchedOSCount over time
    },
  ];
  
  const optionOne = {
    chart: {
      type: 'area',
      height: 430,
    },
    title: {
      text: 'Security Overview',
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#263238',
      },
    },
    xaxis: {
      categories: dates, // Use the dates from your lineGraph data
      title: {
        text: 'Date',
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Count',
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        format: 'dd MMM yyyy', // Format the date as per your needs
      },
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '14px',
    },
  };
  
  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      {loading ? (
        <div className={`overlay ${loading ? "active" : ""}`}>
          <PageLoader className="loader" />
        </div>
      ) : (
        <div className="main main-app p-3 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              {/* <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="#">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Organization Overview
                </li>
              </ol> */}
              <h4 className="main-title mb-0 fs-18 fw-bolder p-0 m-0 ">
                Welcome to Dashboard
              </h4>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0">
              {/* <Button
                variant=""
                className="btn-white d-flex align-items-center gap-2"
              >
                <i className="ri-share-line fs-18 lh-1"></i>Share
              </Button> */}

              <Button
                variant=""
                className="btn-white d-flex align-items-center gap-2"
              >
                <i className="ri-printer-line fs-18 lh-1"></i>Print
              </Button>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate
                <span className="d-none d-sm-inline"> Report</span>
              </Button>
            </div>
          </div>

          <div class="text-right">
            <div class="row">
              <div class="col-2">
                <div className="mb-3">
                  <label htmlFor="departments" className="form-label">
                    Departments
                  </label>
                  <Select
                    options={departments.map((dept) => ({
                      label: dept.departmentName,
                      value: dept._id,
                    }))}
                    onChange={handleDepartmentChange}
                    value={selectedDepartment} // bind selected value
                  />
                </div>
              </div>
              <div class="col-2">
                <div className="mb-3">
                  <label htmlFor="sections" className="form-label">
                    Sections
                  </label>
                  <Select
                    options={sections.map((section) => ({
                      label: section.sectionName,
                      value: section._id,
                    }))}
                    onChange={handleSectionChange}
                    isDisabled={!selectedDepartment}
                    value={selectedSection} // bind selected value
                  />
                </div>
              </div>
              <div class="col-2">
                <div className="mb-3">
                  <label htmlFor="subsections" className="form-label">
                    Subsections
                  </label>
                  <Select
                    options={subsections.map((subSection) => ({
                      label: subSection.subSectionName,
                      value: subSection._id,
                    }))}
                    onChange={(selectedOption) =>
                      setSelectedSubsection(selectedOption)
                    }
                    isDisabled={!selectedSection}
                    value={selectedSubsection} // bind selected value
                  />
                </div>
              </div>
              <div class="col-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ marginTop: "30px" }}
                  onClick={() => {
                    setSelectedDepartment(null);
                    setSelectedSection(null);
                    setSelectedSubsection(null);
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          <Row className="g-3">
            <Col xl="4">
              <DashCard
                dashIcon={windowIcon}
                dashNumber={windowCount}
                handleRoute={() => navigate("/window-dash")}
                dashTitle={"Windows Endpoints"}
                riskNumber={windowCountRisk}
                riskPerc={windows_unsafe_per ? windows_unsafe_per : "0"}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={linuxIcon}
                dashNumber={linuxCount}
                handleRoute={() => navigate("/linux-dash")}
                dashTitle={"Linux Endpoints"}
                riskNumber={linuxCountRisk}
                riskPerc={(linux_count) ? linux_count  : "0"}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={macIcon}
                dashNumber={macCount}
                dashTitle={"Mac Endpoints"}
                handleRoute={() => navigate("/macOs-dash")}
                riskNumber={macCountRisk}
                riskPerc={macc_unsafe_per ? macc_unsafe_per : "0"}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={chromeIcon}
                dashNumber={chromeOsCount}
                dashTitle={"ChromeOS Endpoints"}
                handleRoute={() => navigate("/chrome-dash")}
                riskNumber={chromeCountRisk}
                riskPerc={chrome_unsafe_per ? chrome_unsafe_per : "0"}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={androidIcon}
                dashNumber={androidCount}
                handleRoute={() => navigate("/android-dash")}
                dashTitle={"Android Endpoints"}
                riskNumber={androidCountRisk}
                riskPerc={android_unsafe_per ? android_unsafe_per : "0"}
              />
            </Col>
            {/* <Col xl="4">
              <DashCard
                dashIcon={iosIcon}
                dashNumber={summoryCount?.androidCount}
                handleRoute={() => navigate("/")}
                dashTitle={"iOS Endpoints"}
                riskNumber={236}
                riskPerc={77}
              />
            </Col> */}

            <Col xl="12">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Cyber Violation Analysis</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="overflow-hidden px-0 pb-3">
                  <ReactApexChart
                    series={seriesOne}
                    options={optionOne}
                    type="area"
                    height={430}
                    className="apex-chart-two"
                  />
                </Card.Body>
              </Card>
            </Col>

            <Col md="4" xl="4">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Violations Categories</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-3">
                  <div className="ht-250">
                    <PolarArea data={dataPolar} options={optionPolar} />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="8">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Department Cyber Score</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-4">
                  <Row className="g-4">
                    <Col md="12">
                      <ReactApexChart
                        series={seriesEight}
                        options={optionEight}
                        height={210}
                        className="apex-chart-three"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Total Devices</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="position-relative d-flex justify-content-center">
                  <ReactApexChart
                    series={[
                      windowCount,
                      linuxCount,
                      macCount,
                      androidCount,
                      chromeOsCount,
                    ]}
                    options={optionDonut}
                    height="auto"
                    type="donut"
                    className="apex-donut-two"
                  />

                  <div className="finance-donut-value">
                    <h1>{TotalDashboard}</h1>
                    {/* <p>86.24%</p> */}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Footer />
        </div>
      )}
    </React.Fragment>
  );
}
