// import React, { useEffect, useState } from "react";

// import { Button, Card, Col, Row } from "react-bootstrap";
// import Header from "../../layouts/Header";

// import chromeIcon from "../../assets/img/DashIcon/newChromeIcon.png";
// import { Link, useLocation } from "react-router-dom";
// import CustomTable from "../../components/common/Table/CustomTable";
// import { androidRowData } from "../../components/common/TableData/ServerityData";

// import axios from "axios";
// import PageLoader from "../../components/common/Loader/PageLoader";

// import useFetchAndroidSummary from "../../hooks/AndroidHooks/useFetchAndroidSummary";
// import ChromeClients from "../../components/common/clients/chromeClients/ChromeClients";
// import { useSelector } from "react-redux";

// function ChromeServerityData() {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const { state } = useLocation();

//   console.log(state, "LOCATION DATA");

//   const { androidSummary } = useFetchAndroidSummary();
//   const [skin, setSkin] = useState(currentSkin);
//   const [apiResponseData, setApiResponseData] = useState(null);
//   const [columnData, setColumnData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");

//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );

//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   const [formData, setFormData] = useState({
//     hostname: "",
//     serialNumber: "",
//     ipAddress: "",
//     clientScore: "",
//   });
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const { user, isAuthenticated } = useSelector((state) => state.authSlice);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   console.log(user, "USERMAC");
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_ANDROID_URL}/${state?.callUrl}/${user?.data?.user?.userId}&osType=CHROME_OS`,
//           {
//             params: formData, // Use formData as filter criteria
//             headers: {
//               Authorization: `Bearer ${user?.data?.accessToken}`,
//             },
//           }
//         );
//         setApiResponseData();
//         console.log(response?.data, "RESPONSEANROID");
//         if (response?.data?.length > 0) {
//           const calculateData = response.data?.map((v) => {
//             console.log(v, "IN THE ANDOIRD DATA");
//             return {
//               hostname: v.androidInfo?.hardware[0]?.brandName || "NA",
//               serialNumber: v.androidInfo?.hardware[0]?.model || "NA",
//               ipAddress: v?.latestDeviceIp || "NA",
//               clientScore: "",
//               action: "yourActionValue",
//               pc_Id: v?.androidId || "NA",
//             };
//           });
//           setColumnData(calculateData);
//         } else {
//           setColumnData([]);
//         }
//       } catch (error) {
//         setError(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [state?.callUrl]);

//   console.log(columnData, "COLUMNDATAA");
//   switchSkin(skin);
//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div className="main main-app p-3 p-lg-4">
//         <div className="d-md-flex align-items-center justify-content-between mb-4">
//           <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
//             <Link to="#">
//               <img src={chromeIcon} alt="Window-logo" width={55} height={55} />
//             </Link>
//             <p className="mx-2 fs-18 m-0 p-0">|</p>
//             <p className="fs-18 fw-bolder p-0 m-0 ">Chrome DashBoard</p>
//           </span>
//           <div className="d-flex gap-2 mt-3 mt-md-0">
//             <Button
//               variant=""
//               className="btn-white d-flex align-items-center gap-2"
//             >
//               <i className="ri-share-line fs-18 lh-1"></i>Share
//             </Button>
//             <Button
//               variant=""
//               className="btn-white d-flex align-items-center gap-2"
//             >
//               <i className="ri-printer-line fs-18 lh-1"></i>Print
//             </Button>
//             <Button
//               variant="primary"
//               className="d-flex align-items-center gap-2"
//             >
//               <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate
//               <span className="d-none d-sm-inline"> Report</span>
//             </Button>
//           </div>
//         </div>

//         <ChromeClients data={androidSummary} />
//         <div className=" card-one my-4 p-4">
//           <Row>
//             <Col xl={12} className="my-4">
//               <p className="fs-20 text-dark fw-bolder p-0 m-0">
//                 {state?.titleState || "Alert Title"}
//               </p>
//               <p className="text-danger p-0 m-0 fw-semibold">
//                 {state?.alertNumber || 0} Clients
//               </p>
//             </Col>
//             <Col xl={12}>
//               {!loading ? (
//                 <CustomTable
//                   rowData={androidRowData}
//                   columnData={columnData}
//                   routeUrl={"/chrome-client-Details"}
//                 />
//               ) : (
//                 <PageLoader />
//               )}
//             </Col>
//           </Row>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// }

// export default ChromeServerityData;

import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
// import Header from "../../layouts/Header";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import chromeIcon from "../../assets/img/DashIcon/newChromeIcon.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomTableAndroid from "../../components/common/Table/CustomTableAndroid";
import axios from "axios";
import PageLoader from "../../components/common/Loader/PageLoader";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import DataTable from "react-data-table-component";
import androidIcon from "../../assets/img/DashIcon/newAndroidIcon.png";
function ChromeServerityData() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const { state } = useLocation();

  const [linuxSummary, setLinuxSummary] = useState({
    totalClients: 0,
    totalSafeClients: 0,
    totalUnsafeClients: 0,
    totalUnresponsivelients: 0,
  });
  const [skin, setSkin] = useState(currentSkin);

  const [columnData, setColumnData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState([]);

  const { user } = useSelector((state) => state.authSlice);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    hostname: "",
    serialNumber: "",
    ipAddress: "",
    clientScore: "",
  });

  const handleInputChange = (e, labelKey) => {
    const { value, name } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [labelKey]: value,
    }));
  };

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  useEffect(() => {
    let isMounted = true;
    let timeoutId;

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_ANDROID_URL}/${state?.callUrl}/${user?.data?.user?.userId}?page=${pageNumber}&pageSize=${pageSize}&osType=CHROME_OS`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );

        console.log(response?.data, "chromeOS ............");
        setTotalDocuments(response?.data?.pagination?.totalDocuments);
        if (isMounted) {
          console.log(response, "IN THE API RESPONSE");

          if (response?.data?.data.length > 0) {
            console.log(response?.data?.data, "LOGRESPONSE");
            const calculateData = response?.data?.data?.map((v) => {
              return {
                id: v?._id || "NA",
                androidId: v?.androidId || "NA",
                androidVersion: v?.androidVersion || "NA",
                brandName: v?.brandName || "NA",
                chromeOSVersion: v?.chromeOSVersion || "NA",
                deviceIp: v?.deviceIp || "NA",
              };
            });
            setColumnData(calculateData);
          } else {
            setColumnData([]);
          }
        }
      } catch (error) {
        if (isMounted) {
          if (error.response) {
            if (error.response.status === 401) {
              console.log("Unauthorized error occurred");
            } else if (error.response.status === 504) {
              console.log("Gateway Timeout error occurred");
            }
          } else {
            console.error("An error occurred:", error.message);
          }
          setError(error);
          console.log(error, "IN THE ERROR");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
          timeoutId = setTimeout(fetchData, 50000); // Adjusted interval to 50 seconds
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  }, [user, state, pageNumber, pageSize]);

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      const csvData = [
        [
          "Android ID",
          "Android Version",
          "Brand Name",
          "Client Score",
          "Device IP",
        ],
        ...columnData.map((item) => [
          item.androidId,
          item.androidVersion,
          item.brandName,
          item.clientScore,
          item.deviceIp,
        ]),
      ];

      const csvString = csvData.map((row) => row.join(",")).join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `${state?.titleState}_Report.csv`);
    }
  };

  // const columns = [
  //   { name: "Android ID", selector: (row) => row.androidId, sortable: true },
  //   { name: "Android Version", selector: (row) => row.androidVersion, sortable: true },
  //   { name: "Brand Name", selector: (row) => row.brandName, sortable: true },
  //   // { name: "ChromeOS Version", selector: (row) => row.chromeOSVersion, sortable: true },
  //   { name: "Device IP", selector: (row) => row.deviceIp, sortable: true },
  //   {
  //     name: "Action",
  //     cell: (row) => (
  //       <Button
  //         variant="primary"
  //         onClick={() => navigate(`/android-client-Details/${row.id}`)}
  //       >
  //         View Details
  //       </Button>
  //     ),
  //   },
  // ];
  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link to="/linux-dash">
              <img src={chromeIcon} alt="Window-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-18 fw-bolder p-0 m-0 ">Chrome DashBoard</p>
          </span>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={exportToCSV}
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate
              <span className="d-none d-sm-inline"> Report</span>
            </Button>
          </div>
        </div>

        <div className="card-one my-4 p-4">
          <Row>
            <Col xl={4}>
              <p className="fs-20 text-dark fw-bolder p-0 m-0">
                {state?.titleState || "Alert Title"}
              </p>
              <p className="text-danger p-0 m-0 fw-semibold">
                {totalDocuments || 0} Clients
              </p>
            </Col>

            <Col xl={12} className="mt-2">
              {!loading ? (
                <CustomTableAndroid
                  rowData={columnData}
                  columnData={columnData}
                  routeUrl={"/chrome-client-Details"}
                  currentPage={pageNumber}
                  pageSize={pageSize}
                  totalDocuments={totalDocuments}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />
              ) : (
                <PageLoader />
              )}
            </Col>

            {/* <Col xl={12} className="mt-2">
              {!loading ? (
                <DataTable
                  columns={columns}
                  data={columnData}
                  pagination
                  paginationServer
                  paginationTotalRows={totalDocuments}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePageSizeChange}
                  highlightOnHover
                />
              ) : (
                <PageLoader />
              )}
            </Col> */}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ChromeServerityData;
