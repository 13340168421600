import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
// import Header from "../../layouts/Header";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import { Link, useLocation } from "react-router-dom";
import CustomTableLinuxUsb from "../../components/common/Table/CustomTableLinuxUsb";
import axios from "axios";
import PageLoader from "../../components/common/Loader/PageLoader";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import Excellogo from "../../assets/img/Excellogo.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable"; // For adding tables easily
function LinuxServerityUsb() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const { state } = useLocation();

  console.log(state, "ststststinusb");
  const [linuxSummary, setLinuxSummary] = useState({
    totalClients: 0,
    totalSafeClients: 0,
    totalUnsafeClients: 0,
    totalUnresponsivelients: 0,
  });
  const [skin, setSkin] = useState(currentSkin);

  const [columnData, setColumnData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState([]);

  const { user } = useSelector((state) => state.authSlice);

  const [formData, setFormData] = useState({
    hostname: "",
    serialNumber: "",
    ipAddress: "",
    clientScore: "",
  });

  const handleInputChange = (e, labelKey) => {
    const { value, name } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [labelKey]: value,
    }));
  };

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  // useEffect(() => {
  //   let isMounted = true;
  //   let timeoutId;

  //   const fetchData = async () => {
  //     try {
  //       setLoading(true);
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_LINUX_URL}/${state?.callUrl}/${user?.data?.user?.userId}?page=${pageNumber}&pageSize=${pageSize}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${user?.data?.accessToken}`,
  //           },
  //         }
  //       );

  //       setTotalDocuments(response?.data?.pagination?.totalDocuments);
  //       if (isMounted) {
  //         console.log(response, "IN THE API RESPONSE");

  //         if (response?.data?.data.length > 0) {
  //           console.log(response?.data?.data, "LOGRESPONSE");
  //           const calculateData = response?.data?.data?.map((v) => {
  //             return {
  //               hostname: v?.hostname || "NA",
  //               serialNumber: v?.serialNumber || "NA",
  //               ipAddress: v?.ipAddress,
  //               openTcpPorts: v?.openTcpPorts || "NA",
  //               usbName: v?.usbName?.split(" ") || [],
  //               usbSerialNumber: v?.usbSerialNumber,
  //               macAddress: v?.macAddress,
  //               usbManufacturer: v?.usbManufacturer,
  //               usbProductId: v?.usbProductId,
  //               usbVendorId: v?.usbVendorId,
  //               localAddress:v?.localAddress,
  //               localPort:v?.localPort,
  //               foreignAddress:v?.foreignAddress,
  //               foreignPort:v?.foreignPort,
  //               time:v?.time,
  //               falsePositive:v?.falsePositive,
  //               usbHistoryId:v?.usbHistoryId,
  //               action: "yourActionValue",
  //               pc_Id: v?.pcId || "NA",
  //             };
  //           });
  //           setColumnData(calculateData);
  //         } else {
  //           setColumnData([]);
  //         }
  //       }
  //     } catch (error) {
  //       if (isMounted) {
  //         if (error.response) {
  //           if (error.response.status === 401) {
  //             console.log("Unauthorized error occurred");
  //           } else if (error.response.status === 504) {
  //             console.log("Gateway Timeout error occurred");
  //           }
  //         } else {
  //           console.error("An error occurred:", error.message);
  //         }
  //         setError(error);
  //         console.log(error, "IN THE ERROR");
  //       }
  //     } finally {
  //       if (isMounted) {
  //         setLoading(false);
  //         timeoutId = setTimeout(fetchData, 50000); // Adjusted interval to 50 seconds
  //       }
  //     }
  //   };

  //   fetchData();

  //   return () => {
  //     isMounted = false;
  //     clearTimeout(timeoutId);
  //   };
  // }, [user, state, pageNumber, pageSize]);

  const fetchData = async (callUrl, userId, pageNum, pageSz) => {
    let isMounted = true;
    let timeoutId;

    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_LINUX_URL}/${callUrl}/${userId}?page=${pageNum}&pageSize=${pageSz}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      setTotalDocuments(response?.data?.pagination?.totalDocuments);
      if (isMounted) {
        console.log(response, "IN THE API RESPONSE");

        if (response?.data?.data.length > 0) {
          console.log(response?.data?.data, "LOGRESPONSE");
          const calculateData = response?.data?.data?.map((v) => {
            return {
              hostname: v?.hostname || "NA",
              serialNumber: v?.serialNumber || "NA",
              ipAddress: v?.ipAddress,
              openTcpPorts: v?.openTcpPorts || "NA",
              usbName: v?.usbName?.split(" ") || [],
              usbSerialNumber: v?.usbSerialNumber,
              macAddress: v?.macAddress,
              usbManufacturer: v?.usbManufacturer,
              usbProductId: v?.usbProductId,
              usbVendorId: v?.usbVendorId,
              localAddress: v?.localAddress,
              localPort: v?.localPort,
              foreignAddress: v?.foreignAddress,
              foreignPort: v?.foreignPort,
              time: v?.time,
              falsePositive: v?.falsePositive,
              usbHistoryId: v?.usbHistoryId,
              action: "yourActionValue",
              pc_Id: v?.pcId || "NA",
              adminActionCount: v?.adminActionCount,
              IsadminActionDone:v?.IsadminActionDone,
              foreignAddressCount:v?.foreignAddressCount,
              pcId:v?.pcId
            };
          });
          setColumnData(calculateData);
        } else {
          setColumnData([]);
        }
      }
    } catch (error) {
      if (isMounted) {
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Unauthorized error occurred");
          } else if (error.response.status === 504) {
            console.log("Gateway Timeout error occurred");
          }
        } else {
          console.error("An error occurred:", error.message);
        }
        setError(error);
        console.log(error, "IN THE ERROR");
      }
    } finally {
      if (isMounted) {
        setLoading(false);
        // timeoutId = setTimeout(() => fetchData(callUrl, userId, pageNum, pageSz), 50000); // Adjusted interval to 50 seconds
      }
    }

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  };

  useEffect(() => {
    fetchData(state?.callUrl, user?.data?.user?.userId, pageNumber, pageSize);
  }, [user, state, pageNumber, pageSize]);

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  // const exportToCSV = () => {
  //   if (columnData && columnData.length > 0) {
  //     // Determine which columns should be included based on non-empty values
  //     const headers = [];
  //     const rows = [];

  //     if (columnData.some((item) => item.hostname)) {
  //       headers.push("Hostname");
  //       rows.push((item) => item.hostname);
  //     }
  //     if (columnData.some((item) => item.serialNumber)) {
  //       headers.push("Serial Number");
  //       rows.push((item) => item.serialNumber);
  //     }
  //     if (columnData.some((item) => item.usbName)) {
  //       headers.push("USB Name");
  //       rows.push((item) => item.usbName);
  //     }
  //     if (columnData.some((item) => item.usbSerialNumber)) {
  //       headers.push("USB Serial No");
  //       rows.push((item) => item.usbSerialNumber);
  //     }
  //     if (columnData.some((item) => item.macAddress)) {
  //       headers.push("MAC Address");
  //       rows.push((item) => item.macAddress);
  //     }
  //     if (columnData.some((item) => item.usbManufacturer)) {
  //       headers.push("USB Manufacturer");
  //       rows.push((item) => item.usbManufacturer);
  //     }
  //     if (columnData.some((item) => item.usbProductId)) {
  //       headers.push("USB ProductId");
  //       rows.push((item) => item.usbProductId);
  //     }
  //     if (columnData.some((item) => item.usbVendorId)) {
  //       headers.push("USB VendorId");
  //       rows.push((item) => item.usbVendorId);
  //     }
  //     if (columnData.some((item) => item.ipAddress)) {
  //       headers.push("IP Address");
  //       rows.push((item) => item.ipAddress);
  //     }
  //     if (columnData.some((item) => Array.isArray(item.openTcpPorts) && item.openTcpPorts.length > 0)) {
  //       headers.push("Open Ports");
  //       rows.push((item) => item.openTcpPorts.join(", "));
  //     }
  //     if (columnData.some((item) => item.localAddress)) {
  //       headers.push("Local Address");
  //       rows.push((item) => item.localAddress);
  //     }
  //     if (columnData.some((item) => item.localPort)) {
  //       headers.push("Local Port");
  //       rows.push((item) => item.localPort);
  //     }
  //     if (columnData.some((item) => item.foreignAddress)) {
  //       headers.push("Foreign Address");
  //       rows.push((item) => item.foreignAddress);
  //     }
  //     if (columnData.some((item) => item.foreignPort)) {
  //       headers.push("Foreign Port");
  //       rows.push((item) => item.foreignPort);
  //     }
  //     if (columnData.some((item) => item.time)) {
  //       headers.push("Timestamp");
  //       rows.push((item) => item.time);
  //     }

  //     // Generate CSV data based on selected headers and rows
  //     const csvData = [
  //       headers,
  //       ...columnData.map((item) => rows.map((row) => row(item))),
  //     ];

  //     const csvString = csvData.map((row) => row.join(",")).join("\n");

  //     const blob = new Blob([csvString], {
  //       type: "text/csv;charset=utf-8",
  //     });

  //     saveAs(blob, `${state?.titleState}_Report.csv`);
  //   }
  // };
  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = new Date().toLocaleString();

      // Add organization name and export date/time at the top of the CSV file
      const csvHeader = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
        "", // Empty line before column headers
      ];

      // Determine which columns should be included based on non-empty values
      const headers = [];
      const rows = [];

      if (columnData.some((item) => item.hostname)) {
        headers.push("Hostname");
        rows.push((item) => item.hostname);
      }
      if (columnData.some((item) => item.serialNumber)) {
        headers.push("Serial Number");
        rows.push((item) => item.serialNumber);
      }
      if (columnData.some((item) => item.usbName)) {
        headers.push("USB Name");
        rows.push((item) => item.usbName);
      }
      if (columnData.some((item) => item.usbSerialNumber)) {
        headers.push("USB Serial No");
        rows.push((item) => item.usbSerialNumber);
      }
      if (columnData.some((item) => item.macAddress)) {
        headers.push("MAC Address");
        rows.push((item) => item.macAddress);
      }
      if (columnData.some((item) => item.usbManufacturer)) {
        headers.push("USB Manufacturer");
        rows.push((item) => item.usbManufacturer);
      }
      if (columnData.some((item) => item.usbProductId)) {
        headers.push("USB ProductId");
        rows.push((item) => item.usbProductId);
      }
      if (columnData.some((item) => item.usbVendorId)) {
        headers.push("USB VendorId");
        rows.push((item) => item.usbVendorId);
      }
      if (columnData.some((item) => item.ipAddress)) {
        headers.push("IP Address");
        rows.push((item) => item.ipAddress);
      }
      if (
        columnData.some(
          (item) =>
            Array.isArray(item.openTcpPorts) && item.openTcpPorts.length > 0
        )
      ) {
        headers.push("Open Ports");
        rows.push((item) => item.openTcpPorts.join(", "));
      }
      if (columnData.some((item) => item.localAddress)) {
        headers.push("Local Address");
        rows.push((item) => item.localAddress);
      }
      if (columnData.some((item) => item.localPort)) {
        headers.push("Local Port");
        rows.push((item) => item.localPort);
      }
      if (columnData.some((item) => item.foreignAddress)) {
        headers.push("Foreign Address");
        rows.push((item) => item.foreignAddress);
      }
      if (columnData.some((item) => item.foreignPort)) {
        headers.push("Foreign Port");
        rows.push((item) => item.foreignPort);
      }
      if (columnData.some((item) => item.time)) {
        headers.push("Timestamp");
        rows.push((item) => item.time);
      }
      if (columnData.some((item) => item.falsePositive)) {
        headers.push("falsePositive");
        rows.push((item) => item.falsePositive);
      }
      if (columnData.some((item) => item.foreignAddressCount)) {
        headers.push("foreign Address Count");
        rows.push((item) => item.foreignAddressCount);
      }

      // Generate CSV data based on selected headers and rows
      const csvData = [
        csvHeader.join(","), // Add org name and export date/time
        headers.join(","), // Add column headers
        ...columnData.map((item) => rows.map((row) => row(item)).join(",")),
      ].join("\n");

      const blob = new Blob([csvData], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `${state?.titleState}_Report.csv`);
    }
  };

  // const exportToPDF = () => {

  //   // const { jsPDF } = window.jspdf; // Ensure you have jsPDF installed and imported
  //   const doc = new jsPDF();

  //   // Organization name and export date/time
  //   const orgName = user?.data?.user?.organization || "Organization Name";
  //   const exportDateTime = new Date().toLocaleString();

  //   // Add organization name and export date/time to PDF header
  //   doc.setFontSize(12);
  //   doc.text(`Organization: ${orgName}`, 10, 10);
  //   doc.text(`Exported: ${exportDateTime}`, 10, 20);

  //   // Initialize table headers and rows
  //   const headers = [];
  //   const rows = [];

  //   // Determine which columns should be included based on non-empty values
  //   if (columnData.some((item) => item.hostname)) {
  //     headers.push("Hostname");
  //     rows.push((item) => item.hostname || "NA");
  //   }
  //   if (columnData.some((item) => item.serialNumber)) {
  //     headers.push("Serial Number");
  //     rows.push((item) => item.serialNumber || "NA");
  //   }
  //   if (columnData.some((item) => item.usbName)) {
  //     headers.push("USB Name");
  //     rows.push((item) => item.usbName?.join(", ") || "NA");
  //   }
  //   if (columnData.some((item) => item.usbSerialNumber)) {
  //     headers.push("USB Serial No");
  //     rows.push((item) => item.usbSerialNumber || "NA");
  //   }
  //   if (columnData.some((item) => item.macAddress)) {
  //     headers.push("MAC Address");
  //     rows.push((item) => item.macAddress || "NA");
  //   }
  //   if (columnData.some((item) => item.usbManufacturer)) {
  //     headers.push("USB Manufacturer");
  //     rows.push((item) => item.usbManufacturer || "NA");
  //   }
  //   if (columnData.some((item) => item.usbProductId)) {
  //     headers.push("USB ProductId");
  //     rows.push((item) => item.usbProductId || "NA");
  //   }
  //   if (columnData.some((item) => item.usbVendorId)) {
  //     headers.push("USB VendorId");
  //     rows.push((item) => item.usbVendorId || "NA");
  //   }
  //   if (columnData.some((item) => item.ipAddress)) {
  //     headers.push("IP Address");
  //     rows.push((item) => item.ipAddress || "NA");
  //   }
  //   if (columnData.some((item) => Array.isArray(item.openTcpPorts) && item.openTcpPorts.length > 0)) {
  //     headers.push("Open Ports");
  //     rows.push((item) => item.openTcpPorts?.join(", ") || "NA");
  //   }
  //   if (columnData.some((item) => item.localAddress)) {
  //     headers.push("Local Address");
  //     rows.push((item) => item.localAddress || "NA");
  //   }
  //   if (columnData.some((item) => item.localPort)) {
  //     headers.push("Local Port");
  //     rows.push((item) => item.localPort || "NA");
  //   }
  //   if (columnData.some((item) => item.foreignAddress)) {
  //     headers.push("Foreign Address");
  //     rows.push((item) => item.foreignAddress || "NA");
  //   }
  //   if (columnData.some((item) => item.foreignPort)) {
  //     headers.push("Foreign Port");
  //     rows.push((item) => item.foreignPort || "NA");
  //   }
  //   if (columnData.some((item) => item.time)) {
  //     headers.push("Timestamp");
  //     rows.push((item) => item.time || "NA");
  //   }

  //   // Add the headers to the PDF
  //   doc.setFontSize(14);
  //   doc.text(headers.join(" | "), 10, 30);

  //   // Add the rows to the PDF
  //   let yPosition = 40;
  //   columnData.forEach((item) => {
  //     const row = rows.map((rowFunc) => rowFunc(item)).join(" | ");
  //     doc.text(row, 10, yPosition);
  //     yPosition += 10; // Adjust line spacing as needed
  //   });

  //   // Save the PDF
  //   doc.save(`${state?.titleState}_Report.pdf`);
  // };

  // const exportToPDF = () => {
  //   const doc = new jsPDF();

  //   // Organization name and export date/time
  //   const orgName = user?.data?.user?.organization || "Organization Name";
  //   const exportDateTime = new Date().toLocaleString();

  //   // Add organization name and export date/time to PDF header
  //   doc.setFontSize(12);
  //   doc.text(`Organization: ${orgName}`, 10, 10);
  //   doc.text(`Exported: ${exportDateTime}`, 150, 10); // Align the export date to the right

  //   // Initialize table headers and rows
  //   const headers = [];
  //   const rows = [];

  //   // Set column widths
  //   const colWidths = {
  //     hostname: 40,
  //     serialNumber: 30,
  //     usbName: 40,
  //     usbSerialNumber: 30,
  //     macAddress: 30,
  //     usbManufacturer: 40,
  //     usbProductId: 20,
  //     usbVendorId: 20,
  //     ipAddress: 30,
  //     openTcpPorts: 50,
  //     localAddress: 50,
  //     localPort: 30,
  //     foreignAddress: 50,
  //     foreignPort: 30,
  //     time: 30,
  //   };

  //   // Determine which columns should be included based on non-empty values
  //   if (columnData.some((item) => item.hostname)) {
  //     headers.push("Hostname");
  //     rows.push((item) => doc.splitTextToSize(item.hostname || "NA", colWidths.hostname));
  //   }
  //   if (columnData.some((item) => item.serialNumber)) {
  //     headers.push("Serial Number");
  //     rows.push((item) => doc.splitTextToSize(item.serialNumber || "NA", colWidths.serialNumber));
  //   }
  //   if (columnData.some((item) => item.usbName)) {
  //     headers.push("USB Name");
  //     rows.push((item) => doc.splitTextToSize(item.usbName?.join(", ") || "NA", colWidths.usbName));
  //   }
  //   if (columnData.some((item) => item.usbSerialNumber)) {
  //     headers.push("USB Serial No");
  //     rows.push((item) => doc.splitTextToSize(item.usbSerialNumber || "NA", colWidths.usbSerialNumber));
  //   }
  //   if (columnData.some((item) => item.macAddress)) {
  //     headers.push("MAC Address");
  //     rows.push((item) => doc.splitTextToSize(item.macAddress || "NA", colWidths.macAddress));
  //   }
  //   if (columnData.some((item) => item.usbManufacturer)) {
  //     headers.push("USB Manufacturer");
  //     rows.push((item) => doc.splitTextToSize(item.usbManufacturer || "NA", colWidths.usbManufacturer));
  //   }
  //   if (columnData.some((item) => item.usbProductId)) {
  //     headers.push("USB ProductId");
  //     rows.push((item) => doc.splitTextToSize(item.usbProductId || "NA", colWidths.usbProductId));
  //   }
  //   if (columnData.some((item) => item.usbVendorId)) {
  //     headers.push("USB VendorId");
  //     rows.push((item) => doc.splitTextToSize(item.usbVendorId || "NA", colWidths.usbVendorId));
  //   }
  //   if (columnData.some((item) => item.ipAddress)) {
  //     headers.push("IP Address");
  //     rows.push((item) => doc.splitTextToSize(item.ipAddress || "NA", colWidths.ipAddress));
  //   }
  //   if (columnData.some((item) => Array.isArray(item.openTcpPorts) && item.openTcpPorts.length > 0)) {
  //     headers.push("Open Ports");
  //     rows.push((item) => doc.splitTextToSize(item.openTcpPorts?.join(", ") || "NA", colWidths.openTcpPorts));
  //   }
  //   if (columnData.some((item) => item.localAddress)) {
  //     headers.push("Local Address");
  //     rows.push((item) => doc.splitTextToSize(item.localAddress || "NA", colWidths.localAddress));
  //   }
  //   if (columnData.some((item) => item.localPort)) {
  //     headers.push("Local Port");
  //     rows.push((item) => doc.splitTextToSize(item.localPort || "NA", colWidths.localPort));
  //   }
  //   if (columnData.some((item) => item.foreignAddress)) {
  //     headers.push("Foreign Address");
  //     rows.push((item) => doc.splitTextToSize(item.foreignAddress || "NA", colWidths.foreignAddress));
  //   }
  //   if (columnData.some((item) => item.foreignPort)) {
  //     headers.push("Foreign Port");
  //     rows.push((item) => doc.splitTextToSize(item.foreignPort || "NA", colWidths.foreignPort));
  //   }
  //   if (columnData.some((item) => item.time)) {
  //     headers.push("Timestamp");
  //     rows.push((item) => doc.splitTextToSize(item.time || "NA", colWidths.time));
  //   }

  //   // Add the headers to the PDF
  //   doc.setFontSize(14);
  //   doc.text(headers.join(" | "), 10, 30);

  //   // Add the rows to the PDF
  //   let yPosition = 40;
  //   columnData.forEach((item) => {
  //     const row = rows.map((rowFunc) => rowFunc(item)).join(" | ");
  //     doc.text(row, 10, yPosition);
  //     yPosition += 10; // Adjust line spacing as needed
  //   });

  //   // Save the PDF
  //   doc.save(`${state?.titleState}_Report.pdf`);
  // };
  // const exportToPDF = () => {
  //   // const doc = new jsPDF();
  //   const doc = new jsPDF({ orientation: "landscape" });
  //   // Organization name and export date/time
  //   const orgName = user?.data?.user?.organization || "Organization Name";
  //   const exportDateTime = new Date().toLocaleString();

  //   // Add organization name and export date/time to PDF header
  //   // doc.setFontSize(12);
  //   // doc.text(`Organization: ${orgName}`, 10, 10);
  //   // doc.text(`Exported: ${exportDateTime}`, 150, 10); // Align the export date to the right

  //   // Add header with org name on the left and export date/time on the right
  //   doc.setFontSize(12);
  //   doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
  //   doc.text(
  //     `Exported: ${exportDateTime}`,
  //     doc.internal.pageSize.getWidth() - 14,
  //     15,
  //     { align: "right" }
  //   ); // Right-aligned

  //   // Add title
  //   doc.setFontSize(15);
  //   doc.text(`${state?.titleState}`, 14, 22);
  //   // Initialize table headers and rows
  //   const headers = [];
  //   const rows = [];

  //   // Set column widths
  //   const colWidths = {
  //     hostname: 40,
  //     serialNumber: 30,
  //     usbName: 40,
  //     usbSerialNumber: 30,
  //     macAddress: 30,
  //     usbManufacturer: 40,
  //     usbProductId: 20,
  //     usbVendorId: 20,
  //     ipAddress: 30,
  //     openTcpPorts: 50,
  //     localAddress: 50,
  //     localPort: 30,
  //     foreignAddress: 50,
  //     foreignPort: 30,
  //     time: 30,
  //     action: 30,
  //   };

  //   // Determine which columns should be included based on non-empty values
  //   if (columnData.some((item) => item.hostname)) {
  //     headers.push("Hostname");
  //     rows.push((item) =>
  //       doc.splitTextToSize(item.hostname || "NA", colWidths.hostname)
  //     );
  //   }
  //   if (columnData.some((item) => item.serialNumber)) {
  //     headers.push("Serial Number");
  //     rows.push((item) =>
  //       doc.splitTextToSize(item.serialNumber || "NA", colWidths.serialNumber)
  //     );
  //   }
  //   if (columnData.some((item) => item.usbName)) {
  //     headers.push("USB Name");
  //     rows.push((item) =>
  //       doc.splitTextToSize(item.usbName?.join(", ") || "NA", colWidths.usbName)
  //     );
  //   }
  //   if (columnData.some((item) => item.usbSerialNumber)) {
  //     headers.push("USB Serial No");
  //     rows.push((item) =>
  //       doc.splitTextToSize(
  //         item.usbSerialNumber || "NA",
  //         colWidths.usbSerialNumber
  //       )
  //     );
  //   }
  //   if (columnData.some((item) => item.macAddress)) {
  //     headers.push("MAC Address");
  //     rows.push((item) =>
  //       doc.splitTextToSize(item.macAddress || "NA", colWidths.macAddress)
  //     );
  //   }
  //   if (columnData.some((item) => item.usbManufacturer)) {
  //     headers.push("USB Manufacturer");
  //     rows.push((item) =>
  //       doc.splitTextToSize(
  //         item.usbManufacturer || "NA",
  //         colWidths.usbManufacturer
  //       )
  //     );
  //   }
  //   if (columnData.some((item) => item.usbProductId)) {
  //     headers.push("USB ProductId");
  //     rows.push((item) =>
  //       doc.splitTextToSize(item.usbProductId || "NA", colWidths.usbProductId)
  //     );
  //   }
  //   if (columnData.some((item) => item.usbVendorId)) {
  //     headers.push("USB VendorId");
  //     rows.push((item) =>
  //       doc.splitTextToSize(item.usbVendorId || "NA", colWidths.usbVendorId)
  //     );
  //   }
  //   if (columnData.some((item) => item.ipAddress)) {
  //     headers.push("IP Address");
  //     rows.push((item) =>
  //       doc.splitTextToSize(item.ipAddress || "NA", colWidths.ipAddress)
  //     );
  //   }
  //   if (
  //     columnData.some(
  //       (item) =>
  //         Array.isArray(item.openTcpPorts) && item.openTcpPorts.length > 0
  //     )
  //   ) {
  //     headers.push("Open Ports");
  //     rows.push((item) =>
  //       doc.splitTextToSize(
  //         item.openTcpPorts?.join(", ") || "NA",
  //         colWidths.openTcpPorts
  //       )
  //     );
  //   }
  //   if (columnData.some((item) => item.localAddress)) {
  //     headers.push("Local Address");
  //     rows.push((item) =>
  //       doc.splitTextToSize(item.localAddress || "NA", colWidths.localAddress)
  //     );
  //   }
  //   if (columnData.some((item) => item.localPort)) {
  //     headers.push("Local Port");
  //     rows.push((item) =>
  //       doc.splitTextToSize(item.localPort || "NA", colWidths.localPort)
  //     );
  //   }
  //   if (columnData.some((item) => item.foreignAddress)) {
  //     headers.push("Foreign Address");
  //     rows.push((item) =>
  //       doc.splitTextToSize(
  //         item.foreignAddress || "NA",
  //         colWidths.foreignAddress
  //       )
  //     );
  //   }
  //   if (columnData.some((item) => item.foreignPort)) {
  //     headers.push("Foreign Port");
  //     rows.push((item) =>
  //       doc.splitTextToSize(item.foreignPort || "NA", colWidths.foreignPort)
  //     );
  //   }
  //   if (columnData.some((item) => item.time)) {
  //     headers.push("Timestamp");
  //     rows.push((item) =>
  //       doc.splitTextToSize(item.time || "NA", colWidths.time)
  //     );
  //   }
  //   if (columnData.some((item) => item.falsePositive)) {
  //     headers.push("falsePositive");
  //     rows.push((item) =>
  //       doc.splitTextToSize(item.falsePositive || "NA", colWidths.falsePositive)
  //     );
  //   }
  //   // Add the headers to the PDF
  //   doc.setFontSize(14);
  //   doc.text(headers.join(" | "), 10, 30);

  //   // // Add the rows to the PDF
  //   let yPosition = 40;
  //   columnData.forEach((item) => {
  //     const row = rows.map((rowFunc) => rowFunc(item)).join(" | ");
  //     doc.text(row, 10, yPosition);
  //     yPosition += 10; // Adjust line spacing as needed
  //   });

  //   // Save the PDF
  //   doc.save(`${state?.titleState}_Report.pdf`);
  // };
  // function exportToPDF() {
  //   if (columnData && columnData.length > 0) {
  //     const doc = new jsPDF({ orientation: "landscape" });
      
  //     // Organization name and export date/time
  //     const orgName = user?.data?.user?.organization || "Organization Name";
  //     const exportDateTime = new Date().toLocaleString();
  
  //     // Add header with org name and export date/time
  //     doc.setFontSize(12);
  //     doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
  //     doc.text(
  //       `Exported: ${exportDateTime}`,
  //       doc.internal.pageSize.getWidth() - 14,
  //       15,
  //       { align: "right" }
  //     ); // Right-aligned
  
  //     // Add title
  //     doc.setFontSize(15);
  //     doc.text(`${state?.titleState}`, 14, 22);
  
  //     // Initialize table headers and rows
  //     const headers = [];
  //     const rows = [];
  
  //     // Set column widths
  //     const colWidths = {
  //       hostname: 40,
  //       serialNumber: 30,
  //       usbName: 40,
  //       usbSerialNumber: 30,
  //       macAddress: 30,
  //       usbManufacturer: 40,
  //       usbProductId: 20,
  //       usbVendorId: 20,
  //       ipAddress: 30,
  //       openTcpPorts: 50,
  //       localAddress: 50,
  //       localPort: 30,
  //       foreignAddress: 50,
  //       foreignPort: 30,
  //       time: 30,
  //       action: 30,
  //       // Add more columns as needed
  //     };
  
  //     // Determine which columns should be included based on non-empty values
  //     const checkAndPushHeader = (key, label) => {
  //       if (columnData.some(item => item[key])) {
  //         headers.push(label);
  //         return (item) => doc.splitTextToSize(item[key] || "NA", colWidths[key]);
  //       }
  //       return null;
  //     };
  
  //     // Setup headers and row functions
  //     const rowFuncs = [
  //       checkAndPushHeader("hostname", "Hostname"),
  //       checkAndPushHeader("serialNumber", "Serial Number"),
  //       checkAndPushHeader("usbName", "USB Name"),
  //       checkAndPushHeader("usbSerialNumber", "USB Serial No"),
  //       checkAndPushHeader("macAddress", "MAC Address"),
  //       checkAndPushHeader("usbManufacturer", "USB Manufacturer"),
  //       checkAndPushHeader("usbProductId", "USB ProductId"),
  //       checkAndPushHeader("usbVendorId", "USB VendorId"),
  //       checkAndPushHeader("ipAddress", "IP Address"),
  //       checkAndPushHeader("openTcpPorts", "Open Ports"),
  //       checkAndPushHeader("localAddress", "Local Address"),
  //       checkAndPushHeader("localPort", "Local Port"),
  //       checkAndPushHeader("foreignAddress", "Foreign Address"),
  //       checkAndPushHeader("foreignPort", "Foreign Port"),
  //       checkAndPushHeader("time", "Timestamp"),
  //       checkAndPushHeader("falsePositive", "False Positive"),
  //       // Add more columns as needed
  //     ].filter(Boolean);
  
  //     // Add the headers to the PDF
  //     doc.setFontSize(14);
  //     doc.text(headers.join(" | "), 10, 30);
  
  //     // Add the rows to the PDF
  //     let yPosition = 40;
  //     columnData.forEach(item => {
  //       const row = rowFuncs.map(func => func(item)).join(" | ");
  //       doc.text(row, 10, yPosition);
  //       yPosition += 10; // Adjust line spacing as needed
  //     });
  
  //     // Save the PDF
  //     doc.save(`${state?.titleState}_Report.pdf`);
  //   }
  // }


  function exportToPDF() {
    if (columnData && columnData.length > 0) {
      const doc = new jsPDF({ orientation: "landscape" });
  
      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = new Date().toLocaleString();
  
      // Add header with org name and export date/time
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned
  
      // Add title
      doc.setFontSize(15);
      doc.text(`${state?.titleState}`, 14, 22);
  
      // Define headers and rows
      const headers = [];
      const rows = [];
  
      const colWidths = {
        hostname: 20,
        serialNumber: 30,
        usbName: 40,
        usbSerialNumber: 30,
        macAddress: 30,
        usbManufacturer: 40,
        usbProductId: 20,
        usbVendorId: 20,
        ipAddress: 30,
        openTcpPorts: 30,
        localAddress: 50,
        localPort: 30,
        foreignAddress: 50,
        foreignPort: 30,
        time: 30,
        action: 30,
      };
  
      const checkAndPushHeader = (key, label) => {
        if (columnData.some(item => item[key])) {
          headers.push(label);
          return (item) => doc.splitTextToSize(item[key] || "NA", colWidths[key]);
        }
        return null;
      };
  
      const rowFuncs = [
        checkAndPushHeader("hostname", "Hostname"),
        checkAndPushHeader("serialNumber", "Serial Number"),
        checkAndPushHeader("usbName", "USB Name"),
        checkAndPushHeader("usbSerialNumber", "USB Serial No"),
        checkAndPushHeader("macAddress", "MAC Address"),
        checkAndPushHeader("usbManufacturer", "USB Manufacturer"),
        checkAndPushHeader("usbProductId", "USB ProductId"),
        checkAndPushHeader("usbVendorId", "USB VendorId"),
        checkAndPushHeader("ipAddress", "IP Address"),
        checkAndPushHeader("openTcpPorts", "Open Ports"),
        checkAndPushHeader("localAddress", "Local Address"),
        checkAndPushHeader("localPort", "Local Port"),
        checkAndPushHeader("foreignAddress", "Foreign Address"),
        checkAndPushHeader("foreignPort", "Foreign Port"),
        checkAndPushHeader("time", "Timestamp"),
        checkAndPushHeader("falsePositive", "False Positive"),
        checkAndPushHeader("foreignAddressCount", "foreign Address Count"),
      ].filter(Boolean);
  
      // Prepare rows data
      columnData.forEach(item => {
        const row = rowFuncs.map(func => func(item)).join(" | ");
        rows.push(row.split(" | "));
      });
  
      // Use autoTable to create the table
      doc.autoTable({
        startY: 30,
        head: [headers],
        body: rows,
        styles: {
          cellPadding: 3,
          lineColor: [44, 62, 80],
          lineWidth: 0.1,
        },
        headStyles: {
          fillColor: [52, 73, 94],
          textColor: 255,
          halign: "center",
          lineWidth: 0.5,
          fontSize: 10, // Set a smaller font size for the header
        },
        bodyStyles: {
          lineColor: [44, 62, 80],
          lineWidth: 0.1,
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240],
        },
        columnStyles: {
          // Set specific column widths here if necessary
          hostname: { cellWidth: colWidths.hostname },
          serialNumber: { cellWidth: colWidths.serialNumber },
          usbName: { cellWidth: colWidths.usbName },
          usbSerialNumber: { cellWidth: colWidths.usbSerialNumber },
          macAddress: { cellWidth: colWidths.macAddress },
          usbManufacturer: { cellWidth: colWidths.usbManufacturer },
          usbProductId: { cellWidth: colWidths.usbProductId },
          usbVendorId: { cellWidth: colWidths.usbVendorId },
          ipAddress: { cellWidth: colWidths.ipAddress },
          openTcpPorts: { cellWidth: colWidths.openTcpPorts },
          localAddress: { cellWidth: colWidths.localAddress },
          localPort: { cellWidth: colWidths.localPort },
          foreignAddress: { cellWidth: colWidths.foreignAddress },
          foreignPort: { cellWidth: colWidths.foreignPort },
          foreignAddressCount: { cellWidth: colWidths.foreignAddressCount },
          
          time: { cellWidth: colWidths.time },
          action: { cellWidth: colWidths.action },
        },
      });
  
      // Save the PDF
      doc.save(`${state?.titleState}_Report.pdf`);
    }
  }
  
  
  
  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "aliceblue" }}
      >
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link
              to="/linux-dash"
              className="shadow"
              style={{ borderRadius: "25px" }}
            >
              <img src={linuxIcon} alt="Linux-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-20 fw-bolder p-0 m-0 ">Linux Dashboard</p>
          </span>
          {/* <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="secondary"
              className="d-flex align-items-center gap-2"
              onClick={exportToCSV}
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
              <span className="d-none d-sm-inline">Generate Report</span>
            </Button>
          </div> */}
        </div>
        {/* <Row>
          <Col xl={6}>
            <p className="fs-20 text-dark fw-bolder p-0 m-0">
              {state?.titleState || "Alert Title"}
            </p>
            <p className="text-danger p-0 m-0 fw-semibold">
              {totalDocuments || 0} Clients
            </p>
          </Col>
        </Row> */}

        <div class="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row allCardEffect shadow">
          <div class="col-xl">
            <p class="fs-20 text-dark fw-bolder p-0 m-0">
              {state?.titleState.includes("Comm") === true
                ? "Unknown Network Communication"
                : state?.titleState || "Alert Title"}
            </p>
            <p class="text-danger p-0 m-0 fw-semibold">
              {" "}
              {totalDocuments || 0}{" "}
              {state?.titleState.includes("Violations") === true
                ? "Violations"
                : "Endpoints"}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={exportToPDF}
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
            </button>
            <button
              type="button"
              className="btn btn-success text-white"
              onClick={exportToCSV}
            >
              <img src={Excellogo} height={20} width={15} />
              &nbsp; CSV
            </button>
          </div>
        </div>

        {/* <div xl={12} className=" card allCardEffect shadow mt-2"> */}
        {!loading ? (
          <CustomTableLinuxUsb
            rowData={columnData}
            columnData={columnData}
            routeUrl={"/linux-client-Details"}
            currentPage={pageNumber}
            pageSize={pageSize}
            totalDocuments={totalDocuments}
            fetchData={fetchData}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        ) : (
          <PageLoader />
        )}
        {/* </div> */}
      </div>
    </React.Fragment>
  );
}

export default LinuxServerityUsb;
