import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../layouts/Header";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { notifyApi } from "../../../api/axiosSet";
import { useSelector } from "react-redux";
import { Button } from "antd";
import Offcanvas from "react-bootstrap/Offcanvas";
import Excellogo from "../../../assets/img/Excellogo.png";
import AsyncSelect from "react-select/async";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function WhitelistSubnets() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingDevice, setEditingDevice] = useState(null);
  const invalidCharacters = /[<>'"]/;
  const hasNumbers = /^[0-9]/;

  const [formState, setFormState] = useState({
    name: "",
    manufacturer: "",
    serialNumber: "",
    productId: "",
    vendorId: "",
  });

  const { user } = useSelector((state) => state.authSlice);
  useEffect(() => {
    if (user?.isPasswordUpdated === false) {
      navigate('/update-profile')
    }
  }, []);

  useEffect(() => {
    const modalElement = document.getElementById("deviceModal");

    const handleModalClose = () => {
      setFormState({
        name: "",
        manufacturer: "",
      });
      setEditingDevice(null);
    };

    modalElement.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await notifyApi.get(
        `/get/whitelist-subnets/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      const devices = response.data.data;
      setColumnData(devices);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const columns = [
    {
      name: "Subnet From",
      selector: (row) => row.subnetFrom,
      sortable: true,
    },
    {
      name: "Subnet To",
      selector: (row) => row.subnetTo,
      sortable: true,
    },
    {
      name: "Updated Time",
      selector: (row) => formatTimestamp(row.updatedAt) || "NA",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button type="button" className="btn btn-dark" onClick={() => handleEdit(row)}>
            <i className="fa-solid fa-pen"></i>
          </button>
          &nbsp;
          <button type="button" className="btn btn-danger" onClick={() => handleDelete(row._id)}>
            <i className="fa-solid fa-trash"></i>
          </button>
        </>
      ),
      sortable: false,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#D7E3E8",
      },
    },
  };

  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      // Determine which columns have data
      const hasData = (key) => columnData.some((item) => item[key]);

      const headers = [
        { label: "Subnet From", key: "subnetFrom" },
        { label: "Subnet To", key: "subnetTo" },
        { label: "Updated Time", key: "updatedAt" },
      ].filter((header) => hasData(header.key));

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail)
            const value = Object.values(detail)
            return `${key}:${value}`;
          })
          .join(" | ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      const formatMultipleIpAddresses = (ipAddresses) => {
        if (typeof ipAddresses === "string") {
          return ipAddresses
        }
        return ipAddresses
          .map((ipAddress) => ipAddress)
          .join(" ")
          .replace(/ /g, " | ");
      };

      const formatUsersPasswordAge = (usersPasswordAge) => {
        if (!usersPasswordAge || usersPasswordAge.length === 0) return "N/A"; // Handle empty or undefined case

        return usersPasswordAge
          .map((user) => `${user.userType}:${user.userName}:${user.passwordAge}`)
          .join(" | ");
      };

      const csvData = [
        headers.map((header) => header.label),
        ...columnData.map((item) =>
          headers.map((header) =>
            header.key === "ipAddress"
              ? formatMultipleIpAddresses(item[header.key])
              :
              header.key === "updatedAt"
                ? formatTimestamp(item[header.key])
                : header.key === "usersPasswordAge"
                  ? formatUsersPasswordAge(item[header.key])
                  : header.key === "sharedDirectories"
                    ? formatSharedDirectories(item[header.key])
                    : header.key === "avDetails"
                      ? formatAvDetails(item[header.key])
                      : header.key === "service"
                        ? formatOsDetails(item[header.key])
                        : item[header.key]
          )
        ),
      ];
      // Add header information
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `whitelist_subnets.csv`);
    }
  };

  function exportToPDF() {
    if (columnData && columnData.length > 0) {
      // Set the orientation to landscape
      const doc = new jsPDF({
        orientation: "landscape", // Force landscape orientation
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      doc.text(`Whitelist Subnets`, 14, 22);

      const hasData = (key) => columnData.some((item) => item[key]);
      const headers = [
        { label: "Subnet From", key: "subnetFrom" },
        { label: "Subnet To", key: "subnetTo" },
        { label: "Updated Time", key: "updatedAt" },
      ].filter((header) => hasData(header.key));

      const tableColumn = headers.map((header) => header.label);
      const tableRows = [];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail).find((k) => k.startsWith("clamav"));
            const avName = key ? key : "Unknown AV";
            const avStatus = detail[key] || "Status not available";

            return `AV Name: ${avName}, Status: ${avStatus}`;
          })
          .join("; ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      columnData.forEach((item) => {
        const rowData = headers.map((header) =>
          header.key === "updatedAt"
            ? formatTimestamp(item[header.key])
            : header.key === "sharedDirectories"
              ? formatSharedDirectories(item[header.key])
              : header.key === "usersPasswordAge"
                ? item[header.key]
                  .map(
                    (user) =>
                      `${user.userType} :${user.userName}: ${user.passwordAge}`
                  )
                  .join("; ")
                : header.key === "avDetails"
                  ? formatAvDetails(item[header.key])
                  : header.key === "service"
                    ? formatOsDetails(item[header.key])
                    : Array.isArray(item[header.key])
                      ? item[header.key].join(", ")
                      : item[header.key]
        );
        tableRows.push(rowData);
      });

      // Add table with borders for rows and columns
      doc.autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: {
          cellPadding: 3, // Padding for cells
          lineColor: [44, 62, 80], // Border color (RGB)
          lineWidth: 0.1, // Border width
        },
        headStyles: {
          fillColor: [52, 73, 94], // Header background color
          textColor: 255, // Header text color
          halign: "center", // Center align header text
          lineWidth: 0.5, // Border width for header
        },
        bodyStyles: {
          lineColor: [44, 62, 80], // Row border color
          lineWidth: 0.1, // Border width for rows
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Background color for alternate rows
        },
      });

      // Save the PDF
      doc.save(`whitelist_subnets.pdf`);
    }
  }

  const handleEdit = (device) => {
    setFormState({
      name: device.subnetFrom,
      manufacturer: device.subnetTo
    });
    setEditingDevice(device?._id); // Store the ID of the device being edited
    document.getElementById("openModalButton").click();
  };

  const handleDelete = async (id) => {
    // Confirm deletion
    const confirmDelete = window.confirm("Are you sure you want to delete this subnet?");
    if (!confirmDelete) {
      return;
    }

    try {
      await notifyApi.post(
        `/delete-subnet/${user?.data?.user?.userId}`,
        {
          id: id,
        }
      );
      toast.success("Subnet deleted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      fetchData(); // Refresh the data
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingDevice) {
      handleSubmitEditDevice();
    } else {
      handleSubmitAddDevice();
    }
  };

  const validate = () => {
    let isValid = true;
    let error;
    if (!formState.name || formState.name.trim() === "") {
      isValid = false;
      error = "Subnet from is not valid";
    }
    else if (!formState.manufacturer || formState.manufacturer.trim() === "") {
      isValid = false;
      error = "Subnet to is not valid";
    }
    else if (invalidCharacters.test(formState.name)) {
      isValid = false;
      error = "Subnet from contains invalid characters";
    }
    else if (invalidCharacters.test(formState.manufacturer)) {
      isValid = false;
      error = "Subnet to contains invalid characters";
    }
    const namelist = formState.name.split(".");
    const manuList = formState.manufacturer.split(".");
    if (namelist?.length !== 4 || manuList?.length !== 4) {
      isValid = false;
      error = "Subnet range is not valid";
    }
    else if (!hasNumbers.test(namelist[0]) || !hasNumbers.test(namelist[1]) || !hasNumbers.test(namelist[2]) || !hasNumbers.test(namelist[3])) {
      isValid = false;
      error = "Subnet from range is not valid";
    }
    else if (!hasNumbers.test(manuList[0]) || !hasNumbers.test(manuList[1]) || !hasNumbers.test(manuList[2]) || !hasNumbers.test(manuList[3])) {
      isValid = false;
      error = "Subnet to range is not valid";
    }
    else if (namelist[0] < 0 || namelist[1] < 0 || namelist[2] < 0 || namelist[3] < 0 || namelist[0] > 255 || namelist[1] > 255 || namelist[2] > 255 || namelist[3] > 255) {
      isValid = false;
      error = "Subnet from range is not valid";
    }
    else if (manuList[0] < 0 || manuList[1] < 0 || manuList[2] < 0 || manuList[3] < 0 || manuList[0] > 255 || manuList[1] > 255 || manuList[2] > 255 || manuList[3] > 255) {
      isValid = false;
      error = "Subnet to range is not valid";
    }
    return { error, isValid };
  };

  const handleSubmitAddDevice = async () => {
    if (
      !formState.name ||
      !formState.manufacturer
    ) {
      alert("Please fill all fields.");
      return;
    }

    const { error, isValid } = validate();
    if (!isValid) {
      alert(error);
      return;
    }

    const data = {
      organizationId: user?.data?.user?.organizationId,
      subnetFrom: formState.name,
      subnetTo: formState.manufacturer,
    };

    try {
      await notifyApi.post(
        `/add/whitelist-subnet/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      toast.success("Subnet added successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setFormState({
        name: "",
        manufacturer: "",
        serialNumber: "",
        productId: "",
        vendorId: "",
      }); // Clear form fields after adding a device
      fetchData(); // Refresh the data
      document.getElementById("closeModal").click(); // Close the modal
    } catch (error) {
      document.getElementById("closeModal").click(); // Close the modal
      toast.error(error.response.data.message);
      console.error("Error adding device:", error.response.data.message);
    }
  };

  const handleSubmitEditDevice = async () => {
    if (
      !formState.name ||
      !formState.manufacturer
    ) {
      alert("Please fill all fields.");
      return;
    }

    const { error, isValid } = validate();
    if (!isValid) {
      alert(error);
      return;
    }

    const data = {
      id: editingDevice,
      organizationId: user?.data?.user?.organizationId,
      subnetFrom: formState.name,
      subnetTo: formState.manufacturer,

    };

    try {
      await notifyApi.put(
        `/update-subnet/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      toast.success("Subnet updated successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setEditingDevice(null); // Reset editing state
      fetchData(); // Refresh the data
      document.getElementById("closeModal").click(); // Close the modal
    } catch (error) {
      document.getElementById("closeModal").click();
      toast.error("Error updating Subnet!");
      console.error("Error updating device:", error);
    }
  };

  return (
    <React.Fragment>
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-20 fw-bolder text-dark  p-0 m-0"> Whitelist Subnets</p>
          </span>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={() => exportToPDF()}
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => exportToCSV()}
            >
              <i className="fa-solid fa-file-excel"></i> &nbsp; CSV
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <div className="col-xl">
            <p className="fs-18 fw-bolder p-0 m-0">Subnets List</p>
            <p className="text-danger p-0 m-0 fw-semibold">
              Total Subnets: {columnData.length}
            </p>
          </div>

          <button
            type="button"
            id="openModalButton"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#deviceModal"
          >
            <i className="fa-solid fa-plus"></i>  Add Subnet
          </button>

        </div>
        <div className="card rounded cardStyle">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataTable
              columns={columns}
              data={columnData}
              pagination
              highlightOnHover
              customStyles={customStyles}
              progressPending={loading}
              progressComponent={<span>Loading...</span>}
              noDataComponent="No Subnets found."
            />
          )}
        </div>
        <div>


          <div
            className="modal fade"
            id="deviceModal"
            tabIndex="-1"
            aria-labelledby="deviceModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="deviceModalLabel">
                    {editingDevice ? "Edit Subnet" : "Add Subnet"}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="closeModal"
                  ></button>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Subnet From <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formState.name}
                        maxLength={15}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="manufacturer" className="form-label">
                        Subnet To <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="manufacturer"
                        name="manufacturer"
                        maxLength={15}
                        value={formState.manufacturer}
                        onChange={handleChange}
                        required
                      />
                    </div>

                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                      {editingDevice ? "Update" : "Add"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WhitelistSubnets;
