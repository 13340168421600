// import React, { useState } from 'react';
// import { Card, CardContent, Typography, IconButton } from '@mui/material';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import './Notification.css';

// const Notification = ({ hostname, eventTriggerType, timeStamp, notificationType, isRead }) => {
//   return (
//     <Card className={`notification ${isRead ? '' : 'unread'}`}>
//       <CardContent>
//         <Typography variant="h6">{hostname}</Typography>
//         <Typography variant="body2" color="textSecondary">
//           {eventTriggerType}
//         </Typography>
//         <Typography variant="caption" color="textSecondary">
//           {timeStamp} &bull; {notificationType}
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// };

// const NotificationListMain = ({ notifications, updateNotifications,onClearAll }) => {
//   const [activeTab, setActiveTab] = useState('unread');

//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
//   };

//   const markAllAsRead = () => {
//     const updatedNotifications = notifications.map((notification) => ({
//       ...notification,
//       isRead: true,
//     }));
//     onClearAll(updatedNotifications);
//   };

//   const filteredNotifications = notifications.filter((notification) =>
//     activeTab === 'unread' ? !notification.isRead : true
//   );

//   return (
//     <div className="notification-list">
//       <div className="header">
//         <Typography variant="h5">Notifications</Typography>
//         <div className="header-icons">
//           <IconButton>
//             <NotificationsIcon />
//           </IconButton>
//           <IconButton>
//             <MoreVertIcon />
//           </IconButton>
//         </div>
//       </div>
//       <div className="tabs">
//         <Typography
//           variant="button"
//           className={activeTab === 'unread' ? 'active' : ''}
//           onClick={() => handleTabChange('unread')}
//         >
//           Unread ({notifications.filter(n => !n.isRead).length})
//         </Typography>
//         <Typography
//           variant="button"
//           className={activeTab === 'all' ? 'active' : ''}
//           onClick={() => handleTabChange('all')}
//         >
//           All
//         </Typography>
//         <button className="mark-all-read" onClick={markAllAsRead}>
//           Mark all as read
//         </button>
//       </div>
//       {filteredNotifications.map((notification, index) => (
//         <Notification key={index} {...notification} />
//       ))}
//     </div>
//   );
// };

// export default NotificationListMain;

// import React, { useState } from "react";
// import { Card, CardContent, Typography, IconButton } from "@mui/material";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import "./Notification.css";

// const Notification = ({
//   hostname,
//   eventTriggerType,
//   timeStamp,
//   notificationType,
//   isRead,
// }) => {
//   return (
//     <Card className={`notification ${isRead ? "" : "unread"}`}>
//       <CardContent>
//         <Typography variant="body1">{hostname}</Typography>
//         <Typography variant="body2" color="textSecondary">
//           {eventTriggerType}
//         </Typography>
//         <Typography variant="caption" color="textSecondary">
//           {timeStamp} &bull; {notificationType}
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// };

// const NotificationListMain = ({ notifications, onClearAll }) => {
//   const [activeTab, setActiveTab] = useState("unread");

//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
//   };

//   const markAllAsRead = () => {
//     const updatedNotifications = notifications.map((notification) => ({
//       ...notification,
//       isRead: true,
//     }));
//     onClearAll(updatedNotifications);
//   };

//   const filteredNotifications = notifications.filter((notification) =>
//     activeTab === "unread" ? !notification.isRead : true
//   );

//   return (
//     <div className="card notification-list rounded-3">
//       <div className="header">
//         <Typography variant="h6">Notifications</Typography>
//         <div className="header-icons">
//           <IconButton>
//             {/* <NotificationsIcon /> */}
//           </IconButton>
//           <IconButton>
//             <MoreVertIcon />
//           </IconButton>
//         </div>
//       </div>

//       <div className="tabs">
//         <Typography
//           variant="button"
//           className={activeTab === "unread" ? "active" : ""}
//           onClick={() => handleTabChange("unread")}
//         >
//           Unread ({notifications.filter((n) => !n.isRead).length})
//         </Typography>
//         <Typography
//           variant="button"
//           className={activeTab === "all" ? "active" : ""}
//           onClick={() => handleTabChange("all")}
//         >
//           All
//         </Typography>
//         <button className="mark-all-read" onClick={markAllAsRead}>
//           Mark all as read
//         </button>
//       </div>
//       <ul class="list-group list-group-flush">
//         {filteredNotifications.map((notification, index) => (
//           <Notification key={index} {...notification} />
//         ))}
//       </ul>
//       <div class="card-footer text-body p-2" style={{backgroundColor:' #e2e5ec'}}>
//         <a href="/">Show all Notifications</a>
//       </div>
//     </div>
//   );
// };

// export default NotificationListMain;

// routing working
// import React, { useState } from "react";
// import { Card, CardContent, Typography, IconButton } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import "./Notification.css";

// const Notification = ({
//   hostname,
//   eventTriggerType,
//   timeStamp,
//   notificationType,
//   isRead,
//   pcId,
// }) => {
//   const navigate = useNavigate();

//   const handleRouteClient = (pcId, timeStamp, eventTriggerType) => {

//     console.log(pcId, timeStamp, eventTriggerType,'wwwwwwwwwwwww')
//     if (eventTriggerType.includes("WINDOWS")) {
//       navigate("/windows-client-Details", {
//         state: {
//           pcInfoId: pcId,
//           timeTrigger: timeStamp,
//         },
//       });
//     } else if (eventTriggerType.includes("LINUX")) {
//       navigate("/linux-client-Details", {
//         state: {
//           pcInfoId: pcId,
//           timeTrigger: timeStamp,
//         },
//       });
//     } else if (eventTriggerType.includes("MAC")) {
//       navigate("/macOs-client-Details", {
//         state: {
//           pcInfoId: pcId,
//           timeTrigger: timeStamp,
//         },
//       });
//     } else if (eventTriggerType.includes("CHROME")) {
//       navigate("/chrome-client-Details", {
//         state: {
//           pcInfoId: pcId,
//           timeTrigger: timeStamp,
//         },
//       });
//     } else if (eventTriggerType.includes("ANDROID")) {
//       navigate("/android-client-Details", {
//         state: {
//           pcInfoId: pcId,
//           timeTrigger: timeStamp,
//         },
//       });
//     }
//   };

//   return (
//     <Card
//       className={`notification ${isRead ? "" : "unread"}`}
//       onClick={() => handleRouteClient(pcId, timeStamp, eventTriggerType)}
//     >
//       <CardContent>
//         <Typography variant="body1">{hostname}</Typography>
//         <Typography variant="body2" color="textSecondary">
//           {eventTriggerType}
//         </Typography>
//         <Typography variant="caption" color="textSecondary">
//           {timeStamp} &bull; {notificationType}
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// };

// const NotificationListMain = ({ notifications, onClearAll,updateNotification }) => {

//   console.log(notifications,'notificationsnotifications')
//   const [activeTab, setActiveTab] = useState("unread");

//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
//   };

//   const markAllAsRead = () => {
//     const updatedNotifications = notifications.map((notification) => ({
//       ...notification,
//       isRead: true,
//     }));
//     onClearAll(updatedNotifications);
//   };

//   const filteredNotifications = notifications.filter((notification) =>
//     activeTab === "unread" ? !notification.isRead : true
//   );

//   return (
//     <div className="card notification-list rounded-3">
//       <div className="header">
//         <Typography variant="h6">Notifications</Typography>
//         <div className="header-icons">
//           <IconButton>
//             {/* <NotificationsIcon /> */}
//           </IconButton>
//           <IconButton>
//             <MoreVertIcon />
//           </IconButton>
//         </div>
//       </div>

//       <div className="tabs">
//         <Typography
//           variant="button"
//           className={activeTab === "unread" ? "active" : ""}
//           onClick={() => handleTabChange("unread")}
//         >
//           Unread ({notifications.filter((n) => !n.isRead).length})
//         </Typography>
//         <Typography
//           variant="button"
//           className={activeTab === "all" ? "active" : ""}
//           onClick={() => handleTabChange("all")}
//         >
//           All
//         </Typography>
//         <button className="mark-all-read" onClick={markAllAsRead}>
//           Mark all as read
//         </button>
//       </div>
//       <ul className="list-group list-group-flush">
//         {filteredNotifications.map((notification, index) => (
//           <Notification key={index} {...notification} />
//         ))}
//       </ul>
//       <div className="card-footer text-body p-2" style={{backgroundColor:' #e2e5ec'}}>
//         <a href="/">Show all Notifications</a>
//       </div>
//     </div>
//   );
// };

// export default NotificationListMain;

// indiviaul working
// import React, { useState } from "react";
// import { Card, CardContent, Typography, IconButton } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import axios from "axios";
// import "./Notification.css";
// import { useDispatch, useSelector } from "react-redux";

// const Notification = ({
//   hostname,
//   eventTriggerType,
//   timeStamp,
//   notificationType,
//   isRead,
//   pcId,
//   updateNotification,
//   _id

// }) => {
//   const navigate = useNavigate();
//   const [notificationID, setNotificationID] =useState(null)
//   const handleRouteClient = (pcId, timeStamp, eventTriggerType,_id) => {

//     setNotificationID(pcId)

//     if (!isRead) {
//       updateNotification(pcId);
//     }

//     if (eventTriggerType.includes("WINDOWS")) {
//       navigate("/windows-client-Details", {
//         state: {
//           pcInfoId: pcId,
//           timeTrigger: timeStamp,
//         },
//       });
//     } else if (eventTriggerType.includes("LINUX")) {
//       navigate("/linux-client-Details", {
//         state: {
//           pcInfoId: pcId,
//           timeTrigger: timeStamp,
//         },
//       });
//     } else if (eventTriggerType.includes("MAC")) {
//       navigate("/macOs-client-Details", {
//         state: {
//           pcInfoId: pcId,
//           timeTrigger: timeStamp,
//         },
//       });
//     } else if (eventTriggerType.includes("CHROME")) {
//       navigate("/chrome-client-Details", {
//         state: {
//           pcInfoId: pcId,
//           timeTrigger: timeStamp,
//         },
//       });
//     } else if (eventTriggerType.includes("ANDROID")) {
//       navigate("/android-client-Details", {
//         state: {
//           pcInfoId: pcId,
//           timeTrigger: timeStamp,
//         },
//       });
//     }
//   };

//   return (
//     <Card
//       className={`notification ${isRead ? "" : "unread"}`}
//       onClick={() => handleRouteClient(_id,pcId, timeStamp, eventTriggerType)}
//     >
//       <CardContent>
//         <Typography variant="body1">{hostname}</Typography>
//         <Typography variant="body2" color="textSecondary">
//           {eventTriggerType}
//         </Typography>
//         <Typography variant="caption" color="textSecondary">
//           {timeStamp} &bull; {notificationType}
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// };

// const NotificationListMain = ({ notifications, onClearAll }) => {

//   const { user } = useSelector((state) => state.authSlice);

//   function updateNotification(notificationID) {
//     axios
//       .post(
//         `${process.env.REACT_APP_NOTIFY_URL}/update-notification/by/${notificationID}/${user?.data?.user?.userId}`,
//         {},
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       )
//       .then((res) => {
//         console.log(res, "UPDATE SUCCESSFULLY");
//       })
//       .catch((error) => {
//         if (error.response) {
//           // The request was made and the server responded with a status code
//           // that falls out of the range of 2xx
//           const statusCode = error.response.status;
//           switch (statusCode) {
//             case 404:
//               console.log("Resource not found (404)");
//               break;
//             case 500:
//               console.log("Internal server error (500)");
//               break;
//             case 504:
//               console.log("Gateway timeout (504)");
//               break;
//             default:
//               console.log(`Error: ${statusCode}`);
//           }
//         } else if (error.request) {
//           // The request was made but no response was received
//           console.log("No response received");
//         } else {
//           // Something happened in setting up the request that triggered an Error
//           console.log("Error", error.message);
//         }
//       });
//   }
//   const [activeTab, setActiveTab] = useState("unread");

//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
//   };

//   const markAllAsRead = () => {
//     const updatedNotifications = notifications.map((notification) => ({
//       ...notification,
//       isRead: true,
//     }));
//     onClearAll(updatedNotifications);
//   };

//   const filteredNotifications = notifications.filter((notification) =>
//     activeTab === "unread" ? !notification.isRead : true
//   );

//   return (
//     <div className="card notification-list rounded-3">
//       <div className="header">
//         <Typography variant="h6">Notifications</Typography>
//         <div className="header-icons">
//           <IconButton>
//             {/* <NotificationsIcon /> */}
//           </IconButton>
//           <IconButton>
//             <MoreVertIcon />
//           </IconButton>
//         </div>
//       </div>

//       <div className="tabs">
//         <Typography
//           variant="button"
//           className={activeTab === "unread" ? "active" : ""}
//           onClick={() => handleTabChange("unread")}
//         >
//           Unread ({notifications.filter((n) => !n.isRead).length})
//         </Typography>
//         <Typography
//           variant="button"
//           className={activeTab === "all" ? "active" : ""}
//           onClick={() => handleTabChange("all")}
//         >
//           All
//         </Typography>
//         <button className="mark-all-read" onClick={markAllAsRead}>
//           Mark all as read
//         </button>
//       </div>
//       <ul className="list-group list-group-flush">
//         {filteredNotifications.map((notification, index) => (
//           <Notification
//             key={index}
//             {...notification}
//             updateNotification={updateNotification}
//           />
//         ))}
//       </ul>
//       <div className="card-footer text-body p-2" style={{backgroundColor:' #e2e5ec'}}>
//         <a href="/">Show all Notifications</a>
//       </div>
//     </div>
//   );
// };

// export default NotificationListMain;

import React, { useState } from "react";
import { Card, CardContent, Typography, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import "./Notification.css";
import { useDispatch, useSelector } from "react-redux";

const Notification = ({
  hostname,
  eventTriggerType,
  timeStamp,
  notificationType,
  isRead,
  pcId,
  updateNotification,
  _id,
  legacyId,
  alertType
}) => {
  const navigate = useNavigate();

  // const handleRouteClient = (pcId, timeStamp, eventTriggerType, _id,alertType, legacyId) => {
  //   console.log(alertType, timeStamp,eventTriggerType,legacyId, "wwwwwwwwwwwwwwwwwwww");

  //   if (!isRead) {
  //     updateNotification(_id);
  //   }

  //     // Check for alertType first
  // // if (alertType.includes("WATCHDOG")) {
  // //   navigate("/linux-client-Details", {
  // //     state: {
  // //       pcInfoId: pcId,
  // //       timeTrigger: timeStamp,
  // //       legacyId: legacyId
  // //     }
  // //   });
  // //   return; // Exit after handling alertType
  // // }

  //   switch (true) {
  //     case eventTriggerType.includes("WINDOWS"):
  //       navigate("/windows-client-Details", {
  //         state: {
  //           pcInfoId: pcId,
  //           timeTrigger: timeStamp,
  //           legacyId: legacyId
  //         }
  //       });
  //       break;
  //     case eventTriggerType.includes("LINUX"):
  //       navigate("/linux-client-Details", {
  //         state: {
  //           pcInfoId: pcId,
  //           timeTrigger: timeStamp,
  //           legacyId: legacyId
  //         }
  //       });
  //       break;
  //     case eventTriggerType.includes("MAC"):
  //       navigate("/macOs-client-Details", {
  //         state: {
  //           pcInfoId: pcId,
  //           timeTrigger: timeStamp,
  //           legacyId: legacyId
  //         }
  //       });
  //       break;
  //     case eventTriggerType.includes("CHROME"):
  //       navigate("/chrome-client-Details", {
  //         state: {
  //           pcInfoId: pcId,
  //           timeTrigger: timeStamp,
  //           legacyId: legacyId
  //         }
  //       });
  //       break;

  //       // case alertType.includes("WATCHDOG"):
  //       //   navigate("/linux-client-Details", {
  //       //     state: {
  //       //       pcInfoId: pcId,
  //       //       timeTrigger: timeStamp,
  //       //       legacyId: legacyId
  //       //     }
  //       //   });
  //       //   break;
  //     case eventTriggerType.includes("ANDROID") || eventTriggerType.includes("ROUTINE"):
  //       navigate("/android-client-Details", {
  //         state: {
  //           pcInfoId: pcId,
  //           timeTrigger: timeStamp,
  //           legacyId: legacyId
  //         }
  //       });
  //       break;
  //     default:
  //       // handle default case if needed
  //   }
  // };

  const handleRouteClient = (
    pcId,
    timeStamp,
    eventTriggerType,
    _id,
    alertType,
    legacyId
  ) => {
    console.log(
      alertType,
      timeStamp,
      eventTriggerType,
      legacyId,
      pcId,
      "wwwwwwwwwwwwwwwwwwww"
    );

    if (!isRead) {
      updateNotification(_id);
    }

    // Check for alertType related to WATCHDOG
    const watchdogAlerts = [
      "WATCHDOG_VERSION_ALERT",
      "WATCHDOG_FILE_ALERT",
      "WATCHDOG_SERVICE_ALERT",
      "WATCHDOG_UPDATE_ALERT",
    ];

    if (watchdogAlerts.includes(alertType)) {
      navigate("/linux-client-Details", {
        state: {
          pcInfoId: pcId,
          timeTrigger: timeStamp,
          legacyId: legacyId,
        },
      });
      return; // Exit after handling the WATCHDOG alertType
    }

    switch (true) {
      case eventTriggerType.includes("WINDOWS"):
        navigate("/windows-client-Details", {
          state: {
            pcInfoId: pcId,
            timeTrigger: timeStamp,
            legacyId: legacyId,
          },
        });
        break;
      case eventTriggerType.includes("LINUX"):
        navigate("/linux-client-Details", {
          state: {
            pcInfoId: pcId,
            timeTrigger: timeStamp,
            legacyId: legacyId,
          },
        });
        break;
      case eventTriggerType.includes("MAC"):
        navigate("/macOs-client-Details", {
          state: {
            pcInfoId: pcId,
            timeTrigger: timeStamp,
            legacyId: legacyId,
          },
        });
        break;
      case eventTriggerType.includes("CHROME"):
        navigate("/chrome-client-Details", {
          state: {
            pcInfoId: pcId,
            timeTrigger: timeStamp,
            legacyId: legacyId,
          },
        });
        break;
      case eventTriggerType.includes("ANDROID") ||
        eventTriggerType.includes("ROUTINE"):
        navigate("/android-client-Details", {
          state: {
            pcInfoId: pcId,
            timeTrigger: timeStamp,
            legacyId: legacyId,
          },
        });
        break;
      default:
      // handle default case if needed
    }
  };

  return (
    <Card
      className={`notification ${isRead ? "" : "unread"} hoverable`}
      onClick={() =>
        handleRouteClient(
          pcId,
          timeStamp,
          eventTriggerType,
          _id,
          alertType,
          legacyId
        )
      }
    >
      <CardContent style={{ padding: "12px" }}>
        <Typography variant="body1">{hostname}</Typography>
        <Typography variant="body2" color="textSecondary">
          {eventTriggerType || alertType}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {timeStamp} &bull; {notificationType}
        </Typography>
      </CardContent>
    </Card>
  );
};

const NotificationListMain = ({
  notifications,
  onClearAll,
  notificationCountAll,
  onChange,
}) => {
  const { user } = useSelector((state) => state.authSlice);

  const handleSomeChange = () => {
    // Call the onChange function when a change occurs
    if (onChange) {
      onChange(); // This will call fetchData
    }
  };
  const handleNotificationUpdate = () => {
    // Some logic here
    handleSomeChange(); // Call when a change happens
  };

  const updateNotification = (notificationID) => {
    axios
      .post(
        `${process.env.REACT_APP_NOTIFY_URL}/update-notification/by/${notificationID}/${user?.data?.user?.userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res, "UPDATE SUCCESSFULLY");
        onChange(); 
      })
      .catch((error) => {
        if (error.response) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 404:
              console.log("Resource not found (404)");
              break;
            case 500:
              console.log("Internal server error (500)");
              break;
            case 504:
              console.log("Gateway timeout (504)");
              break;
            default:
              console.log(`Error: ${statusCode}`);
          }
        } else if (error.request) {
          console.log("No response received");
        } else {
          console.log("Error", error.message);
        }
      });
  };

  const [activeTab, setActiveTab] = useState("unread");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const markAllAsRead = () => {
    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      isRead: true,
    }));
    onClearAll(updatedNotifications);
    onChange(); // Call onChange after marking all as read
  };

  const filteredNotifications = notifications.filter((notification) =>
    activeTab === "unread" ? !notification.isRead : true
  );

  return (
    <div
      className="card notification-list rounded-3"
      style={{ width: "400px" }}
    >
      <div className="header">
        <Typography variant="h6">Notifications</Typography>

        <div className="header-icons">
          <IconButton>{/* <NotificationsIcon /> */}</IconButton>
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>

      <div className="tabs">
        <Typography
          variant="button"
          style={{ cursor: "pointer" }}
          className={activeTab === "unread" ? "active" : ""}
          onClick={() => handleTabChange("unread")}
        >
          {/* Unread ({notifications.filter((n) => !n.isRead).length})        */}
          Unread
        </Typography>
        <Typography
          variant="button"
          style={{ cursor: "pointer" }}
          className={activeTab === "all" ? "active" : ""}
          onClick={() => handleTabChange("all")}
        >
          All ({notificationCountAll})
        </Typography>
        <button className="mark-all-read" onClick={markAllAsRead}>
          Mark all as read
        </button>
      </div>
      <ul className="list-group list-group-flush">
        {filteredNotifications.map((notification, index) => (
          <Notification
            key={index}
            {...notification}
            updateNotification={updateNotification}
          />
        ))}
      </ul>
      <div
        className="card-footer text-body p-2"
        style={{
          backgroundColor: " #e2e5ec",
          position: "sticky",
          bottom: " 0",
          zIndex: "1",
        }}
      >
        <a href="/all-notification">Show all Notifications</a>
      </div>
    </div>
  );
};

export default NotificationListMain;
