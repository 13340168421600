import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToaster } from "../../feature/slices/toastSlice";
import CustomToaster from "./CustomToaster";
import "./common.css";

function Alerts({
  alertIcon,
  alertTitle,
  alertNumber,
  routeUrl,
  apiUrl,
  controlApi = true,
  ...otherAlertData
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClickAlert = () => {
    if (controlApi) {
      navigate(routeUrl, {
        state: {
          titleState: alertTitle,
          alertNumber: alertNumber,
          callUrl: apiUrl,
        },
      });
    } else {
      dispatch(
        showToaster({
          title: "",
          message: "Not Avalable at this movement",
          color: "warning",
        })
      );
    }
  };

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  let onClickHandler = null;
  if (alertNumber > 0) {
    onClickHandler = handleClickAlert;
  }

  return (
    <>
      <div
        className="p-3 border shadow-sm rounded  d-flex flex-column align-items-start gap-2 pe-auto alerthover  shadow p-3"
        onClick={onClickHandler}
      >
        <img src={alertIcon} alt="AlertIcon" width={35} />
        <p className="p-0 m-0 fs-15 fw-bolder">{alertTitle}</p>
        <p className="p-0 m-0 text-danger fw-semibold">{alertNumber} {alertTitle.includes("Violations") === true ? "Violations" : "Endpoints" }</p>
      </div>
    </>
  );
}

export default Alerts;
