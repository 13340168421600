import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
// import Header from "../../layouts/Header";
import { dp3 } from "../../data/DashboardData";
import ServerityOkIcon from "../../assets/img/ServerityIcon/allOk.png";
import ServerityGreenIcon from "../../assets/img/ServerityIcon/greenOk.png";
import ServerityRedIcon from "../../assets/img/ServerityIcon/redOk.png";
import ServerityYellowIcon from "../../assets/img/ServerityIcon/yellowOk.png";
import windowOkIcon from "../../assets/img/ServerityIcon/blueRec.png";
import windowGreenIcon from "../../assets/img/ServerityIcon/greenRec.png";
import windowRedIcon from "../../assets/img/ServerityIcon/redRec.png";
import windowOYellowIcon from "../../assets/img/ServerityIcon/yellowRec.png";
import DetailCard from "../../components/common/DashBoard/detailCard";
import windowIcon from "../../assets/img/DashIcon/windowIcon.png";

import ReactApexChart from "react-apexcharts";
import Alerts from "../../components/common/Alerts";
import avBlueIcon from "../../assets/img/Alerts/shieldBlue.png";
import avRedIcon from "../../assets/img/Alerts/shieldRed.png";
import avBlackIcon from "../../assets/img/Alerts/shieldBlack.png";

import edrIcon from "../../assets/img/Alerts//edrIcon.png";

import napIcon from "../../assets/img/Alerts/NapIcon.png";

import malwareIcon from "../../assets/img/Alerts/malwareIcon.png";

import fireIcon from "../../assets/img/Alerts/fireIcon.png";

import usbIcon from "../../assets/img/Alerts/usbIcon.png";

import closeAlertIcon from "../../assets/img/Alerts/closeAlertIcon.png";
import IssueAlert from "../../components/common/IssueAlert";
import WindowClients from "../../components/common/clients/windowClients/WIndowClients";
import useFetchWindowSummaryData from "../../hooks/windowsHooks/useFetchWindowSummary";
import PageLoader from "../../components/common/Loader/PageLoader";
import {
  ANTIVIRUS_NOT_INSTALLED_ENDPOINT,
  ANTI_VIRUS_MALFUNCTION_ENDPOINT,
  ANTI_VIRUS_NOT_UPDATED_ENDPOINT,
  BIOS_BATTERY,
  BLACKLIST_PROGRAME,
  BLUETOOTH_ENABLED,
  CD_DRIVE_ENABLED,
  EDR_MALFUNCTION_ENDPOINT,
  EDR_NOT_INSTALLED_ENDPOINT,
  FIRWALL_OFF,
  IS_HIGH_CPU_USAGE,
  IS_HIGH_RAM_USAGE,
  MALWARE_DETECTION_ENDPOINT,
  MULTIPLE_PASSWORD,
  NAC_NAP,
  OLD_PASSWORD,
  OPEN_NETWORK_CONNECTION,
  OS_PATCHED,
  OUT_OF_DOMAIN,
  RDP_ENABLED,
  SCANNER_INSTALLED,
  SHARED_FOLDER,
  UNKNOWN_SERVICES,
  USB_VOILATION,
  WIFI_ENABLED,
  WINDOW_NOT_ACTIVATED,
  SAFE_WINDOW_CLIENT,
  UNRESPONSIVE_WINDOWS,
  UNSAFE_WINDOW_CLIENTS,
  WINDOW_ALL_CLIINT,
} from "../../api/endPoints/windowAlertEndPoint";
import CustomToaster from "../../components/common/CustomToaster";

export default function WindowDetail() {
  const dataPolar = {
    datasets: [
      {
        data: [50, 25, 60, 80, 20],
        backgroundColor: [
          "#506fd9",
          "#85b6ff",
          "#d3dbf6",
          "#6e7985",
          "#dbdde1",
        ],
      },
    ],
  };

  const optionPolar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  const seriesOne = [
    {
      name: "series1",
      data: dp3,
    },
    {
      name: "series2",
      data: dp3,
    },
  ];

  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      type: "area",
      toolbar: { show: false },
      stacked: true,
    },
    colors: ["#4f6fd9", "#506fd9"],
    grid: {
      borderColor: "rgba(72,94,144, 0.08)",
      padding: { top: -20 },
      yaxis: {
        lines: { show: false },
      },
    },
    stroke: {
      curve: "straight",
      width: [2, 0],
    },
    xaxis: {
      type: "numeric",
      tickAmount: 13,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "11px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      },
    },
    dataLabels: { enabled: false },
    legend: { show: false },
    tooltip: { enabled: false },
  };

  const seriesEight = [
    {
      type: "column",
      data: [
        [0, 2],
        [1, 3],
        [2, 5],
        [3, 7],
        [4, 12],
        [5, 17],
        [6, 10],
        [7, 14],
        [8, 15],
        [9, 12],
        [10, 8],
      ],
    },
    {
      type: "column",
      data: [
        [0, 12],
        [1, 7],
        [2, 4],
        [3, 5],
        [4, 8],
        [5, 10],
        [6, 4],
        [7, 7],
        [8, 11],
        [9, 9],
        [10, 5],
      ],
    },
  ];

  const optionEight = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      type: "line",
      toolbar: { show: false },
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: -20,
        left: 5,
        bottom: -15,
      },
    },
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    colors: ["#506fd9", "#e5e9f2"],
    plotOptions: {
      bar: {
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    stroke: {
      curve: "straight",
      lineCap: "square",
      width: 0,
    },
    yaxis: {
      min: 0,
      max: 30,
      tickAmount: 5,
    },
    xaxis: {
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "10px",
          fontWeight: "500",
        },
      },
    },
    tooltip: { enabled: false },
    legend: { show: false },
  };

  const optionDonut = {
    chart: {
      parentHeightOffset: 0,
    },
    colors: ["#506fd9", "#85b6ff", "#51596d"],
    dataLabels: { enabled: false },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    legend: { show: false },
  };

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const { loading, windowSummary, windowAlertSummary,totalDashboard } =
    useFetchWindowSummaryData();

  console.log(totalDashboard, "WINDOWS_SUMMARY totalDashboard");

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  var TotalWindowsEndpoint = (windowSummary?.totalClients / totalDashboard) * 100;

  console.log(TotalWindowsEndpoint,'TotalWindowsEndpoint')

var totalWindowCount = windowSummary?.totalSafeClients + windowSummary?.totalUnsafeClients + windowSummary?.totalUnresponsiveClients;

// Percentage calculations
var windows_Safe_Per = (windowSummary?.totalSafeClients / windowSummary?.totalClients) * 100;
var windows_UnSafe_Per = (windowSummary?.totalUnsafeClients / windowSummary?.totalClients) * 100;
var windows_UnResponsive_Per = (windowSummary?.totalUnresponsiveClients / windowSummary?.totalClients) * 100;

// Rounding the percentages
windows_Safe_Per = Math.round(windows_Safe_Per);
windows_UnSafe_Per = Math.round(windows_UnSafe_Per);
windows_UnResponsive_Per = Math.round(windows_UnResponsive_Per);
TotalWindowsEndpoint  = Math.round(TotalWindowsEndpoint);

console.log(TotalWindowsEndpoint,'TotalWindowsEndpoint')



  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link to="/window-dash">
              <img src={windowIcon} alt="Window-logo" width={25} height={25} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-18 fw-bolder p-0 m-0 ">Windows DashBoard</p>
          </span>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant=""
              className="btn-white d-flex align-items-center gap-2"
            >
              <i className="ri-share-line fs-18 lh-1"></i>Share
            </Button>
            <Button
              variant=""
              className="btn-white d-flex align-items-center gap-2"
            >
              <i className="ri-printer-line fs-18 lh-1"></i>Print
            </Button>
          </div>
        </div>
        {!loading ? (
          <>
            <Row className="g-3">
              <Col xl="3">
                <DetailCard
                  severityName={"Total Windows Endpoints"}
                  severityIcon={ServerityOkIcon}
                  severityNumber={windowSummary?.totalClients || 0}
                  cardIcon={windowOkIcon}
                  severityColor={"primary"}
                  apiUrl={WINDOW_ALL_CLIINT}
                  routeUrl={"/serverity-Alerts"}
                  footerTitle={"endpoints are Windows"}
                  riskAt={TotalWindowsEndpoint ? TotalWindowsEndpoint :'0'}
                  
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Safe Windows Endpoints"}
                  severityIcon={ServerityGreenIcon}
                  severityNumber={
                    windowSummary?.totalSafeClients || 0
                  }
                  cardIcon={windowGreenIcon}
                  severityColor={"success"}
                  apiUrl={SAFE_WINDOW_CLIENT}
                  routeUrl={"/serverity-Alerts"}
                  riskAt = {windows_Safe_Per ? windows_Safe_Per :'0' }
                  footerTitle={"endpoints secure"}
                  
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Unsafe Windows Endpoints"}
                  severityIcon={ServerityRedIcon}
                  severityNumber={
                    windowSummary?.totalUnsafeClients || 0
                  }
                  cardIcon={windowRedIcon}
                  severityColor={"danger"}
                  apiUrl={UNSAFE_WINDOW_CLIENTS}
                  routeUrl={"/serverity-Alerts"}
                  riskAt={windows_UnSafe_Per ? windows_UnSafe_Per : '0'}
                  footerTitle={"endpoints at risk"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Unresponsive Windows Endpoints"}
                  severityIcon={ServerityYellowIcon}
                  severityNumber={
                    windowSummary?.totalUnresponsiveClients || 0
                  }
                  cardIcon={windowOYellowIcon}
                  severityColor={"warning"}
                  apiUrl={UNRESPONSIVE_WINDOWS}
                  routeUrl={"/serverity-Alerts"}
                  riskAt={windows_UnResponsive_Per ? windows_UnResponsive_Per :'0'}
                  footerTitle={"endpoints unresponsive"}
                />
              </Col>
            </Row>
            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Alerts Found</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-center">
                      <Col xl="3">
                        <Alerts
                          alertIcon={avBlueIcon}
                          alertTitle={"AV Not Installed"}
                          alertNumber={windowAlertSummary?.avNotInstalled || 0}
                          apiUrl={ANTIVIRUS_NOT_INSTALLED_ENDPOINT}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={avRedIcon}
                          alertTitle={"AV Malfunction"}
                          alertNumber={windowAlertSummary?.avMalfunction || 0}
                          apiUrl={ANTI_VIRUS_MALFUNCTION_ENDPOINT}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={avBlackIcon}
                          alertTitle={"AV Not Updated"}
                          alertNumber={windowAlertSummary?.avNotUpdated || 0}
                          apiUrl={ANTI_VIRUS_NOT_UPDATED_ENDPOINT}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={edrIcon}
                          alertTitle={"EDR Not Installed"}
                          alertNumber={windowAlertSummary?.edrNotInstalled || 0}
                          apiUrl={EDR_NOT_INSTALLED_ENDPOINT}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={edrIcon}
                          alertTitle={"EDR Malfunction"}
                          alertNumber={windowAlertSummary?.edrMalfunction || 0}
                          apiUrl={EDR_MALFUNCTION_ENDPOINT}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"OS Not Patched"}
                          alertNumber={windowAlertSummary?.osNotPatched || 0}
                          apiUrl={OS_PATCHED}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={napIcon}
                          alertTitle={"NAP/NAC Disabled"}
                          alertNumber={windowAlertSummary?.napnacDisabled || 0}
                          apiUrl={NAC_NAP}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"Windows Not Activated"}
                          alertNumber={windowAlertSummary?.windowsNotActivated || 0}
                          apiUrl={WINDOW_NOT_ACTIVATED}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={malwareIcon}
                          alertTitle={"Malware detected"}
                          alertNumber={windowAlertSummary?.malawareDetected || 0}
                          apiUrl={MALWARE_DETECTION_ENDPOINT}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={fireIcon}
                          alertTitle={"Firewall OFF"}
                          alertNumber={windowAlertSummary?.firwallOff || 0}
                          apiUrl={FIRWALL_OFF}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={usbIcon}
                          alertTitle={"USB Violations"}
                          alertNumber={windowAlertSummary?.usbViolation || 0}
                          apiUrl={USB_VOILATION}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={closeAlertIcon}
                          alertTitle={"Blacklisted Software"}
                          alertNumber={windowAlertSummary?.blacklistedSoftware || 0}
                          apiUrl={BLACKLIST_PROGRAME}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={true}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              {/* <Col xl="3">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Issues Analysis</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href="">
                        <i className="ri-refresh-line"></i>
                      </Nav.Link>
                      <Nav.Link href="">
                        <i className="ri-more-2-fill"></i>
                      </Nav.Link>
                    </Nav>
                  </Card.Header>
                  <Card.Body className="d-flex flex-column align-items-center justify-content-around">
                    <div className="position-relative d-flex justify-content-center">
                      <ReactApexChart
                        series={[windowSummary?.totalSafeClients, windowSummary?.totalUnsafeClients, windowSummary?.totalUnresponsiveClients]}
                        options={optionDonut}
                        height="auto"
                        type="donut"
                        className="apex-donut-two"
                      />

                      <div className="finance-donut-value">
                        <h1>{windowSummary?.totalClients}</h1>
                      </div>
                    </div>
                    <Row>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#506FD9"}
                          issueName={"Critical Alerts"}
                          issueNumber={150}
                          issuePerc={50}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#85B6FF"}
                          issueName={"Critical Alerts"}
                          issueNumber={50}
                          issuePerc={10}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#50586D"}
                          issueName={"Critical Alerts"}
                          issueNumber={40}
                          issuePerc={8}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#B9BDC9"}
                          issueName={"Critical Alerts"}
                          issueNumber={60}
                          issuePerc={12}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>

            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">High Alerts Found</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"Unknown Services"}
                          alertNumber={windowAlertSummary?.unknownServices || 0}
                          apiUrl={UNKNOWN_SERVICES}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"Old Admin Passwords"}
                          alertNumber={windowAlertSummary?.oldAdminPasswords || 0}
                          apiUrl={OLD_PASSWORD}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"Multiple Admins"}
                          alertNumber={windowAlertSummary?.mulitpleAdmins || 0}
                          apiUrl={MULTIPLE_PASSWORD}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>
                      {/* 
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"UAC Disabled"}
                          alertNumber={windowAlertSummary?.uacDisabledCount}
                          apiUrl={ua}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col> */}
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"Shared Folders Detected"}
                          alertNumber={windowAlertSummary?.sharedFolder || 0}
                          apiUrl={SHARED_FOLDER}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"Wifi Enabled"}
                          alertNumber={windowAlertSummary?.wifiPcCount || 0}
                          apiUrl={WIFI_ENABLED}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"Bluetooth Enabled"}
                          alertNumber={windowAlertSummary?.blueToothCount || 0}
                          apiUrl={BLUETOOTH_ENABLED}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"Out of Domain"}
                          alertNumber={windowAlertSummary?.outOfDomain || 0}
                          apiUrl={OUT_OF_DOMAIN}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"RDP Enabled"}
                          alertNumber={windowAlertSummary?.rdpEnabled || 0}
                          apiUrl={RDP_ENABLED}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>
                      {/* <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"Software Center Faulty"}
                          alertNumber={windowAlertSummary?.so}
                          apiUrl={MALWARE_DETECTION_ENDPOINT}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col> */}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Minor Alerts Found</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"CD Drive Enabled"}
                          alertNumber={windowAlertSummary?.cdDriveEnabled || 0}
                          apiUrl={CD_DRIVE_ENABLED}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"Bios Battery Health"}
                          alertNumber={windowAlertSummary?.biosBatteryHealth || 0}
                          apiUrl={BIOS_BATTERY}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"Scanner Installed"}
                          alertNumber={windowAlertSummary?.scannerInstalled || 0}
                          apiUrl={SCANNER_INSTALLED}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"High RAM Usages"}
                          alertNumber={windowAlertSummary?.highRamUsage || 0}
                          apiUrl={IS_HIGH_RAM_USAGE}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"High CPU Usages"}
                          alertNumber={windowAlertSummary?.highCpuUsage || 0}
                          apiUrl={IS_HIGH_CPU_USAGE}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"OS Formatted"}
                          alertNumber={0}
                          apiUrl={OS_PATCHED}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={false}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"Hardware Changed"}
                          alertNumber={0}
                          apiUrl={NAC_NAP}
                          routeUrl={"/serverity-Alerts"}
                          controlApi={false}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"Open Network Connection"}
                          alertNumber={
                            windowAlertSummary?.openNetworkConnection || 0
                          }
                          apiUrl={OPEN_NETWORK_CONNECTION}
                          routeUrl={"/serverity-Alerts"}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <CustomToaster />

            <Footer />
          </>
        ) : (
          <PageLoader />
        )}
      </div>
    </React.Fragment>
  );
}
