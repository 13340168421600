import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
// import Header from "../../layouts/Header";
import { dp3 } from "../../data/DashboardData";
// import DashCard from "../../components/common/DashBoard/DashCard";
import windowIcon from "../../assets/img/DashIcon/windowIcon.png";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import androidIcon from "../../assets/img/DashIcon/newAndroidIcon.png";
// import PageLoader from "../../components/common/Loader/PageLoader";

// import ServerityOkIcon from "../assets/img/ServerityIcon/allOk.png";
// import ServerityGreenIcon from "../assets/img/ServerityIcon/greenOk.png";
// import ServerityRedIcon from "../assets/img/ServerityIcon/redOk.png";
// import ServerityYellowIcon from "../assets/img/ServerityIcon/yellowOk.png";
// import windowOkIcon from "../assets/img/ServerityIcon/blueRec.png";
// import windowGreenIcon from "../assets/img/ServerityIcon/greenRec.png";
// import windowRedIcon from "../assets/img/ServerityIcon/redRec.png";
// import windowOYellowIcon from "../assets/img/ServerityIcon/yellowRec.png";
// import DetailCard from "../../components/common/DashBoard/detailCard";
import { RWebShare } from "react-web-share";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactApexChart from "react-apexcharts";
import Alerts from "../../components/common/Alerts";
import avBlueIcon from "../../assets/img/Alerts/shieldBlue.png";
import avRedIcon from "../../assets/img/Alerts/shieldRed.png";
import avBlackIcon from "../../assets/img/Alerts/shieldBlack.png";

import edrIcon from "../../assets/img/Alerts//edrIcon.png";

import napIcon from "../../assets/img/Alerts/NapIcon.png";

import malwareIcon from "../../assets/img/Alerts/malwareIcon.png";

import fireIcon from "../../assets/img/Alerts/fireIcon.png";

import usbIcon from "../../assets/img/Alerts/usbIcon.png";

import closeAlertIcon from "../../assets/img/Alerts/closeAlertIcon.png";
import IssueAlert from "../../components/common/IssueAlert";

import PageLoader from "../../components/common/Loader/PageLoader";
import {
  ANTIVIRUS_NOT_INSTALLED_ENDPOINT,
  ANTI_VIRUS_MALFUNCTION_ENDPOINT,
  ANTI_VIRUS_NOT_UPDATED_ENDPOINT,
  EDR_MALFUNCTION_ENDPOINT,
  EDR_NOT_INSTALLED_ENDPOINT,
  FIRWALL_OFF,
  MALWARE_DETECTION_ENDPOINT,
  NAC_NAP,
  OS_PATCHED,
  USB_VOILATION,
  WINDOW_NOT_ACTIVATED,
} from "../../api/endPoints/windowAlertEndPoint";
import {
  LINUX_BLACKLIST,
  LINUX_FIREWALL_OFF,
  LINUX_NIC,
  LINUX_NOT_ACTIVATED,
  LINUX_OS_NOT_PATCH,
} from "../../api/endPoints/linuxAlertEndPoint";
import AndroidClients from "../../components/common/clients/androidClients/AndroidClients";
import useFetchAndroidSummary from "../../hooks/AndroidHooks/useFetchAndroidSummary";
import {
  BANNED_APPS,
  BLUETOOTH_ENABLED,
  DANGEROUS_APPS,
  DEVELOPER_STATUS,
  DEVICE_LOCK_STATUS,
  EMULATOR_DETECTED,
  KEYBOARD_FOUND,
  OLD_PATCH,
  OPEN_NETWORK_CONNECTION,
  ROOTED_DEVICE,
  SCREEN_TIME,
  SE_LINUX_STATUS,
  TIMEOUT_STATUS,
  UNTRUSTED_APP_SOURCE,
  USB_DEBUGGING_STATUS,
  USB_LIST_DATA,
  USB_VOLIATION,
} from "../../api/endPoints/anrdroidEndPoints";

export default function AndroidDetail() {
  console.log("LINUX DASHBOARD");

  const dataPolar = {
    datasets: [
      {
        data: [50, 25, 60, 80, 20],
        backgroundColor: [
          "#506fd9",
          "#85b6ff",
          "#d3dbf6",
          "#6e7985",
          "#dbdde1",
        ],
      },
    ],
  };

  const optionPolar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  const seriesOne = [
    {
      name: "series1",
      data: dp3,
    },
    {
      name: "series2",
      data: dp3,
    },
  ];

  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      type: "area",
      toolbar: { show: false },
      stacked: true,
    },
    colors: ["#4f6fd9", "#506fd9"],
    grid: {
      borderColor: "rgba(72,94,144, 0.08)",
      padding: { top: -20 },
      yaxis: {
        lines: { show: false },
      },
    },
    stroke: {
      curve: "straight",
      width: [2, 0],
    },
    xaxis: {
      type: "numeric",
      tickAmount: 13,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "11px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      },
    },
    dataLabels: { enabled: false },
    legend: { show: false },
    tooltip: { enabled: false },
  };

  const seriesEight = [
    {
      type: "column",
      data: [
        [0, 2],
        [1, 3],
        [2, 5],
        [3, 7],
        [4, 12],
        [5, 17],
        [6, 10],
        [7, 14],
        [8, 15],
        [9, 12],
        [10, 8],
      ],
    },
    {
      type: "column",
      data: [
        [0, 12],
        [1, 7],
        [2, 4],
        [3, 5],
        [4, 8],
        [5, 10],
        [6, 4],
        [7, 7],
        [8, 11],
        [9, 9],
        [10, 5],
      ],
    },
  ];

  const optionEight = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      type: "line",
      toolbar: { show: false },
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: -20,
        left: 5,
        bottom: -15,
      },
    },
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    colors: ["#506fd9", "#e5e9f2"],
    plotOptions: {
      bar: {
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    stroke: {
      curve: "straight",
      lineCap: "square",
      width: 0,
    },
    yaxis: {
      min: 0,
      max: 30,
      tickAmount: 5,
    },
    xaxis: {
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "10px",
          fontWeight: "500",
        },
      },
    },
    tooltip: { enabled: false },
    legend: { show: false },
  };

  const optionDonut = {
    chart: {
      parentHeightOffset: 0,
    },
    colors: ["#506fd9", "#85b6ff", "#51596d", "#eff1f5"],
    dataLabels: { enabled: false },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    legend: { show: false },
  };

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const { loading, androidSummary, androidAlertSummary,totalDashboard } =
    useFetchAndroidSummary();

  

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  console.log(androidAlertSummary, "SUMMARYANRDOID");
  // Api Call

  // useEffect(() => {
  //   // Request interceptor
  //   const requestInterceptor = axios.interceptors.request.use(
  //     (config) => {
  //       // Modify the request config, e.g., set loading to true
  //       setLoading(true);
  //       return config;
  //     },
  //     (error) => {
  //       // Handle request error
  //       setLoading(false);
  //       return Promise.reject(error);
  //     }
  //   );

  //   // Response interceptor
  //   const responseInterceptor = axios.interceptors.response.use(
  //     (response) => {
  //       // Modify the response data, e.g., set loading to false
  //       setLoading(false);
  //       return response;
  //     },
  //     (error) => {
  //       // Handle response error
  //       setLoading(false);
  //       return Promise.reject(error);
  //     }
  //   );
  //   axios
  //     .get(process.env.REACT_APP_SUMMARY_URL)
  //     .then((res) => {
  //       // setSummoryCount({
  //       //   windowCount: res?.data?.windows.windowsPCcount ,
  //       //   linuxCount: res?.data?.android.androidDevicesCount,
  //       //   androidCount: res?.data?.linux.linuxPCCount ,
  //       // });
  //       const {
  //         SafeWindowsClients,
  //         UnsafeWindowsClients,
  //         windowsPCcount,
  //         getInactiveWindowsCount,
  //         firewallOffPCCount,
  //         antivirusNotInstalledCount,
  //         getAntivirusMalfunctionCount,
  //         AntivirusNotUpdatedCount,
  //         EDRNotInstalledCount,
  //         EDRMalfunctionCount,
  //         getNotPatchedOSCount,
  //         nacDisabledCount,
  //         MalwareDetectionCount,
  //         totalUsbInsertionsCount,
  //         blacklistedProgramsCount,
  //       } = res?.data?.windows;
  //       setWindowSummary({
  //         windowClients: windowsPCcount,
  //         safeWindowClients: SafeWindowsClients,
  //         unsafeWindowClients: UnsafeWindowsClients,
  //         unResponsiveWindowClients: getInactiveWindowsCount,
  //       });

  //       setWindowAlertSummary({
  //         avNotInstalled: antivirusNotInstalledCount,
  //         avMalfunction: getAntivirusMalfunctionCount,
  //         avNotUpdated: AntivirusNotUpdatedCount,
  //         edrNotInstalled: EDRNotInstalledCount,
  //         edrMalfunction: EDRMalfunctionCount,
  //         osNotPatched: getNotPatchedOSCount,
  //         napnacDisabled: nacDisabledCount,
  //         windowsNotActivated: getInactiveWindowsCount,
  //         malawareDetected: MalwareDetectionCount,
  //         firwallOff: firewallOffPCCount,
  //         usbViolation: totalUsbInsertionsCount,
  //         blacklistedSoftware: blacklistedProgramsCount,
  //       });
  //     })
  //     .catch((error) => console.log("error", error));

  //   // Clean up the interceptors when the component unmounts
  //   return () => {
  //     axios.interceptors.request.eject(requestInterceptor);
  //     axios.interceptors.response.eject(responseInterceptor);
  //   };
  // }, [windowSummary, windowAlertSummary]);

  // API CALL END
  const generatePdf = () => {
    const input = document.getElementById("pdfContent");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("android-detail.pdf");
    });
  };


console.log(androidAlertSummary,'androidAlertSummary .....................')
  
  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link to="#">
              <img src={androidIcon} alt="Window-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-18 fw-bolder p-0 m-0 ">Android DashBoard</p>
          </span>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            {/* <RWebShare
              data={{
                text: "Check out this Android detail PDF",
                      file: "android-detail.pdf",
              }}
              // onClick={() => console.log("shared successfully!")}
            >
              <Button
                variant=""
                className="btn-white d-flex align-items-center gap-2"
              >
                <i className="ri-share-line fs-18 lh-1"></i>Share
              </Button>
            </RWebShare> */}
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={generatePdf}
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate
              <span className="d-none d-sm-inline"> Report</span>
            </Button>
            {/* <Button
              variant=""
              className="btn-white d-flex align-items-center gap-2"
              onClick={generatePdf}
            >
              <i className="ri-printer-line fs-18 lh-1"></i>Print
            </Button> */}
          
          </div>
        </div>
        {!loading ? (
          <>
          <div id="pdfContent">
            <AndroidClients data={androidSummary} totalDashboard={totalDashboard} />

            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Alerts Found</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start align-items-center">
                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"Old Security Patch"}
                          alertNumber={androidAlertSummary?.oldSecurityPatch}
                          apiUrl={OLD_PATCH}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"SELinux Status"}
                          alertNumber={androidAlertSummary?.seLinuxStatus}
                          apiUrl={SE_LINUX_STATUS}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"Rooted / Jailbreaked"}
                          alertNumber={
                            androidAlertSummary?.deviceRootedJailbreaked
                          }
                          apiUrl={ROOTED_DEVICE}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"Developer Mode Status"}
                          alertNumber={androidAlertSummary?.developerModeStatus}
                          apiUrl={DEVELOPER_STATUS}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"USB Debugging Status"}
                          alertNumber={androidAlertSummary?.usbDebuggingStatus}
                          apiUrl={USB_DEBUGGING_STATUS}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"Device Lock Status"}
                          alertNumber={androidAlertSummary?.deviceLockStatus}
                          apiUrl={DEVICE_LOCK_STATUS}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"Open Network Connection"}
                          alertNumber={
                            androidAlertSummary?.openNetworkConnection
                          }
                          apiUrl={OPEN_NETWORK_CONNECTION}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"Untrusted App Source"}
                          alertNumber={androidAlertSummary?.untrustedAppSource}
                          apiUrl={UNTRUSTED_APP_SOURCE}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"Banned Apps"}
                          alertNumber={androidAlertSummary?.bannedApps}
                          apiUrl={BANNED_APPS}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"Dangerous Apps (RDP)"}
                          alertNumber={androidAlertSummary?.dangerousAppsRDP}
                          apiUrl={DANGEROUS_APPS}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"Bluetooth Enabled"}
                          alertNumber={androidAlertSummary?.bluetoothEnabled}
                          apiUrl={BLUETOOTH_ENABLED}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"Screen Timeout > 60 sec"}
                          alertNumber={
                            androidAlertSummary?.screenTimeoutGreaterThan60Sec
                          }
                          apiUrl={SCREEN_TIME}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"Timezone Status"}
                          alertNumber={androidAlertSummary?.timeZoneStatus}
                          apiUrl={TIMEOUT_STATUS}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"3rd-party Keyboard Found"}
                          alertNumber={
                            androidAlertSummary?.thirdPartyKeyboardFound
                          }
                          apiUrl={KEYBOARD_FOUND}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"USB Violations"}
                          alertNumber={androidAlertSummary?.usbVoliation}
                          // apiUrl={USB_LIST_DATA}
                          apiUrl={'usbinfo/device-with-unwhitelisted-usb-insertion'}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={androidIcon}
                          alertTitle={"Emulator Detected"}
                          alertNumber={androidAlertSummary?.emulatorDetected}
                          apiUrl={EMULATOR_DETECTED}
                          routeUrl={"/android-serverity"}
                          controlApi={true}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              {/* <Col xl="4">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Issues Analysis</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href="">
                        <i className="ri-refresh-line"></i>
                      </Nav.Link>
                      <Nav.Link href="">
                        <i className="ri-more-2-fill"></i>
                      </Nav.Link>
                    </Nav>
                  </Card.Header>
                  <Card.Body className="d-flex flex-column align-items-center justify-content-around">
                    <div className="position-relative d-flex justify-content-center">
                      <ReactApexChart
                        series={[35, 20, 20, 15]}
                        options={optionDonut}
                        height="auto"
                        type="donut"
                        className="apex-donut-two"
                      />

                      <div className="finance-donut-value">
                        <h1>1.8B</h1>
                        <p>86.24%</p>
                      </div>
                    </div>
                    <Row>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#506FD9"}
                          issueName={"Critical Alerts"}
                          issueNumber={150}
                          issuePerc={50}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#85B6FF"}
                          issueName={"Critical Alerts"}
                          issueNumber={50}
                          issuePerc={10}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#50586D"}
                          issueName={"Critical Alerts"}
                          issueNumber={40}
                          issuePerc={8}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#B9BDC9"}
                          issueName={"Critical Alerts"}
                          issueNumber={60}
                          issuePerc={12}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>
            <Footer />
            </div>
          </>
        ) : (
          <PageLoader />
        )}
      </div>
    </React.Fragment>
  );
}
